import React, { useState } from 'react';

import CellIcon from '@/components/CellIcon';
import { Cell as CellComponent } from '@/components/Table/Cell';
import { useComponentWithProps } from '@/hooks/use_component_with_props';

export const TableRow = (props: {
  isAdmin: boolean;
  searchString: string;
  sharedMap: {
    id: number;
    name: string;
    remote_link: string;
    emails: string[];
    expiration_date?: string;
    last_viewed?: string;
  };
}) => {
  const { isAdmin, searchString, sharedMap } = props;

  const [showBtns, setShowBtns] = useState(false);

  const Cell = useComponentWithProps(CellComponent, { searchString });

  return (
    <tr
      className='source-sans-font fs-14 fuscous-gray-color'
      onMouseEnter={() => isAdmin && setShowBtns(true)}
      onMouseLeave={() => isAdmin && setShowBtns(false)}
    >
      <Cell>{sharedMap.name}</Cell>
      <Cell link={location.href + '/' + sharedMap.remote_link}>{`/${sharedMap.remote_link}`}</Cell>
      <Cell className='max-column'>{sharedMap.emails}</Cell>
      <Cell>{sharedMap.expiration_date}</Cell>
      <Cell>{sharedMap.last_viewed}</Cell>

      <td>
        <div className='d-flex'>
          <a
            className='no-link'
            data-remote='true'
            href={location.href + '/' + sharedMap.id + '/share'}
          >
            <CellIcon show={showBtns} icon='share-square' className='pointer ms-2' />
          </a>

          <a
            className='no-link'
            data-remote='true'
            href={location.href + '/' + sharedMap.id + '/edit'}
          >
            <CellIcon show={showBtns} icon='pencil-alt' className='pointer ms-2' />
          </a>
        </div>
      </td>
    </tr>
  );
};
