import * as React from 'react';
import { useContext } from 'react';

import { StasContext } from '@Deals/context/StasContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ExpandBtn = ({ dealId, isRelatedDeal }: { dealId: number; isRelatedDeal: boolean }) => {
  const { expandedRows, setExpandedRows } = useContext(StasContext);
  const handleRowClick = () => {
    if (setExpandedRows)
      setExpandedRows(
        expandedRows!.includes(dealId)
          ? expandedRows!.filter((id) => id !== dealId)
          : expandedRows!.concat(dealId)
      );
  };
  return (
    <div
      className={
        (isRelatedDeal ? 'd-flex' : 'd-none') + ' h-20p justify-content-center align-items-center'
      }
      onClick={() => handleRowClick()}
    >
      <FontAwesomeIcon
        icon={expandedRows && expandedRows.includes(dealId) ? 'angle-down' : 'angle-right'}
        className='fas-icon pointer'
      />
    </div>
  );
};

export default ExpandBtn;
