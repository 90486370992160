import Tagify from '@yaireo/tagify';

import { UserOption } from '@/containers/DealWindow/typings';

window.initTagifyNotification = async (selector, users?) => {
  const inputsNotification: NodeListOf<HTMLTextAreaElement> = document.querySelectorAll(selector);
  if (inputsNotification.length === 0) return;

  const res = users ?? (await $.get({ url: '/ajax-company-users-list', dataType: 'json' }));
  if (!res) return console.error('Cannot get users');

  Array.from(inputsNotification).map((input) => {
    if ($(input).data('inited') || $(input).hasClass('tagify')) return;
    $(input).data('inited', true);

    const notificationTagify = new Tagify(input, {
      mode: 'mix',
      pattern: /@/,
      enforceWhitelist: true,
      whitelist: res.map((user: UserOption) => ({ value: user.nickname, title: user.id })),
      dropdown: { enabled: 0 },
    });
    // move caret to the end of input/textarea
    notificationTagify.on('change', () => input.focus());
    return notificationTagify;
  });
};

declare global {
  interface Window {
    initTagifyNotification: (selector: JQuery.Selector, users?: Array<UserOption>) => Promise<void>;
  }
}
