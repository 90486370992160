import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';

import { hideContextMenu } from '@/scripts/context_menu';
import { openSavedDealWindow } from '@/scripts/deal';
import { updateDealPosition } from '@/scripts/deal/update_position';
import { CLUSTER_MAX_ZOOM, mapClickCallback, mapClickListener } from '@/scripts/map';

import { createLabel, createPinMarker } from './marker_factory';
import { MapDeal, Markers, PinMarker } from './marker_manager';

const LONG_PRESS_DURATION = 2000;

class PinMarkerManager {
  private clusterer: MarkerClusterer;
  private map: google.maps.Map;
  private cachedPinMarkers: Markers<PinMarker> = new Map();
  private renderedPinMarkers: PinMarker[] = [];
  private removeMarkers: PinMarker[] = [];
  private newMarkers: PinMarker[] = [];
  private labelsVisible = false;
  private longPressTimer?: ReturnType<typeof setTimeout>;

  constructor(map: google.maps.Map) {
    this.map = map;
    const algorithm = new SuperClusterAlgorithm({ minPoints: 2, maxZoom: CLUSTER_MAX_ZOOM });
    this.clusterer = new MarkerClusterer({ map, algorithm });
  }

  render(deals: MapDeal[], ids: Set<number>, zoom: number, prevZoom: number) {
    this.checkLabelsVisiblity(zoom, prevZoom);
    this.prepareMarkers(ids, deals);
    this._render(zoom, prevZoom);
  }

  clear() {
    this.renderedPinMarkers.forEach((memoryMarker) => memoryMarker.setMap(null));
    this.clusterer.clearMarkers();
    this.renderedPinMarkers.length = 0;
  }

  getMarker(dealId: number) {
    return this.cachedPinMarkers.get(dealId);
  }

  changeMarkerType(dealId: number, icon: google.maps.Symbol | google.maps.Icon, iconType: string) {
    const marker = this.cachedPinMarkers.get(dealId);
    if (marker) {
      marker.setIcon(icon);
      marker.iconType = iconType;
    }
  }

  clearMarker(dealId: number) {
    const marker = this.getMarker(dealId);
    if (marker) {
      marker.setMap(null);
      this.clusterer.removeMarker(marker);
    }
  }

  changeMarkerLabel(dealId: number, nickname: string) {
    const marker = this.cachedPinMarkers.get(dealId);
    if (marker) {
      marker.labelText = nickname;
      marker.setLabel(createLabel(nickname));
    }
  }

  removeClusterIdleListener() {
    const clusterer = this.clusterer as unknown as {
      idleListener: google.maps.MapsEventListener;
    };
    google.maps.event.removeListener(clusterer.idleListener);
  }

  private prepareMarkers(ids: Set<number>, deals: MapDeal[]) {
    this.removeMarkers.length = 0;
    this.newMarkers.length = 0;
    const renderedIds: Set<number> = new Set();

    for (let i = this.renderedPinMarkers.length - 1; i >= 0; i--) {
      const m = this.renderedPinMarkers[i];
      if (ids.has(m.dealId)) {
        renderedIds.add(m.dealId);
        this.setLabel(m);
      } else {
        this.removeMarkers.push(m);
        this.renderedPinMarkers.splice(i, 1);
      }
    }

    deals.forEach((deal) => {
      if (!renderedIds.has(deal.id)) {
        const marker = this.cachedPinMarkers.get(deal.id);
        if (marker) {
          this.newMarkers.push(marker);
          this.setLabel(marker);
        } else {
          const marker = createPinMarker(deal);
          this.setLabel(marker);

          marker.markerClickListener = google.maps.event.addListener(marker, 'click', () => {
            openSavedDealWindow({
              id: deal.id,
              lat: parseFloat(deal.latitude),
              lng: parseFloat(deal.longitude),
            });
            hideContextMenu();
          });

          google.maps.event.addListener(marker, 'mousedown', () => {
            clearTimeout(this.longPressTimer!);
            document.body.classList.add('cursor-wait');
            this.longPressTimer = setTimeout(() => {
              document.body.classList.add('cursor-grab');
              document.body.classList.remove('cursor-wait');
              this.startMarkerDrag(marker);
            }, LONG_PRESS_DURATION);
          });

          google.maps.event.addListener(marker, 'mouseup', () => {
            document.body.classList.remove('cursor-wait');
            clearTimeout(this.longPressTimer!);
          });

          this.newMarkers.push(marker);
          this.cachedPinMarkers.set(deal.id, marker);
        }
      }
    });
  }

  private startMarkerDrag = (marker: PinMarker) => {
    let isDraggingPin = true;

    if (marker.markerClickListener) google.maps.event.removeListener(marker.markerClickListener);
    google.maps.event.removeListener(mapClickListener);
    google.maps.event.clearListeners(marker, 'mousedown');

    marker.setClickable(false);

    const moveListener = google.maps.event.addListener(
      this.map,
      'mousemove',
      (event: google.maps.MapMouseEvent) => {
        if (isDraggingPin) {
          marker.setPosition(event.latLng!);
        }
      }
    );

    google.maps.event.addListener(this.map, 'mousedown', () => {
      clearTimeout(this.longPressTimer!);
      this.longPressTimer = setTimeout(() => {
        if (isDraggingPin) {
          isDraggingPin = false;

          //update deal position
          updateDealPosition(marker);

          //remove drag listeners
          google.maps.event.removeListener(moveListener);
          google.maps.event.clearListeners(this.map, 'mousedown');
          google.maps.event.clearListeners(this.map, 'mouseup');

          //return default listeners
          marker.markerClickListener = google.maps.event.addListener(marker, 'click', () => {
            openSavedDealWindow({
              id: marker.dealId,
              lat: marker.getPosition()!.lat(),
              lng: marker.getPosition()!.lng(),
            });
            hideContextMenu();
          });

          google.maps.event.addListener(marker, 'mousedown', () => {
            document.body.classList.add('cursor-wait');
            clearTimeout(this.longPressTimer!);
            this.longPressTimer = setTimeout(() => {
              document.body.classList.add('cursor-grab');
              document.body.classList.remove('cursor-wait');
              this.startMarkerDrag(marker);
            }, LONG_PRESS_DURATION);
          });

          marker.setClickable(true);
          document.body.classList.remove('cursor-grab');
        }
      }, LONG_PRESS_DURATION / 4);
    });

    google.maps.event.addListener(this.map, 'mouseup', () => {
      clearTimeout(this.longPressTimer!);
      if (!isDraggingPin) {
        this.map.addListener('click', mapClickCallback);
      }
    });
  };

  private _render(zoom: number, prevZoom: number) {
    if (zoom > CLUSTER_MAX_ZOOM) {
      if (prevZoom <= CLUSTER_MAX_ZOOM) {
        this.renderedPinMarkers.forEach((m) => m.setMap(this.map));
        this.clusterer.render(); // clears cluster markers because zoom greater than CLUSTER_MAX_ZOOM
      }
      this.newMarkers.forEach((m) => m.setMap(this.map));
      this.removeMarkers.forEach((m) => m.setMap(null));
    } else {
      if (prevZoom >= CLUSTER_MAX_ZOOM + 1) {
        this.clusterer.addMarkers(this.renderedPinMarkers, true);
      }
      this.clusterer.addMarkers(this.newMarkers, true);
      this.clusterer.removeMarkers(this.removeMarkers, true);
      this.clusterer.render();
    }
    this.renderedPinMarkers.push(...this.newMarkers);
  }

  private setLabel(m: PinMarker) {
    if (this.labelsVisible) {
      if (m.labelText && !m.getLabel()) m.setLabel(createLabel(m.labelText));
    } else {
      if (m.labelText && m.getLabel()) m.setLabel(null);
    }
  }

  private checkLabelsVisiblity(zoom: number, prevZoom: number) {
    // closer you are to ground higher zoom
    const LABEL_ZOOM_LEVEL = 17;
    if (zoom !== prevZoom) {
      if (!this.labelsVisible) {
        if (zoom >= LABEL_ZOOM_LEVEL) {
          this.labelsVisible = true;
        }
      } else {
        if (zoom < LABEL_ZOOM_LEVEL) {
          this.labelsVisible = false;
        }
      }
    }
  }
}

export default PinMarkerManager;
