import * as React from 'react';
import { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import IconBtn from '@/components/IconBtn';
import { drawParcelBordersAndBuildings } from '@/scripts/report_all';
import { ParcelService } from '@/services/parcel_service';
import { Coordinates } from '@/typings';
import { expanderStyle } from '@/utils';

import { Parcel } from './typings';

const Parcels = (props: {
  deal: { id: number } & Coordinates;
  children?: (
    parcels: Parcel[],
    setParcels: (parcels: Parcel[]) => void,
    deal: { id: number } & Coordinates,
    deleteParcel: (id: number) => Promise<void>
  ) => React.ReactChild;
}) => {
  const { children, deal } = props;
  const [expanded, setExpanded] = useState(false);
  const [parcels, setParcels] = useState<Parcel[]>([]);

  useEffect(() => {
    getParcels();
  }, []);

  const getParcels = async () => {
    const res = await ParcelService.index(deal.id);
    if (res.ok) setParcels(res.data.parcels);
    else console.error('getParcels:', res.error);
  };

  const deleteParcel = async (id: number) => {
    const res = await ParcelService.destroy(id);
    if (res.ok) {
      const newParcels = parcels.filter((parcel) => id !== parcel.id);

      if (children)
        drawParcelBordersAndBuildings(
          [...newParcels, deal].map((parcel) => new google.maps.LatLng(parcel))
        );

      setParcels(newParcels);
    } else console.error('Cannot delete parcel:', res.error);
  };

  return (
    <div>
      <div>
        <div className='d-flex align-items-center pointer' onClick={() => setExpanded(!expanded)}>
          <FontAwesomeIcon icon={expanded ? ['far', 'minus'] : ['far', 'plus']} className='me-1' />
          <p className='m-0'>
            {parcels.length} {parcels.length === 1 ? 'parcel' : 'parcels'}
          </p>
        </div>
        <div style={expanderStyle(expanded)}>
          {parcels.map((parcel) => (
            <div key={parcel.id}>
              <p className='details-tags'>
                {parcel.lat.toFixed(6)}, {parcel.lng.toFixed(6)}
              </p>
              <IconBtn icon='trash-alt' style='danger' onClick={() => deleteParcel(parcel.id)} />
            </div>
          ))}
        </div>
      </div>
      {children && children(parcels, setParcels, deal, deleteParcel)}
    </div>
  );
};

export default Parcels;
