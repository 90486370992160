import * as React from 'react';
import { useContext } from 'react';

import { StasContext } from '@Deals/context/StasContext';
import { StaDeal } from '@Deals/typings';

import DealRow from './DealRow';

const TableBody = () => {
  const { staState } = useContext(StasContext);
  const sta = staState.sta;

  if (!sta) return null;
  if (sta.length === 0)
    return (
      <tbody>
        <tr>
          <td colSpan={20}>
            <div className='d-flex justify-content-center'>
              <p className='fs-24 no-result source-sans-semi-bold-font'>No Results</p>
            </div>
          </td>
        </tr>
      </tbody>
    );

  return (
    <tbody>
      {sta.map((deal: StaDeal) => (
        <DealRow key={deal.id} deal={deal} />
      ))}
    </tbody>
  );
};

export default TableBody;
