import * as React from 'react';
import { useEffect, useState } from 'react';

import Form from 'react-bootstrap/Form';
import Select from 'react-select';

import FormBtn from '@/components/IconBtn';
import { FileDeal } from '@/containers/DealWindow/Files/typings';
import { DealService } from '@/services/deal_service';

const InputForm = (props: {
  defaultValue?: string;
  onSave: (arg: string) => void;
  onClose: () => void;
  onDelete: () => void;
  type?: string;
  fieldType?: string;
  dealId?: number;
}) => {
  const { defaultValue, onSave, onClose, onDelete, type, fieldType, dealId } = props;
  const [value, setValue] = useState(defaultValue);
  const [dealFiles, setDealFiles] = useState<FileDeal[] | null>(null);
  const [imageDefaultValue, setImageDefaultValue] = useState({ value: '', label: '' });

  const getFiles = async (dealId: number) => {
    const res = await DealService.getFiles(dealId);
    if (res.ok) {
      imageDefaultOption(res.data);
      return setDealFiles(res.data);
    } else console.error('Error in getFiles:', res.error);
  };

  const imageDefaultOption = (files: FileDeal[]) => {
    files.forEach((file) => {
      if (file.url === value) {
        setImageDefaultValue({ value: file.url, label: file.name });
        return;
      }
    });
  };

  useEffect(() => {
    if (fieldType === 'file' && dealId) getFiles(dealId);
  }, []);

  return (
    <div className='d-flex mb-2 align-items-center'>
      {fieldType === 'file' && dealFiles ? (
        <Select
          defaultValue={imageDefaultValue}
          onChange={(newValue) => setValue(newValue?.value)}
          options={dealFiles?.map((file) => ({ value: file.url, label: file.name }))}
          closeMenuOnSelect
          isClearable
          className='me-3'
        />
      ) : (
        <Form.Control
          defaultValue={value}
          type={type ?? 'text'}
          min={1}
          onChange={(e) => setValue(e.target.value)}
          className='w-198p'
        />
      )}

      <FormBtn
        onClick={() => {
          if (value) {
            onSave(value);
            setValue(value);
          }
        }}
        icon='check'
        style='success'
      />
      <FormBtn
        onClick={() => {
          onClose();
          setValue(defaultValue);
        }}
        icon='times'
        style='primary'
      />
      <FormBtn
        onClick={() => {
          onDelete();
          setValue(undefined);
        }}
        icon='trash'
        style='danger'
      />
    </div>
  );
};

export default InputForm;
