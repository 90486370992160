import * as React from 'react';
import { useState } from 'react';

import flatpickr from 'flatpickr';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Avatar from '@/components/Avatar';
import CompleteBtn from '@/components/CompleteBtn';
import ExpandCommentsBtn from '@/components/DealWindow/ExpandCommentsBtn';
import TextWithTagsModal from '@/components/DealWindow/TextWithTagsModal';
import { TaskData } from '@/services/task_service';

import { UserOption } from '../typings';
import TaskModal from './TaskModal';
import TaskText from './TaskText';
import { Task } from './typings';

const TaskRow = (props: {
  task: Task;
  deleteTask: (taskId: number) => Promise<void>;
  updateTask: (taskId: number, data: TaskData) => Promise<void>;
  updateTaskStatus: (taskId: number) => Promise<void>;
  createComment: (text: string, taskId: number) => Promise<void>;
  users: UserOption[];
  currentUserId: number;
  children: React.ReactNode;
}) => {
  const { task, updateTaskStatus, deleteTask, updateTask, createComment, users, currentUserId } =
    props;
  const [showComments, setShowComments] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleDelete = async () => {
    if (
      !(await window.customConfirm(
        'Delete Task',
        'Are you sure that you want to delete this Task?'
      ))
    )
      return;

    deleteTask(task.id);
  };

  const handleUpdate = (data: TaskData) => {
    updateTask(task.id, data);
    setShowEditModal(false);
  };

  const handleCreateComment = (text: string) => {
    createComment(text, task.id);
    setShowCommentModal(false);
  };

  return (
    <div className='deal-window-task mb-15'>
      <div className='d-flex align-items-center'>
        <CompleteBtn
          id={task.id}
          isCompleted={task.status === 'completed'}
          updateStatus={updateTaskStatus}
        />
        <div
          className={
            'd-flex align-items-center justify-content-between ' +
            (task.status === 'completed' ? 'op-50' : 'op-100')
          }
          style={{ width: '100%' }}
        >
          <TaskText name={task.name} description={task.description} />

          <p className='fs-14 mt-3 min-w-70 px-2'>
            {task.dueDate ? flatpickr.formatDate(new Date(task.dueDate), 'm/d/y') : ''}
          </p>

          <div className='d-flex align-items-center me-2'>
            <Avatar avatar={task.assignedTo.avatar} />

            <div className='min-w-80 d-flex justify-content-between p-2'>
              <FontAwesomeIcon
                icon={['far', 'comments']}
                className='light-blue-color pointer'
                onClick={() => setShowCommentModal(true)}
              />
              <FontAwesomeIcon
                icon={['fas', 'pencil-alt']}
                className='light-blue-color pointer'
                onClick={() => setShowEditModal(true)}
              />
              <FontAwesomeIcon
                icon={['fas', 'trash-alt']}
                className='light-blue-color pointer'
                onClick={handleDelete}
              />
            </div>
          </div>
        </div>
      </div>


      <ExpandCommentsBtn
        toggleCommentsVisibility={() => setShowComments(!showComments)}
        commentsLength={task.taskComments.length}
        isCommentsVisible={showComments}
        isCompleted={task.status === 'completed'}
      />

      {showComments && (
        <div className={task.status === 'completed' ? 'op-50' : 'op-100'}>
          <div className='deal-window-task-comments'>{props.children}</div>
        </div>
      )}
      <TextWithTagsModal
        title='Add comment'
        label='Comment'
        handleClose={() => setShowCommentModal(false)}
        handleSubmit={handleCreateComment}
        show={showCommentModal}
        users={users}
      />
      <TaskModal
        title='Edit task'
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        handleSubmit={handleUpdate}
        currentUserId={currentUserId}
        users={users}
        task={task}
      />
    </div>
  );
};

export default TaskRow;
