import React, { useState } from 'react';

import { Form } from 'react-bootstrap';
import M from 'react-bootstrap/Modal';

import style from '@/components/Modal/style.module.scss';
import { UserService } from '@/services/user_service';

import { User } from '../Settings/Users';
import { TwoFactorAuthentication } from './TwoFactorAuthentication';

const ProfileTab = (props: {
  user: User;
  getUser: () => void;
  setShow: (show: boolean) => void;
  setTab: (tab: 'profile' | 'preferences') => void;
}) => {
  const { user, getUser, setShow, setTab } = props;

  const [showChangePassword, setShowChangePassword] = useState(false);
  const [avatar, setAvatar] = useState<File | null>(null);
  const [errors, setErrors] = useState<Record<string, string[]>>({});

  const defaultState = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    currentPassword: '',
    password: null,
    passwordConfirmation: null,
  };
  const [
    { firstName, lastName, email, currentPassword, password, passwordConfirmation },
    setState,
  ] = useState(defaultState);

  const onClose = () => {
    setShow(false);
    setErrors({});
    setState(defaultState);
  };

  const onSave = async () => {
    const res = await UserService.updateFromHome({
      first_name: firstName,
      last_name: lastName,
      current_password: currentPassword,
      email,
      avatar,
      password,
      password_confirmation:
        password !== null && passwordConfirmation === null ? '' : passwordConfirmation,
    });
    if (res.ok) {
      setTab('preferences');
      setErrors({});
      setState({ ...defaultState, firstName, lastName, email });
    } else {
      if (!!res.data.errors) {
        setErrors(res.data.errors);
      } else setErrors({ current_password: ['is incorrect'] });
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <>
      <M.Body>
        <div className='d-flex justify-content-between mb-3'>
          <div className='modal-input-width-half'>
            <Form.Group className='mb-3'>
              <Form.Label>First Name</Form.Label>
              <Form.Control type='name' value={firstName} name='firstName' onChange={onChange} />
              <small className='error-msg'>{errorMessage(errors?.first_name, 'First name')}</small>
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Last Name</Form.Label>
              <Form.Control type='name' value={lastName} name='lastName' onChange={onChange} />
              <small className='error-msg'>{errorMessage(errors?.last_name, 'Last name')}</small>
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Email</Form.Label>
              <Form.Control type='email' value={email} name='email' onChange={onChange} />
              <small className='error-msg'>{errorMessage(errors?.email, 'Email')}</small>
            </Form.Group>
          </div>

          <div className='modal-input-width-half'>
            <div className='image-loader'>
              <span className='avatar text-secondary'>Avatar</span>

              <input
                type='file'
                className='user-avatar-field'
                id='profile-img'
                onChange={(e) => {
                  const file = e.target.files ? e.target.files[0] : null;
                  setAvatar(file);
                }}
              />
              <img
                src={
                  !!avatar
                    ? URL.createObjectURL(avatar)
                    : 'url' in user.avatar
                    ? user?.avatar.url
                    : undefined
                }
                id='profile-img-tag'
                className='profile-img-tag'
              />
              <div className='loader-footer'>
                <i className='fas fa-cloud-upload-alt'></i> Choose Photo
              </div>
            </div>
          </div>
        </div>

        <Form.Group className='mb-3'>
          <Form.Label>Current Password</Form.Label>
          <Form.Control
            type='password'
            value={currentPassword}
            name='currentPassword'
            onChange={onChange}
          />
          <small className='boulder-color'>
            We need your current password to confirm your changes.
          </small>
          <br />
          <small className='error-msg'>
            {errorMessage(errors?.current_password, 'Current password')}
          </small>
        </Form.Group>

        {showChangePassword && (
          <div>
            <Form.Group className='mb-3'>
              <Form.Label>Password</Form.Label>
              <Form.Control type='password' name='password' onChange={onChange} />
              <small className='boulder-color'>Leave blank if you don't want to change it.</small>
              <br />
              <small className='error-msg'>{errorMessage(errors?.password, 'Password')}</small>
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control type='password' name='passwordConfirmation' onChange={onChange} />
              <small className='error-msg'>
                {errorMessage(errors?.password_confirmation, 'Password confirmation')}
              </small>
            </Form.Group>
          </div>
        )}

        <TwoFactorAuthentication user={user} getUser={getUser} />
      </M.Body>

      <M.Footer className='justify-content-between'>
        <div className={style.grayBtn} onClick={() => setShowChangePassword(!showChangePassword)}>
          Change Password
        </div>
        <div className='d-flex align-items-center'>
          <div className={style.cancelBtn} onClick={onClose}>
            Cancel
          </div>
          <div className={style.saveBtn} onClick={onSave}>
            Next
          </div>
        </div>
      </M.Footer>
    </>
  );
};

const errorMessage = (arr: string[], field: string) => {
  if (!!arr)
    return arr.map((err) => (
      <div key={err}>
        {field} {err}
        <br />
      </div>
    ));
};

export default ProfileTab;
