import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import Modal from '@/components/Modal';
import { CityService } from '@/services/city_service';
import { City, Option, SetState } from '@/typings';

import { DealResource as DResource } from '.';
import { STATES } from '../Brokers/typings';

type DealResource = Omit<Omit<DResource, 'id'>, 'lastUpdated'>;

const ModalDealResource = (props: {
  show: boolean;
  setShow: SetState<boolean>;
  title: string;
  tags: Option[];
  onSave: (resource: DealResource) => void;
  resource?: DResource;
}) => {
  const { show, title, setShow, onSave } = props;
  const states = STATES.map((state) => state[1]);

  const defaultResource = props.resource ?? {
    name: '',
    link: '',
    city: null,
    state: '',
    tags: [],
  };

  const [cities, setCities] = useState<City[]>([]);
  const [resource, setResource] = useState<DealResource>(defaultResource);
  const [errors, setErrors] = useState({ name: false, link: false });

  useEffect(() => {
    loadCities('', (cities) => setCities(cities));
  }, [resource.state]);

  const handleClose = () => {
    setShow(false);
    setResource(defaultResource);
    setErrors({ name: false, link: false });
  };

  const handleSave = () => {
    if (!resource.name) return setErrors({ ...errors, name: true });
    else setErrors({ ...errors, name: false });

    if (resource.link && !isValidHttpUrl(resource.link))
      return setErrors({ ...errors, link: true });
    else setErrors({ ...errors, link: false });

    onSave(resource);
    setResource(defaultResource);
    setShow(false);
  };

  const loadCities = (inputValue: string, callback: (cities: City[]) => void) => {
    (async function () {
      const res = await CityService.search(inputValue, resource.state);
      if (res.ok) callback(res.data.cities);
      else console.error('Error loading cities:', res.error);
    })();
  };

  const statesOptions = useMemo(() => states.map(toSelectOption), [states]);

  return (
    <Modal
      modalProps={{ show, backdrop: true }}
      onSave={handleSave}
      onClose={handleClose}
      title={title}
    >
      <>
        <Form.Group className='mb-3'>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type='text'
            placeholder='Name'
            defaultValue={resource.name}
            onChange={(e) => setResource({ ...resource, name: e.target.value.trim() })}
            isInvalid={errors.name}
          />
          <Form.Control.Feedback type='invalid'>Required Information</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Link</Form.Label>
          <Form.Control
            type='url'
            placeholder='Link'
            defaultValue={resource.link}
            onChange={(e) => setResource({ ...resource, link: e.target.value.trim() })}
            isInvalid={errors.link}
          />
          <Form.Control.Feedback type='invalid'>
            Link should be URL, example: https://example.com/
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className='mb-3'>
          <Row>
            <Col>
              <Form.Label>City</Form.Label>
              <AsyncSelect
                value={resource.city}
                loadOptions={loadCities}
                onChange={(city) => setResource({ ...resource, city, state: city?.state ?? '' })}
                closeMenuOnSelect
                isClearable
                defaultOptions={cities}
                getOptionValue={(option) => option.id.toString()}
                getOptionLabel={(option) => option.name}
              />
            </Col>
            <Col>
              <Form.Label>State</Form.Label>
              <Select
                value={resource.state === '' ? null : toSelectOption(resource.state)}
                onChange={(newValue) =>
                  setResource({
                    ...resource,
                    state: newValue?.value ?? '',
                    city: newValue?.value === resource.city?.state ? resource.city : null,
                  })
                }
                options={statesOptions}
                closeMenuOnSelect
                isClearable
              />
            </Col>
          </Row>
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Tags</Form.Label>
          <Select
            defaultValue={resource.tags}
            onChange={(newValue) => setResource({ ...resource, tags: [...newValue] ?? [] })}
            options={props.tags}
            closeMenuOnSelect
            isClearable
            isMulti
            getOptionValue={(option) => option.id.toString()}
            getOptionLabel={(option) => option.name}
          />
        </Form.Group>
      </>
    </Modal>
  );
};

const toSelectOption = (str: string) => ({ label: str, value: str });

export default ModalDealResource;

function isValidHttpUrl(str: string): boolean {
  let url;
  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}
