import * as React from 'react';
import { useState } from 'react';

import { Form, Modal } from 'react-bootstrap';
import Select from 'react-select';

import {
  BaseCommunication,
  CommunicationKinds,
} from '@/containers/DealWindow/CommunicationTab/typings';
import { useEffectNotFirstRender } from '@/hooks';

const CommunicationModal = (props: {
  show: boolean;
  communication: BaseCommunication;
  title: string;
  handleClose: () => void;
  handleSubmit: (com: BaseCommunication) => void;
}) => {
  const { handleClose, handleSubmit, show, communication, title } = props;
  const [com, setCom] = useState<BaseCommunication>(communication);
  const [validated, setValidated] = useState(false);

  useEffectNotFirstRender(() => {
    setCom(communication);
  }, [communication]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (com.title.trim() === '') {
      setValidated(true);
      return;
    }
    setCom(communication);
    handleSubmit(com);
  };

  const onClose = () => {
    setCom(communication);
    handleClose();
  };

  return (
    <Modal show={show} onHide={onClose} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmit}>
        <Modal.Body>
          <Form.Group className='d-flex mb-3'>
            <div>
              <Form.Label>Type</Form.Label>
              <Select
                defaultValue={com.kind ? { label: com.kind, value: com.kind } : null}
                onChange={(newValue) => {
                  setCom({ ...com, kind: newValue?.value ?? null });
                }}
                options={CommunicationKinds.map((type) => ({ value: type, label: type }))}
                closeMenuOnSelect
                isClearable
                className='me-3'
              />
            </div>
            <div className='flex-fill'>
              <Form.Label>Title*</Form.Label>
              <Form.Control
                isInvalid={com.title === '' && validated}
                defaultValue={com.title}
                type='text'
                onChange={(e) => setCom({ ...com, title: e.target.value.trim() })}
                className='w-100'
              />
              <Form.Control.Feedback
                type='invalid'
                className={com.title === '' && validated ? 'd-block' : ''}
              >
                Title can not be empty
              </Form.Control.Feedback>
            </div>
          </Form.Group>

          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              defaultValue={com.description ?? ''}
              type='text'
              onChange={(e) =>
                setCom({ ...com, description: nullifyEmptyString(e.target.value.trim()) })
              }
              className='w-100'
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <div className='modal-cancel-btn pointer' onClick={onClose}>
            Cancel
          </div>
          <button type='submit' className='btn modal-submit-btn fw-600 pointer'>
            Save
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CommunicationModal;

const nullifyEmptyString = (s: string): null | string => (s === '' ? null : s);
