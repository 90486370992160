import * as React from 'react';
import { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CropModal from '@/components/CropModal';
import { clearMapImages } from '@/scripts/map';
import { MapImageService } from '@/services/map_image_service';

import { MapImage } from './typings';

const EditImageWindow = () => {
  const [showCrop, setShowCrop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mapImage, setMapImage] = useState<MapImage>();
  useEffect(() => {
    document.addEventListener('crop-image-click', async (e) => {
      setLoading(true);
      setShowCrop(true);
      const elem = e.target as HTMLElement;
      try {
        const res = await MapImageService.show(parseInt(elem.dataset['id']!));
        if (res.ok) {
          const file = res.data.file;
          const blob = await fetch(file.url, {
            headers: {
              'Cache-Control': 'no-cache',
            },
          }).then((res) => res.blob());
          const url = URL.createObjectURL(blob);
          setMapImage({ ...res.data, file: { name: file.name, url } });
        }
      } finally {
        setLoading(false);
      }
    });
  }, []);

  const uploadFile = async (fd: FormData) => {
    if (mapImage) {
      const res = await MapImageService.replaceFile(mapImage.id, fd);
      if (res.ok) {
        clearMapImages();
        setShowCrop(false);
        document.dispatchEvent(new Event('render_markers'));
      }
    }
  };

  return (
    <>
      <div className='edit-image d-none fs-14 fw-400 boulder-color'>
        <div>Edit Image</div>
        <hr className='mt-1 mb-2' />
        <div className='d-flex light-blue-color justify-content-between mb-2 pointer'>
          <FontAwesomeIcon icon='crop-simple' className='crop-image' />
          <FontAwesomeIcon icon='arrows-repeat' className='replace-image' />
          <FontAwesomeIcon icon='trash-alt' className='delete-image' />
        </div>
        <div className='mb-2'>
          <label className='form-label'>Rotate</label>
          <div className='d-flex'>
            <div className='mx-1'>
              <input type='range' className='form-range range-slider w-6r' />
            </div>
            <div className='input-group input-group-sm '>
              <input type='number' className='form-control range-input' min='0' max='100' />
              <span className='input-group-text'>%</span>
            </div>
          </div>
        </div>

        <div className='mb-2'>
          <label className='form-label'>Opacity</label>
          <div className='d-flex'>
            <div className='mx-1'>
              <input type='range' className='form-range opacity-slider w-6r' />
            </div>
            <div className='input-group input-group-sm '>
              <input type='number' className='form-control opacity-input' min='0' max='100' />
              <span className='input-group-text'>%</span>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-center'>
          <a className='save-edit-image no-link light-blue-color text-uppercase pointer fw-600'>
            Save
          </a>
        </div>
      </div>
      {mapImage && (
        <CropModal
          loading={loading}
          show={showCrop}
          setShow={setShowCrop}
          file={mapImage.file}
          uploadFile={uploadFile}
        />
      )}
    </>
  );
};

export default EditImageWindow;
