import * as React from 'react';
import { useState } from 'react';

import Form from 'react-bootstrap/Form';

import { DealService } from '@/services/deal_service';

const Confidential = (props: {
  confidential: boolean;
  dealId: number;
  setConfidential?: (confidetnial: boolean) => void;
}) => {
  const { dealId } = props;
  const [error, setError] = useState<[string, boolean]>(['', false]);
  const [confidential, setConfidential] = props.setConfidential
    ? [props.confidential, props.setConfidential]
    : useState(props.confidential);

  return (
    <Form>
      <Form.Check type='switch'>
        <Form.Check.Input
          isInvalid={error[1]}
          checked={confidential}
          onChange={async () => {
            const res = await DealService.updateConfidentialStatus(dealId, confidential);
            if (res.ok) {
              setConfidential(!confidential);
              setError(['', false]);
            } else {
              console.error(res.error);
              setError([`Error: status: ${res.status}, text: ${res.statusText}`, true]);
            }
          }}
        />
        <Form.Control.Feedback type='invalid'>{error[0]}</Form.Control.Feedback>
      </Form.Check>
    </Form>
  );
};

export default Confidential;
