import * as React from 'react';
import { createContext } from 'react';

import { User } from '@/containers/Settings/Users';
import { useFetchInitial } from '@/hooks';
import { UserService } from '@/services/user_service';

type UsersState = {
  currentUser: User | null;
};

export const UsersContext = createContext<UsersState>(null as unknown as UsersState);

export const UsersProvider: React.FC = ({ children }) => {
  const currentUser = useFetchInitial(
    console.error,
    UserService.current,
    'Cannot get currentUser',
    true
  );
  return <UsersContext.Provider value={{ currentUser }}>{children}</UsersContext.Provider>;
};
