import * as React from 'react';
import { useContext, useState } from 'react';

import { Deal, DealsContext } from '@Deals/context/DealsContext';
import { UsersContext } from '@Deals/context/UsersContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PinDetailsComponent from '@/components/DealWindow/Details/PinDetailsComponent';
import Parcels from '@/containers/DealWindow/Parcels';
import { ParamsNames } from '@/services/deal_service';
import { Option } from '@/typings';

const PinDetails = (props: {
  currentDeal: Deal;
  onSave: (
    option: Option[] | Option | string,
    paramName: ParamsNames,
    callback: () => void
  ) => void;
  onDelete: (paramName: ParamsNames, callback: () => void) => void;
}) => {
  const { options, setConfidential, showDealWindow } = useContext(DealsContext);
  const { currentDeal } = props;
  const { currentUser } = useContext(UsersContext);

  const [showEdit, _setShowEdit] = useState({
    opportunities: false,
    tags: false,
    transaction_type: false,
    nickname: false,
    radius: false,
    custom_radius: false,
    related_deals: false,
    working_users: false,
    followers: false,
  });
  const setShowEdit = (param: ParamsNames, bool: boolean) => {
    _setShowEdit((showEdit) => ({ ...showEdit, [param]: bool }));
  };

  const onSave = async (option: Option[] | Option | string, paramName: ParamsNames) => {
    props.onSave(option, paramName, () => setShowEdit(paramName, false));
  };

  const onDelete = async (paramName: ParamsNames) =>
    props.onDelete(paramName, () => setShowEdit(paramName, false));

  if (currentDeal.details)
    return (
      <PinDetailsComponent
        deal={{
          id: currentDeal.id,
          confidential: currentDeal.confidential,
          tags: currentDeal.details.tags,
          opportunities: currentDeal.details.opportunities,
          workingUsers: currentDeal.details.workingUsers,
          followers: currentDeal.details.followers,
          relatedDeals: currentDeal.details.relatedDeals,
          nickname: currentDeal.details.nickname,
          transactionType: currentDeal.details.transactionType,
          latitude: currentDeal.coordinates.lat.toString(),
          longitude: currentDeal.coordinates.lng.toString(),
        }}
        options={options}
        canViewConfidential={currentUser?.canViewConfidential ?? false}
        setConfidential={setConfidential}
        onSave={onSave}
        onDelete={onDelete}
        openNewDeal={(deal) => showDealWindow(deal.id)}
        Radius={
          <a
            className='d-flex text-decoration-none'
            href={currentDeal.link}
            target='_blank'
            rel='noopener noreferrer'
            title='Open deal on map'
          >
            {currentDeal.details.radius ? (
              <p className='text-dark'>
                {typeof currentDeal.details.radius === 'number'
                  ? `${currentDeal.details.radius}mi `
                  : 'Custom radius '}
              </p>
            ) : (
              <p className='boulder-color'>Open on map</p>
            )}
            <FontAwesomeIcon icon='external-link-alt' className='ms-1 link' />
          </a>
        }
        Parcels={<Parcels deal={{ id: currentDeal.id, ...currentDeal.coordinates }} />}
        showEdit={showEdit}
        setShowEdit={setShowEdit}
      />
    );
  else return <p>No Details retrieved</p>;
};

export default PinDetails;
