import * as React from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

import { PinService } from '@/services/pin_service';
import { PinType } from '@/typings';

import { ErrorContext } from './ErrorContext';

type PinsState = {
  pins: [number, string, PinType][];
};

const defaultState: PinsState = {
  pins: [],
};

export const PinsContext = createContext<PinsState>(defaultState);

export const PinsProvider: React.FC = ({ children }) => {
  const { addError } = useContext(ErrorContext);
  const [pins, setPins] = useState<[number, string, PinType][]>(defaultState.pins);
  useEffect(() => {
    const getPins = async () => {
      const res = await PinService.options();
      if (res.ok) setPins(res.data);
      else
        addError('Error in PinsProvider', {
          status: res.status,
          statusText: 'Cannot get pins: ' + res.statusText,
          error: res.error,
        });
    };
    getPins();
  }, []);
  return <PinsContext.Provider value={{ pins }}>{children}</PinsContext.Provider>;
};
