import React, { useState } from 'react';

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import HeaderBlock from '@/components/HeaderBlock';

import Cities from './Cities';
import Companies from './Companies';
import CustomFields from './CustomFields';
import Opportunities from './Opportunities';
import Pins from './Pins';
import Tags from './Tags';
import Users from './Users';

const Settings = () => {
  const [activeTab, setActiveTab] = useState(window.location.hash.split('#')[1] ?? 'users');
  const [header, setHeader] = useState<JSX.Element | null>(null);

  const tabSwitch = (tab: string | null) => {
    if (tab) {
      window.location.hash = tab;
      setActiveTab(tab);
    }
  };

  return (
    <React.StrictMode>
      <HeaderBlock h1='Settings'>{header}</HeaderBlock>

      <Tab.Container activeKey={activeTab} onSelect={tabSwitch}>
        <Nav variant='tabs' className='block-tabs ml-20'>
          <Nav.Item>
            <Nav.Link
              as='button'
              eventKey='users'
              className='source-sans-semi-bold-font fuscous-gray-color'
            >
              Users
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              as='button'
              eventKey='pins'
              className='source-sans-semi-bold-font fuscous-gray-color'
            >
              Pins
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              as='button'
              eventKey='custom-fields'
              className='source-sans-semi-bold-font fuscous-gray-color'
            >
              Custom Fields
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              as='button'
              eventKey='tags'
              className='source-sans-semi-bold-font fuscous-gray-color'
            >
              Tags
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              as='button'
              eventKey='opportunities'
              className='source-sans-semi-bold-font fuscous-gray-color'
            >
              Tenants
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              as='button'
              eventKey='companies'
              className='source-sans-semi-bold-font fuscous-gray-color'
            >
              Companies
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              as='button'
              eventKey='cities'
              className='source-sans-semi-bold-font fuscous-gray-color'
            >
              Cities
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey='users'>
            <Users show={activeTab === 'users'} setHeader={setHeader} />
          </Tab.Pane>

          <Tab.Pane eventKey='pins'>
            <Pins show={activeTab === 'pins'} setHeader={setHeader} />
          </Tab.Pane>

          <Tab.Pane eventKey='custom-fields'>
            <CustomFields show={activeTab === 'custom-fields'} setHeader={setHeader} />
          </Tab.Pane>

          <Tab.Pane eventKey='tags'>
            <Tags show={activeTab === 'tags'} setHeader={setHeader} />
          </Tab.Pane>

          <Tab.Pane eventKey='opportunities'>
            <Opportunities show={activeTab === 'opportunities'} setHeader={setHeader} />
          </Tab.Pane>

          <Tab.Pane eventKey='companies'>
            <Companies show={activeTab === 'companies'} setHeader={setHeader} />
          </Tab.Pane>

          <Tab.Pane eventKey='cities'>
            <Cities show={activeTab === 'cities'} setHeader={setHeader} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </React.StrictMode>
  );
};

export default Settings;
