import * as React from 'react';
import { createContext, useRef, useState } from 'react';

type LoadingState = {
  isLoading: boolean;
  loading: Function;
};

export const LoadingContext = createContext<LoadingState>(null as unknown as LoadingState);

export const LoadingProvider: React.FC = ({ children }) => {
  const loadingCount = useRef<number>(0);
  const [isLoading, setLoading] = useState<boolean>(false);

  const incLoadingCount = () => {
    loadingCount.current += 1;
    setLoading(loadingCount.current === 0 ? false : true);
  };
  const decLoadingCount = () => {
    loadingCount.current -= 1;
    setLoading(loadingCount.current === 0 ? false : true);
  };

  const loading = async (fn: Function) => {
    incLoadingCount();
    const res = await fn();
    decLoadingCount();
    return res;
  };

  return (
    <LoadingContext.Provider
      value={{
        isLoading,
        loading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};
