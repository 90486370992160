import { MapImageService } from '@/services/map_image_service';

export const initImageOverlayClass = () => {
  // google.maps.OverlayView is availiable only after google maps api is loaded
  class ImageOverlay extends google.maps.OverlayView {
    public bounds: google.maps.LatLngBounds;
    public id: number;
    private map: google.maps.Map;
    private image: string;
    private rotation: number;
    private opacity: number;
    private div?: HTMLElement;
    private rectangle?: google.maps.Rectangle;
    private infowindow?: google.maps.InfoWindow;

    constructor(
      id: number,
      bounds: google.maps.LatLngBounds,
      image: string,
      map: google.maps.Map,
      rotation: number,
      opacity: number
    ) {
      super();

      this.id = id;
      this.map = map;
      this.bounds = bounds;
      this.image = image;
      this.rotation = rotation;
      this.opacity = opacity;
    }

    /**
     * onAdd is called when the map's panes are ready and the overlay has been
     * added to the map.
     */
    onAdd() {
      const div = document.createElement('div');
      div.style.borderStyle = 'none';
      div.style.borderWidth = '0px';
      div.style.position = 'absolute';

      const rectangle = new google.maps.Rectangle({
        strokeColor: '#000000',
        strokeOpacity: 0,
        fillOpacity: 0,
        strokeWeight: 2,
        map: this.map,
        bounds: this.bounds,
      });

      const content = document.querySelector('.edit-image')?.cloneNode(true) as HTMLElement;

      const infowindow = new google.maps.InfoWindow({
        position: this.bounds.getNorthEast(),
        content,
      });

      rectangle.addListener('bounds_changed', () => {
        this.bounds = rectangle.getBounds()!;
        infowindow.setPosition(this.bounds.getNorthEast());
      });

      rectangle.addListener('click', () => {
        this.setEditable(true);
        content.classList.remove('d-none');
        infowindow.open(this.map);
      });

      const replaceBtn = content.querySelector('.replace-image') as HTMLElement;
      replaceBtn.addEventListener('click', () => {
        replaceBtn.dataset['id'] = this.id.toString();
        replaceBtn.dispatchEvent(new Event('replace-image-click', { bubbles: true }));
      });

      const cropBtn = content.querySelector('.crop-image') as HTMLElement;
      cropBtn.addEventListener('click', () => {
        cropBtn.dataset['id'] = this.id.toString();
        cropBtn.dispatchEvent(new Event('crop-image-click', { bubbles: true }));
      });

      const saveBtn = content.querySelector('.save-edit-image') as HTMLElement;
      saveBtn.addEventListener('click', async () => {
        const res = await MapImageService.update(this.id, {
          latitude: this.bounds.getCenter().lat(),
          longitude: this.bounds.getCenter().lng(),
          bounds: this.bounds.toJSON(),
          rotation: this.rotation,
          opacity: this.opacity,
        });
        if (res.ok) {
          this.setEditable(false);
          infowindow.close();
        } else console.error('update MapImage:', res.error);
      });

      infowindow.addListener('closeclick', () => {
        this.setEditable(false);
        infowindow.close();
      });

      const deleteBtn = content.querySelector('.delete-image') as HTMLElement;
      deleteBtn.addEventListener('click', async () => {
        if (
          await window.customConfirm(
            'Delete Image',
            'Are you sure that you want to delete this Image?'
          )
        ) {
          const res = await MapImageService.destroy(this.id);
          if (res.ok) {
            this.setMap(null);
          } else console.error('destroy MapImage:', res.error);
        }
      });

      const rangeSlider = content.querySelector('.range-slider') as HTMLInputElement;
      rangeSlider.value = (this.rotation / 3.6).toString();
      rangeSlider?.addEventListener('change', (e) => {
        const value = (e.target as HTMLInputElement).value;
        this.rotate(parseInt(value) * 3.6);
        rangeInput.value = value;
      });

      const rangeInput = content.querySelector('.range-input') as HTMLInputElement;
      rangeInput.value = (this.rotation / 3.6).toString();
      rangeInput?.addEventListener('change', (e) => {
        let value = parseInt((e.target as HTMLInputElement).value);
        value = value < 0 ? 0 : value;
        value = value > 100 ? 100 : value;
        this.rotate(value * 3.6);
        rangeSlider.value = value.toString();
        rangeInput.value = value.toString();
      });

      const opacitySlider = content.querySelector('.opacity-slider') as HTMLInputElement;
      opacitySlider.value = this.opacity.toString();
      opacitySlider?.addEventListener('change', (e) => {
        const value = (e.target as HTMLInputElement).value;
        this.setOpacity(parseInt(value));
        opacityInput.value = value;
      });

      const opacityInput = content.querySelector('.opacity-input') as HTMLInputElement;
      opacityInput.value = this.opacity.toString();
      opacityInput?.addEventListener('change', (e) => {
        let value = parseInt((e.target as HTMLInputElement).value);
        value = value < 0 ? 0 : value;
        value = value > 100 ? 100 : value;
        this.setOpacity(value);
        opacitySlider.value = value.toString();
        opacityInput.value = value.toString();
      });

      // Create the img element and attach it to the div.
      const img = document.createElement('img');
      img.src = this.image;
      img.style.width = '100%';
      img.style.height = '100%';
      img.style.position = 'absolute';
      div.appendChild(img);

      this.div = div;
      this.rectangle = rectangle;
      this.infowindow = infowindow;
      // Add the element to the "overlayLayer" pane
      const panes = this.getPanes()!;
      panes.overlayLayer.appendChild(this.div);
      this.rotate(this.rotation);
      this.setOpacity(this.opacity);
    }

    draw() {
      // We use the south-west and north-east
      // coordinates of the overlay to peg it to the correct position and size.
      // To do this, we need to retrieve the projection from the overlay.
      const overlayProjection = this.getProjection();

      // Retrieve the south-west and north-east coordinates of this overlay
      // in LatLngs and convert them to pixel coordinates.
      // We'll use these coordinates to resize the div.
      const sw = overlayProjection.fromLatLngToDivPixel(this.bounds.getSouthWest())!;
      const ne = overlayProjection.fromLatLngToDivPixel(this.bounds.getNorthEast())!;

      // Resize the image's div to fit the indicated dimensions.
      if (this.div) {
        this.div.style.left = sw.x + 'px';
        this.div.style.top = ne.y + 'px';
        this.div.style.width = ne.x - sw.x + 'px';
        this.div.style.height = sw.y - ne.y + 'px';
      }
    }

    /**
     * The onRemove() method will be called automatically from the API if
     * we ever set the overlay's map property to 'null'.
     */
    onRemove() {
      if (this.div) {
        (this.div.parentNode as HTMLElement).removeChild(this.div);
        delete this.div;
        this.rectangle?.setMap(null);
        this.infowindow?.close();
      }
    }

    setEditable(isEditable: boolean) {
      this.rectangle?.setOptions({
        editable: isEditable,
        draggable: isEditable,
        strokeOpacity: isEditable ? 0.5 : 0,
        fillOpacity: isEditable ? 0.2 : 0,
      });
    }

    rotate(deg: number) {
      this.rotation = deg;
      if (this.div) {
        this.div.style.transform = `rotate(${deg}deg)`;
      }
    }

    setOpacity(percent: number) {
      this.opacity = percent;
      if (this.div) {
        this.div.style.opacity = `${percent / 100}`;
      }
    }
  }
  return ImageOverlay;
};
