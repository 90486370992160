import { User } from '@/containers/Settings/Users';
import { DataType, RequestMethod, Result } from '@/typings';
import { fetchResult, getFormData } from '@/utils';

export const UserService = {
  search: (q: Record<string, string>, filters: { roles: string[] }): Promise<Result<User[]>> => {
    const body = $.param({ q, filters });
    const url = '/users/search?' + body;
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  current: (): Promise<Result<User>> => {
    const req = { url: '/users/current', method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  createFromModal: (user: {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    canViewConfidential: boolean;
  }): Promise<Result<User>> => {
    const body = JSON.stringify({
      user: { first_name: user.firstName, last_name: user.lastName, email: user.email },
      membership: { role: user.role, can_view_confidential: user.canViewConfidential },
    });
    const req = {
      url: '/users',
      method: RequestMethod.Post,
      dataType: DataType.JSON,
      body,
    };
    return fetchResult(req);
  },
  update: (user: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    canViewConfidential: boolean;
  }): Promise<Result<User>> => {
    const body = JSON.stringify({
      user: { first_name: user.firstName, last_name: user.lastName, email: user.email },
      membership: { role: user.role, can_view_confidential: user.canViewConfidential },
    });
    const req = {
      url: `/users/${user.id}`,
      method: RequestMethod.Put,
      dataType: DataType.JSON,
      body,
    };
    return fetchResult(req);
  },

  sendInvitation: (email: string) => {
    const body = JSON.stringify({ email });
    const req = {
      url: `/users/send_invitation`,
      method: RequestMethod.Post,
      dataType: DataType.JSON,
      body,
    };
    return fetchResult(req);
  },

  archive: (id: number, new_user_id?: number): Promise<Result<User>> => {
    const body = JSON.stringify({ new_user_id });
    const req = {
      url: `/users/${id}/archive`,
      method: RequestMethod.Put,
      dataType: DataType.JSON,
      body,
    };
    return fetchResult(req);
  },

  updateFromHome: (user: {
    first_name: string;
    last_name: string;
    email: string;
    avatar: File | null;
    current_password: string;
    password: string | null;
    password_confirmation: string | null;
  }): Promise<Result<User>> => {
    const req = {
      url: `/users/update_current`,
      method: RequestMethod.Put,
      body: getFormData(user, 'user'),
    };
    return fetchResult(req);
  },

  generateOtp: (): Promise<Result<{ uri: string; secret: string }>> => {
    const req = {
      url: `/users/generate_otp`,
      method: RequestMethod.Get,
    };
    return fetchResult(req);
  },

  enableOtp: (otp_code: string): Promise<Result<'ok'>> => {
    const body = JSON.stringify({ otp_code });
    const req = {
      url: `/users/enable_otp`,
      method: RequestMethod.Post,
      dataType: DataType.JSON,
      body,
    };
    return fetchResult(req);
  },

  disableOtp: (): Promise<Result<'ok'>> => {
    const req = {
      url: `/users/disable_otp`,
      method: RequestMethod.Delete,
    };
    return fetchResult(req);
  },

  getOtp: (): Promise<Result<{ uri: string; secret: string }>> => {
    const req = {
      url: `/users/otp`,
      method: RequestMethod.Get,
    };
    return fetchResult(req);
  },
};
