import React, { useEffect, useRef } from 'react';

import Form from 'react-bootstrap/Form';

const ColorPicker = (props: { color?: string; setColor: (color: string) => void }) => {
  const { color, setColor } = props;
  const picker = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (picker.current)
      ($(picker.current) as any).minicolors({
        theme: 'bootstrap',
        change: (value: string) => setColor(value),
      });
  }, []);

  return (
    <Form.Control
      type='text'
      ref={picker}
      defaultValue={color}
      placeholder='Color (hexadecimal)'
      required
    />
  );
};

export default ColorPicker;
