import * as React from 'react';
import { useEffect } from 'react';

import { Coordinates } from '@/typings';

const StreetView = (props: {
  className: string;
  apiKey: string | null;
  coordinates: Coordinates;
}) => {
  const { className, apiKey, coordinates } = props;
  useEffect(() => {
    const mapsApiUrl = 'https://maps.googleapis.com/maps/api/js';
    if (document.querySelectorAll(`script[src^="${mapsApiUrl}"]`).length) {
      createStreetView(coordinates);
    } else if (apiKey) {
      const script = document.createElement('script');
      script.src = `${mapsApiUrl}?key=${apiKey}&language=en`;
      script.onload = () => createStreetView(coordinates);
      document.head.append(script);
    }
    return () => streetView?.setVisible(false);
  }, [coordinates]);
  return <div id='street-view' className={className} />;
};

let streetView: google.maps.StreetViewPanorama;
const createStreetView = (position: Coordinates) => {
  const streetViewContainer = document.getElementById('street-view')!;
  streetView = new google.maps.StreetViewPanorama(streetViewContainer, {
    position,
    addressControlOptions: {
      position: google.maps.ControlPosition.LEFT_CENTER,
    },
  });
};

export default StreetView;
