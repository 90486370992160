import * as React from 'react';
import { useState } from 'react';

import F from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import Form from '@/containers/Brokers/Form';
import { useEffectNotFirstRender } from '@/hooks';
import { Nullable, Option, SetState } from '@/typings';
import { isEmailValid, isNullOrEmptyString } from '@/utils';

import { Broker, DefaultBroker } from './typings';

const ModalBroker = (props: {
  show: boolean;
  setShow: SetState<boolean>;
  title: string;
  saveBroker: (broker: Nullable<Broker>) => Promise<void>;
  isOwner: boolean;
  workingCompanies: Option[];
  initBroker?: Broker;
}) => {
  const { show, setShow, title, saveBroker, initBroker, isOwner, workingCompanies } = props;
  const [broker, setBroker] = useState<Nullable<Broker>>(initBroker ?? DefaultBroker);
  const [validated, setValidated] = useState(false);

  useEffectNotFirstRender(() => {
    setBroker(initBroker ?? DefaultBroker);
  }, [initBroker]);

  const handleClose = () => {
    setBroker(initBroker ?? DefaultBroker);
    setValidated(false);
    setShow(false);
  };

  const handleSave = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValidBroker(broker)) {
      setValidated(true);
      return;
    }
    setValidated(false);

    if (isOwner) broker.status = 'owner';
    else broker.status = 'broker';
    saveBroker(broker);
    setBroker(initBroker ?? DefaultBroker);
    setShow(false);
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <F onSubmit={handleSave}>
        <Modal.Body>
          <Form
            broker={broker}
            setBroker={setBroker}
            validated={validated}
            isOwner={isOwner}
            workingCompanies={workingCompanies}
          />
        </Modal.Body>
        <Modal.Footer>
          <a type='button' className='fs-16 filters-btn gray-color' onClick={handleClose}>
            Cancel
          </a>
          <button type='submit' className='btn modal-submit-btn fw-600 pointer'>
            Save
          </button>
        </Modal.Footer>
      </F>
    </Modal>
  );
};

export default ModalBroker;

const isValidBroker = (broker: Nullable<Broker>): boolean => {
  return !isNullOrEmptyString(broker.name) && isNullOrEmptyString(broker.email)
    ? true
    : isEmailValid(broker.email);
};

export const stringToStateOption = (str: string): { name: string }[] => {
  return str.split(',').map((market) => ({ name: market }));
};
