import * as React from 'react';

const Avatar = ({
  avatar,
  className,
}: {
  avatar: { url: string } | { initialLetters: string };
  className?: string;
}) =>
  'url' in avatar ? (
    <img className={'bor-rad-circle ' + className} src={avatar.url} width='30' height='30' />
  ) : (
    <div className={'init-icon-30 bor-rad-circle ' + className}>{avatar.initialLetters}</div>
  );

export default Avatar;
