$(document).on('turbolinks:load', () => {
  const forms = document.querySelectorAll('form[action=ajax-proceed-shared-map]');
  forms.forEach((form) => {
    $(form).on('submit', function (event) {
      const csrfToken = document.querySelector('meta[name=csrf-token]')?.getAttribute('content');
      const csrfParam = document.querySelector('meta[name=csrf-param]')?.getAttribute('content');
      (event.target as HTMLElement)?.insertAdjacentHTML(
        'beforeend',
        '<input type="hidden" name="' + csrfParam + '" value="' + csrfToken + '">'
      );
    });
  });

  const initCloseBtn = ($select: JQuery<HTMLElement>) => {
    const select = $select[0].selectize;
    const $control = (select as any).$control as JQuery<HTMLElement>;
    const $wrapper = (select as any).$wrapper as JQuery<HTMLElement>;
    $wrapper
      .next('.close-btn-select')
      .off('mousedown')
      .on('mousedown', () => {
        if (!$control.hasClass('dropdown-active')) {
          select.open();
        }
      });
  };

  const proceedNewMap = () => {
    const selectizeTagsFilter = $('.js-select-tags-filters');
    const selectizeOpportunitiesFilter = $('.js-select-opportunities-filters');
    const selectizePinsFilter = $('.js-select-pins-filters');

    selectizeTagsFilter.selectize({
      sortField: 'text',
      plugins: ['remove_button'],
      delimiter: ',',
      persist: false,
    });

    selectizeOpportunitiesFilter.selectize({
      sortField: 'text',
      plugins: ['remove_button'],
      delimiter: ',',
      persist: false,
    });

    selectizePinsFilter.selectize({
      sortField: 'text',
      plugins: ['remove_button'],
      delimiter: ',',
      persist: false,
      render: {
        option: function (data) {
          return '<div class="option" data-icon="' + data.icon + '">' + data.text + '</div>';
        },
        item: function (data) {
          return '<div class="item" data-icon="' + data.icon + '">' + data.text + '</div>';
        },
      },
    });

    initCloseBtn(selectizeOpportunitiesFilter);
    initCloseBtn(selectizePinsFilter);
    initCloseBtn(selectizeTagsFilter);
  };

  const proceedEditMap = () => {
    const selectizeOpportunitiesFilter = $('.js-select-opportunities-filters-index');
    const selectizeTagsFilter = $('.js-select-tags-filters-index');
    const selectizePinsFilter = $('.js-select-pins-filters-index');
    const pins = selectizePinsFilter.val();

    selectizeTagsFilter.selectize({
      sortField: 'text',
      plugins: ['remove_button'],
      delimiter: ',',
      persist: false,
    });

    selectizeOpportunitiesFilter.selectize({
      sortField: 'text',
      plugins: ['remove_button'],
      delimiter: ',',
      persist: false,
    });

    selectizePinsFilter.selectize({
      sortField: 'text',
      plugins: ['remove_button'],
      delimiter: ',',
      persist: false,
      render: {
        option: function (data) {
          return '<div class="option" data-icon="' + data.icon + '">' + data.text + '</div>';
        },
        item: function (data) {
          return '<div class="item" data-icon="' + data.icon + '">' + data.text + '</div>';
        },
      },
    });

    const pinsSelectize = selectizePinsFilter[0].selectize;

    pinsSelectize.clear();
    if (Array.isArray(pins))
      pins.forEach((pin) => {
        pinsSelectize.addItem(pin);
      });
    else pinsSelectize.addItem(pins);

    initCloseBtn(selectizeOpportunitiesFilter);
    initCloseBtn(selectizePinsFilter);
    initCloseBtn(selectizeTagsFilter);
  };

  const initDatePicker = () => {
    const init = () => {
      if (($('.shared-map-content').find('.js-exp-check')[0] as HTMLInputElement).checked) {
        $('.shared-map-content')
          .find('.exp-checkbox-wrap')
          .removeClass('d-none')
          .addClass('d-block');
      } else {
        $('.shared-map-content')
          .find('.exp-checkbox-wrap')
          .removeClass('d-block')
          .addClass('d-none');
      }
    };
    if ($('.shared-map-content').length !== 0) {
      init();
    }

    $('.exp-checkbox').on('click', () => {
      init();
    });
  };

  window.sharedMapInitModal = (newMap?: boolean) => {
    initDatePicker();

    if (newMap) {
      proceedNewMap();
    } else {
      proceedEditMap();
    }
  };

  initDatePicker();

  $('.toggle-password').on('click', function () {
    $(this).toggleClass('fa-eye fa-eye-slash');
    const input = $($(this).attr('toggle') as string);
    if (input.attr('type') == 'password') {
      input.attr('type', 'text');
    } else {
      input.attr('type', 'password');
    }
  });
});
