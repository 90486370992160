import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

import Cookies from 'js-cookie';
import { Dropdown } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DropdownBtn, { style } from '@/components/DropdownBtn';
import { DealTableProfileService } from '@/services/deal_table_profile_service';
import { Option } from '@/typings';

import { TableSettingsContext } from '../context/TableSettingsContext';
import { FiltersOptions, defaultFilters } from '../typings';
import CustomView from './CustomView';
import CustomViewModal from './CustomViewModal';

const CustomViews = (props: {
  restoreFilters: (filters: string) => void;
  clearAllFilters: (objectToFilter: string, defaultFilters: FiltersOptions) => void;
}) => {
  const { restoreFilters, clearAllFilters } = props;
  const { tableId, updateTableSettings } = useContext(TableSettingsContext);
  const [profiles, setProfiles] = useState<(Option & { active: boolean })[]>([]);
  const [showNewModal, setShowNewModal] = useState(false);
  const [editId, setEditId] = useState<number | null>(null);

  useEffect(() => {
    getProfiles();
  }, []);

  const getProfiles = async () => {
    const res = await DealTableProfileService.index();
    if (res.ok) {
      setProfiles(res.data);
      const activeProfile = res.data.find((profile) => profile.active);
      if (!!activeProfile) onSelect(activeProfile.id);
    } else console.error(res.error);
  };

  const handleSubmitNewModal = async (name: string) => {
    const filters = Cookies.get(`deals-index-saved-filters`) ?? '';
    const res = await DealTableProfileService.create({ name, filters, tableId });
    if (res.ok) {
      setProfiles([
        ...profiles.map((p) => ({ ...p, active: false })),
        { name, id: res.data, active: true },
      ]);
      setShowNewModal(false);
    } else console.error(res.error);
  };

  const handleSubmitEditModal = async (id: number, name: string) => {
    const res = await DealTableProfileService.update(id, name);
    if (res.ok) {
      setProfiles(
        profiles.map((profile) =>
          id === profile.id ? { id, name, active: profile.active } : profile
        )
      );
      setEditId(null);
    } else console.error(res.error);
  };

  const onSelect = async (id: number) => {
    const res = await DealTableProfileService.show(id);
    if (res.ok) {
      updateTableSettings(res.data.settings);
      restoreFilters(res.data.filters);
    } else console.error(res.error, res.error.responseText);
  };

  const onDelete = async (id: number) => {
    if (
      await window.customConfirm(
        'Delete Custom View',
        'Are you sure that you want to delete this custom view?'
      )
    ) {
      const res = await DealTableProfileService.destroy(id);
      if (res.ok) {
        clearAllFilters('deals', defaultFilters);
        setProfiles(profiles.filter((profile) => profile.id !== id));
      } else console.error(res.error);
    }
  };

  const onActivate = async (id: number) => {
    const res = await DealTableProfileService.activate(id);
    if (res.ok) {
      setProfiles(
        profiles.map((profile) =>
          id === profile.id
            ? { ...profile, active: res.data }
            : { ...profile, active: res.data ? false : profile.active }
        )
      );
      if (res.data) onSelect(id);
    } else console.error(res.error);
  };

  return (
    <>
      <CustomViewModal
        show={showNewModal}
        handleClose={() => setShowNewModal(false)}
        handleSubmit={handleSubmitNewModal}
      />
      <DropdownBtn
        title='Custom views'
        dropdownClassName='me-3'
        btnClassName={style.btn}
        icon='grid'
        variant='light'
        chevron='chevron-down'
        autoClose='outside'
      >
        <>
          <Dropdown.Item
            className={profiles.length ? style.line : ''}
            onClick={() => setShowNewModal(true)}
          >
            <div className='d-flex align-items-center'>
              <div>
                <FontAwesomeIcon icon='floppy-disk' className='me-2' />
              </div>
              <span>Save Current View</span>
            </div>
          </Dropdown.Item>
          <Dropdown.Header className={style.header}>My Saved Views</Dropdown.Header>
          {profiles.map((profile) => (
            <React.Fragment key={profile.id}>
              <CustomView
                profile={profile}
                handleSelect={onSelect}
                handleEdit={(id) => setEditId(id)}
                handleActivate={onActivate}
              />
              <CustomViewModal
                show={profile.id === editId}
                handleClose={() => setEditId(null)}
                handleSubmit={(name) => handleSubmitEditModal(profile.id, name)}
                handleDelete={onDelete}
                profile={profile}
              />
            </React.Fragment>
          ))}
        </>
      </DropdownBtn>
    </>
  );
};

export default CustomViews;
