import * as React from 'react';
import { createContext } from 'react';

import { useErrorState } from '@/hooks';
import { Error, ErrorTuple } from '@/typings';

type ErrorState = {
  errors: ErrorTuple[];
  addError: (errorMessage: string, error: Error) => void;
  removeError: (id: number) => void;
};

export const ErrorContext = createContext<ErrorState>(null as unknown as ErrorState);

export const ErrorsProvider: React.FC = ({ children }) => {
  const [errors, addError, removeError] = useErrorState();

  return (
    <ErrorContext.Provider
      value={{
        errors,
        addError,
        removeError,
      }}
    >
      {children}
    </ErrorContext.Provider>
  );
};
