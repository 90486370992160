import React, { useState } from 'react';

import { Form } from 'react-bootstrap';
import Select from 'react-select';

import Modal from '@/components/Modal';
import { Option, SetState } from '@/typings';

const ArchiveModal = (props: {
  users: Option[];
  show: boolean;
  setShow: SetState<boolean>;
  onSave: (newUserId: number) => void;
}) => {
  const { users, show, setShow, onSave } = props;
  const user = users[0];
  const [newUser, setNewUser] = useState<Option>(user);

  const handleClose = () => {
    setShow(false);
    setNewUser(user);
  };

  const handleSave = () => {
    onSave(newUser.id);
    handleClose();
  };

  return (
    <Modal
      modalProps={{ show, backdrop: 'static' }}
      title='Archive User'
      onClose={handleClose}
      onSave={handleSave}
    >
      <p className='fst-italic'>
        Select an active users to assign all pins and tasks to in order to archive this user
      </p>
      <Form.Group>
        <Form.Label>New user</Form.Label>
        <Select
          defaultValue={user}
          onChange={(newValue) => setNewUser(newValue ?? user)}
          options={users}
          closeMenuOnSelect
          getOptionValue={(option) => option.id.toString()}
          getOptionLabel={(option) => option.name}
        />
      </Form.Group>
    </Modal>
  );
};

export default ArchiveModal;
