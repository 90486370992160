import * as React from 'react';
import { useState } from 'react';

import HtmlParser from 'html-react-parser';

import CellIcon from '@/components/CellIcon';
import { highlightSearchString } from '@/utils';

import TagModal from './TagModal';
import { SelectionType, Tag } from './typings';

const TagRow = (props: {
  tag: Tag;
  searchString: string;
  deleteTag: (tag: Tag) => void;
  updateTag: (tag: { id: number; name: string; selectionType: SelectionType }) => Promise<void>;
  restoreTag: (tag: Tag) => void;
  activeNames: string[];
}) => {
  const { tag, searchString, deleteTag, updateTag, restoreTag, activeNames } = props;
  const [showBtns, setShowBtns] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const highlightedText =
    typeof tag.name === 'string'
      ? HtmlParser(highlightSearchString(tag.name, searchString))
      : tag.name;

  const update = (newTag: { name: string; selectionType: SelectionType }) =>
    updateTag({ ...newTag, id: tag.id });

  return (
    <>
      <TagModal
        saveTag={update}
        title='Edit Tag'
        initTag={tag}
        show={showEditModal}
        setShow={setShowEditModal}
        activeNames={activeNames}
      />
      <tr
        className={`tags-item fs-14 fuscous-gray-color ${!!tag.deletedAt ? 'inactive' : ''}`}
        onMouseEnter={() => setShowBtns(true)}
        onMouseLeave={() => setShowBtns(false)}
      >
        <td className='ps-4'>{highlightedText}</td>

        {!!tag.deletedAt ? (
          <td className='w-55p'>
            <CellIcon
              className='pointer'
              onClick={() => restoreTag(tag)}
              icon='undo-alt'
              show={showBtns}
            />
          </td>
        ) : (
          <td className='w-55p d-flex justify-content-between'>
            <CellIcon
              className='pointer'
              onClick={() => setShowEditModal(true)}
              icon='pencil-alt'
              show={showBtns}
            />
            <CellIcon
              className='pointer'
              onClick={() => deleteTag(tag)}
              icon='trash-alt'
              show={showBtns}
            />
          </td>
        )}
      </tr>
    </>
  );
};

export default TagRow;
