import * as React from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

import { AjaxRequest, RequestMethod } from '@/typings';

import { TableSettings } from '../typings';
import { ErrorContext } from './ErrorContext';
import { LoadingContext } from './LoadingContext';

type TableSettingsState = {
  tableId: number;
  tableSettings: TableSettings;
  updateTableSettings: (tableSettings: TableSettings) => void;
};

const defaultState = {
  tableId: 0,
  tableSettings: [
    ['id', true] as [string, boolean],
    ['nickname', true] as [string, boolean],
    ['opportunities', true] as [string, boolean],
    ['tags', true] as [string, boolean],
    ['formatted_address', true] as [string, boolean],
    ['city', true] as [string, boolean],
    ['state', true] as [string, boolean],
    ['postal_code', true] as [string, boolean],
    ['updated_at', true] as [string, boolean],
    ['related_updated_at', true] as [string, boolean],
    ['broker', true] as [string, boolean],
    ['followers', true] as [string, boolean],
    ['working_users', true] as [string, boolean],
    ['assemblage', true] as [string, boolean],
  ],
  updateTableSettings: (_: TableSettings) => {},
};

export const TableSettingsContext = createContext<TableSettingsState>(defaultState);

export const TableSettingsProvider: React.FC = ({ children }) => {
  const [tableSettings, setTableSettings] = useState<TableSettings>(defaultState.tableSettings);
  const [tableId, setTableId] = useState<number>(defaultState.tableId);
  const { loading } = useContext(LoadingContext);
  const { addError } = useContext(ErrorContext);

  useEffect(() => {
    const req: AjaxRequest = {
      url: 'ajax-deals-table-settings',
      method: RequestMethod.Get,
      dataType: 'json',
    };
    loading(async () => {
      try {
        const res = await $.ajax(req);
        setTableSettings(res.settings);
        setTableId(res.id);
      } catch (error: any) {
        addError('Error in TableSettingsContext', {
          status: error?.status,
          statusText: error?.statusText,
          error,
        });
      }
    });
  }, []);

  const updateTableSettings = async (tableSettings: TableSettings) => {
    const req: AjaxRequest = {
      url: 'ajax-deals-update-table-settings',
      method: RequestMethod.Put,
      dataType: 'json',
      data: {
        deals_table: tableSettings.sort(sortHiddenLast).reduce(formatTableSettings, {}),
      },
    };
    try {
      await $.ajax(req);
      setTableSettings(tableSettings);
    } catch (error: any) {
      addError('Error in TableSettingsProvider: ', {
        status: error.status,
        statusText: error.statusText,
        error,
      });
    }
  };

  return (
    <TableSettingsContext.Provider
      value={{
        tableId,
        tableSettings,
        updateTableSettings,
      }}
    >
      {children}
    </TableSettingsContext.Provider>
  );
};

const formatTableSettings = (acc: Record<string, number>, item: [string, boolean], i: number) => {
  const newItem = [...item] as [string, boolean];
  switch (newItem[0]) {
    case 'id':
      newItem[0] = 'pin_id';
      break;
    case 'updated_at':
      newItem[0] = 'deal_updated_at';
      break;
  }
  if (newItem[1]) acc[newItem[0]] = i + 1;
  else acc[newItem[0]] = 0;
  return acc;
};

const sortHiddenLast = (a: [string, boolean], b: [string, boolean]) => {
  if (a[1] === b[1]) return 0;
  if (b[1]) return 1;
  return -1;
};
