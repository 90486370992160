import {
  BaseCommunication,
  UpdateCommunication,
} from '@/containers/DealWindow/CommunicationTab/typings';
import { DataType, RequestMethod, Result } from '@/typings';
import { fetchResult } from '@/utils';

export const CommunicationService = {
  index: (dealId: number, searchString: string, currentPage: number): Promise<Result> => {
    const q = { communication_search_cont: searchString };
    const data = { deal_id: dealId, current_page: currentPage, q };
    const body = $.param(data);
    const url = '/communications?' + body;
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  create: (dealId: number, com: BaseCommunication): Promise<Result> => {
    const { kind, ...communication } = com;
    const data = {
      deal_id: dealId,
      communication: { ...communication, kind: kind?.toLocaleLowerCase() },
    };
    const body = JSON.stringify(data);
    const url = '/communications';
    const req = { url, method: RequestMethod.Post, dataType: DataType.JSON, body };
    return fetchResult(req);
  },
  update: (com: UpdateCommunication): Promise<Result> => {
    const { id, kind, ...communication } = com;
    const data = { communication: { ...communication, kind: kind?.toLocaleLowerCase() } };
    const body = JSON.stringify(data);
    const url = `/communications/${id}`;
    const req = { url, method: RequestMethod.Put, dataType: DataType.JSON, body };
    return fetchResult(req);
  },
  destroy: (id: number): Promise<Result> => {
    const url = `/communications/${id}`;
    const req = { url, method: RequestMethod.Delete, dataType: DataType.JSON };
    return fetchResult(req);
  },
};
