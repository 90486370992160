import React, { useEffect } from 'react';
import { useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import ReactLassoSelect, { getCanvas } from 'react-lasso-select';
import { Point } from 'react-lasso-select/lib/helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CropModal = (props: {
  loading: boolean;
  show: boolean;
  setShow: (bool: boolean) => void;
  file: { url: string; name: string };
  uploadFile: (formData: FormData) => void;
}) => {
  const { show, setShow, loading, file, uploadFile } = props;
  const [points, setPoints] = useState<Point[]>([]);
  const [previewCanvas, setPreviewCanvas] = useState<HTMLCanvasElement>();

  useEffect(() => {
    setPreviewCanvas(undefined);
    setPoints([]);
  }, [file]);

  const handleSave = () => {
    if (!previewCanvas) return alert('Please choose and crop image');
    const formData = new FormData();
    previewCanvas.toBlob((blob) => {
      formData.append('file', blob!, file.name);
      uploadFile(formData);
    });
    setShow(false);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} fullscreen backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>Crop Site Plan</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {loading ? (
          <FontAwesomeIcon icon='spinner' pulse />
        ) : (
          <>
            <div>
              <p className='mb-2'>Crop:</p>
              <ReactLassoSelect
                value={points}
                src={file.url}
                imageStyle={{ maxWidth: '100%' }}
                onComplete={(value) => {
                  setPoints(value);
                  if (!value.length) return;
                  getCanvas(file.url, value, (err, canvas) => {
                    if (!err) {
                      setPreviewCanvas(canvas);
                    } else console.error('Error in ReactLassoSelect:', err);
                  });
                }}
              />
            </div>
            {previewCanvas && (
              <div className='mt-2'>
                <p className='mb-2'>Preview:</p>
                <img src={previewCanvas.toDataURL()} alt='clipped' className='mw-100' />
              </div>
            )}
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <div className='modal-cancel-btn pointer' onClick={handleClose}>
          Cancel
        </div>
        <div className='btn modal-submit-btn fw-600 pointer' onClick={handleSave}>
          Save
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CropModal;
