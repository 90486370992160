import React, { useContext, useEffect, useState } from 'react';

import { NotesContext } from '@/contexts/NotesContext';
import { useEffectInfiniteScroll } from '@/hooks';
import { NoteService } from '@/services/note_service';
import { camelize } from '@/utils';

import { UserOption } from '../typings';
import AddNote from './AddNote';
import Row from './Row';
import { Note } from './typings';

const NotesTab = (props: { show: boolean; users: UserOption[]; dealId: number }) => {
  const { show, users, dealId } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number | null>(null);
  const { notes, setNotes, createNote } = useContext(NotesContext);

  const getNotes = async (notePage: number) => {
    const res = await NoteService.index(dealId, notePage);
    if (res.ok) {
      const result = res.data;
      let newNotes = camelize(result.notes) as Note[];
      newNotes = result.current_notes_page !== 1 ? [...notes, ...newNotes] : newNotes;
      setNotes(newNotes);
      setCurrentPage(result.current_notes_page);
      setTotalPages(result.total_notes_pages);
    } else console.error('Error in getNotes: ', res.error);
  };

  const updateNote = async (id: number, text: string) => {
    const res = await NoteService.update(id, text);
    if (res.ok) {
      const newNote = camelize(res.data) as Note;
      const newNotes = !newNote.parentNoteId
        ? notes.map((note) => (note.id !== newNote.id ? note : newNote))
        : notes.map((note) => {
            if (note.id !== newNote.parentNoteId) return note;
            const notes = note.notes!.map((childNote) =>
              childNote.id !== newNote.id ? childNote : newNote
            );
            return { ...note, notes };
          });
      setNotes(newNotes);
    } else console.error('Error in updateNote: ', res.error);
  };

  const deleteNote = async (id: number, parentId?: number) => {
    const res = await NoteService.destroy(id);
    if (res.ok) {
      const newNotes = !parentId
        ? notes.filter((note) => note.id !== id)
        : notes.map((note) => {
            if (note.id !== parentId) return note;
            return { ...note, notes: note.notes!.filter((childNote) => childNote.id !== id) };
          });
      setNotes(newNotes);
    } else console.error('Error in deleteNote: ', res.error);
  };

  const stopCondition = currentPage === totalPages || !show;
  useEffectInfiniteScroll(
    '.explore-overflow-deal-block',
    stopCondition,
    () => getNotes(currentPage + 1),
    [stopCondition]
  );

  useEffect(() => {
    getNotes(1);
  }, [dealId]);

  if (!show) return null;
  return (
    <div>
      <AddNote users={users} />
      {!notes?.length ? (
        <div className='d-flex justify-content-center'>
          <p className='fs-14 mt-2 silver-color fst-italic'>No Notes Retrieved</p>
        </div>
      ) : (
        <div className='deal-window-notes'>
          {notes.map((note) => (
            <Row
              key={note.id}
              note={note}
              users={users}
              createNote={createNote}
              updateNote={updateNote}
              deleteNote={deleteNote}
              getNotes={getNotes}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default NotesTab;
