import React, { useEffect, useState } from 'react';

import ErrorComponent from '@/components/ErrorComponent';
import HeaderBlock, { HeaderBlockEnd } from '@/components/HeaderBlock';
import Pagination from '@/components/Pagination';
import SearchForm from '@/components/SearchForm';
import Table from '@/components/Table';
import TableBody from '@/components/Table/TableBody';
import TableHead from '@/components/Table/TableHead';
import TextWithIconBtn from '@/components/TextWithIconBtn';
import { useEffectNotFirstRender, useErrorState } from '@/hooks';
import { SharedMapService } from '@/services/shared_map_service';
import { SortMethod } from '@/typings';

import { TableRow } from './TableRow';

export type SharedMap = {
  id: number;
  name: string;
  remote_link: string;
  emails: string[];
  expiration_date?: string;
  last_viewed?: string;
};

export const SharedMaps = (props: {
  admin: boolean;
  shared_maps: SharedMap[];
  total_pages: number;
}) => {
  const isAdmin = props.admin;
  const [sharedMaps, setSharedMaps] = useState(props.shared_maps);
  const [totalPages, setTotalPages] = useState(props.total_pages);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [perPage, setPerPage] = useState(12);
  const [sort, setSort] = useState({ entity: 'created_at', method: SortMethod.Desc });

  const [errors, addError, removeError] = useErrorState();

  const getSharedMaps = async () => {
    const res = await SharedMapService.index(currentPage, perPage, searchString, sort);
    if (res.ok) {
      setSharedMaps(res.data.shared_maps);
      setTotalPages(res.data.total_pages);
    } else addError('Error in getSharedMaps:', res);
  };

  useEffectNotFirstRender(() => {
    getSharedMaps();
  }, [currentPage, sort, searchString, perPage]);

  useEffect(() => {
    window.sharedMapInitModal(true);
  }, []);

  return (
    <div className='shared-map-page'>
      <ErrorComponent errors={errors} removeError={removeError} />
      <HeaderBlock h1='Maps'>
        <SearchForm
          onChange={(e) => setSearchString($(e.target).val()?.toString() ?? '')}
          placeholder='Search Shared Maps...'
          className='search-input'
          wrapperClassName='mx-4'
        />
        {isAdmin && (
          <HeaderBlockEnd>
            <TextWithIconBtn
              icon='plus-circle'
              text='Add Map'
              onClick={() => $('#staticSharedMapNew').modal('show')}
              className='add-new-btn h-100'
            />
          </HeaderBlockEnd>
        )}
      </HeaderBlock>
      <div>
        <Table>
          <>
            <TableHead
              setSort={setSort}
              columns={columns}
              defaultSort={{ entity: 'created_at', method: SortMethod.Desc }}
            />
            <TableBody isResultPresent={sharedMaps.length > 0} columnsLength={columns.length}>
              {sharedMaps.map((sharedMap) => (
                <TableRow
                  key={sharedMap.id}
                  isAdmin={isAdmin}
                  searchString={searchString}
                  sharedMap={sharedMap}
                />
              ))}
            </TableBody>
          </>
        </Table>
        <Pagination
          showAllHandler={() => setPerPage(-1)}
          clickHandler={setCurrentPage}
          totalPages={totalPages}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

const columns = [
  { children: 'Map Name', entity: 'name', className: 'table-column-middle' },
  { children: 'Shared Link', entity: 'remote_link', className: 'table-column-middle' },
  { children: 'Shared With', entity: 'emails', className: 'table-column-long' },
  { children: 'Expiration', entity: 'expiration_date' },
  { children: 'Last Viewed', entity: 'updated_at' },
  { children: '', entity: 'buttons', className: 'table-column-checkbox' },
];
