import * as React from 'react';

const PaginationBtns = ({ totalPages, currentPage, handleClick }: PaginationBtnsProps) => {
  const paginationElipses = <a className='page-link pointer'>...</a>;
  const paginationItem = (j: number) => (
    <a onClick={handleClick} className='page-link pointer'>
      {j}
    </a>
  );

  const renderPaginationBtn = (j: number): JSX.Element | null => {
    if (currentPage === j) return <a className='page-link pointer'>{j}</a>;
    if (totalPages <= 9) return paginationItem(j);
    if (currentPage <= 5) {
      if (j > 7 && j < totalPages - 1) return null;
      if (j === totalPages - 1) return paginationElipses;
      return paginationItem(j);
    }
    if (currentPage >= totalPages - 5) {
      if (j > 2 && j <= totalPages - 7) return null;
      if (j === 2) return paginationElipses;
      return paginationItem(j);
    }
    if (j > 2 && j < currentPage - 2) return null;
    if (j < totalPages - 1 && j > currentPage + 2) return null;
    if (j === 2 || j === totalPages - 1) return paginationElipses;
    return paginationItem(j);
  };

  return (
    <>
      {[...Array(totalPages)].map((_, i) => {
        const j = ++i;
        return (
          <li key={j} className={`page-item ${currentPage === j ? 'active' : ''}`}>
            {renderPaginationBtn(j)}
          </li>
        );
      })}
    </>
  );
};

export default PaginationBtns;

type PaginationBtnsProps = {
  totalPages: number;
  currentPage: number;
  handleClick: React.MouseEventHandler;
};
