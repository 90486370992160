import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

import DropdownBtn, { style } from '@/components/DropdownBtn';
import Filter from '@/components/Filter';

import { StasContext } from '../context/StasContext';
import { FiltersOptions, defaultFilters } from '../typings';

const Filters = (props: {
  restoreSelectedFiltersFromSaved: (savedFilters: FiltersOptions) => void;
  setSavedFiltersFromSelected: (obj: string, selectedFilters: FiltersOptions) => void;
  clearAllFilters: (obj: string, defaultFilters: FiltersOptions) => void;
  getAvailableFilters: () => void;
}) => {
  const {
    restoreSelectedFiltersFromSaved,
    setSavedFiltersFromSelected,
    getAvailableFilters,
    clearAllFilters,
  } = props;
  const { availableFilters, selectedFilters, setSelectedFilters, savedFilters } =
    useContext(StasContext);
  const [show, setShow] = useState(false);

  useEffect(() => {
    getAvailableFilters();
  }, []);

  const onToggle = (show: boolean) => {
    setShow(show);
    if (!show) restoreSelectedFiltersFromSaved(savedFilters);
  };

  const onSave = () => {
    setShow(false);
    setSavedFiltersFromSelected('deals', selectedFilters);
  };

  const onClear = () => {
    setShow(false);
    clearAllFilters('deals', defaultFilters);
  };

  return (
    <DropdownBtn
      title='Filters'
      variant='light'
      btnClassName={style.btn}
      dropdownClassName='me-3'
      text=' Filters'
      icon='filter'
      onToggle={onToggle}
      show={show}
    >
      {show && (
        <>
          <Filter
            label='Tenant'
            selectedOptions={selectedFilters.opportunities}
            setSelectedOptions={setSelectedFilters.opportunities}
            options={availableFilters.opportunities}
          />
          <Filter
            label='Tags'
            selectedOptions={selectedFilters.tags}
            setSelectedOptions={setSelectedFilters.tags}
            options={availableFilters.tags}
          />
          <Filter
            label='Working Deal'
            selectedOptions={selectedFilters.users}
            setSelectedOptions={setSelectedFilters.users}
            options={availableFilters.users}
          />
          <Filter
            label='Following Deal'
            selectedOptions={selectedFilters.followers}
            setSelectedOptions={setSelectedFilters.followers}
            options={availableFilters.users}
          />
          <Filter
            label='Pin Type'
            selectedOptions={selectedFilters.pins}
            setSelectedOptions={setSelectedFilters.pins}
            options={availableFilters.pins}
          />
          <Filter
            label='Transaction Type'
            selectedOptions={selectedFilters.transactionTypes}
            setSelectedOptions={setSelectedFilters.transactionTypes}
            options={availableFilters.transactionTypes}
          />
          <Filter
            label='Cities'
            selectedOptions={selectedFilters.cities}
            setSelectedOptions={setSelectedFilters.cities}
            options={availableFilters.cities.map(stringToOption)}
          />
          <Filter
            label='States'
            selectedOptions={selectedFilters.states}
            setSelectedOptions={setSelectedFilters.states}
            options={availableFilters.states.map(stringToOption)}
          />
          <Filter
            label='Zip'
            selectedOptions={selectedFilters.zips}
            setSelectedOptions={setSelectedFilters.zips}
            options={availableFilters.zips.map(stringToOption)}
          />
          <Filter
            label='Broker'
            selectedOptions={selectedFilters.brokers}
            setSelectedOptions={setSelectedFilters.brokers}
            options={availableFilters.brokers}
          />

          <li className='d-flex align-items-center justify-content-between'>
            <a type='button' className='fs-14 filters-btn' onClick={onClear}>
              Clear filters
            </a>

            <div className='btn fs-14 add-new-btn mr-15 hover-btn' onClick={onSave}>
              Save
            </div>
          </li>
        </>
      )}
    </DropdownBtn>
  );
};

const stringToOption = (str: string) => ({ id: str, name: str });

export default Filters;
