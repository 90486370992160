import * as React from 'react';

import { TabType } from '@/typings';

const Tab = <T extends string>(props: {
  tab: TabType<T>;
  activeTab: T;
  setActiveTab: (name: T) => void;
}) => {
  const { tab, activeTab, setActiveTab } = props;
  const { id, name, text, className } = tab;

  const isActive: boolean = name === activeTab;

  return (
    <li className='nav-item'>
      <button
        className={`nav-link ${
          isActive ? 'active' : ''
        } source-sans-semi-bold-font fuscous-gray-color ${className ?? 'ml-20'}`}
        id={id}
        type='button'
        aria-controls={id}
        aria-selected={isActive}
        onClick={() => setActiveTab(name)}
      >
        {text}
      </button>
    </li>
  );
};

export default Tab;
