import { AjaxRequest, DataType, FetchRequest, Result } from '@/typings';

export const ajaxResult = async (req: AjaxRequest): Promise<Result> => {
  try {
    const data = await $.ajax(req);
    return { ok: true, data };
  } catch (error: any) {
    return { status: error.status, statusText: error.statusText, error: error };
  }
};

export const fetchResult = async (req: FetchRequest): Promise<Result> => {
  req.headers = addCSRFToken(req.headers);
  req.headers = addContentType(req.headers, req.dataType);
  const { url, ...request } = req;
  const res = await fetch(url, request);
  try {
    const data = await res.json();
    if (res.ok) return { ok: true, data };
    else return { status: res.status, statusText: res.statusText, error: res, data };
  } catch (error: any) {
    return { status: res.status, statusText: res.statusText, error: res, data: error };
  }
};

const addCSRFToken = (headers: Headers | undefined) => {
  const csrfMetaTag = document.querySelector('[name=csrf-token]') as HTMLMetaElement;
  if (!headers) headers = new Headers();
  headers.append('X-CSRF-TOKEN', csrfMetaTag.content);
  return headers;
};

const addContentType = (headers: Headers, dataType: DataType | undefined): Headers => {
  switch (dataType) {
    case 'json':
      headers.append('Content-Type', 'application/json');
      return headers;
    default:
      return headers;
  }
};
