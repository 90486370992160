import { CookiesService } from '@/services/cookies_service';

import { createTagMarker } from './marker_factory';
import { MapDeal, Marker, Markers } from './marker_manager';

class TagMarkerManager {
  private map: google.maps.Map;
  private tagMarkers: Marker[] = [];
  private cachedTagMarkers: Markers<Marker> = new Map();

  constructor(map: google.maps.Map) {
    this.map = map;
  }

  render(deals: MapDeal[], ids: Set<number>, zoom: number) {
    const renderedIds: Set<number> = new Set();

    if (!this.isVisible(zoom)) {
      this.clear();
      return;
    }

    for (let i = this.tagMarkers.length - 1; i >= 0; i--) {
      const m = this.tagMarkers[i];
      if (ids.has(m.dealId)) {
        renderedIds.add(m.dealId);
      } else {
        m.setMap(null);
        this.tagMarkers.splice(i, 1);
      }
    }

    const hasTags = (deal: MapDeal): deal is MapDeal & { tags: string } => {
      return !!deal.tags;
    };
    deals.forEach((deal) => {
      if (!renderedIds.has(deal.id) && hasTags(deal)) {
        let marker = this.cachedTagMarkers.get(deal.id);
        if (!marker) {
          marker = createTagMarker(deal);
          this.cachedTagMarkers.set(deal.id, marker);
        }
        this.tagMarkers.push(marker);
        marker.setMap(this.map);
      }
    });
  }

  clear() {
    this.tagMarkers.forEach((memoryMarker) => memoryMarker.setMap(null));
    this.tagMarkers.length = 0;
  }

  isVisible(zoom: number) {
    const isZoomValid = (zoom: number) => zoom > 13 && zoom < 19;
    return isZoomValid(zoom) && CookiesService.showTags.get();
  }

  setVisibility(show: boolean) {
    this.tagMarkers.forEach((marker) => marker.setVisible(show));
  }

  clearMarker(dealId: number) {
    for (let i = this.tagMarkers.length - 1; i >= 0; i--) {
      if (this.tagMarkers[i].dealId === dealId) {
        this.tagMarkers[i].setMap(null);
        this.cachedTagMarkers.delete(dealId);
        this.tagMarkers.splice(i, 1);
        break;
      }
    }
  }
}

export default TagMarkerManager;
