import { Parcel } from '@/containers/DealWindow/Parcels/typings';
import { DataType, RequestMethod, Result } from '@/typings';
import { fetchResult } from '@/utils';

export const ParcelService = {
  index: (dealId: number): Promise<Result<{ parcels: Parcel[] }>> => {
    const url = `/parcels?deal_id=${dealId}`;
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  create: (
    deal_id: number,
    latitude: number,
    longitude: number,
    parcel_id: string
  ): Promise<Result<number>> => {
    const body = JSON.stringify({
      parcel: { deal_id, latitude, longitude, parcel_id },
    });
    const req = { url: `/parcels`, method: RequestMethod.Post, dataType: DataType.JSON, body };
    return fetchResult(req);
  },
  destroy: (id: number): Promise<Result> => {
    const req = { url: `/parcels/${id}`, method: RequestMethod.Delete, dataType: DataType.JSON };
    return fetchResult(req);
  },
};
