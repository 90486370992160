import { Option } from '@/typings';

export type Tag = Option & {
  deletedAt: string | undefined;
  selectionType: SelectionType;
};

export enum TabName {
  Pins = 'pins',
  Resources = 'resources',
}

export enum SelectionType {
  Pin = 'pin',
  Resource = 'resource',
}

export type TagRequestParams = {
  tag: {
    text: string | undefined;
    selection_type: string | undefined;
  };
};
