import React from 'react';
import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ReminderModal from '@/components/ReminderModal';
import { InfoDeal } from '@/containers/DealWindow/typings';

const ReminderButton = (props: { className: string; deal: InfoDeal }) => {
  const [openModal, setOpenModal] = useState(false);
  const { className, deal } = props;

  return (
    <>
      <div className={className}>
        <button
          className='btn init-icon-30 blue bor-rad-circle'
          type='button'
          onClick={() => setOpenModal(true)}
          title='Set Reminder'
        >
          <FontAwesomeIcon icon={['far', 'alarm-clock']} className='light-blue-color pointer' />
        </button>
      </div>
      <ReminderModal
        openModal={openModal}
        deal={deal}
        setOpenModal={setOpenModal}
        title='Set a Reminder'
      />
    </>
  );
};

export default ReminderButton;
