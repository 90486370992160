import * as React from 'react';
import { useState } from 'react';

import ExpandCommentsBtn from '@/components/DealWindow/ExpandCommentsBtn';

import { UserOption } from '../typings';
import NoteComponent from './NoteComponent';
import { Note } from './typings';

const Row = (props: {
  note: Note;
  users: UserOption[];
  updateNote: (id: number, text: string) => Promise<void>;
  deleteNote: (id: number, parentId?: number) => Promise<void>;
  createNote: (text: string, parentId?: number) => Promise<void>;
  getNotes?: (notePage: number) => Promise<void>;
  dealId?: number;
}) => {
  const { note, users, createNote, updateNote, deleteNote, getNotes } = props;
  const [showChildren, setShowChildren] = useState(false);
  const toggleChildrenVisibility = () => setShowChildren(!showChildren);

  return (
    <div className='deal-window-note deal-window-note-block mb-15'>
      <NoteComponent {...props} />
      <ExpandCommentsBtn
        toggleCommentsVisibility={toggleChildrenVisibility}
        commentsLength={note.notes?.length ?? 0}
        isCommentsVisible={showChildren}
      />
      {showChildren && (
        <div className='ml-30 deal-window-child-notes'>
          {note.notes?.map((note) => (
            <div key={note.id} className='deal-window-note-block'>
              <NoteComponent
                note={note}
                users={users}
                createNote={createNote}
                updateNote={updateNote}
                deleteNote={deleteNote}
                getNotes={getNotes}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Row;
