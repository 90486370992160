import Cookies from 'js-cookie';

import { toggleDealsListBlock } from '@/scripts/deal';
import { CLUSTER_MAX_ZOOM, map } from '@/scripts/map';
import { CookiesService } from '@/services/cookies_service';
import { DataType, RequestMethod, Result } from '@/typings';
import { fetchResult, parseIntNoNaN } from '@/utils';

import CustomRadius from './custom_radius';
import NewMarker from './new_marker';
import PinMarkerManager from './pin_marker_manager';
import Radius from './radius';
import SelectedMarkerCircle from './selected_marker_circle';
import TagMarkerManager from './tag_marker_manager';

export type Markers<T extends Marker> = Map<number, T>;
export type Marker = google.maps.Marker & { dealId: number };
export type PinMarker = Marker & {
  iconType: string;
  labelText?: string;
  markerClickListener?: google.maps.MapsEventListener;
};
export type MapDeal = {
  id: number;
  latitude: string;
  longitude: string;
  pin_color: string;
  pin_icon: string;
  nickname?: string;
  pin_custom_icon: string;
  tags?: string;
};

class MarkerManager {
  public map: google.maps.Map;
  private _newMarker: NewMarker;
  private _selectedMarkerCircle: SelectedMarkerCircle;
  private _radiusMarker: Radius;
  private _customRadiusMarker: CustomRadius;
  private _pinMarkerManager: PinMarkerManager;
  private _tagMarkerManager: TagMarkerManager;
  private prevZoom = 0;
  private currentBounds: google.maps.LatLngBounds | undefined;
  private controller: AbortController | undefined;
  private previousBody: string = '';
  private static instance: MarkerManager;

  constructor() {
    this._customRadiusMarker = new CustomRadius(map);
    this._radiusMarker = new Radius(map);
    this._selectedMarkerCircle = new SelectedMarkerCircle(map);
    this._newMarker = new NewMarker(map);
    this._pinMarkerManager = new PinMarkerManager(map);
    this._tagMarkerManager = new TagMarkerManager(map);

    this.map = map;
    MarkerManager.instance = this;
  }

  static getInstance(): MarkerManager {
    if (!MarkerManager.instance) {
      MarkerManager.instance = new MarkerManager();
    }
    return MarkerManager.instance;
  }

  get pinMarkerManager() {
    return this._pinMarkerManager;
  }

  get tagMarkerManager() {
    return this._tagMarkerManager;
  }

  get newMarker() {
    return this._newMarker;
  }

  get radiusMarker() {
    return this._radiusMarker;
  }

  get customRadiusMarker() {
    return this._customRadiusMarker;
  }

  get selectedMarkerCircle() {
    return this._selectedMarkerCircle;
  }

  get selectedMarker() {
    if (!this._selectedMarkerCircle.dealId) return null;
    else return this._pinMarkerManager.getMarker(this._selectedMarkerCircle.dealId);
  }

  bringSelectedMarkerToFront() {
    const zIndex = this.selectedMarkerCircle.marker.getZIndex();
    if (zIndex) this.selectedMarker?.setZIndex(zIndex + 1000);
  }

  async render(bounds: google.maps.LatLngBounds, url: string, changed = false) {
    const center = bounds.getCenter();
    const zoom = this.map.getZoom()!;
    const prevZoom = this.prevZoom;
    this.prevZoom = zoom;
    if ($('.shared-map').length === 0) this.updateLocationData(center);

    if (CookiesService.showPins.get()) {
      changed = changed || this.isRequestBodyChanged();
      if (
        prevZoom === zoom &&
        zoom <= CLUSTER_MAX_ZOOM &&
        !changed &&
        this.currentBounds?.contains(bounds.getNorthEast()) &&
        this.currentBounds?.contains(bounds.getSouthWest())
      ) {
        return;
      }

      // Create the new bounds object with the wider and higher corners
      // This is done to hide from user places where new markers are created
      const scale = 2;
      bounds = zoom <= 4 ? bounds : this.scaleBounds(scale, bounds, center);
      this.currentBounds = bounds;

      const deals = await this.getMapDeals(url, bounds);
      if (!deals) return;

      const ids: Set<number> = new Set();
      deals.forEach((d) => {
        ids.add(d.id);
      });

      if (deals.length) {
        this._pinMarkerManager.render(deals, ids, zoom, prevZoom);
        this._tagMarkerManager.render(deals, ids, zoom);

        toggleDealsListBlock(Array.from(ids).slice(0, 400));
      } else {
        this._tagMarkerManager.clear();
        this._pinMarkerManager.clear();
        toggleDealsListBlock([]);
        $('.explore-deals-block, .js-deals-hide-btn').addClass('d-none');
      }
    } else {
      this._tagMarkerManager.clear();
      this._pinMarkerManager.clear();
    }
  }

  private updateLocationData(center: google.maps.LatLng) {
    Cookies.set('current-latitude', `${center.lat()}`);
    Cookies.set('current-longitude', `${center.lng()}`);
  }

  private async getMapDeals(
    url: string,
    bounds: google.maps.LatLngBounds
  ): Promise<MapDeal[] | null> {
    this.controller?.abort();
    this.controller = new AbortController();
    const body = $.param(this.mapDealsRequestData(bounds));
    const req = {
      url: `${url}?` + body,
      dataType: DataType.JSON,
      method: RequestMethod.Get,
      signal: this.controller.signal,
    };
    const res: Result<MapDeal[]> = await fetchResult(req);
    if (res.ok) {
      return res.data;
    } else {
      console.error('getMapDeals', res.error);
      return null;
    }
  }

  private isRequestBodyChanged(): boolean {
    const body = $.param(this.mapDealsRequestData());
    if (this.previousBody === body) {
      return false;
    } else {
      this.previousBody = body;
      return true;
    }
  }

  private mapDealsRequestData(bounds?: google.maps.LatLngBounds) {
    const filters = CookiesService.explorePageFilters;
    const opportunities = filters.get('opportunities');
    const tags = filters.get('tags');
    const pins = filters.get('pins');
    const users = filters.get('users');
    const followers = filters.get('followers');
    const transaction_types = filters.get('transaction-types');
    const cities = filters.get('cities');
    const states = filters.get('states');
    const zip_codes = filters.get('zip_codes');
    const brokers = filters.get('brokers');
    const deal_id = parseIntNoNaN(Cookies.get('deal-id'));
    const show_related_deals = Cookies.get('show-related-deals') == 'true';
    const show_tags = this._tagMarkerManager.isVisible(this.map.getZoom()!);

    const data = {
      deal_id,
      show_related_deals,
      opportunities,
      tags,
      pins,
      users,
      followers,
      transaction_types,
      cities,
      states,
      zip_codes,
      brokers,
      show_tags,
    };
    if (!bounds) return data;
    else {
      const sw = bounds.getSouthWest();
      const ne = bounds.getNorthEast();
      const coords = {
        south_west_lat: sw.lat(),
        south_west_lng: sw.lng(),
        north_east_lat: ne.lat(),
        north_east_lng: ne.lng(),
      };
      return { ...data, ...coords };
    }
  }

  private scaleBounds(scale: number, bounds: google.maps.LatLngBounds, center: google.maps.LatLng) {
    const span = bounds.toSpan();
    const lat = (span.lat() * scale) / 2;
    const lng = (span.lng() * scale) / 2;
    const northEast = new google.maps.LatLng(center.lat() + lat, center.lng() + lng);
    const southWest = new google.maps.LatLng(center.lat() - lat, center.lng() - lng);
    return new google.maps.LatLngBounds(southWest, northEast);
  }
}

export default MarkerManager;
