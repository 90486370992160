import * as React from 'react';

import { isNonEmptyArray, isString } from '@/utils';

const Value = ({
  value,
  onClick,
  showEdit,
  sharedMapWindow,
  children,
}: {
  value?: { name: string }[] | string | null;
  onClick: React.MouseEventHandler;
  showEdit: boolean;
  sharedMapWindow?: boolean;
  children: React.ReactNode;
}) => {
  if (showEdit) return <>{children}</>;
  else if (value && value?.length !== 0)
    return (
      <div className='pointer' onClick={onClick}>
        {isNonEmptyArray(value) &&
          value.map((tag) => (
            <p key={tag.name} className='details-tags'>
              {tag.name}
            </p>
          ))}
        {isString(value) && <p>{value}</p>}
      </div>
    );
  else if (!sharedMapWindow)
    return (
      <p className='boulder-color pointer' onClick={onClick}>
        Edit
      </p>
    );
  else return null;
};

export default Value;
