import { City, DataType, RequestMethod, Result } from '@/typings';
import { fetchResult } from '@/utils';

export const CityService = {
  search: (
    input: string,
    state?: string,
    limit: null | number = 30
  ): Promise<Result<{ cities: City[] }>> => {
    const body = $.param({ search: input, state, limit });
    const url = `/cities/search?${body}`;
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  create: (city: { name: string; state: string }): Promise<Result<number>> => {
    const body = JSON.stringify({ city });
    const url = '/cities';
    const req = { url, method: RequestMethod.Post, dataType: DataType.JSON, body };
    return fetchResult(req);
  },
};
