import * as React from 'react';

import Alert from 'react-bootstrap/Alert';

import { ErrorTuple } from '@/typings';

const ErrorComponent = (props: { removeError: (id: number) => void; errors: ErrorTuple[] }) => {
  const { removeError, errors } = props;

  return (
    <>
      {errors.map(([id, error]) => {
        return (
          <Alert key={id} variant='danger' onClose={() => removeError(id)} dismissible>
            <Alert.Heading>Error:</Alert.Heading>
            <p>Status: {error.status}</p>
            <p>Status text: {error.statusText}</p>
            {error.data && (
              <p>Message: {!!error.data.errors ? error.data.errors : error.data.message}</p>
            )}
          </Alert>
        );
      })}
    </>
  );
};

export default ErrorComponent;
