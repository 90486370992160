import Cookies from 'js-cookie';

import { map } from '@/scripts/map';

type Layout = {
  btn: JQuery<HTMLElement>;
  typeId: string;
  active: boolean;
  checkbox?: JQuery<HTMLElement>;
};

type Layouts = {
  mapLayout: Layout;
  satelliteLayout: Layout;
  terrainLayout: Layout;
  hybridLayout: Layout;
};

const initMapTypeLayouts = () => {
  const currentMapType = Cookies.get('current-map-type');
  const mapLayout = {
    btn: $('.js-map-btn'),
    typeId: 'roadmap',
    active: 'roadmap' === currentMapType,
  };
  const satelliteLayout = {
    btn: $('.js-satellite-btn'),
    typeId: 'satellite',
    active: 'satellite' === currentMapType,
  };
  const terrainLayout = {
    btn: mapLayout.btn.find('.js-terrain-btn'),
    checkbox: mapLayout.btn.find('#terrain'),
    typeId: 'terrain',
    active: 'terrain' === currentMapType,
  };
  const hybridLayout = {
    btn: satelliteLayout.btn.find('.js-hybrid-btn'),
    checkbox: satelliteLayout.btn.find('#hybrid'),
    typeId: 'hybrid',
    active: 'hybrid' === currentMapType,
  };
  const layouts: Layouts = { mapLayout, satelliteLayout, terrainLayout, hybridLayout };

  initMapTypeBtns(layouts);
  onLayoutBtnClick(map, layouts);
};

const initMapTypeBtns = ({ mapLayout, satelliteLayout, terrainLayout, hybridLayout }: Layouts) => {
  const toggleMapTypeBtns = ([checkboxes, remove, add]: Props) => {
    checkboxes.forEach((checkbox) => checkbox.checkbox?.prop('checked', checkbox.active));
    remove.removeClass('dropdown text-dark');
    add.addClass('dropdown text-dark');

    remove.children('.dropdown-content').addClass('d-none');
    add.children('.dropdown-content').removeClass('d-none');
  };
  const checkboxes = [hybridLayout, terrainLayout];
  type Props = [Layout[], JQuery<HTMLElement>, JQuery<HTMLElement>];
  const props: Props =
    mapLayout.active || terrainLayout.active
      ? [checkboxes, satelliteLayout.btn, mapLayout.btn]
      : [checkboxes, mapLayout.btn, satelliteLayout.btn];
  toggleMapTypeBtns(props);
};

const onLayoutBtnClick = (map: google.maps.Map, layouts: Layouts) => {
  const setMapTypeId = (layout: Layout) => {
    if (layout.btn.hasClass('dropdown')) return;
    if (layout.checkbox?.is(':checked')) {
      map.setMapTypeId(
        layout === layouts.terrainLayout ? layouts.mapLayout.typeId : layouts.satelliteLayout.typeId
      );
    } else {
      map.setMapTypeId(
        layout === layouts.satelliteLayout ? layouts.hybridLayout.typeId : layout.typeId
      );
    }
    map.setTilt(0);
    initMapTypeLayouts();
  };
  Object.keys(layouts).forEach((key) => {
    const layout = layouts[key as keyof Layouts];

    layout.btn.off('click').on('click', () => {
      if (layout.btn.children('.dropdown-content').hasClass('d-none')) {
        layout.btn.children('.dropdown-content').removeClass('d-none');
      } else {
        layout.btn.children('.dropdown-content').addClass('d-none');
      }
      setMapTypeId(layout);
    });
    if (!layout.checkbox) return;

    layout.checkbox.off('click').on('click', () => {
      setMapTypeId(layout);
    });
  });
};

export default initMapTypeLayouts;
