import { CustomField } from '@/containers/Settings/CustomFields';

import { CustomFieldData, FieldType } from '../containers/DealWindow/CustomFields/typings';
import { DataType, Option, RequestMethod, Result } from '../typings';
import { ajaxResult, fetchResult } from '../utils';

export const CustomFieldService = {
  index: (q: Record<string, string>): Promise<Result<CustomField[]>> => {
    const body = $.param({ q });
    const url = `/custom_fields?` + body;
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  updateOrderNumber: (custom_fields: number[]): Promise<Result<'ok'>> => {
    const body = JSON.stringify({ custom_fields });
    const url = '/ajax-custom-fields-update-order-number';
    const req = { url, method: RequestMethod.Patch, dataType: DataType.JSON, body };
    return fetchResult(req);
  },
  create: (customField: {
    name: string;
    privacy: 'private' | 'public';
    fieldType: FieldType;
    pins: Option[];
  }): Promise<Result<number>> => {
    const body = JSON.stringify({
      custom_field: {
        name: customField.name,
        privacy: customField.privacy,
        field_type: customField.fieldType,
        pins: customField.pins.map((pin) => pin.id),
        location: 0,
      },
    });
    const url = '/custom_fields';
    const req = { url, method: RequestMethod.Post, dataType: DataType.JSON, body };
    return fetchResult(req);
  },
  update: (customField: {
    id: number;
    name: string;
    privacy: 'private' | 'public';
    fieldType: FieldType;
    pins: Option[];
  }): Promise<Result<CustomField>> => {
    const body = JSON.stringify({
      custom_field: {
        name: customField.name,
        privacy: customField.privacy,
        field_type: customField.fieldType,
        pins: customField.pins.map((pin) => pin.id),
      },
    });
    const url = '/custom_fields/' + customField.id;
    const req = { url, method: RequestMethod.Put, dataType: DataType.JSON, body };
    return fetchResult(req);
  },
  destroy: (id: number): Promise<Result<CustomField>> => {
    const url = `/custom_fields/${id}`;
    const req = { url, method: RequestMethod.Delete, dataType: DataType.JSON };
    return fetchResult(req);
  },
  restore: (id: number): Promise<Result<CustomField>> => {
    const url = `/custom_fields/${id}/restore`;
    const req = { url, method: RequestMethod.Put, dataType: DataType.JSON };
    return fetchResult(req);
  },

  createFromDeal: async (customField: CustomFieldData): Promise<Result> => {
    const data = { custom_field: customField };
    const url = '/custom_fields/create_from_deal_modal';
    const req = { url, method: RequestMethod.Post, dataType: 'json', data };
    return ajaxResult(req);
  },
  updateFromDeal: async (customField: CustomFieldData, id: number) => {
    const url = `/custom_fields/${id}/update_from_deal_modal`;
    const data = { custom_field: customField };
    const req = { url, method: RequestMethod.Put, data, dataType: 'json' };
    return ajaxResult(req);
  },
  destroyFromDeal: async (id: number): Promise<Result<'ok'>> => {
    const url = `/custom_fields/${id}/destroy_from_deal`;
    const req = { url, method: RequestMethod.Delete };
    return ajaxResult(req);
  },
};
