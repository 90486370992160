import * as React from 'react';
import { useState } from 'react';

import { Form, Modal } from 'react-bootstrap';

import DateInput from '@/components/DateInput';
import { SnoozeNotificationService } from '@/services/snooze_notification_service';
import { formatDateServer, nextDay } from '@/utils';

import { Notification } from './typings';

const SnoozeModal = (props: {
  show: boolean;
  setShow: (show: boolean) => void;
  notification: Notification<'Deal'>;
}) => {
  const { show, setShow, notification } = props;
  const [dueDate, setDueDate] = useState<Date | null | undefined>(
    notification.parentSnoozeNotificationDate
  );

  const handleClose = () => {
    setShow(false);
  };

  const handleSave = async () => {
    if (typeof dueDate === 'string') {
      setShow(false);
      return;
    }

    if (dueDate) {
      const res = await SnoozeNotificationService.create(
        notification.deal?.id ?? null,
        formatDateServer(dueDate),
        notification.id
      );
      if (res.ok) {
        setShow(false);
      } else console.error('Error in SnoozeModal: ', res.error, res.data);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>Snooze Deal Reminder</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {notification.deal && <p>{notification.deal.address}</p>}
        <Form.Group>
          <Form.Label>Set Snooze</Form.Label>
          <DateInput
            show={show}
            dueDate={dueDate}
            onChange={(selectedDates) => setDueDate(selectedDates[0])}
            config={{
              minDate: nextDay().setHours(0, 0, 0, 0),
              dateFormat: 'm/d/y',
            }}
          />
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
        <div className='modal-cancel-btn pointer' onClick={handleClose}>
          Cancel
        </div>
        <div className='btn modal-submit-btn fw-600 pointer' onClick={handleSave}>
          Save
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SnoozeModal;
