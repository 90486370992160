import React from 'react';

import { Modal } from 'react-bootstrap';

import style from '@/components/Modal/style.module.scss';
import { SetState } from '@/typings';

export const NoteConfirmationModal = (props: {
  setShowModal: SetState<boolean>;
  showModal: boolean;
  resolveFn: (value: boolean) => void;
}) => {
  const { setShowModal, showModal, resolveFn } = props;

  const handleConfirm = () => {
    document.dispatchEvent(new Event('saveNote'));
    setShowModal(false);
    resolveFn(true);
  };

  const handleCloseWithoutSaving = () => {
    setShowModal(false);
    resolveFn(true);
  };

  const handleCancel = () => {
    setShowModal(false);
    resolveFn(false);
  };

  return (
    <>
      <Modal show={showModal} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className='d-flex align-items-center justify-content-center'>
          <span className='fst-italic'>Do you want to save this note?</span>
        </Modal.Body>
        <Modal.Footer>
          <button className={style.cancelBtn} onClick={handleCloseWithoutSaving}>
            Close Without Saving
          </button>
          <button className={style.cancelBtn} onClick={handleCancel}>
            Cancel
          </button>
          <button className={style.saveBtn} onClick={handleConfirm}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
