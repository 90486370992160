import { Coordinates } from '@/typings';

class SelectedMarkerCircle {
  private _marker: google.maps.Marker;
  public dealId: number | null = null;

  constructor(map: google.maps.Map) {
    const markerOptions = {
      map,
      visible: false,
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        fillOpacity: 0.85,
        fillColor: '#ffffff',
        strokeOpacity: 1.0,
        strokeColor: '#ffffff',
        strokeWeight: 1.0,
        scale: 20, //pixels
      },
      zIndex: -5,
    } as google.maps.MarkerOptions;
    this._marker = new google.maps.Marker(markerOptions);
  }

  get marker() {
    return this._marker;
  }

  draw(position: google.maps.LatLng | Coordinates, dealId: number) {
    this.dealId = dealId;
    this._marker.setPosition(position);
    this._marker.setVisible(true);
  }

  clear() {
    this.dealId = null;
    this._marker.setPosition(null);
    this._marker.setVisible(false);
  }
}

export default SelectedMarkerCircle;
