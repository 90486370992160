import { DataType, Option, RequestMethod, Result } from '@/typings';
import { fetchResult } from '@/utils';

export const DealTableProfileService = {
  index: (): Promise<Result<(Option & { active: boolean })[]>> => {
    const url = '/deal_table_profiles';
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  show: (id: number): Promise<Result<{ settings: [string, boolean][]; filters: string }>> => {
    const url = `/deal_table_profiles/${id}`;
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  create: (data: { name: string; filters: string; tableId: number }): Promise<Result<number>> => {
    const url = '/deal_table_profiles';
    const { tableId, ...profile } = data;
    const body = JSON.stringify({ profile: { ...profile, deals_table_id: tableId } });
    const req = { url, method: RequestMethod.Post, dataType: DataType.JSON, body };
    return fetchResult(req);
  },
  destroy: (id: number): Promise<Result<'ok'>> => {
    const url = `/deal_table_profiles/${id}`;
    const req = { url, method: RequestMethod.Delete, dataType: DataType.JSON };
    return fetchResult(req);
  },
  update: (id: number, name: string): Promise<Result<'ok'>> => {
    const url = `/deal_table_profiles/${id}`;
    const body = JSON.stringify({ name });
    const req = { url, method: RequestMethod.Patch, dataType: DataType.JSON, body };
    return fetchResult(req);
  },
  activate: (id: number): Promise<Result<boolean>> => {
    const url = `/deal_table_profiles/${id}/activate`;
    const req = { url, method: RequestMethod.Patch, dataType: DataType.JSON };
    return fetchResult(req);
  },
};
