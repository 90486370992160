import * as React from 'react';
import { useRef, useState } from 'react';

import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Select, { MultiValue, SingleValue } from 'react-select';
import Creatable from 'react-select/creatable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tagify from '@yaireo/tagify';
import Tags from '@yaireo/tagify/dist/react.tagify';

import DateInput from '@/components/DateInput';
import { useEffectNotFirstRender } from '@/hooks';
import { SetState } from '@/typings';
import { tagifySettings } from '@/utils';
import { getPinIcon } from '@/utils/pin_icon';

import { UserOption } from '../DealWindow/typings';
import { Deal, DefaultTask, Tag, Task } from './typings';

const ModalTask = (props: {
  show: boolean;
  setShow: SetState<boolean>;
  title: string;
  saveTask: (task: Task, setTask: SetState<Task>) => Promise<void>;
  currentUserId: number;
  users: UserOption[];
  deals: Deal[] | undefined;
  initTask?: Task;
  followerNames?: string;
}) => {
  const { show, setShow, title, saveTask, initTask, currentUserId, users, deals } = props;
  const [task, setTask] = useState(initTask ?? DefaultTask);
  const [tags, setTags] = useState(task?.tags || []);
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState(task?.nameRaw);
  const [description, setDescription] = useState(task?.descriptionRaw);
  const [assignedTo, setAssignedTo] = useState<SingleValue<UserOption | undefined>>(
    users?.find((user) => user.id === (task?.assignedToId ?? currentUserId))
  );
  const [assignedDeal, setAssignedDeal] = useState<SingleValue<Deal | undefined>>(
    deals?.find((deal) => deal.id === task.dealId)
  );
  const [followers, setFollowers] = useState<MultiValue<UserOption>>(
    task.id
      ? users.filter((user) => task.followers!.map((follower) => follower.id).includes(user.id))
      : []
  );
  const [dueDate, setDueDate] = useState(task.dueDate ? new Date(task.dueDate) : undefined);

  const tagsRef = useRef<Tagify | undefined>();

  useEffectNotFirstRender(() => {
    setTask(initTask ?? DefaultTask);
  }, [initTask]);

  useEffectNotFirstRender(() => {
    setAssignedDeal(deals?.find((deal) => deal.id === task?.dealId));
  }, [deals]);

  const handleClose = () => {
    setValidated(false);
    setShow(false);
  };

  const handleSave = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValidName(name) || !isValidName(description)) {
      if (isNullOrEmptyString(description)) {
        tagsRef.current?.DOM.scope.classList.add('is-invalid');
      }
      setValidated(true);
      return;
    }
    tagsRef.current?.DOM.scope.classList.remove('is-invalid');

    saveTask(
      {
        ...task,
        assignedToId: assignedTo?.id,
        nameRaw: name,
        descriptionRaw: description,
        followers: followers.map((follower) => {
          return { id: follower.id };
        }),
        dealId: assignedDeal?.id,
        dueDate: dueDate ? new Date(dueDate).toLocaleDateString('en-GB') : task.dueDate,
        tags: tags,
      },
      setTask
    );
    setName('');
    setDescription('');
    setValidated(false);
    setShow(false);
  };

  const renderOption = (option: Readonly<any>) => (
    <div className='option'>
      <FontAwesomeIcon
        icon={getPinIcon(option.pin?.icon)}
        style={{ color: option.pin?.color }}
        className='me-2'
      />
      {option.address}
    </div>
  );

  return (
    <Modal show={show} onHide={handleClose} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSave}>
        <Modal.Body>
          <Form.Group className='mb-3'>
            <Form.Label>Task Name *</Form.Label>
            <Form.Control
              type='text'
              placeholder='Task Name'
              defaultValue={name}
              onChange={(e) => setName(e.target.value)}
              isInvalid={validated && isNullOrEmptyString(name)}
            />
            <Form.Control.Feedback type='invalid'>Name can not be empty</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Description *</Form.Label>
            <Tags
              InputMode='textarea'
              className='form-control min-h-10rem'
              tagifyRef={tagsRef}
              value={description ?? ''}
              settings={tagifySettings(users)}
              onChange={(e: any) => setDescription(e.detail.value.trim())}
            />
            {validated && isNullOrEmptyString(description) && (
              <div className='invalid-feedback d-block'>Description can not be empty</div>
            )}
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Due date</Form.Label>
            <DateInput
              show={show}
              dueDate={dueDate}
              onChange={(selectedDates) => setDueDate(selectedDates[0])}
              config={{
                enableTime: true,
                minDate: 'today',
                dateFormat: 'm/d/y h:i K',
              }}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Assigned to</Form.Label>
            <Select
              components={{ IndicatorSeparator: () => null }}
              onChange={setAssignedTo}
              defaultValue={assignedTo}
              options={users}
              isClearable={false}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => option.name}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Followers</Form.Label>
            <Select
              components={{ IndicatorSeparator: () => null }}
              onChange={setFollowers}
              isMulti
              defaultValue={followers}
              options={users}
              isClearable={false}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => option.name}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Assign to Saved Pin</Form.Label>
            <Select
              components={{ IndicatorSeparator: () => null }}
              onChange={setAssignedDeal}
              defaultValue={deals?.find((deal) => deal.id === assignedDeal?.id)}
              options={deals}
              isClearable={false}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={renderOption as any}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Tags</Form.Label>
            <Creatable
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              defaultValue={tags.map(toSelectOption)}
              options={tags.map(toSelectOption)}
              closeMenuOnSelect={true}
              isClearable={true}
              menuShouldScrollIntoView={false}
              onChange={(options) => {
                setTags(options.map(toTag));
              }}
              isMulti
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer className='justify-content-between'>
          <p className='ms-3 fst-italic fs-14 silver-color'>
            {' '}
            {task.createdBy && `Created by: ${task.createdBy}`}
          </p>
          <div className='ml-192'>
            <a type='button' className='me-4 fs-16 filters-btn gray-color' onClick={handleClose}>
              Cancel
            </a>
            <button type='submit' className='btn modal-submit-btn fw-600 pointer'>
              Save
            </button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalTask;

const isValidName = (name: string | undefined): boolean => {
  return !isNullOrEmptyString(name);
};

const isNullOrEmptyString = (str: string | undefined): boolean =>
  str ? str.trim().length === 0 : true;

const toSelectOption = (tag: Tag) => ({ label: tag.text, value: tag.id });
const toTag = (option: { label: string; value: string }): Tag => ({
  id: option.value,
  text: option.label,
});
