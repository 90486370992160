import * as React from 'react';

import Nav from 'react-bootstrap/Nav';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SetState } from '@/typings';

const Tabs = <T extends string>(props: {
  titles: T[];
  activeTabTitle: T;
  setActiveTabTitle: SetState<T>;
}) => {
  const { titles, activeTabTitle, setActiveTabTitle } = props;
  return (
    <Nav
      variant='tabs'
      className='deal-window-tabs'
      as='ul'
      activeKey={activeTabTitle}
      onSelect={(selectedKey) => {
        if (activeTabTitle === selectedKey) return;
        const title = titles.find((title) => title === selectedKey);
        if (title) setActiveTabTitle(title);
      }}
    >
      {titles.map((title) => (
        <Nav.Item as='li' className='deal-window-tab' key={title}>
          <Nav.Link as='button' eventKey={title} title={title}>
            <FontAwesomeIcon icon={getIcon(title)} />
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  );
};

export default Tabs;

const IconNames: Record<string, IconProp> = {
  Details: 'info-circle',
  History: 'clock',
  Tasks: 'check',
  Notes: 'comment',
  Communication: ['fass', 'phone-volume'],
  'Parcel Data': 'building',
};
const getIcon = (title: string): IconProp => {
  if (IconNames[title]) return IconNames[title];
  return 'question-circle';
};
