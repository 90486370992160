import * as React from 'react';
import { useState } from 'react';

// Components
import DetailsSectionHeader from '@/components/DealWindow/Details/SectionHeader';

import FileDropzone from './FileDropzone';

const Files = ({ dealId }: { dealId: number }) => {
  const [isExpanded, setExpanded] = useState(true);

  return (
    <div className='mt-20 ml-20 mr-20'>
      <DetailsSectionHeader
        title='Files'
        isBodyExpanded={isExpanded}
        onClick={() => setExpanded(!isExpanded)}
      />
      {isExpanded && <FileDropzone dealId={dealId} />}
    </div>
  );
};

export default Files;
