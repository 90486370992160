import * as React from 'react';
import { ReactNode } from 'react';

const Row = ({ children, name }: { children: ReactNode | ReactNode[]; name: string }) => (
  <div className='d-flex'>
    <p className='source-sans-semi-bold-font w-260p fuscous-gray-color'>{name}</p>
    {children}
  </div>
);

export default Row;
