import React, { useEffect, useState } from 'react';

import ErrorComponent from '@/components/ErrorComponent';
import { HeaderBlockEnd } from '@/components/HeaderBlock';
import SearchForm from '@/components/SearchForm';
import Table from '@/components/Settings/Table';
import TableBody from '@/components/Table/TableBody';
import TextWithIconBtn from '@/components/TextWithIconBtn';
import { useErrorState } from '@/hooks';
import { WorkingCompanyService } from '@/services/working_company_service';
import { Option } from '@/typings';

import CompanyModal from './CompanyModal';
import CompanyRow from './CompanyRow';

const Companies = (props: { show: boolean; setHeader: (elem: JSX.Element) => void }) => {
  const { show, setHeader } = props;
  const [errors, addError, removeError] = useErrorState();
  const [searchString, setSearchString] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [companies, setCompanies] = useState<(Option & { active: boolean })[]>([]);

  useEffect(() => {
    if (show)
      setHeader(
        <>
          <SearchForm
            onChange={(e) => setSearchString($(e.target)!.val()!.toString())}
            placeholder='Search Companies...'
            className='search-input'
            wrapperClassName='mx-4'
          />

          <HeaderBlockEnd>
            <TextWithIconBtn
              icon='plus-circle'
              text='Add Company'
              onClick={() => setShowAddModal(true)}
              className='box-shadow add-new-btn h-100'
            />
          </HeaderBlockEnd>
        </>
      );
  }, [show]);

  useEffect(() => {
    setLoading(true);
    getCompanies();
  }, [searchString]);

  const getCompanies = async () => {
    const res = await WorkingCompanyService.index({ name_cont: searchString });
    if (res.ok) {
      setCompanies(res.data);
      setLoading(false);
    } else addError('Error in getCompanies:', res);
  };

  const createCompany = async (name: string) => {
    const res = await WorkingCompanyService.create(name);
    if (res.ok) setCompanies([{ id: res.data, name, active: true }, ...companies]);
    else addError('Error in createCompany:', res);
  };

  const updateCompany = async (company: Option) => {
    const res = await WorkingCompanyService.update(company);
    if (res.ok)
      setCompanies(
        companies.map((com) => (com.id === company.id ? { ...company, active: com.active } : com))
      );
    else addError('Error in updateCompany:', res);
  };

  const deleteCompany = async (id: number) => {
    if (
      !(await window.customConfirm(
        'Archive Company',
        'Are you sure that you want to archive this company?'
      ))
    )
      return;

    const res = await WorkingCompanyService.destroy(id);
    if (res.ok) setCompanies(companies.map((com) => (com.id === id ? res.data : com)));
    else addError('Error in deleteCompany:', res);
  };

  const restoreCompany = async (id: number) => {
    if (
      !(await window.customConfirm(
        'Restore Company',
        'Are you sure that you want to restore this company?'
      ))
    )
      return;

    const res = await WorkingCompanyService.restore(id);
    if (res.ok) setCompanies(companies.map((com) => (com.id === id ? res.data : com)));
    else addError('Error in restoreCompany:', res);
  };

  return (
    <div>
      <CompanyModal
        show={showAddModal}
        title='Add Company'
        setShow={setShowAddModal}
        onSave={createCompany}
      />
      <ErrorComponent errors={errors} removeError={removeError} />

      <Table title='Companies'>
        <thead>
          <tr className='table-header-font'>
            <th className='w-25'>Name</th>
            <th />
          </tr>
        </thead>

        <TableBody isResultPresent={companies.length > 0} isLoading={isLoading} columnsLength={2}>
          {companies.map((company) => (
            <CompanyRow
              key={company.id}
              company={company}
              onUpdate={updateCompany}
              onArchive={deleteCompany}
              onRestore={restoreCompany}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default Companies;
