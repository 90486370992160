import * as React from 'react';
import { useContext, useState } from 'react';

import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { RequestMethod } from '@/typings';

import { DealsContext } from '../context/DealsContext';

const ToggleConfidentialModal = ({
  show,
  handleClose,
}: {
  show: boolean;
  handleClose: () => void;
}) => {
  const [isConfidential, setIsConfidential] = useState(false);
  const [error, setError] = useState<[string, boolean]>(['', false]);
  const { bulkedDeals, setBulkedConfidential } = useContext(DealsContext);

  React.useEffect(() => setIsConfidential(false), [show]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const req = {
      url: 'ajax-deal-update-confidential',
      dataType: 'json',
      method: RequestMethod.Patch,
      data: { deals_ids: bulkedDeals, is_confidential: isConfidential },
    };
    try {
      await $.ajax(req);
      setError(['', false]);
      setBulkedConfidential(bulkedDeals, isConfidential);
      handleClose();
    } catch (e: any) {
      setError([`Error: status: ${e.status}, text: ${e.statusText}`, true]);
      console.error(e);
    }
  };
  return (
    <Modal size='sm' show={show} onHide={handleClose} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>Set Confidential</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body className='d-flex'>
          <Form.Label className='me-2'>Confidential</Form.Label>
          <Form.Check type='switch'>
            <Form.Check.Input
              isInvalid={error[1]}
              onChange={() => {
                setIsConfidential(!isConfidential);
              }}
            />
            <Form.Control.Feedback type='invalid'>{error[0]}</Form.Control.Feedback>
          </Form.Check>
        </Modal.Body>
        <Modal.Footer>
          <div className='modal-cancel-btn pointer' onClick={handleClose}>
            Cancel
          </div>
          <button type='submit' className='btn modal-submit-btn fw-600 pointer'>
            Save
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ToggleConfidentialModal;
