import { MapImage } from '@/containers/MapImage/typings';
import { DataType, RequestMethod, Result } from '@/typings';
import { fetchResult } from '@/utils';

export const MapImageService = {
  index: (bounds: string): Promise<Result<{ map_images: MapImage[] }>> => {
    const url = `/map_images?bounds=${bounds}`;
    const req = { url, method: RequestMethod.Get };
    return fetchResult(req);
  },
  show: (id: number): Promise<Result<MapImage>> => {
    const url = `/map_images/${id}`;
    const req = { url, method: RequestMethod.Get };
    return fetchResult(req);
  },
  create: (formData: FormData): Promise<Result<'ok'>> => {
    const url = `/map_images`;
    const req = { url, body: formData, method: RequestMethod.Post };
    return fetchResult(req);
  },
  update: (
    id: number,
    data: {
      latitude: number;
      longitude: number;
      bounds: google.maps.LatLngBoundsLiteral;
      rotation: number;
      opacity: number;
    }
  ) => {
    const url = `/map_images/${id}`;
    const body = JSON.stringify(data);
    const req = { url, method: RequestMethod.Patch, dataType: DataType.JSON, body };
    return fetchResult(req);
  },
  destroy: (id: number) => {
    const url = `/map_images/${id}`;
    const req = { url, method: RequestMethod.Delete, dataType: DataType.JSON };
    return fetchResult(req);
  },
  replaceFile: (id: number, data: FormData) => {
    const url = `/map_images/${id}/replace_file`;
    const req = { url, method: RequestMethod.Patch, body: data };
    return fetchResult(req);
  },
};
