import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CellIcon from '@/components/CellIcon';
import { FieldType, FieldTypeTitle } from '@/containers/DealWindow/CustomFields/typings';
import { Option } from '@/typings';
import { capitalize } from '@/utils';

import { CustomField } from '.';
import CustomFieldModal from './CustomFieldModal';

const CustomFieldRow = (props: {
  customField: CustomField;
  onUpdate: (customField: {
    id: number;
    name: string;
    privacy: 'private' | 'public';
    fieldType: FieldType;
    pins: Option[];
  }) => void;
  onArchive: (id: number) => void;
  onRestore: (id: number) => void;
  onMouseDown: React.MouseEventHandler;
  draggableClassName: string;
  pins: Option[] | undefined;
}) => {
  const { onMouseDown, customField, onArchive, onRestore, onUpdate, draggableClassName, pins } =
    props;
  const [showBtns, setShowBtns] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  return (
    <>
      <CustomFieldModal
        onSave={(cf) => onUpdate({ ...cf, id: customField.id })}
        show={showEditModal}
        setShow={setShowEditModal}
        title='Edit Custom Field'
        pins={pins}
        initCustomField={customField}
      />
      <tr
        className={`fs-14 fuscous-gray-color ${
          customField.active ? '' : 'inactive'
        } ${draggableClassName}`}
        onMouseEnter={() => setShowBtns(true)}
        onMouseLeave={() => setShowBtns(false)}
      >
        <td>
          <FontAwesomeIcon icon='align-justify' fixedWidth onMouseDown={onMouseDown} />
        </td>
        <td>{customField.name}</td>
        <td>{FieldTypeTitle[customField.fieldType]}</td>
        <td>Details</td>
        <td>{capitalize(customField.privacy)}</td>

        <td>
          {customField.pins.length > 0
            ? customField.pins.map((p) => p.name).join(', ')
            : 'Show For All Pin Types'}
        </td>

        <td className='w-55p d-flex justify-content-between'>
          <CellIcon
            className='pointer'
            icon='pencil'
            show={showBtns}
            onClick={() => setShowEditModal(true)}
          />
          {customField.active ? (
            <CellIcon
              className='pointer'
              onClick={() => onArchive(customField.id)}
              icon='archive'
              show={showBtns}
            />
          ) : (
            <CellIcon
              className='pointer'
              onClick={() => onRestore(customField.id)}
              icon='undo-alt'
              show={showBtns}
            />
          )}
        </td>
      </tr>
    </>
  );
};

export default CustomFieldRow;
