import React, { useState } from 'react';

import DropdownBtn, { style } from '@/components/DropdownBtn';
import Filter from '@/components/Filter';
import { FilterOption } from '@/typings';

const UsersFilter = (props: { setFilters: (filters: { roles: string[] }) => void }) => {
  const { setFilters } = props;
  const [roles, setRoles] = useState<FilterOption[]>([]);
  const [show, setShow] = useState(false);
  const onToggle = () => setShow(!show);

  const onClear = () => {
    setFilters({ roles: [] });
    setRoles([]);
    setShow(false);
  };

  const onSave = () => {
    setFilters({ roles: roles.map((role) => role.id as string) });
    setShow(false);
  };

  return (
    <DropdownBtn
      title='Filters'
      variant='light'
      btnClassName={style.btn}
      text=' Filters'
      icon='filter'
      onToggle={onToggle}
      show={show}
    >
      <>
        <Filter
          label='Role'
          options={[
            { id: 'admin', name: 'Admin' },
            { id: 'user', name: 'User' },
          ]}
          selectedOptions={roles}
          setSelectedOptions={setRoles}
        />

        <li className='d-flex align-items-center justify-content-between mt-2'>
          <a type='button' className='fs-14 filters-btn' onClick={onClear}>
            Clear filters
          </a>

          <div className='btn fs-14 add-new-btn mr-15 hover-btn' onClick={onSave}>
            Save
          </div>
        </li>
      </>
    </DropdownBtn>
  );
};

export default UsersFilter;
