import React, { useEffect, useState } from 'react';

import Form from 'react-bootstrap/Form';
import { useDropzone } from 'react-dropzone';

import Modal from '@/components/Modal';
import { useEffectNotFirstRender } from '@/hooks';
import { Opportunity } from '@/services/opportunity_service';

const OpportunityModal = (props: {
  title: string;
  show: boolean;
  setShow: (bool: boolean) => void;
  initOpportunity?: Opportunity;
  onSave: (name: string, logo?: File) => void;
}) => {
  const { title, show, setShow, initOpportunity, onSave } = props;
  const [name, setName] = useState(initOpportunity?.name);
  const [preview, setPreview] = useState(initOpportunity?.logo);
  const [logo, setLogo] = useState<File>();
  const [validated, setValidated] = useState(false);

  useEffectNotFirstRender(() => setName(initOpportunity?.name), [initOpportunity]);

  const handleClose = () => {
    setValidated(false);
    setName(initOpportunity?.name);
    setPreview(initOpportunity?.logo);
    setLogo(undefined);
    setShow(false);
  };

  const handleSave = () => {
    if (!name) {
      setValidated(true);
      return;
    }
    onSave(name, logo);
    handleClose();
  };

  const onDropAccepted = async (acceptedFiles: File[]) => {
    setPreview(URL.createObjectURL(acceptedFiles[0]));
    setLogo(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    maxFiles: 1,
    accept: {
      'image/*': [],
    },
  });

  useEffect(() => {
    return () => {
      if (preview) URL.revokeObjectURL(preview);
    };
  }, []);

  return (
    <Modal
      onSave={handleSave}
      onClose={handleClose}
      title={title}
      modalProps={{ backdrop: 'static', show }}
    >
      <Form.Group className='mb-3'>
        <Form.Label>Name *</Form.Label>
        <Form.Control
          type='text'
          placeholder='Name'
          defaultValue={name}
          onChange={(e) => setName(e.target.value?.trim())}
          isInvalid={validated && !name}
        />
        <Form.Control.Feedback type='invalid'>Name is required</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className='mb-3'>
        <Form.Label>Logo</Form.Label>
        <div {...getRootProps({ className: 'dropzone mb-4' })}>
          <input {...getInputProps()} />
          <p>Drag and drop files, or upload here</p>
          <em>(Only *.jpeg and *.png images will be accepted)</em>
          <img
            src={preview}
            className='max-w-30'
            onLoad={() => {
              if (preview) URL.revokeObjectURL(preview);
            }}
          />
        </div>
      </Form.Group>
    </Modal>
  );
};

export default OpportunityModal;
