import * as React from 'react';
import { useContext } from 'react';

import { StasContext } from '@Deals/context/StasContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StarredBtn = ({ dealId, isStarred }: { dealId: number; isStarred: boolean }) => {
  const { starDeal } = useContext(StasContext);
  return (
    <div className='d-flex h-20p justify-content-center align-items-center'>
      <span className='fa-layers fa-fw pointer' onClick={() => starDeal(dealId, isStarred)}>
        <FontAwesomeIcon icon={['far', 'star']} />
        <FontAwesomeIcon
          icon={['fas', 'star']}
          className={isStarred ? '' : 'd-none'}
          transform='shrink-5'
          style={{ color: 'yellow' }}
        />
      </span>
    </div>
  );
};
export default StarredBtn;
