import React, { useContext } from 'react';

import { Form } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tags from '@yaireo/tagify/dist/react.tagify';

import { NotesContext } from '@/contexts/NotesContext';
import { UnsavedChangeContext } from '@/contexts/UnsavedChangeContext';
import { tagifySettings } from '@/utils';

import { UserOption } from '../typings';

const AddNote = (props: { users: UserOption[] }) => {
  const { users } = props;
  const { isNewNoteValidated, newNoteText, setNewNoteText, submitNewNote, tagifyNewNoteInputRef } =
    useContext(NotesContext);
  const { setUnsavedChange } = useContext(UnsavedChangeContext);

  return (
    <div className='d-flex align-items-center justify-content-between mx-20p my-15p'>
      <div className='w-100'>
        <Tags
          InputMode='textarea'
          className='form-control box-shadow'
          tagifyRef={tagifyNewNoteInputRef}
          defaultValue={newNoteText}
          settings={tagifySettings(users)}
          onChange={(e) => {
            const text = e.detail.value.trim();
            setUnsavedChange(!!text ? 'note' : null);
            setNewNoteText(text);
          }}
          placeholder='Add a Note...'
        />
        {!newNoteText && isNewNoteValidated && (
          <Form.Control.Feedback type='invalid'>Note can not be empty</Form.Control.Feedback>
        )}
      </div>
      <div
        onClick={submitNewNote}
        className='d-flex align-items-center add-new-btn ms-2 source-sans-font fs-18 pointer box-shadow'
      >
        <FontAwesomeIcon icon='plus-circle' />
        <span className='ml-10'> Save </span>
      </div>
    </div>
  );
};

export default AddNote;
