import * as React from 'react';
import { useEffect, useState } from 'react';

import Form from 'react-bootstrap/Form';

import Row from '@/components/DealWindow/Details/Row';
// Components
import SectionHeader from '@/components/DealWindow/Details/SectionHeader';
import IconBtn from '@/components/IconBtn';
import ModalBroker from '@/containers/Brokers/ModalBroker';
// Types
import { Broker } from '@/containers/Brokers/typings';
import { BrokerService } from '@/services/broker_service';
// Other
import { DealService } from '@/services/deal_service';
import { WorkingCompanyService } from '@/services/working_company_service';
import { Error, Nullable, Option } from '@/typings';

// Containers
import ModalChooseBroker from './ModalChooseBroker';

const isBroker = <T extends { status: 'broker' | 'owner' }>(broker: T | null): broker is T =>
  broker?.status === 'broker';

const isOwner = <T extends { status: 'broker' | 'owner' }>(broker: T | null): broker is T =>
  broker?.status === 'owner';

const BrokerOrOwner = (props: {
  dealId: number;
  isForSale: boolean | null;
  setIsForSale: (isForSale: boolean | null) => void;
  sharedMapWindow?: boolean;
  onChange?: () => void;
}) => {
  const { dealId, sharedMapWindow, isForSale, setIsForSale, onChange } = props;
  const [isExpanded, setExpanded] = useState(true);
  const [broker, setBroker] = useState<Broker | null>(null);
  const [brokersOptions, setBrokersOptions] = useState<Option[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [isShowBrokerModal, setShowBrokerModal] = useState(false);
  const [isShowOwnerModal, setShowOwnerModal] = useState(false);
  const [workingCompanies, setWorkingCompanies] = useState<Option[]>([]);

  const addError = (e: Error, fnName: string) => {
    setError(e);
    console.error(`Error in ${fnName}:`, e);
  };

  useEffect(() => {
    getWorkingCompanies();
  }, []);

  useEffect(() => {
    getBroker(dealId);
  }, [dealId]);

  useEffect(() => {
    if (error) setError(null);
  }, [broker]);

  const getBroker = async (dealId: number) => {
    const res = await DealService.getBroker(dealId);
    if (res.ok) {
      const result = res.data;
      if (result?.broker) setBroker(res.data.broker);
      else setBroker(null);
      if (result?.brokers_options) setBrokersOptions(result.brokers_options);
    } else addError(res, 'getBroker');
  };

  const getWorkingCompanies = async () => {
    const res = await WorkingCompanyService.options();
    if (res.ok) setWorkingCompanies(res.data);
    else addError(res, 'Error in getWorkingCompanies:');
  };

  const updateBrokerDeal = async (brokerId: number) => {
    const res = await DealService.updateParam(dealId, 'broker_id', {
      broker_id: brokerId,
      is_for_sale: isShowBrokerModal,
    });
    if (res.ok) {
      setIsForSale(isShowBrokerModal);
      getBroker(dealId);
      onChange && onChange();
    } else addError(res, 'updateBrokerDeal');
  };

  const removeBrokerFromDeal = async () => {
    const res = await DealService.deleteParam(dealId, 'broker_id');
    if (res.ok) {
      setIsForSale(null);
      setBroker(null);
      onChange && onChange();
      if (isOwner(broker)) destroyOwner(broker.id);
    } else addError(res, 'removeBrokerFromDeal');
  };

  const saveOwner = async (owner: Nullable<Broker>) => {
    const res = await (owner.id ? BrokerService.update(owner) : BrokerService.create(owner));

    if (res.ok && !res.data?.errors) updateBrokerDeal(res.data.id);
    else addError(res.data?.errors || res, 'saveOwner');
  };

  const destroyOwner = async (ownerId: number) => {
    const res = await BrokerService.destroy(ownerId);
    if (!res.ok) addError(res, 'destroyOwner');
  };

  return (
    <React.StrictMode>
      <div className='mt-20 ml-20 mr-20'>
        <SectionHeader
          title='Broker/Owner'
          isBodyExpanded={isExpanded}
          onClick={() => setExpanded(!isExpanded)}
        />
        {isExpanded && (
          <>
            <Row name='Listed?'>
              <RadioCheck
                label='Yes'
                checked={isForSale === true}
                onClick={() => {
                  if (!sharedMapWindow)
                    isForSale === false
                      ? confirmAction(() => {
                          removeBrokerFromDeal();
                          setShowBrokerModal(true);
                        })
                      : setShowBrokerModal(true);
                }}
              />
              <RadioCheck
                label='No'
                checked={isForSale === false}
                onClick={() => {
                  if (!sharedMapWindow)
                    isForSale
                      ? confirmAction(() => {
                          removeBrokerFromDeal();
                          setShowOwnerModal(true);
                        })
                      : setShowOwnerModal(true);
                }}
              />
              {broker && !sharedMapWindow && (
                <>
                  <IconBtn
                    onClick={() => (isForSale ? setShowBrokerModal(true) : setShowOwnerModal(true))}
                    icon='pencil-alt'
                    style='primary'
                  />
                  <IconBtn
                    onClick={() => confirmAction(removeBrokerFromDeal)}
                    icon='trash'
                    style='danger'
                  />
                </>
              )}
            </Row>
            <BrokerError error={error} />
            <BrokerInfo broker={broker} />
            <ModalChooseBroker
              show={isShowBrokerModal}
              setShow={setShowBrokerModal}
              defaultOption={isBroker(broker) ? { name: broker.name, id: broker.id } : null}
              defaultBroker={broker}
              options={brokersOptions}
              updateBrokerDeal={updateBrokerDeal}
              saveOwner={saveOwner}
              workingCompanies={workingCompanies}
            />
            <ModalBroker
              title='Add Owner'
              show={isShowOwnerModal}
              setShow={setShowOwnerModal}
              saveBroker={saveOwner}
              isOwner={true}
              initBroker={broker && isOwner(broker) ? broker : undefined}
              workingCompanies={workingCompanies}
            />
          </>
        )}
      </div>
    </React.StrictMode>
  );
};

export default BrokerOrOwner;

const RadioCheck = (props: {
  checked: boolean;
  label: string;
  onClick: React.MouseEventHandler;
}) => {
  const { checked, label, onClick } = props;
  return (
    <Form.Check
      inline
      label={label}
      name='group1'
      type='radio'
      checked={checked}
      onChange={() => {}}
      onClick={onClick}
    />
  );
};

const BrokerInfo = ({ broker }: { broker: Broker | null }) => {
  if (broker) {
    return (
      <>
        <Row name='Name'>{broker.name}</Row>
        <Row name='Phone'>{broker.phone}</Row>
        <Row name='Email'>{broker.email}</Row>
        <Row name='Address'>{broker.address}</Row>
        {isBroker(broker) && <Row name='Market'>{broker.market}</Row>}
        {broker.workingCompany && <Row name='Company'>{broker.workingCompany.name}</Row>}
      </>
    );
  } else return null;
};

const BrokerError = ({ error }: { error: Error | null }) => {
  if (error) {
    return (
      <Row name='Error:'>
        Status: {error.status ?? 'Rollback'} Status Text: {error.statusText ?? error}
      </Row>
    );
  } else return null;
};

const confirmAction = (fn: () => void) => {
  if (confirm('Are you sure?')) fn();
};
