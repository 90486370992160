import * as React from 'react';

const TableBody = ({
  isResultPresent,
  isLoading,
  children,
  columnsLength,
}: {
  isLoading?: boolean;
  isResultPresent: boolean;
  children: React.ReactNode;
  columnsLength: number;
}) => {
  if (isResultPresent) return <tbody>{children}</tbody>;
  else
    return (
      <tbody>
        <tr>
          <td colSpan={columnsLength}>
            {isLoading ? (
              <div className='justify-content-center d-flex'>
                <div className='spinner-border allign-self-center light-blue-color' />
              </div>
            ) : (
              <p className='fs-24 no-result source-sans-semi-bold-font'>No Results</p>
            )}
          </td>
        </tr>
      </tbody>
    );
};

export default TableBody;
