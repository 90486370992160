import * as React from 'react';

import { Dropdown } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PinType } from '@/typings';
import { getPinIcon } from '@/utils/pin_icon';

import DropdownBtn, { style } from '../DropdownBtn';

export type DropdownPin = { id: number; name: string; icon: PinType };
const PinTypeDropdown = (props: {
  dealId: number;
  currentPin: DropdownPin | null;
  pins: DropdownPin[];
  deleteDeal: (dealId: number) => void;
  changePinType: (dealId: number, pinId: number) => void;
}) => {
  const { dealId, currentPin, deleteDeal, changePinType, pins } = props;

  if (!pins) return null;

  return (
    <DropdownBtn
      title='Change pin type'
      icon={getPinIcon(currentPin?.icon)}
      text={<span className='text-uppercase pl-5'>{currentPin ? currentPin.name : 'unknown'}</span>}
      variant='light'
      btnClassName={style.windowBtn}
    >
      {pins
        .filter((pin) => pin.id !== currentPin?.id)
        .map((pin) => (
          <Dropdown.Item
            key={pin.id}
            className='d-flex fs-14'
            onClick={() => changePinType(dealId, pin.id)}
          >
            <span className='w-20p text-center'>
              <FontAwesomeIcon icon={getPinIcon(pin.icon)} />
            </span>
            <span className='text-uppercase'>{pin.name}</span>
          </Dropdown.Item>
        ))}
      <Dropdown.Divider />
      <li className='text-center'>
        <a
          onClick={() => deleteDeal(dealId)}
          className='no-link light-blue-color text-uppercase pointer fs-14'
        >
          Unsave pin
        </a>
      </li>
    </DropdownBtn>
  );
};

export default PinTypeDropdown;
