import * as React from 'react';
import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ResourceRow = (props: {
  resource: { id: number; name: string; link: string };
  sharedMapWindow: boolean;
  removeResource: (id: number) => void;
}) => {
  const { resource, sharedMapWindow, removeResource } = props;
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);

  return (
    <div
      className='d-flex ms-4'
      onMouseEnter={() => setShowDeleteBtn(true)}
      onMouseLeave={() => setShowDeleteBtn(false)}
    >
      <p className='source-sans-semi-bold-font w-198p fuscous-gray-color'>
        {resource.name}
        {!!resource.link && (
          <a href={resource.link} target='_blank' rel='noopener noreferrer'>
            <FontAwesomeIcon icon='external-link-alt' className='ms-2' />
          </a>
        )}
      </p>

      <div className='w-20p me-4'>
        {!sharedMapWindow && showDeleteBtn && (
          <a className='btn p-0 m-top-3' onClick={() => removeResource(resource.id)}>
            <FontAwesomeIcon icon='trash-alt' className='light-blue-color mr-15 pointer' />
          </a>
        )}
      </div>
    </div>
  );
};

export default ResourceRow;
