import * as React from 'react';

import { TabType } from '@/typings';

import Tab from './Tab';

const Tabs = <T extends string>({
  tabs,
  activeTab,
  setActiveTab,
}: {
  tabs: TabType<T>[];
  activeTab: T;
  setActiveTab: (name: T) => void;
}) => (
  <ul className='nav nav-tabs block-tabs'>
    {tabs.map((tab) => (
      <Tab key={tab.id} tab={tab} activeTab={activeTab} setActiveTab={setActiveTab} />
    ))}
  </ul>
);

export default Tabs;
