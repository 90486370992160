import * as React from 'react';

import Dropdown from 'react-bootstrap/Dropdown';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BootstrapVariant } from '@/typings';

export { default as style } from './style.module.scss';

const DropdownBtn = (props: {
  title: string;
  children: React.ReactNode;
  text?: React.ReactNode;
  icon?: IconProp;
  chevron?: IconProp;
  variant?: BootstrapVariant;
  dropdownClassName?: string;
  btnClassName?: string;
  onToggle?: (
    show: boolean,
    meta?: {
      source: string | undefined;
      originalEvent: React.SyntheticEvent | KeyboardEvent | MouseEvent | undefined;
    }
  ) => void;
  show?: boolean;
  autoClose?: 'outside' | 'inside' | false;
}) => {
  const { title, text, icon, chevron, variant, children, onToggle, show, autoClose } = props;
  return (
    <Dropdown
      show={show}
      autoClose={autoClose}
      className={props.dropdownClassName}
      title={title}
      aria-label={title}
      onToggle={onToggle}
    >
      <Dropdown.Toggle variant={variant} className={props.btnClassName}>
        <>
          {icon && <FontAwesomeIcon icon={icon} className={text ? 'me-1' : ''} />}
          {text}
          {chevron && <FontAwesomeIcon icon={chevron} className='fs-14 ms-2' />}
        </>
      </Dropdown.Toggle>
      <Dropdown.Menu>{(show === undefined || show) && children}</Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownBtn;
