import * as React from 'react';

import Form from 'react-bootstrap/Form';

import InputForm from '@/components/DealWindow/Details/InputForm';
import FormBtn from '@/components/IconBtn';
import { deleteDrawingManager, getDrawingManager } from '@/scripts/drawing_manager';
import CustomRadius from '@/scripts/map/markers/custom_radius';
import MarkerManager from '@/scripts/map/markers/marker_manager';

import CustomRadiusForm from './CustomRadiusForm';

const Radius = (props: {
  radius: number;
  customRadius: string | null;
  showEditRadius: boolean;
  showEditCustomRadius: boolean;
  setShowEditRadius: (bool: boolean) => void;
  setShowEditCustomRadius: (bool: boolean) => void;
  sharedMapWindow: boolean;
  onSave: (param: string, paramName: 'radius' | 'custom_radius') => void;
  onDelete: (paramName: 'radius' | 'custom_radius') => void;
}) => {
  const {
    radius,
    customRadius,
    showEditCustomRadius,
    showEditRadius,
    setShowEditCustomRadius,
    setShowEditRadius,
    sharedMapWindow,
    onSave,
    onDelete,
  } = props;

  if (showEditRadius) {
    return (
      <InputForm
        defaultValue={`${radius}`}
        onSave={(option) => {
          if (option && parseInt(option) > 0) onSave(option, 'radius');
        }}
        onDelete={() => onDelete('radius')}
        onClose={() => setShowEditRadius(false)}
        type='number'
      />
    );
  } else if (showEditCustomRadius) {
    return (
      <CustomRadiusForm
        onSave={() => {
          const manager = MarkerManager.getInstance();
          if (manager.customRadiusMarker.tmpMarker)
            onSave(CustomRadius.toString(manager.customRadiusMarker.tmpMarker), 'custom_radius');
        }}
        onClose={() => {
          setShowEditCustomRadius(false);
          deleteDrawingManager();
          const manager = MarkerManager.getInstance();
          if (customRadius)
            manager.customRadiusMarker.draw(CustomRadius.stringToCoordinates(customRadius));
        }}
        onDelete={() => onDelete('custom_radius')}
        onDrawModeBtnClick={(mode) => getDrawingManager()?.setDrawingMode(mode)}
      />
    );
  } else {
    return (
      <>
        <Form.Check
          className='boulder-color'
          disabled={!!customRadius}
          inline
          label={radius ? radius + ' mi' : 'Miles'}
          name='group-radius'
          type='radio'
          checked={!!radius}
          onChange={() => setShowEditRadius(true)}
        />
        <Form.Check
          className='boulder-color'
          disabled={!!radius}
          inline
          label='Custom'
          name='group-radius'
          type='radio'
          checked={!!customRadius}
          onChange={() => setShowEditCustomRadius(true)}
        />
        {(radius || customRadius) && !sharedMapWindow && (
          <>
            <FormBtn
              onClick={() => (radius ? setShowEditRadius(true) : setShowEditCustomRadius(true))}
              icon='pencil-alt'
              style='primary'
            />
            <FormBtn
              onClick={() => (radius ? onDelete('radius') : onDelete('custom_radius'))}
              icon='trash'
              style='danger'
            />
          </>
        )}
      </>
    );
  }
};

export default Radius;
