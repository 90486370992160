export const FieldTypes = ['short_text', 'hyperlink', 'file'] as const;
export type FieldType = (typeof FieldTypes)[number];

export const FieldTypeTitle = {
  hyperlink: 'HyperLink',
  short_text: 'Short Text',
  file: 'File',
} as const;

export type CustomField = {
  id: number;
  name: string;
  privacy: 'public' | 'private';
  fieldType: FieldType;
  value: { id: number; text: string } | null;
  global: boolean;
};

export type CustomFieldData = {
  name: string;
  field_type: FieldType;
  privacy: 'public' | 'private';
  location?: number;
  deal_id?: number;
};
