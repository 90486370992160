import { Preference } from '@/containers/Profile/ProfileModal';
import { DataType, RequestMethod, Result } from '@/typings';
import { fetchResult } from '@/utils';

export const NotififcationPreferenceService = {
  current: (): Promise<Result<Preference>> => {
    const url = '/notification_preferences/current';
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  update: (
    id: number,
    notification_preference: {
      task_mention_in_app: boolean;
      task_mention_email: boolean;
      task_assign_in_app: boolean;
      task_assign_email: boolean;
      note_mention_in_app: boolean;
      note_mention_email: boolean;
      task_follow_in_app: boolean;
      task_follow_email: boolean;
      task_uncompleted_in_app: boolean;
      task_uncompleted_email: boolean;
      task_completed_in_app: boolean;
      task_completed_email: boolean;
    }
  ) => {
    const url = `/notification_preferences/${id}/update_from_modal`;
    const body = JSON.stringify({ notification_preference });
    const req = { url, method: RequestMethod.Put, dataType: DataType.JSON, body };
    return fetchResult(req);
  },
};
