import * as React from 'react';
import { useState } from 'react';

import { useDropzone } from 'react-dropzone';

import Modal from '@/components/Modal';
import { map } from '@/scripts/map';
import { MapImageService } from '@/services/map_image_service';

const AddMapImageModal = (props: { show: boolean; setShow: (b: boolean) => void }) => {
  const { show, setShow } = props;
  const [file, setFile] = useState<File | null>(null);
  const handleClose = () => {
    setShow(false);
    setFile(null);
  };
  const handleSave = async () => {
    if (!file) return alert('Please add image');

    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('latitude', map.getCenter()!.lat().toString());
    formData.append('longitude', map.getCenter()!.lng().toString());
    const ne = map.getBounds()!.getNorthEast();
    const sw = map.getBounds()!.getSouthWest();
    const dLat = ne.lat() - sw.lat();
    const dLng = ne.lng() - sw.lng();
    formData.append(
      'bounds',
      JSON.stringify(
        new google.maps.LatLngBounds(
          { lat: sw.lat() + dLat / 4, lng: sw.lng() + dLng / 4 },
          { lat: ne.lat() - dLat / 4, lng: ne.lng() - dLng / 4 }
        ).toJSON()
      )
    );
    const res = await MapImageService.create(formData);
    if (res.ok) {
      setShow(false);
      setFile(null);
      document.dispatchEvent(new Event('render_markers'));
    } else console.error(res.error);
  };

  const onDropAccepted = async (files: File[]) => {
    if (!files[0].type.includes('image')) return alert(`${files[0].name} is not an image`);
    setFile(files[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDropAccepted });
  return (
    <Modal
      modalProps={{ show, backdrop: true }}
      title='Add Image'
      onSave={handleSave}
      onClose={handleClose}
    >
      <>
        <div {...getRootProps({ className: 'dropzone mb-4' })}>
          <input {...getInputProps()} />
          <p>Drag and drop image or upload here</p>
        </div>
        {file && (
          <ul className='deal-files-list p-0 mb-3'>
            <li className='deal-file'>{file.name.substring(0, 50)}</li>
          </ul>
        )}
      </>
    </Modal>
  );
};

export default AddMapImageModal;
