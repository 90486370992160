import * as React from 'react';
import { useContext } from 'react';

import { StasContext } from '@Deals/context/StasContext';
import { StaRelatedDeal } from '@Deals/typings';

import RowColumns from './RowColumns';

const RelatedDealRows = (props: {
  relatedDeals: StaRelatedDeal[];
  dealId: number;
  brokerId?: number | null;
}) => {
  const { brokerId, relatedDeals, dealId } = props;
  const { expandedRows } = useContext(StasContext);
  const renderRelatedDeals = () => {
    if (!expandedRows || !expandedRows.includes(dealId)) return;
    return relatedDeals.map((relatedDeal: StaRelatedDeal) => (
      <tr key={relatedDeal.id + 'expand'} className='fs-14 fuscous-gray-color'>
        {!brokerId && <td></td>}
        <td></td>
        <td></td>
        <RowColumns deal={relatedDeal} />
      </tr>
    ));
  };
  return <>{renderRelatedDeals()}</>;
};

export default RelatedDealRows;
