import React from 'react';

import { City } from '@/typings';

const CityRow = (props: { city: City }) => {
  const { city } = props;

  return (
    <>
      <tr className={`fs-14 fuscous-gray-color`}>
        <td>{city.name}</td>
        <td>{city.state}</td>
      </tr>
    </>
  );
};

export default CityRow;
