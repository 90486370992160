import MeasureTool from 'measuretool-googlemaps-v3/dist/gmaps-measuretool.esm.js';

import { hideContextMenu } from './context_menu';
import { mapClickListener, setMapClickListener } from './map';

export const measureToolInit = (
  map: google.maps.Map,
  mapClickCallback: (e: google.maps.MapMouseEvent) => void,
  contextMenu: Element
): MeasureTool => {
  const measureTool = new MeasureTool(map, {
    contextMenu: false,
    showSegmentLength: true,
    tooltip: true,
    unit: MeasureTool.UnitTypeId.IMPERIAL,
  });

  measureTool.addListener('measure_change', (MeasureEvent: any) => {
    $('.measure-tool-modal-js .distance').html(`Total Distance: ${MeasureEvent.result.lengthText}`);
  });

  measureTool.addListener('measure_start', () => {
    $('.measure-tool-modal-js').removeClass('d-none');

    google.maps.event.removeListener(mapClickListener);
  });

  measureTool.addListener('measure_end', () => {
    $('.measure-tool-modal-js').addClass('d-none');
    setMapClickListener(map.addListener('click', mapClickCallback));
  });

  $('.measure-tool-modal-js .close').on('click', () => {
    $('.measure-tool-modal-js').addClass('d-none');
    measureTool.end();
  });

  const measureToolBtn = contextMenu.querySelector('.measure-tool');
  measureToolBtn?.addEventListener('click', () => {
    measureTool.start();
    hideContextMenu();
  });

  return measureTool;
};
