import * as React from 'react';
import { useEffect, useState } from 'react';

import F from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';

import Form from '@/containers/Brokers/Form';
import { Broker, DefaultBroker } from '@/containers/Brokers/typings';
import { Nullable, Option, SetState } from '@/typings';
import { isEmailValid, isNullOrEmptyString } from '@/utils';

const isBroker = <T extends { status: 'broker' | 'owner' }>(broker: T | Nullable<T>): broker is T =>
  broker.status === 'broker';

const isOwner = <T extends { status: 'broker' | 'owner' }>(broker: T | Nullable<T>): broker is T =>
  broker.status === 'owner';

const ModalChooseBroker = (props: {
  show: boolean;
  setShow: SetState<boolean>;
  defaultOption: Option | null;
  defaultBroker: Broker | null;
  options: Option[];
  updateBrokerDeal: (id: number) => void;
  saveOwner: (broker: Nullable<Broker>) => void;
  workingCompanies: Option[];
}) => {
  const {
    show,
    setShow,
    defaultOption,
    options,
    updateBrokerDeal,
    saveOwner,
    defaultBroker,
    workingCompanies,
  } = props;
  const [selectedBroker, setSelectedBroker] = useState(defaultOption);
  const [broker, setBroker] = useState(defaultBroker ?? DefaultBroker);
  const [validated, setValidated] = useState(false);
  const [showBrokerInputs, setShowBrokerInputs] = useState(false);

  useEffect(() => {
    setBroker(defaultBroker ?? DefaultBroker);
  }, [defaultBroker]);

  useEffect(() => {
    setSelectedBroker(defaultOption);
  }, [defaultOption]);

  const handleClose = () => {
    setShow(false);
    setValidated(false);
    setBroker(defaultBroker ?? DefaultBroker);
    setShowBrokerInputs(false);
  };

  const handleSave = () => {
    if ((isOwner(broker) || showBrokerInputs) && !isValidBroker(broker)) {
      setValidated(true);
      return;
    }
    setValidated(false);

    if ((!broker.id || isBroker(broker)) && !showBrokerInputs)
      selectedBroker && updateBrokerDeal(selectedBroker.id);
    else saveOwner(broker);

    setShow(false);
    setBroker(DefaultBroker);
    setShowBrokerInputs(false);
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>Add Broker</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='mb-3'>
          <label className='form-label'>Broker Name *</label>
          <Select
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            defaultValue={selectedBroker}
            options={options}
            closeMenuOnSelect={true}
            isMulti={false}
            isClearable={false}
            isDisabled={isOwner(broker) || showBrokerInputs}
            menuShouldScrollIntoView={false}
            onChange={(option) => setSelectedBroker(option)}
            getOptionValue={(option) => `${option.id}`}
            getOptionLabel={(option) => option.name}
          />
        </div>
        <div className='mb-3 d-flex'>
          <F.Check
            onChange={() => {
              setBroker({
                ...DefaultBroker,
                status: broker?.status === 'owner' ? 'broker' : 'owner',
              });
              setShowBrokerInputs(false);
            }}
            checked={broker?.status === 'owner'}
          />
          <label className='form-label ms-2 me-3'>For Sale by Owner</label>

          <p
            className='pointer light-blue-color'
            onClick={() => {
              setBroker({ ...DefaultBroker, status: 'broker' });
              setShowBrokerInputs(true);
            }}
          >
            Add New Broker
          </p>
        </div>
        {(showBrokerInputs || isOwner(broker)) && (
          <Form
            broker={broker}
            setBroker={setBroker}
            validated={validated}
            isOwner={isOwner(broker)}
            workingCompanies={workingCompanies}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <a type='button' className='fs-16 filters-btn gray-color' onClick={handleClose}>
          Cancel
        </a>
        <button type='button' className='btn modal-submit-btn fw-600 pointer' onClick={handleSave}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const isValidBroker = (broker: Nullable<Broker>): broker is Broker & boolean => {
  return !isNullOrEmptyString(broker.name) && isNullOrEmptyString(broker.email)
    ? true
    : isEmailValid(broker.email);
};

export default ModalChooseBroker;
