import React, { createContext, useState } from 'react';

import { NoteConfirmationModal } from '@/components/NoteConfirmationModal';
import { SetState } from '@/typings';

type UnsavedChange = null | 'note';

type UnsavedChangeState = {
  unsavedChange: UnsavedChange;
  setUnsavedChange: SetState<UnsavedChange>;
  noteConfirm: () => Promise<boolean>;
};

export const UnsavedChangeContext = createContext(null as unknown as UnsavedChangeState);

export const UnsavedChangeProvider: React.FC = ({ children }) => {
  const [unsavedChange, setUnsavedChange] = useState<UnsavedChange>(null);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [resolveNoteFn, setResolveNoteFn] = useState<(value: boolean) => void>(() => {});

  const noteConfirm = () => {
    setShowNoteModal(true);
    return new Promise((resolve: (value: boolean) => void) => {
      setResolveNoteFn(() => resolve);
    });
  };

  return (
    <UnsavedChangeContext.Provider value={{ unsavedChange, setUnsavedChange, noteConfirm }}>
      <NoteConfirmationModal
        showModal={showNoteModal}
        setShowModal={setShowNoteModal}
        resolveFn={resolveNoteFn}
      />
      {children}
    </UnsavedChangeContext.Provider>
  );
};
