import { hideExploreDeal } from '../map';
import MarkerManager from '../map/markers/marker_manager';

export const unsaveDeal = async (dealId: number) => {
  const answer = await window.customConfirm(
    'Delete Deal',
    'Are you sure that you want to delete this deal?'
  );
  if (!answer) return;
  $.ajax({
    method: 'delete',
    url: 'ajax-deal-unsave',
    data: {
      id: dealId,
    },
    success: () => {
      MarkerManager.getInstance().pinMarkerManager.clearMarker(dealId);
      hideExploreDeal();
    },
  });
};
