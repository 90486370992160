import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Avatar from '@/components/Avatar';
import TextWithTagsModal from '@/components/DealWindow/TextWithTagsModal';
import { NoteService } from '@/services/note_service';

import { UserOption } from '../typings';
import { Note } from './typings';

const NoteComponent = (props: {
  note: Note;
  users: UserOption[];
  updateNote: (id: number, text: string) => Promise<void>;
  deleteNote: (id: number, parentId?: number) => Promise<void>;
  createNote: (text: string, parentId?: number) => Promise<void>;
  getNotes?: (notePage: number) => Promise<void>;
}) => {
  const { note, users, updateNote, deleteNote, createNote, getNotes } = props;
  const [showEditModal, setShowEditModal] = useState(false);
  const [showReplyModal, setShowReplyModal] = useState(false);
  const [pinned, setPinned] = useState(note.pinned);

  const handleDelete = async () => {
    if (
      await window.customConfirm('Delete Note', 'Are you sure that you want to delete this note?')
    ) {
      deleteNote(note.id, note.parentNoteId);
    }
  };

  const handleUpdate = (text: string) => {
    updateNote(note.id, text);
    setShowEditModal(false);
  };

  const handleCreateReply = (text: string) => {
    const parentId = note.parentNoteId ?? note.id;
    createNote(text, parentId);
    setShowReplyModal(false);
  };

  const pinNote = async (noteId: number) => {
    const res = await NoteService.pin(noteId);
    if (res.ok) {
      setPinned(res.data);
      if (getNotes) getNotes(1);
    }
  };

  const pinnedClass = pinned ? 'light-blue-color' : '';

  return (
    <>
      <div className='d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center'>
          <Avatar avatar={note.user.avatar} />
          <p className='mt-0 mb-0 ml-10 source-sans-semi-bold-font'>{note.user.fullName}</p>
        </div>
        <div className='d-flex align-items-center fs-14 source-sans-font'>
          {note.parentNoteId === null && (
            <>
              <span className='mr-10'>
                <a
                  className='pointer'
                  onClick={() => {
                    pinNote(note.id);
                  }}
                >
                  <FontAwesomeIcon icon='map-pin' className={`fas-icon ${pinnedClass}`} />
                </a>
              </span>
            </>
          )}
          <span className='mr-10'>{note.date}</span>
          <span className='corduroyapprox-color mr-10'>{note.time}</span>
        </div>
      </div>
      <div className='d-flex justify-content-between align-items-end source-sans-font mt-2 fs-14'>
        <p
          className='pre-line break-word pl-5'
          dangerouslySetInnerHTML={{
            __html:
              note.text.html === '<span class="light-blue-color"></span>'
                ? note.text.raw
                : note.text.html,
          }}
        ></p>
        <div className='d-flex mt-1'>
          <a className='note-link mr-30 pointer' onClick={() => setShowReplyModal(true)}>
            Reply
          </a>
          <a className='note-link mr-30 pointer' onClick={() => setShowEditModal(true)}>
            <FontAwesomeIcon icon='pencil-alt' className='light-blue-color' />
          </a>
          <a className='note-link mr-15' onClick={handleDelete}>
            <FontAwesomeIcon icon='trash-alt' className='light-blue-color' />
          </a>
        </div>
      </div>
      <TextWithTagsModal
        title='Edit note'
        label='Note'
        text={note.text.raw}
        users={users}
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        handleSubmit={handleUpdate}
      />
      <TextWithTagsModal
        title='Add reply'
        label='Reply'
        users={users}
        show={showReplyModal}
        handleClose={() => setShowReplyModal(false)}
        handleSubmit={handleCreateReply}
      />
    </>
  );
};

export default NoteComponent;
