import * as React from 'react';
import { useContext } from 'react';

import { DealsContext } from '@Deals/context/DealsContext';
import { StasContext } from '@Deals/context/StasContext';
import { TableSettingsContext } from '@Deals/context/TableSettingsContext';
import { StaDeal, StaRelatedDeal, TabName } from '@Deals/typings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Cell } from '@/components/Table/Cell';
import { Option } from '@/typings';

import { ActiveTabContext } from '../context/ActiveTabContext';

const RowColumns = ({ deal }: { deal: StaDeal | StaRelatedDeal }) => {
  const { activeTab } = useContext(ActiveTabContext);
  const { tableSettings } = useContext(TableSettingsContext);
  const { showDealWindow } = useContext(DealsContext);
  const { searchString } = useContext(StasContext);

  const pinType = (
    <div className='d-flex align-items-center'>
      <FontAwesomeIcon icon='circle' className='fas-icon me-2' style={{ color: deal.pin.color }} />
      {deal.pin.name}
    </div>
  );

  const relatedUpdatedAt =
    isStaDeal(deal) && deal.relatedDeals.length
      ? lastUpdatedDeal(deal.relatedDeals).updatedAtFormatted
      : null;

  const columns = tableSettings
    .map(
      (tableSetting: [string, boolean]): { key: string; children: React.ReactNode } | undefined => {
        if (!tableSetting[1]) return;
        if (activeTab === TabName.NonSta && tableSetting[0] === 'related_updated_at') return;
        return [
          { key: 'id', children: pinType },
          { key: 'nickname', children: deal.nickname },
          { key: 'opportunities', children: deal.opportunitiesNames },
          { key: 'tags', children: deal.tagsNames },
          { key: 'formatted_address', children: deal.formattedAddress },
          { key: 'city', children: deal.city },
          { key: 'state', children: deal.state },
          { key: 'postal_code', children: deal.postalCode },
          { key: 'working_users', children: formatUsers(deal.workingUsers) },
          { key: 'updated_at', children: deal.updatedAtFormatted },
          { key: 'related_updated_at', children: relatedUpdatedAt },
          { key: 'broker', children: deal.brokerName },
          { key: 'followers', children: formatUsers(deal.followers) },
          { key: 'assemblage', children: deal.assemblage ? 'Yes' : 'No' },
        ].find((col) => col.key === tableSetting[0]);
      }
    )
    .filter(<T,>(elm: T | undefined): elm is T => elm !== undefined);

  return (
    <>
      {columns.map((col) => {
        return (
          <Cell
            key={col.key}
            onClick={() => showDealWindow(deal.id)}
            label='Open Deal Window'
            searchString={searchString}
          >
            {col.children}
          </Cell>
        );
      })}
    </>
  );
};

export default RowColumns;

const isStaDeal = (deal: StaDeal | StaRelatedDeal): deal is StaDeal =>
  'relatedDeals' in deal && !!deal.relatedDeals;

const lastUpdatedDeal = (relatedDeals: StaRelatedDeal[]): StaRelatedDeal =>
  relatedDeals.reduce((a, b) =>
    Date.parse(a.updatedAtFormatted) > Date.parse(b.updatedAtFormatted) ? a : b
  );

const formatUsers = (users: Option[]) => users.map((user) => user.name.trim()).join(', ');
