import * as React from 'react';
import { useState } from 'react';

import Avatar from '@/components/Avatar';
import CellIcon from '@/components/CellIcon';

import { User } from '.';
import ArchiveModal from './ArchiveModal';
import UserModal from './UserModal';

const UserRow = (props: {
  user: User;
  users: User[];
  currentUser: User;
  restoreUser: (id: number) => void;
  archiveUser: (id: number, newId: number) => void;
  sendInvite: (email: string) => void;
  updateUser: (user: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    canViewConfidential: boolean;
  }) => void;
  isAdmin: boolean;
}) => {
  const { user, users, restoreUser, archiveUser, currentUser, isAdmin, updateUser, sendInvite } =
    props;
  const [showBtns, setShowBtns] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);

  return (
    <>
      <UserModal
        show={showEditModal}
        setShow={setShowEditModal}
        title='Edit User'
        isAdmin={isAdmin}
        onSave={(newUser) => updateUser({ ...newUser, id: user.id })}
        user={user}
      />
      <ArchiveModal
        users={users.filter((u) => u.active && user.id !== u.id)}
        onSave={(newId) => archiveUser(user.id, newId)}
        show={showArchiveModal}
        setShow={setShowArchiveModal}
      />
      <tr
        className={`fs-14 fuscous-gray-color ${!user.active ? 'inactive' : ''}`}
        onMouseEnter={() => setShowBtns(true)}
        onMouseLeave={() => setShowBtns(false)}
      >
        <td className='min-w-30'>
          {!!user.avatar && <Avatar avatar={user.avatar} className='users-page-users-table-icon' />}
        </td>
        <td>{user.name}</td>
        <td>{user.email}</td>
        <td>{user.role.name}</td>
        {!!user.lastLogin ? (
          <td>{user.lastLogin}</td>
        ) : (
          <td>
            <a
              className='no-link hippie-blue-color fw-600 pointer'
              onClick={() => sendInvite(user.email)}
            >
              Resend Invitation
            </a>
          </td>
        )}

        <td className='w-55p d-flex justify-content-between'>
          {(currentUser?.role.value === 'admin' || currentUser.id === user.id) && (
            <>
              <CellIcon
                className='pointer'
                onClick={() => setShowEditModal(true)}
                icon='pencil-alt'
                show={showBtns}
              />
              {user.active ? (
                <CellIcon
                  className='pointer'
                  onClick={() => setShowArchiveModal(true)}
                  icon='archive'
                  show={showBtns}
                />
              ) : (
                <CellIcon
                  className='pointer'
                  onClick={() => restoreUser(user.id)}
                  icon='undo-alt'
                  show={showBtns}
                />
              )}
            </>
          )}
        </td>
      </tr>
    </>
  );
};

export default UserRow;
