import * as React from 'react';
import { useState } from 'react';

import InputForm from '@/components/DealWindow/Details/InputForm';
import { CustomField } from '@/containers/DealWindow/CustomFields/typings';

const CustomFieldValue = ({
  customField,
  updateCustomFieldValue,
  deleteCustomFieldValue,
  sharedMapWindow,
  createCustomFieldValue,
  dealId,
}: {
  customField: CustomField;
  updateCustomFieldValue: (value: string, valueId: number, customFieldId: number) => Promise<void>;
  deleteCustomFieldValue: (valueId: number, customFieldId: number) => Promise<void>;
  sharedMapWindow?: boolean;
  createCustomFieldValue: (value: string, customFieldId: number) => Promise<void>;
  dealId: number;
}) => {
  const [isVisible, setVisibility] = useState(false);

  const onSave = (text: string) => {
    if (text !== customField.value?.text && customField.value) {
      updateCustomFieldValue(text, customField.value!.id, customField.id);
    } else if (text !== customField.value?.text) {
      createCustomFieldValue(text, customField.id);
    }
    setVisibility(false);
  };

  const onClose = () => setVisibility(false);

  const onDelete = () => {
    if (customField.value) deleteCustomFieldValue(customField.value.id, customField.id);
    setVisibility(false);
  };

  if (isVisible) {
    return (
      <InputForm
        defaultValue={customField.value?.text}
        onSave={onSave}
        onClose={onClose}
        onDelete={onDelete}
        fieldType={customField.fieldType}
        dealId={dealId}
      />
    );
  }

  const Value = () => {
    if (customField.value) {
      if (customField.fieldType === 'hyperlink') return <>Click icon to view link</>;
      else if (customField.fieldType === 'file') return <>Click icon to view the file</>;
      else return <>{customField.value.text}</>;
    } else if (sharedMapWindow) return null;
    else return <span className='boulder-color d-block'>Edit value</span>;
  };

  return (
    <p onClick={() => !sharedMapWindow && setVisibility(true)}>
      <Value />
    </p>
  );
};

export default CustomFieldValue;
