import * as React from 'react';
import { useState } from 'react';

import { Form, Modal } from 'react-bootstrap';

import style from '@/components/Modal/style.module.scss';
import { Option } from '@/typings';

const CustomViewModal = (props: {
  show: boolean;
  handleClose: () => void;
  handleSubmit: (name: string) => void;
  handleDelete?: (id: number) => void;
  profile?: Option;
}) => {
  const { show, handleSubmit, handleDelete } = props;
  const [name, setName] = useState(props.profile?.name);
  const [isInvalid, setIsInvalid] = useState(false);

  const handleClose = () => {
    setName(props.profile?.name);
    props.handleClose();
  };

  const onSubmit = () => {
    if (name && name.length > 0 && name.length <= 30) {
      setIsInvalid(false);
      handleSubmit(name);
      setName(props.profile?.name ? name : undefined);
    } else setIsInvalid(true);
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>Custom View</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='fst-italic fs-16'>
          Current filters and table configuration will be saved with this view
        </p>
        <Form.Label>Name</Form.Label>
        <Form.Control
          isInvalid={isInvalid}
          defaultValue={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Form.Control.Feedback type='invalid'>
          Name must be present, maximum 30 characters
        </Form.Control.Feedback>
      </Modal.Body>

      <Modal.Footer className='justify-content-between'>
        {!!props.profile ? (
          <div
            className={style.grayBtn}
            onClick={() => handleDelete && handleDelete(props.profile!.id)}
          >
            Delete
          </div>
        ) : (
          <div />
        )}
        <div className='d-flex align-items-center'>
          <div className={style.cancelBtn} onClick={handleClose}>
            Cancel
          </div>
          <div className={style.saveBtn} onClick={onSubmit}>
            Save
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomViewModal;
