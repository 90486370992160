import { CookiesService } from '@/services/cookies_service';

export const initImagesLayer = () => {
  const btn = document.querySelector('.js-images-explore-btn') as HTMLElement;
  btn.addEventListener('click', () => {
    setParcelLayout(!isImagesLayoutVisible());
    $(btn).addClass(isImagesLayoutVisible() ? 'js-images-on' : 'js-images-off');
    $(btn).removeClass(isImagesLayoutVisible() ? 'js-images-off' : 'js-images-on');
    $(btn).find('input:checkbox:first').prop('checked', isImagesLayoutVisible());
    document.dispatchEvent(new Event('render_markers'));
  });
};

export const isImagesLayoutVisible = () => CookiesService.showImages.get();

const setParcelLayout = (active: boolean) => CookiesService.showImages.set(active);
