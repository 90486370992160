import React from 'react';
import { useState } from 'react';

import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import DateInput from '@/components/DateInput';
import { InfoDeal } from '@/containers/DealWindow/typings';
import { DealService } from '@/services/deal_service';
import { SetState } from '@/typings';

const ReminderModal = (props: {
  title: string;
  deal: InfoDeal;
  openModal: boolean;
  setOpenModal: SetState<boolean>;
}) => {
  const { title, deal, openModal } = props;
  const [reminderDate, setReminderDate] = useState(
    deal.reminderDate ? new Date(deal.reminderDate) : null
  );

  const handleClose = () => {
    props.setOpenModal(false);
    setReminderDate(deal.reminderDate ? new Date(deal.reminderDate) : null);
  };

  const onSubmit: React.FormEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    updateReminderDate(deal.id);
  };

  const updateReminderDate = async (dealId: number) => {
    if (!reminderDate) return;
    const res = await DealService.updateReminderDate(dealId, reminderDate);
    if (res.ok) {
      props.setOpenModal(false);
    } else {
      console.error('Error in updateReminderDate', res.error);
    }
  };

  return (
    <Modal show={openModal} onHide={handleClose} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmit}>
        <Modal.Body>
          <Form.Group className='mb-3' controlId='formDealReminder'>
            <Form.Label>Set Reminder</Form.Label>
            <DateInput
              show={openModal}
              dueDate={reminderDate}
              onChange={(selectedDates) => setReminderDate(selectedDates[0])}
              config={{
                enableTime: false,
                minDate: 'today',
                dateFormat: 'm/d/y',
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <div className='modal-cancel-btn pointer' onClick={handleClose}>
            Cancel
          </div>
          <button type='submit' className='btn modal-submit-btn fw-600 pointer'>
            Add
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ReminderModal;
