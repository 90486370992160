import * as React from 'react';
import { createContext, useState } from 'react';

import Cookies from 'js-cookie';

import { TabName } from '../typings';

type ActiveTabState = {
  activeTab: TabName;
  setActiveTab: (tabName: TabName) => void;
  restoreActiveTab: () => void;
};

export const ActiveTabContext = createContext<ActiveTabState>(null as unknown as ActiveTabState);

export const ActiveTabProvider: React.FC = ({ children }) => {
  const [activeTab, _setActiveTab] = useState<TabName>(TabName.ActiveSta);
  const setActiveTab = (tabName: TabName) => {
    _setActiveTab(tabName);
    Cookies.set('active-tab', tabName);
  };

  const restoreActiveTab = () => {
    const tabName = Cookies.get('active-tab') as TabName | undefined;
    if (tabName) setActiveTab(tabName);
  };

  return (
    <ActiveTabContext.Provider
      value={{
        activeTab,
        setActiveTab,
        restoreActiveTab,
      }}
    >
      {children}
    </ActiveTabContext.Provider>
  );
};
