import React, { useState } from 'react';

import { Col, Form, Row } from 'react-bootstrap';
import M from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DraggableList from '@/components/DraggableList';
import style from '@/components/Modal/style.module.scss';
import { DealTabService } from '@/services/deal_tab_service';
import { NotififcationPreferenceService } from '@/services/notification_preference_service';
import { arrayMove } from '@/utils';

import { Preference, PreferenceValue } from './ProfileModal';

const PreferencesTab = (props: {
  preference: Preference;
  setShow: (bool: boolean) => void;
  dealsTabs: { title: string; id: number }[];
  setTab: (tab: 'profile' | 'preferences') => void;
  setDealsTabs: (dealTab: { title: string; id: number }[]) => void;
}) => {
  const { setShow, setTab, setDealsTabs, dealsTabs } = props;
  const isInApp = (preferenceValue: PreferenceValue) =>
    preferenceValue === 'in_app' || preferenceValue === 'both';

  const isEmail = (preferenceValue: PreferenceValue) =>
    preferenceValue === 'email' || preferenceValue === 'both';

  const [preference, setPreference] = useState({
    task_mention_in_app: isInApp(props.preference.taskMention),
    task_mention_email: isEmail(props.preference.taskMention),
    task_assign_in_app: isInApp(props.preference.taskAssign),
    task_assign_email: isEmail(props.preference.taskAssign),
    note_mention_in_app: isInApp(props.preference.noteMention),
    note_mention_email: isEmail(props.preference.noteMention),
    task_follow_in_app: isInApp(props.preference.taskFollow),
    task_follow_email: isEmail(props.preference.taskFollow),
    task_uncompleted_in_app: isInApp(props.preference.taskUncompleted),
    task_uncompleted_email: isEmail(props.preference.taskUncompleted),
    task_completed_in_app: isInApp(props.preference.taskCompleted),
    task_completed_email: isEmail(props.preference.taskCompleted),
  });

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    if (oldIndex === newIndex) return;
    const newDealsTabs = arrayMove(dealsTabs, oldIndex, newIndex);
    setDealsTabs(newDealsTabs);
  };

  const onClose = () => {
    setShow(false);
    setTab('profile');
  };

  const onSave = async () => {
    const res = await NotififcationPreferenceService.update(props.preference.id, preference);
    if (res.ok) {
      setShow(false);
      setTab('profile');
    } else console.error('Error in NotificationPreference update:', res);
    const dealTabRes = await DealTabService.sort(dealsTabs.map((dealTab) => dealTab.id));
    if (!dealTabRes.ok) console.error('Error in PreferencesTab:', dealTabRes);
  };

  const PreferenceRow = (props: {
    title: string;
    inApp: keyof typeof preference;
    email: keyof typeof preference;
  }) => {
    const { title, inApp, email } = props;
    return (
      <Row>
        <Col sm={7}>
          <p>{title}</p>
        </Col>
        <Col>
          <div className='d-flex justify-content-center'>
            <Form.Check
              onChange={(e) => setPreference({ ...preference, [inApp]: e.currentTarget.checked })}
              checked={preference[inApp]}
            />
          </div>
        </Col>
        <Col>
          <div className='d-flex justify-content-center'>
            <Form.Check
              onChange={(e) => setPreference({ ...preference, [email]: e.currentTarget.checked })}
              checked={preference[email]}
            />
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <M.Body>
        <Row>
          <Col sm={7}>
            <p className='fw-700'>Notifications</p>
          </Col>
          <Col>
            <div className='d-flex justify-content-center'>
              <p>In-app</p>
            </div>
          </Col>
          <Col>
            <div className='d-flex justify-content-center'>
              <p>Email</p>
            </div>
          </Col>
        </Row>
        <PreferenceRow
          title='When I am @mentioned in a task'
          inApp='task_mention_in_app'
          email='task_mention_email'
        />
        <PreferenceRow
          title='When I am assigned to a task'
          inApp='task_assign_in_app'
          email='task_assign_email'
        />
        <PreferenceRow
          title='When I am following a task'
          inApp='task_follow_in_app'
          email='task_follow_email'
        />
        <PreferenceRow
          title='When task has been completed'
          inApp='task_completed_in_app'
          email='task_completed_email'
        />
        <PreferenceRow
          title="When task hasn't been completed by it's due date"
          inApp='task_uncompleted_in_app'
          email='task_uncompleted_email'
        />
        <PreferenceRow
          title='When I am @mentioned in a note'
          inApp='note_mention_in_app'
          email='note_mention_email'
        />

        <Row className='h-48'>
          <Col sm={7} className='mt-3'>
            <p className='fw-700'>Saved Pin Menu</p>
          </Col>
        </Row>
        <DraggableList
          items={dealsTabs}
          onReorder={onSortEnd}
          Container={({ children }) => <ul className='deals-tabs-preferences-list'>{children}</ul>}
          Item={({ value, onMouseDown, draggableClassName }) => (
            <li className={'pointer ' + draggableClassName}>
              <FontAwesomeIcon icon='bars' onMouseDown={onMouseDown} />
              <span className='ml-10'>{value.title}</span>
            </li>
          )}
        />
      </M.Body>

      <M.Footer>
        <div className={style.cancelBtn} onClick={onClose}>
          Cancel
        </div>
        <div className={style.saveBtn} onClick={onSave}>
          Save
        </div>
      </M.Footer>
    </>
  );
};

export default PreferencesTab;
