export const inside = (point: { lat: number; lng: number }, vs: { lat: number; lng: number }[]) => {
  // ray-casting algorithm
  const x = point.lat,
    y = point.lng;

  let inside = false;
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    const xi = vs[i].lat,
      yi = vs[i].lng;
    const xj = vs[j].lat,
      yj = vs[j].lng;

    const intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
};
