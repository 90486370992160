import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { HTMLAndRaw } from '../typings';

const TaskText = (props: { name: HTMLAndRaw; description: HTMLAndRaw }) => {
  const { name, description } = props;
  const nameElement = useRef<HTMLParagraphElement>(null);
  const descriptionElement = useRef<HTMLParagraphElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    [nameElement.current, descriptionElement.current].map((elem) => (elem!.style.width = ''));
    setIsOverflow(
      isOverflown(nameElement.current!) ||
        isOverflown(descriptionElement.current!) ||
        isMultiline(description.raw, name.raw)
    );
    [nameElement.current, descriptionElement.current].map((elem) => setParentsWidth(elem!));
  }, [name.html, description.html]);

  const toggleExpand = () => {
    [nameElement.current!, descriptionElement.current!].map((element) => {
      if (element.classList.value.includes('text-truncate')) {
        element.classList.remove('text-truncate');
        element.classList.add('pre-line');
      } else {
        element.classList.add('text-truncate');
        element.classList.remove('pre-line');
      }
    });
  };
  return (
    <>
      <div className='deal-window-task-text w-350p'>
        <div className='overflow-hidden d-block'>
          <p
            ref={nameElement}
            className='deal-window-task-name w-max fuscous-gray-color source-sans-semi-bold-font text-truncate'
            dangerouslySetInnerHTML={{ __html: name.html }}
          />
          <p
            ref={descriptionElement}
            className='fst-italic mb-0 fs-14 fuscous-gray-color w-max source-sans-font text-truncate'
            dangerouslySetInnerHTML={{ __html: description.html }}
          />
        </div>
      </div>
      {isOverflow ? (
        <div
          onClick={toggleExpand}
          className='w-25p h-25p mx-2 mt-0 bor-rad-circle light-blue-background description-btn justify-content-center align-items-center d-flex'
        >
          <FontAwesomeIcon icon='ellipsis-h' className='white-color tasks-expand-description p-0' />
        </div>
      ) : (
        <div className='w-25p mx-2' />
      )}
    </>
  );
};

export default TaskText;

export const isOverflown = (element: HTMLElement): boolean => {
  if (!element) return false;
  return element.clientWidth > element.parentElement!.clientWidth;
};

const setParentsWidth = (element: HTMLElement) => {
  element.style.width = element.parentElement!.clientWidth + 'px';
};

const isMultiline = (descriptionRaw: string, nameRaw: string): boolean => {
  return descriptionRaw.includes('\n') || nameRaw.includes('\n');
};
