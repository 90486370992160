import * as React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { HTMLDivElementProps } from '@/typings';

const CellIcon = (
  props: {
    show: boolean;
    icon: IconProp;
    setRef?: (ref: HTMLDivElement) => void;
  } & HTMLDivElementProps
) => {
  const { show, setRef, icon, ...rest } = props;

  return (
    <div {...rest} ref={setRef}>
      {show ? (
        <FontAwesomeIcon icon={icon} className='light-blue-color' fixedWidth />
      ) : (
        <div style={{ width: '17.5px' }} />
      )}
    </div>
  );
};

export default CellIcon;
