import Cookies from 'js-cookie';

import { DealService } from '@/services/deal_service';
import { Coordinates, TabTitle } from '@/typings';

import { hideExploreDeal, map } from './map';

export const validateField = (event: JQuery.SubmitEvent, selector: string) => {
  const target = event.target as HTMLFormElement;
  $('[data-bs-dismiss="modal"]').on('click', () => {
    $(selector).removeClass('border-danger');
    target.classList.remove('was-validated');
  });
  if (target.checkValidity()) {
    $(selector).removeClass('border-danger');
    target.classList.remove('was-validated');
    return true;
  } else {
    event.preventDefault();
    event.stopPropagation();
    $(selector).addClass('border-danger');
    target.classList.add('was-validated');
    return false;
  }
};

export const openSavedDealWindow = async (
  obj: {
    id: number;
  } & Coordinates,
  tab?: TabTitle
) => {
  const { id, lat, lng } = obj;

  hideExploreDeal();
  document.dispatchEvent(new CustomEvent('openSavedDealWindow', { detail: { id, tab } }));

  setDealListVisibility(false);

  moveToLocation(lat, lng);
};

export const toggleDealsListBlock = async (ids: number[]) => {
  if (Cookies.get('deals-list-hidden') !== 'true') {
    $('.explore-deals-block, .js-deals-hide-btn').removeClass('d-none');
    const deals = await getExploreList(ids);
    addDealsToExploreList(deals);
  }
  $('.js-deals-hide-btn')
    .off('click')
    .on('click', () => setDealListVisibility(false));

  $('.js-deals-explore-btn')
    .off('click')
    .on('click', async () => {
      const deals = await getExploreList(ids);
      addDealsToExploreList(deals);
      setDealListVisibility(true);
    });
};

const getExploreList = async (ids: number[]) => {
  const res = await DealService.getExploreList(ids);
  if (res.ok) return res.data;
  else {
    console.error('getExploreList', res.error);
    return null;
  }
};

export type ListDeal = { id: number; html: string; latitude: string; longitude: string };
const addDealsToExploreList = (deals: ListDeal[] | null) => {
  $('.explore-deals-block').html('');
  if (deals) {
    const urlString = (
      document.head.querySelector(
        'script[defer][src^="https://maps.googleapis.com/maps/api/js"]'
      ) as HTMLScriptElement
    ).src;
    const key = new URL(urlString).searchParams.get('key');

    deals.forEach((deal) => {
      $('.explore-deals-block').append(`${deal.html}`);
      const src =
        `https://maps.googleapis.com/maps/api/streetview?` +
        `size=700x200&location=${deal.latitude},${deal.longitude}&key=${key}&language=en`;
      $(`#explore-deal-img-${deal.id}`).attr('src', src);

      $(`.explore-deal-from-list-block`)
        .off('click')
        .on('click', function () {
          openSavedDealWindow({
            id: deal.id,
            lat: parseFloat(deal.latitude),
            lng: parseFloat(deal.longitude),
          });
        });
    });
  }
};

const setDealListVisibility = (show: boolean) => {
  const exploreDealsBlock = $('.explore-deals-block, .js-deals-hide-btn');
  const dealsExploreBtn = $('.js-deals-explore-btn');

  if (show) {
    exploreDealsBlock.removeClass('d-none');
    dealsExploreBtn.addClass('d-none');
    Cookies.remove('deals-list-hidden');
  } else {
    exploreDealsBlock.addClass('d-none');
    dealsExploreBtn.removeClass('d-none');
    Cookies.set('deals-list-hidden', 'true');
  }
};

export const showStreetView = (coords: google.maps.StreetViewPanoramaOptions['position']) => {
  const pano = document.getElementById('pano');
  if (pano) {
    const panorama = new google.maps.StreetViewPanorama(pano, {
      position: coords,
      addressControlOptions: {
        position: google.maps.ControlPosition.LEFT_CENTER,
      },
    });
    map.setStreetView(panorama);
  } else console.error('Error in showStreetView: Cannot find pano');
};

export const moveToLocation = async (lat: number, lng: number) => {
  const center = new google.maps.LatLng(lat, lng);
  const zoom = map.getZoom();
  if (zoom && zoom < 17) {
    map.setZoom(17);
  }
  map.panTo(center);
  map.panBy(270, 0);
};
