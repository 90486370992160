import { DealResource } from '@/containers/DealResources';
import { TFilters } from '@/containers/DealResources/Filters';
import { DataType, Id, Option, RequestMethod, Result, SortObject } from '@/typings';
import { fetchResult } from '@/utils';

export const DealResourceService = {
  resources: (
    current_page: number,
    sort: SortObject,
    search_string: string,
    per_page: number,
    filters: TFilters
  ): Promise<Result<{ total_pages: number; resources: (Id & DealResource)[] }>> => {
    const data = { current_page, sort, search_string, per_page, filters };
    const body = $.param(data);
    const url = `/deal_resources/resources?${body}`;
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  create: (data: Omit<Omit<DealResource, 'id'>, 'lastUpdated'>): Promise<Result<DealResource>> => {
    const { city, ...deal_resource } = { ...data, city_id: data.city?.id };
    const body = JSON.stringify({ deal_resource });
    const url = `/deal_resources/`;
    const req = { url, method: RequestMethod.Post, dataType: DataType.JSON, body };
    return fetchResult(req);
  },
  update: (data: Omit<DealResource, 'lastUpdated'>): Promise<Result<DealResource>> => {
    const { city, ...deal_resource } = { ...data, city_id: data.city?.id };
    const body = JSON.stringify({ deal_resource });
    const url = `/deal_resources/${deal_resource.id}`;
    const req = { url, method: RequestMethod.Put, dataType: DataType.JSON, body };
    return fetchResult(req);
  },
  destroy: (id: number): Promise<Result<Id>> => {
    const url = `/deal_resources/${id}`;
    const req = { url, method: RequestMethod.Delete, dataType: DataType.JSON };
    return fetchResult(req);
  },
  removeDeal: (id: number, dealId: number) => {
    const body = JSON.stringify({ deal_id: dealId });
    const url = `/deal_resources/${id}/remove_deal`;
    const req = { url, method: RequestMethod.Delete, dataType: DataType.JSON, body };
    return fetchResult(req);
  },
  findByCityAndState: (
    city: Option | null,
    state: string
  ): Promise<Result<{ resources: Option[] }>> => {
    const data = { city_id: city?.id, state };
    const body = $.param(data);
    const url = `/deal_resources/find_by_city_and_state?${body}`;
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
};
