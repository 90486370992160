import React, { useRef, useState } from 'react';

import { Form, Modal } from 'react-bootstrap';

import Tags from '@yaireo/tagify/dist/react.tagify';

import { UserOption } from '@/containers/DealWindow/typings';
import { useEffectNotFirstRender } from '@/hooks';
import { tagifySettings } from '@/utils';

const TextWithTagsModal = (props: {
  title: string;
  label: string;
  text?: string;
  show: boolean;
  handleClose: () => void;
  handleSubmit: (text: string) => void;
  users: UserOption[];
}) => {
  const { title, label, show, users, handleClose, handleSubmit } = props;
  const [text, setText] = useState(props.text?.trim() ?? '');
  const [validated, setValidated] = useState(false);
  const tagsRef = useRef<Tagify | undefined>();

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!text) {
      tagsRef.current?.DOM.scope.classList.add('is-invalid');
      setValidated(true);
      return;
    }
    handleSubmit(text);
    setText('');
  };

  useEffectNotFirstRender(() => {
    if (!show) setValidated(false);
    setText(props.text?.trim() ?? '');
  }, [show]);

  return (
    <Modal show={show} onHide={handleClose} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmit}>
        <Modal.Body>
          <Form.Group className='mb-3'>
            <Form.Label>{label} *</Form.Label>
            <Tags
              InputMode='textarea'
              className='form-control min-h-10rem'
              tagifyRef={tagsRef}
              defaultValue={props.text?.trim() ?? ''}
              settings={tagifySettings(users)}
              onChange={(e) => setText(e.detail.value.trim())}
            />
            <Form.Control.Feedback
              type='invalid'
              className={text === '' && validated ? 'd-block' : ''}
            >
              {label} can not be empty
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <div className='modal-cancel-btn pointer' onClick={handleClose}>
            Cancel
          </div>
          <button type='submit' className='btn modal-submit-btn fw-600 pointer'>
            {props.text ? 'Save' : 'Add'}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default TextWithTagsModal;
