import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CellIcon from '@/components/CellIcon';
import { Pin } from '@/typings';
import { getPinIcon } from '@/utils/pin_icon';

import PinModal from './PinModal';

const PinRow = (props: {
  pin: Pin & { active: boolean; reminderDuration: number | null };
  isAdmin: boolean;
  onUpdate: (
    pin: Pin & {
      reminderDuration: number | null;
    }
  ) => void;
  onArchive: (id: number) => void;
  onRestore: (id: number) => void;
}) => {
  const { pin, isAdmin, onUpdate, onArchive, onRestore } = props;
  const [showBtns, setShowBtns] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  return (
    <>
      <PinModal
        show={showEditModal}
        setShow={setShowEditModal}
        title='Edit Pin'
        onSave={(p) => onUpdate({ ...p, id: pin.id })}
        initPin={pin}
      />
      <tr
        className={`fs-14 fuscous-gray-color ${pin.active ? '' : 'inactive'}`}
        onMouseEnter={() => setShowBtns(true)}
        onMouseLeave={() => setShowBtns(false)}
      >
        <td>{pin.name}</td>
        <td>
          <div className='d-flex'>
            <div
              className='pins-color-box me-2'
              style={{
                backgroundColor: pin.color,
                opacity: pin.active ? 1 : 0.5,
              }}
            ></div>
            {pin.color}
          </div>
        </td>
        <td className={`pins-page-pins-table-icon-block ${pin.icon}-icon`}>
          <FontAwesomeIcon icon={getPinIcon(pin.icon)} />
        </td>
        <td>{pin.reminderDuration}</td>
        <td className='min-w-55 d-flex justify-content-between'>
          <CellIcon
            className='pointer'
            icon='pencil'
            show={showBtns}
            onClick={() => setShowEditModal(true)}
          />
          {isAdmin &&
            (pin.active ? (
              <CellIcon
                className='pointer'
                onClick={() => onArchive(pin.id)}
                icon='archive'
                show={showBtns}
              />
            ) : (
              <CellIcon
                className='pointer'
                onClick={() => onRestore(pin.id)}
                icon='undo-alt'
                show={showBtns}
              />
            ))}
        </td>
      </tr>
    </>
  );
};

export default PinRow;
