import { DataType, RequestMethod, Result, TabTitle } from '@/typings';
import { fetchResult } from '@/utils';

export const DealTabService = {
  index: (): Promise<Result<TabTitle[]>> => {
    const url = '/deal_tabs';
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  current_user_state: (): Promise<Result<{ title: string; id: number }[]>> => {
    const url = '/deal_tabs/current_user_state';
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  sort: (ids: number[]): Promise<Result<'ok'>> => {
    const url = '/deal_tabs/sort';
    const body = JSON.stringify({ deal_tab: ids });
    const req = { url, method: RequestMethod.Put, dataType: DataType.JSON, body };
    return fetchResult(req);
  },
};
