import React from 'react';

import { Dropdown } from 'react-bootstrap';

import DropdownBtn, { style } from './DropdownBtn';

const PaginationSelect = (props: { selectHandler: (perPage: number) => void; perPage: number }) => {
  const { selectHandler, perPage } = props;

  const listItem = (perPage: number) => (
    <Dropdown.Item onClick={() => selectHandler(perPage)}>Show {perPage}</Dropdown.Item>
  );

  return (
    <DropdownBtn
      title='Pagination Select'
      text={`Show ${perPage < 0 ? 'all' : perPage}`}
      btnClassName={style.btn}
      dropdownClassName='me-3'
      chevron='chevron-down'
      variant='light'
    >
      {listItem(10)}
      {listItem(25)}
      {listItem(50)}
      {listItem(100)}
      <Dropdown.Item onClick={() => selectHandler(-1)}>Show all</Dropdown.Item>
    </DropdownBtn>
  );
};

export default PaginationSelect;
