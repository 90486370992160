import React, { useState } from 'react';

import CellIcon from '@/components/CellIcon';
import { Option } from '@/typings';

import CompanyModal from './CompanyModal';

const CompanyRow = (props: {
  company: Option & { active: boolean };
  onUpdate: (company: Option) => void;
  onArchive: (id: number) => void;
  onRestore: (id: number) => void;
}) => {
  const { company, onArchive, onUpdate, onRestore } = props;
  const [showBtns, setShowBtns] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  return (
    <>
      <CompanyModal
        show={showEditModal}
        setShow={setShowEditModal}
        title='Edit Company'
        initCompany={company}
        onSave={(name) => onUpdate({ id: company.id, name })}
      />
      <tr
        className={`fs-14 fuscous-gray-color ${company.active ? '' : 'inactive'}`}
        onMouseEnter={() => setShowBtns(true)}
        onMouseLeave={() => setShowBtns(false)}
      >
        <td>{company.name}</td>

        <td className='w-55p d-flex justify-content-between'>
          <CellIcon
            className='pointer'
            icon='pencil'
            show={showBtns}
            onClick={() => setShowEditModal(true)}
          />
          {company.active ? (
            <CellIcon
              className='pointer'
              onClick={() => onArchive(company.id)}
              icon='archive'
              show={showBtns}
            />
          ) : (
            <CellIcon
              className='pointer'
              onClick={() => onRestore(company.id)}
              icon='undo-alt'
              show={showBtns}
            />
          )}
        </td>
      </tr>
    </>
  );
};

export default CompanyRow;
