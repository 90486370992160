import * as React from 'react';

import { Option } from '@/typings';
import { isNonEmptyArray } from '@/utils';

const DealsList = <T extends Option>(props: {
  deals: T[];
  onClick: (deal: T) => void;
  children?: React.ReactNode;
}) => {
  const { deals, onClick, children } = props;
  if (isNonEmptyArray(deals))
    return (
      <div>
        {deals.map((deal) => (
          <p className='link' key={deal.id} onClick={() => onClick(deal)}>
            {deal.name}
          </p>
        ))}
        <>{children}</>
      </div>
    );
  else return null;
};

export default DealsList;
