import React, {
  MutableRefObject,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import Tagify from '@yaireo/tagify';

import { Note } from '@/containers/DealWindow/NotesTab/typings';
import { NoteService } from '@/services/note_service';
import { SetState } from '@/typings';
import { camelize } from '@/utils';

import { UnsavedChangeContext } from './UnsavedChangeContext';

type NotesState = {
  createNote: (text: string, parentId?: number) => Promise<void>;
  notes: Note[];
  setNotes: SetState<Note[]>;
  newNoteText: string;
  setNewNoteText: SetState<string>;
  submitNewNote: () => void;
  tagifyNewNoteInputRef: MutableRefObject<Tagify | undefined>;
  isNewNoteValidated: boolean;
};

export const NotesContext = createContext(null as unknown as NotesState);

export const NotesProvider: React.FC<{ dealId: number }> = ({ children, dealId }) => {
  const [notes, setNotes] = useState<Note[]>([]);

  const [newNoteText, setNewNoteText] = useState('');
  const tagifyNewNoteInputRef = useRef<Tagify | undefined>();
  const [isNewNoteValidated, setIsNewNoteValidated] = useState(false);

  const { setUnsavedChange } = useContext(UnsavedChangeContext);

  const createNote = async (text: string, parentId?: number) => {
    const res = await NoteService.create(text, dealId, parentId);
    if (res.ok) {
      const newNote = camelize(res.data) as Note;
      const newNotes = !newNote.parentNoteId
        ? [newNote, ...notes]
        : notes.map((note) => {
            if (note.id !== newNote.parentNoteId) return note;
            else return { ...note, notes: [...(note.notes ?? []), newNote] };
          });
      setNotes(newNotes);
    } else console.error('Error in createNote: ', res.error);
  };

  const submitNewNote = () => {
    if (!newNoteText) {
      tagifyNewNoteInputRef.current?.DOM.scope.classList.add('is-invalid');
      setIsNewNoteValidated(true);
      return;
    }
    createNote(newNoteText);
    setNewNoteText('');
    setUnsavedChange(null);
    tagifyNewNoteInputRef.current?.DOM.scope.classList.remove('is-invalid');
    tagifyNewNoteInputRef.current?.loadOriginalValues('');
    setIsNewNoteValidated(false);
  };

  useEffect(() => {
    document.addEventListener('saveNote', submitNewNote);
    return () => {
      document.removeEventListener('saveNote', submitNewNote);
    };
  }, [newNoteText]);

  return (
    <NotesContext.Provider
      value={{
        notes,
        setNotes,
        createNote,
        newNoteText,
        setNewNoteText,
        submitNewNote,
        tagifyNewNoteInputRef,
        isNewNoteValidated,
      }}
    >
      {children}
    </NotesContext.Provider>
  );
};
