import { Coordinates } from '@/typings';

class CustomRadius {
  private _marker: google.maps.Polygon;
  public tmpMarker: google.maps.Polygon | null;

  constructor(map: google.maps.Map) {
    this._marker = new google.maps.Polygon({
      map,
      visible: false,
      clickable: false,
      strokeColor: '#000000',
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: '#000000',
      fillOpacity: 0.2,
    });
    this.tmpMarker = null;
  }

  get marker() {
    return this._marker;
  }

  draw(path: Coordinates[]): void {
    if (path.length === 0) return;
    this.tmpMarker?.setMap(null);
    this.tmpMarker = null;
    this._marker.setPath(path);
    this._marker.setVisible(true);
  }

  clear(): void {
    this.tmpMarker?.setMap(null);
    this.tmpMarker = null;
    this._marker.setPath([]);
    this._marker.setVisible(false);
  }

  static toString(poly: google.maps.Polygon): string {
    const coordinates = poly.getPath().getArray();
    const str = coordinates.reduce(
      (str, latLng) => `${latLng.lat()},${latLng.lng()};${str}`,
      'polygon'
    );
    return str;
  }

  static polygonToCoordinates(poly: google.maps.Polygon): Coordinates[] {
    return poly
      .getPath()
      .getArray()
      .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));
  }

  static stringToCoordinates(str: string): Coordinates[] {
    const strings = str.split(';');
    strings.pop();
    const coordinates = strings.map((str) => {
      return str.split(',').reduce(
        (latLng, coordinate, i) => {
          const float = parseFloat(coordinate);
          i ? (latLng.lng = float) : (latLng.lat = float);
          return latLng;
        },
        { lng: 0, lat: 0 }
      );
    });
    return coordinates;
  }
}

export default CustomRadius;
