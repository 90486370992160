import * as React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BootstrapVariant } from '@/typings';

const IconBtn = ({
  style,
  icon,
  onClick,
  className,
}: {
  style: BootstrapVariant;
  icon: IconProp;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
}) => {
  return (
    <button
      onClick={onClick}
      type='button'
      className={`btn btn-${style} ml-10 hw-38p position-relative ${className ?? ''}`}
    >
      <FontAwesomeIcon icon={icon} className='position-absolute top-50 start-50 translate-middle' />
    </button>
  );
};

export default IconBtn;
