import { RequestMethod, Result } from '../typings';
import { ajaxResult } from '../utils';

export const CustomFieldValueService = {
  create: (text: string, customFieldId: number, dealId: number): Promise<Result> => {
    const data = {
      custom_field_value: { deal_id: dealId, custom_field_id: customFieldId, text },
    };
    const req = { url: '/custom_field_values', method: RequestMethod.Post, data };
    return ajaxResult(req);
  },
  update: (
    text: string,
    valueId: number,
    customFieldId: number,
    dealId: number
  ): Promise<Result> => {
    const data = {
      custom_field_value_id: valueId,
      custom_field_value: { deal_id: dealId, custom_field_id: customFieldId, text },
    };
    const req = { url: '/custom_field_values', method: RequestMethod.Post, data };
    return ajaxResult(req);
  },
  destroy: async (valueId: number): Promise<Result> => {
    const url = `/custom_field_values/${valueId}`;
    const req = { url, method: RequestMethod.Delete };
    return ajaxResult(req);
  },
};
