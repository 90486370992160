import { openSavedDealWindow } from '../deal';
import { getAddressComponent } from '../map';
import MarkerManager from '../map/markers/marker_manager';

export const onCreateDealWithCoordsClick = () => {
  $('.js-create-deal-with-coords')
    .off('click')
    .on('click', () => {
      const pinId = $('.custom-select-input').val() as string;
      const position = new google.maps.LatLng(
        parseFloat($('.js-deal-lat').val() as string),
        parseFloat($('.js-deal-lng').val() as string)
      );

      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: position }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results) {
          $('#staticBackdropPinMapNew').modal('hide');
          const place = results[0];

          saveDeal(pinId, place, position);
        }
      });
    });
};

export const onSavePinBtnClick = (
  place: google.maps.places.PlaceResult | null,
  position: google.maps.LatLng
) => {
  $('.ajax-save-pin')
    .off('click')
    .on('click', function () {
      const pinId = $(this).data('pin-id');

      saveDeal(pinId, place, position);
    });
};

const saveDeal = (
  pinId: number | string,
  place: google.maps.places.PlaceResult | null,
  position: google.maps.LatLng
) => {
  const latitude = position.lat;
  const longitude = position.lng;
  const streetNumber = place ? getAddressComponent(place, 'street_number') : null;
  const route = place ? getAddressComponent(place, 'route') : null;
  const city = place ? getAddressComponent(place, 'locality') : null;
  const area = place ? getAddressComponent(place, 'administrative_area_level_1') : null;
  const postalCode = place ? getAddressComponent(place, 'postal_code') : null;
  const address = streetNumber && route ? `${streetNumber} ${route}` : null;

  MarkerManager.getInstance().newMarker.clear();
  $.post({
    url: '/deals',
    dataType: 'json',
    data: {
      deal: {
        latitude: latitude,
        longitude: longitude,
        address: address,
        city: city,
        state: area,
        saved: true,
        postal_code: postalCode,
        name: place?.name,
        phone: place?.formatted_phone_number,
        place_id: place?.place_id,
        pin_id: pinId,
        formatted_address: place?.formatted_address,
      },
    },
    success: (data) => {
      document.dispatchEvent(new Event('render_markers'));
      const latitude = parseFloat(data.latitude);
      const longitude = parseFloat(data.longitude);
      const dealId = data.dealId;

      setParamsAfterDealSaving(dealId, latitude, longitude);

      const deal = { id: dealId, lat: latitude, lng: longitude };
      openSavedDealWindow(deal);
    },
  });
};

const setParamsAfterDealSaving = (dealId: number, latitude: number, longitude: number) => {
  $('.js-deal-param-parent-deals').attr('data-deal-id', dealId);
  $('.js-deal-param-edit').attr('data-deal-id', dealId);
  $('.js-deal-nickname-title').attr('data-deal-id', dealId);
  $(`.js-deal-coords`).html(`${latitude}, ${longitude}`);
  $('.explore-deal-hidden-field').val(dealId);

  $('.js-deal-window-tabs').removeClass('d-none');
  $('.js-deal-coords-block').addClass('d-none');
  $('.js-deal-parcel-data-block').addClass('d-none');
};
