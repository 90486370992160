import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';

import { ActiveTabContext } from '@Deals/context/ActiveTabContext';
import { StasContext } from '@Deals/context/StasContext';
import { TableSettingsContext } from '@Deals/context/TableSettingsContext';
import { Column, ColumnName, TabName } from '@Deals/typings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getSortIcon, nextSortState } from '@/components/Table/TableHead';
import { SortMethod, SortState } from '@/typings';

const TableHead = () => {
  const { activeTab } = useContext(ActiveTabContext);
  const { staState, setStaState, setExpandedRows, brokerId } = useContext(StasContext);
  const { tableSettings } = useContext(TableSettingsContext);
  const [columns, setColumns] = useState(tableHeadColumns);

  const sortedTableColumns = useMemo(() => {
    return tableSettings
      .map((tableSetting: [string, boolean]) => {
        if (!tableSetting[1]) return;
        return tableHeadColumns.find((col: Column) => col.identifier === tableSetting[0]);
      })
      .filter(<T,>(elm: T | undefined): elm is T => elm !== undefined);
  }, [tableSettings]);

  useEffect(() => {
    setColumns(sortedTableColumns);
  }, [sortedTableColumns]);

  useEffect(() => {
    const sort = staState.sort;
    let newColumns = columnsFromSortObject(sortedTableColumns, sort);

    if (activeTab === TabName.NonSta)
      newColumns = newColumns.filter((col) => col.identifier !== 'related_updated_at');

    setColumns(newColumns);
  }, [activeTab, sortedTableColumns]);

  const updateSortState = (column: Column): void => {
    const newColumns: Column[] = columns.map((col: Column): Column => {
      if (col.identifier !== column.identifier) {
        col.sortState = SortState.Default;
        return col;
      }
      column.sortState = nextSortState(column.sortState);
      setStaState({ ...staState, sort: getSortObject(column) });
      return column;
    });

    setColumns(newColumns);
    if (activeTab !== TabName.NonSta && setExpandedRows) setExpandedRows([]);
  };

  return (
    <thead>
      <tr className='tr-title'>
        {!brokerId && (
          <th scope='col' className='deals-page-deals-table-column table-column-checkbox' />
        )}
        <th scope='col' className='deals-page-deals-table-column table-column-checkbox' />
        <th scope='col' className='deals-page-deals-table-column table-column-checkbox' />
        {columns.map((column: Column, i) => (
          <th
            key={i + activeTab}
            scope='col'
            className={`deals-page-deals-table-column ${column.cssClass}`}
          >
            <a className='deals-page-sort-link' onClick={() => updateSortState(column)}>
              {ColumnName[column.identifier as keyof typeof ColumnName]}&nbsp;
              <FontAwesomeIcon icon={getSortIcon(column.sortState)} className='fas-icon' />
            </a>
          </th>
        ))}
      </tr>
    </thead>
  );
};

const getSortObject = (column: Column) => ({
  entity: column.sortState === SortState.Default ? 'id' : column.identifier,
  method: column.sortState === SortState.Up ? SortMethod.Asc : SortMethod.Desc,
});

const columnsFromSortObject = (columns: Column[], sort: { entity: string; method: SortMethod }) => {
  return columns.map((col) => {
    if (col.identifier === 'id')
      return {
        ...col,
        sortState: sort.method === SortMethod.Desc ? SortState.Default : SortState.Up,
      };
    else if (col.identifier === sort.entity)
      return {
        ...col,
        sortState: sort.method === SortMethod.Desc ? SortState.Down : SortState.Up,
      };
    else return { ...col, sortState: SortState.Default };
  });
};

export default TableHead;

const tableHeadColumns: Readonly<Column[]> = Object.freeze([
  {
    sortState: SortState.Default,
    cssClass: 'table-column-short',
    identifier: 'id',
  },
  {
    sortState: SortState.Default,
    cssClass: '',
    identifier: 'nickname',
  },
  {
    sortState: SortState.Default,
    cssClass: '',
    identifier: 'opportunities',
  },
  {
    sortState: SortState.Default,
    cssClass: '',
    identifier: 'tags',
  },
  {
    sortState: SortState.Default,
    cssClass: 'table-column-long',
    identifier: 'formatted_address',
  },
  {
    sortState: SortState.Default,
    cssClass: 'table-column-short',
    identifier: 'city',
  },
  {
    sortState: SortState.Default,
    cssClass: 'table-column-short-reduced',
    identifier: 'state',
  },
  {
    sortState: SortState.Default,
    cssClass: 'table-column-short-reduced',
    identifier: 'postal_code',
  },
  {
    sortState: SortState.Default,
    cssClass: '',
    identifier: 'updated_at',
  },
  {
    sortState: SortState.Default,
    cssClass: '',
    identifier: 'related_updated_at',
  },
  {
    sortState: SortState.Default,
    cssClass: '',
    identifier: 'broker',
  },
  {
    sortState: SortState.Default,
    cssClass: '',
    identifier: 'followers',
  },
  {
    sortState: SortState.Default,
    cssClass: '',
    identifier: 'working_users',
  },
  {
    sortState: SortState.Default,
    cssClass: '',
    identifier: 'assemblage',
  },
]);
