import * as React from 'react';

import { ActiveTabProvider } from './ActiveTabContext';
import { DealsProvider } from './DealsContext';
import { ErrorsProvider } from './ErrorContext';
import { LoadingProvider } from './LoadingContext';
import { PinsProvider } from './PinsContext';
import { StasProvider } from './StasContext';
import { TableSettingsProvider } from './TableSettingsContext';
import { UsersProvider } from './UsersContext';

const ContextProvider: React.FC = ({ children }) => {
  return (
    <LoadingProvider>
      <ErrorsProvider>
        <PinsProvider>
          <UsersProvider>
            <ActiveTabProvider>
              <StasProvider>
                <DealsProvider>
                  <TableSettingsProvider>{children}</TableSettingsProvider>
                </DealsProvider>
              </StasProvider>
            </ActiveTabProvider>
          </UsersProvider>
        </PinsProvider>
      </ErrorsProvider>
    </LoadingProvider>
  );
};

export default ContextProvider;
