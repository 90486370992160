import * as React from 'react';
import { useContext, useState } from 'react';

import Form from 'react-bootstrap/Form';

import { DealsContext } from '@Deals/context/DealsContext';
import { StasContext } from '@Deals/context/StasContext';
import { StaDeal } from '@Deals/typings';

import ExpandBtn from './ExpandBtn';
import RelatedDealRows from './RelatedDealRows';
import RowColumns from './RowColumns';
import StarredBtn from './StarredBtn';

const DealRow = ({ deal }: { deal: StaDeal }) => {
  const { setBulkedDeals, bulkedDeals } = useContext(DealsContext);
  const { brokerId } = useContext(StasContext);
  const [checked, setChecked] = useState(bulkedDeals.some((id) => id === deal.id));

  return (
    <>
      <tr className='fs-14 fuscous-gray-color'>
        {!brokerId && (
          <td>
            <div className='d-flex h-20p justify-content-end align-items-center'>
              <Form.Check
                aria-label='bulk action'
                onChange={(e) => {
                  if (e.currentTarget.checked) setBulkedDeals((bulked) => [...bulked, deal.id]);
                  else setBulkedDeals((bulked) => bulked.filter((id) => id === deal.id));
                  setChecked(e.currentTarget.checked);
                }}
                checked={checked}
              />
            </div>
          </td>
        )}
        <td>
          <StarredBtn dealId={deal.id} isStarred={deal.isStarred} />
        </td>
        <td>
          <ExpandBtn dealId={deal.id} isRelatedDeal={deal.relatedDeals.length > 0} />
        </td>
        <RowColumns deal={deal} />
      </tr>

      <RelatedDealRows relatedDeals={deal.relatedDeals} dealId={deal.id} brokerId={brokerId} />
    </>
  );
};

export default DealRow;
