import { CookiesService } from '@/services/cookies_service';
import { onClassMutation } from '@/utils';

type FilterNames = [
  'tags',
  'opportunities',
  'pins',
  'users',
  'transaction-types',
  'followers',
  'cities',
  'states',
  'zip_codes',
  'brokers'
];
type SelectizedFilters = JQuery<HTMLElement>[];
export const setDealFilters = () => {
  $('.filters-dropdown-menu')
    .off('click')
    .on('click', (e) => {
      e.stopPropagation();
    });
  const filters: FilterNames = [
    'tags',
    'opportunities',
    'pins',
    'users',
    'transaction-types',
    'followers',
    'cities',
    'states',
    'zip_codes',
    'brokers',
  ];
  const selectizedFilters = initSelectizeFilters(filters);

  showDropdown(filters);
  onSelectAll(filters, selectizedFilters);
  onClearAll(filters, selectizedFilters);

  $('#dropdownNavbarFilter')[0].addEventListener('mousedown', () => {
    restoreSavedFilters(filters, selectizedFilters);
  });

  $('.js-filters-submit-btn')
    .off('click')
    .on('click', () => {
      setCookiesFilters(filters, selectizedFilters);

      $('#dropdownNavbarFilter').dropdown('toggle');
      setFiltersCounter(filters);

      document.dispatchEvent(new Event('render_markers'));
    });

  $('.js-clear-filters-btn')
    .off('click')
    .on('click', () => {
      clearFilters(filters, selectizedFilters);

      $('#dropdownNavbarFilter').dropdown('toggle');
      $('#dropdownNavbarFilter').html('<i class="fas fa-filter fs-14"></i> Filter Pins');

      document.dispatchEvent(new Event('render_markers'));
    });

  setFiltersCounter(filters);
};

const setFiltersCounter = (filters: FilterNames) => {
  const filtersCounter = filters.reduce((filtersCounter, filter) => {
    const cookieFilter = CookiesService.explorePageFilters.get(filter);
    if (cookieFilter && cookieFilter.length > 2) return filtersCounter + 1;
    else return filtersCounter;
  }, 0);

  if (filtersCounter > 0) {
    $('#dropdownNavbarFilter').html(
      `<i class='fas fa-filter fs-14'></i> Filter Pins (${filtersCounter})`
    );
  } else {
    $('#dropdownNavbarFilter').html('<i class="fas fa-filter fs-14"></i> Filter Pins');
  }
};

const initSelectizeFilters = (filters: FilterNames): SelectizedFilters => {
  return filters.map((filter) => {
    const $filter = $(`select.js-select-${filter}-filters`);
    if ($filter[0].selectize) $filter[0].selectize.destroy();
    const selectizeOptions: Selectize.IOptions<any, any> = {
      sortField: 'text',
      plugins: {
        remove_button: 'remove_button',
        dropdown_header: {
          title:
            `<a id='${filter}-select-all' class='link me-2'>Select all</a>` +
            `<a id='${filter}-clear-all' class='link'>Clear all</a>`,
          html: function (data: any) {
            return `<div class='${data.headerClass}'>${data.title}</div>`;
          },
        },
      },
      delimiter: ',',
      persist: false,
    };

    if ($filter.hasClass('js-select-pins-filters'))
      selectizeOptions.render = {
        option: function (data) {
          return `<div class='option' data-icon='${data.icon}'>${data.text}</div>`;
        },
        item: function (data) {
          return `<div class='item' data-icon='${data.icon}'>${data.text}</div>`;
        },
      };

    const $selectized = $filter.selectize(selectizeOptions);

    const $arrow = $(`#close-select-${filter}-filters`);
    $arrow.off().on('mousedown', () => {
      const $input = $(`.selectize-control.js-select-${filter}-filters`).find('.selectize-input');
      if (!$input.hasClass('input-active')) $selectized[0].selectize.open();
    });
    return $selectized;
  });
};

const showDropdown = (filters: FilterNames) => {
  filters.forEach((filter) => {
    const $selectizeControl = $(`.selectize-control.js-select-${filter}-filters`);
    onClassMutation($selectizeControl.find('.selectize-input')[0], (target) => {
      if (!target.classList.contains('input-active')) return;

      const dropdown = target.nextElementSibling as HTMLElement;
      if (dropdown.style.display !== 'none') return;
      dropdown.style.display = 'block';
      dropdown.style.top = `${target.offsetHeight}px`;
      dropdown.style.width = `${target.offsetWidth}px`;
    });
  });
};

const onSelectAll = (filters: FilterNames, selectizedFilters: SelectizedFilters) =>
  filters.forEach((filter, i) =>
    $(`#${filter}-select-all`).on('mousedown', () => {
      const allOptions = Object.keys(selectizedFilters[i][0].selectize.options);
      selectizedFilters[i][0].selectize.setValue(allOptions);
    })
  );

const onClearAll = (filters: FilterNames, selectizedFilters: SelectizedFilters) =>
  filters.forEach((filter, i) =>
    $(`#${filter}-clear-all`).on('mousedown', () =>
      setTimeout(() => {
        selectizedFilters[i][0].selectize.clear();
      }, 250)
    )
  );

const restoreSavedFilters = (filters: FilterNames, selectizedFilters: SelectizedFilters) =>
  filters.forEach((filter, i) => {
    const cookieFilters = CookiesService.explorePageFiltersSaved.get(filter);
    if (!cookieFilters || cookieFilters === '[]') {
      selectizedFilters[i][0].selectize.clear();
      return;
    }
    JSON.parse(cookieFilters).forEach((item: [number | string, string]) => {
      selectizedFilters[i][0].selectize.addOption({
        value: item[0],
        text: item[1],
      });
      selectizedFilters[i][0].selectize.addItem(item[0]);
    });
  });

const setCookiesFilters = (filters: FilterNames, selectizedFilters: SelectizedFilters): string[] =>
  filters.map((filter, i) => {
    const filterItemsValue = selectizedFilters[i][0].selectize.getValue();
    const filterItems = unwrapSelectizeOptions(selectizedFilters[i][0].selectize);
    CookiesService.explorePageFilters.set(filter, filterItemsValue);
    CookiesService.explorePageFiltersSaved.set(filter, filterItems);
    return filterItemsValue;
  });

const clearFilters = (filters: FilterNames, selectizedFilters: SelectizedFilters) =>
  filters.forEach((filter, i) => {
    selectizedFilters[i][0].selectize.clear();
    CookiesService.explorePageFilters.remove(filter);
    CookiesService.explorePageFiltersSaved.remove(filter);
  });

const unwrapSelectizeOptions = (selectize: Selectize.IApi<any, any>): [string, string][] => {
  const values = selectize.getValue();
  return Object.keys(selectize.options).reduce((acc: [string, string][], key) => {
    if (values.includes(selectize.options[key].value))
      return [...acc, [selectize.options[key].value, selectize.options[key].text]];
    else return acc;
  }, []);
};
