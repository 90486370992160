import React, { useContext, useState } from 'react';

import Button from 'react-bootstrap/Button';

import { TableSettingsContext } from '@Deals/context/TableSettingsContext';
import { ColumnName } from '@Deals/typings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DraggableList from '@/components/DraggableList';
import style from '@/components/DropdownBtn/style.module.scss';
import Modal from '@/components/Modal';
import { arrayMove } from '@/utils';

const TableSettings = () => {
  const { tableSettings, updateTableSettings } = useContext(TableSettingsContext);
  const [show, setShow] = useState(false);
  const [list, setList] = useState(tableSettings);

  const handleCloseModal = () => {
    setShow(false);
    setList(tableSettings);
  };

  const handleShowModal = () => {
    setShow(true);
    setList(tableSettings);
  };

  const handleSave = () => {
    setShow(false);
    updateTableSettings(list);
  };

  const toggleItemVisibility = (item: [string, boolean]) => {
    setList(
      list.map((listItem) => {
        if (listItem[0] !== item[0]) return listItem;
        return [item[0], !item[1]];
      })
    );
  };

  const List = ({ children }: { children: React.ReactNode }) => {
    return <div className='d-flex flex-column'>{children}</div>;
  };

  const Item = (props: {
    value: [keyof typeof ColumnName, boolean];
    onMouseDown: React.MouseEventHandler;

    draggableClassName: string;
  }) => {
    const { value, onMouseDown, draggableClassName } = props;
    return (
      <div className={'mb-2 ' + draggableClassName}>
        <FontAwesomeIcon icon='bars' className='pointer fas-icon me-3' onMouseDown={onMouseDown} />
        {ColumnName[value[0]]}
        <FontAwesomeIcon
          icon={value[1] ? 'eye' : 'eye-slash'}
          className='pointer fas-icon ms-2'
          style={value[1] ? { color: '#006E8C' } : { color: '#C3C3C3' }}
          onClick={() => toggleItemVisibility(value)}
        />
      </div>
    );
  };

  return (
    <>
      <Button variant='light' className={style.btn + ` me-3`} onClick={handleShowModal}>
        <FontAwesomeIcon icon='wrench' />
      </Button>

      <Modal
        modalProps={{ size: 'sm', backdrop: 'static', show }}
        title='Table Configuration'
        onSave={handleSave}
        onClose={handleCloseModal}
      >
        <DraggableList
          items={list}
          onReorder={(removeIndex, insertIndex) => {
            setList(arrayMove(list, removeIndex, insertIndex));
          }}
          Container={List}
          Item={Item}
        />
      </Modal>
    </>
  );
};

export default TableSettings;
