import React, { useState } from 'react';

import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DraggableList from '@/components/DraggableList';
import style from '@/components/DropdownBtn/style.module.scss';
import Modal from '@/components/Modal';
import { Option } from '@/typings';
import { arrayMove } from '@/utils';

import { ResourceColumnNames } from '.';

export type ResourceColumn = Option & { active: boolean };
const ResourcesTableSettings = (props: {
  columnsSettings: ResourceColumn[];
  updateColumnsSettings: (cols: ResourceColumn[]) => void;
}) => {
  const { columnsSettings, updateColumnsSettings } = props;
  const [show, setShow] = useState(false);
  const [columns, setColumns] = useState<ResourceColumn[]>(columnsSettings);

  const handleShow = () => {
    setShow(true);
    setColumns(columnsSettings);
  };
  const handleClose = () => {
    setShow(false);
    setColumns(columnsSettings);
  };
  const handleSave = () => {
    setShow(false);
    updateColumnsSettings(columns);
  };

  const toggleItemVisibility = (item: ResourceColumn) => {
    setColumns(
      columns.map((col) => {
        if (col.name !== item.name) return col;
        return { ...col, active: !col.active };
      })
    );
  };

  const Container = ({ children }: { children: React.ReactNode }) => {
    return <div className='d-flex flex-column'>{children}</div>;
  };

  const Item = (props: {
    value: ResourceColumn;
    onMouseDown: React.MouseEventHandler;
    draggableClassName: string;
  }) => {
    const { value, onMouseDown, draggableClassName } = props;
    return (
      <div className={'mb-2 ' + draggableClassName}>
        <FontAwesomeIcon icon='bars' className='pointer fas-icon me-3' onMouseDown={onMouseDown} />
        {ResourceColumnNames[value.name as keyof typeof ResourceColumnNames] ?? value.name}
        <FontAwesomeIcon
          icon={value.active ? 'eye' : 'eye-slash'}
          className='pointer fas-icon ms-2'
          style={value.active ? { color: '#006E8C' } : { color: '#C3C3C3' }}
          onClick={() => toggleItemVisibility(value)}
        />
      </div>
    );
  };

  return (
    <>
      <Button variant='light' className={style.btn + ` me-3`} onClick={handleShow}>
        <FontAwesomeIcon icon='wrench' />
      </Button>

      <Modal
        modalProps={{ size: 'sm', backdrop: 'static', show }}
        title='Table Configuration'
        onSave={handleSave}
        onClose={handleClose}
      >
        <DraggableList
          items={columns}
          onReorder={(fromIdx, toIdx) => setColumns(arrayMove(columns, fromIdx, toIdx))}
          Container={Container}
          Item={Item}
        />
      </Modal>
    </>
  );
};

export default ResourcesTableSettings;
