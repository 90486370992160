import Cookies from 'js-cookie';

import { MapImage } from '@/containers/MapImage/typings';
import { MapImageService } from '@/services/map_image_service';
import { SharedMapService } from '@/services/shared_map_service';
import { Coordinates, RequestMethod, TabTitle } from '@/typings';
import { debounce, parseFloatNoNaN, parseIntNoNaN, waitFor } from '@/utils';

import { hideContextMenu, initContextMenu } from './context_menu';
import { initCustomSelect } from './custom_select';
import { openSavedDealWindow } from './deal';
import { openNewDealWindow } from './deal/open_new';
import { onCreateDealWithCoordsClick } from './deal/save';
import { getDrawingManager } from './drawing_manager';
import { clearSearchMarkers, searchDeal } from './global_search';
import { initImagesLayer, isImagesLayoutVisible } from './images_layer';
import { initPinsExploreBtn, initTagsExploreBtn } from './map/explore_btns';
import { setDealFilters } from './map/filters/set_deal_filters';
import { initImageOverlayClass } from './map/image_overlay';
import initMapTypeLayouts from './map/layouts';
import MarkerManager from './map/markers/marker_manager';
import { measureToolInit } from './measure_tool';
import { clearParcelBuildings, initParcelLayout } from './report_all';
import './task';

window.REPLoaded = false;

export let map: google.maps.Map;
export let mapClickListener: google.maps.MapsEventListener;
export const setMapClickListener = (listener: google.maps.MapsEventListener) => {
  mapClickListener = listener;
};
export let mapClickCallback: (e: google.maps.MapMouseEvent) => void;
const DEFAULT_MAP_ZOOM = 14;
export const CLUSTER_MAX_ZOOM = 12;

export const initMap = async (
  defaultLat: number,
  defaultLng: number,
  urlParams: URLSearchParams
) => {
  const sharedMapData = await getSharedMapData();
  const [latitude, longitude] = getInitCoordinates(urlParams, sharedMapData?.coordinates);
  if (latitude && longitude) {
    defaultLat = latitude;
    defaultLng = longitude;
  }
  const mapEl = document.getElementById('map');
  if (!mapEl) return console.error('Error in initMap: Cannot find document.getElementById("map")');

  map = new google.maps.Map(mapEl, {
    center: { lat: defaultLat, lng: defaultLng },
    zoom: parseIntNoNaN(Cookies.get('current-map-zoom')) ?? DEFAULT_MAP_ZOOM,
    streetViewControl: false,
    mapTypeControl: false,
    mapTypeId: Cookies.get('current-map-type') ?? 'terrain',
    tilt: 0,
  });

  setDealFilters();

  google.maps.event.addListener(map, 'maptypeid_changed', () => {
    const mapTypeId = map.getMapTypeId();
    if (mapTypeId) Cookies.set('current-map-type', mapTypeId);
  });

  const manager = new MarkerManager();

  google.maps.event.addListener(map, 'zoom_changed', () => {
    Cookies.set('current-map-zoom', `${map.getZoom()}`);
    manager.bringSelectedMarkerToFront();
  });

  const url = $('.shared-map').length === 0 ? '/map-deals' : window.location.pathname;

  const renderMarkersD = debounce(manager.render.bind(manager), 750);
  const renderMapImageOverlayD = debounce(renderMapImageOverlay, 1000);

  google.maps.event.addListener(map, 'idle', () => {
    manager.pinMarkerManager.removeClusterIdleListener(); // to gain controll when should cluster render
    hideContextMenu();
    const bounds = map.getBounds();
    if (bounds) {
      renderMarkersD(bounds, url);
      renderMapImageOverlayD(bounds, isImagesLayoutVisible());
    } else console.error('Error in initMap cannot get map.getBounds()', map);
  });

  document.addEventListener('render_markers', () => {
    const bounds = map.getBounds();
    if (bounds) {
      manager.render(bounds, url, true);
      renderMapImageOverlay(bounds, isImagesLayoutVisible());
    } else console.error('Error in initMap cannot get map.getBounds()', map);
  });

  mapClickCallback = (e: google.maps.MapMouseEvent) => {
    hideContextMenu();
    manager.selectedMarkerCircle.clear();
    if (e.latLng) openNewDealWindow(e.latLng);
  };
  mapClickListener = map.addListener('click', mapClickCallback);

  const contextMenu = initContextMenu(map);
  measureToolInit(map, mapClickCallback, contextMenu);

  $('.js-share-btn').on('click', () => {
    setParamsForNewShareMap();
  });

  if ($('.shared-map').length === 0) searchDeal(map);

  initMapTypeLayouts();
  initCustomSelect();
  setTimeout(() => toCurrentLocation(defaultLat, defaultLng), 1000);
  openDealFromLink(urlParams);
  onCreateDealWithCoordsClick();

  const isRepInitialized = await waitFor(() => window.REPLoaded === true, 10);
  if (isRepInitialized) {
    const parcelExploreBtn = $('.js-parcel-explore-btn');
    initParcelLayout(parcelExploreBtn, sharedMapData?.isParcelLayer);
  } else alert('Cannot initialize REP API');

  const callback = (e: KeyboardEvent) => {
    if (
      e.key === 'Escape' &&
      !(e.ctrlKey || e.altKey || e.shiftKey) &&
      !(e.target as HTMLElement).classList.contains('modal')
    )
      hideExploreDeal();
  };
  document.addEventListener(`keydown`, callback);
  document.addEventListener('turbolinks:before-cache', function () {
    document.removeEventListener('keydown', callback);
  });

  initImagesLayer();
  initTagsExploreBtn();
  initPinsExploreBtn();
};

const getInitCoordinates = (
  urlParams: URLSearchParams,
  sharedMapCoordinates: Coordinates | undefined | null
) => {
  let latitude = parseFloatNoNaN(urlParams.get('deal_latitude'));
  let longitude = parseFloatNoNaN(urlParams.get('deal_longitude'));
  if (latitude && longitude) return [latitude, longitude];

  if ($('.shared-map').length > 0) {
    latitude = sharedMapCoordinates?.lat ?? null;
    longitude = sharedMapCoordinates?.lng ?? null;
  } else {
    latitude = parseFloatNoNaN(Cookies.get('current-latitude'));
    longitude = parseFloatNoNaN(Cookies.get('current-longitude'));
  }

  return [latitude, longitude];
};

const getSharedMapData = async (): Promise<{
  coordinates: Coordinates | null;
  isParcelLayer: boolean;
} | null> => {
  if ($('.shared-map').length > 0) {
    const res = await SharedMapService.info(window.location.pathname.split('/').pop()!);
    if (res.ok) {
      Cookies.set('show-tags', res.data.tag_layer);
      const lat = parseFloatNoNaN(res.data.search_latitude);
      const lng = parseFloatNoNaN(res.data.search_longtitude);
      return {
        coordinates: lat && lng ? { lat, lng } : null,
        isParcelLayer: !!res.data.parcel_layer,
      };
    } else {
      console.error('Cannot get shared map info:', res.error);
      return null;
    }
  } else return null;
};

const openDealFromLink = (urlParams: URLSearchParams) => {
  const openNewDeal = (lat: number, lng: number) => {
    MarkerManager.getInstance().selectedMarkerCircle.clear();
    openNewDealWindow(new google.maps.LatLng(lat, lng));
  };

  const { id, lat, lng, tab } = getUrlParamsDeal(urlParams);
  if (!lat || !lng) return;

  if (id) {
    const res = $.ajax({ url: `/deals/${id}`, method: RequestMethod.Get, dataType: 'json' });
    res
      .then((res) => {
        if (res) openSavedDealWindow({ id, lat, lng }, getTabTitle(tab));
        else openNewDeal(lat, lng);
      })
      .catch((e) => console.error(e));
  } else openNewDeal(lat, lng);
  window.history.pushState({}, document.title, window.location.href.split('?')[0]);
};

const getTabTitle = (str: string | null): TabTitle => {
  switch (str) {
    case 'note':
      return 'Notes';
    case 'task':
      return 'Tasks';
    default:
      return 'Details';
  }
};

const setParamsForNewShareMap = () => {
  const tagsSelectize = $('.shared-map-content').find('.js-select-tags-filters')[0].selectize;
  tagsSelectize.clear();
  const tagsArr = $('.js-deals-tags-block').find('.js-select-tags-filters').val() as string[];
  tagsArr.forEach((tag) => tagsSelectize.addItem(tag));

  const opportunitiesSelectize = $('.shared-map-content').find(
    '.js-select-opportunities-filters'
  )[0].selectize;
  opportunitiesSelectize.clear();
  const opportunitiesArr = $('.js-deals-opportunities-block')
    .find('.js-select-opportunities-filters')
    .val() as string[];
  opportunitiesArr.forEach((opportunity) => opportunitiesSelectize.addItem(opportunity));

  const pinsSelectize = $('.shared-map-content').find('.js-select-pins-filters')[0].selectize;
  pinsSelectize.clear();
  const pinsArr = $('.js-deals-type-block').find('.js-select-pins-filters').val() as string[];
  pinsArr.forEach((pin) => pinsSelectize.addItem(pin));

  const center = map.getCenter();
  if (center) {
    $('.js-search-latitude').val(center.lat());
    $('.js-search-longitude').val(center.lng());
  }
};

const getUrlParamsDeal = (urlParams: URLSearchParams) => {
  const lat = parseFloatNoNaN(urlParams.get('deal_latitude'));
  const lng = parseFloatNoNaN(urlParams.get('deal_longitude'));
  const tab = urlParams.get('deal_tab');
  const id = parseIntNoNaN(urlParams.get('deal_id'));
  return { id, lat, lng, tab };
};

export const getAddressComponent = (
  place: google.maps.places.PlaceResult,
  type: string
): string | null => {
  const components = place.address_components?.filter((address_component) => {
    return address_component.types[0] === type;
  });

  if (components && components.length) return components[0].short_name;
  else return null;
};

const toCurrentLocation = (defaultLat: number, defaultLng: number) => {
  const locationButton = $('.js-current-location-btn');
  const createLocationCircle = (mapPosition: { lat: number; lng: number }) => {
    map.setCenter(mapPosition);
    new google.maps.Marker({
      position: mapPosition,
      map: map,
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 10,
        fillOpacity: 1,
        strokeWeight: 2,
        fillColor: '#5384ED',
        strokeColor: '#ffffff',
      },
    });
  };
  locationButton.off('click').on('click', () => {
    // Try HTML5 geolocation.
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const mapPosition = { lat: position.coords.latitude, lng: position.coords.longitude };
          createLocationCircle(mapPosition);
        },
        (error) => {
          const mapPosition = { lat: defaultLat, lng: defaultLng };
          createLocationCircle(mapPosition);
          handleLocationError(map.getCenter() ?? null, error.message);
        }
      );
    } else
      handleLocationError(map.getCenter() ?? null, "Your browser doesn't support geolocation.");
  });
};

const handleLocationError = (position: google.maps.LatLng | null, message: string) => {
  const infoWindow = new google.maps.InfoWindow({ position, content: `Error: ${message} ` });
  infoWindow.open(map);
};

export const onBackBtnClick = () => {
  $('.back-btn')
    .off('click')
    .on('click', () => {
      hideExploreDeal();
    });
};

export const hideExploreDeal = () => {
  document.dispatchEvent(new Event('closeDealWindow'));
  const manager = MarkerManager.getInstance();
  manager.radiusMarker.clear();
  clearParcelBuildings();
  manager.selectedMarkerCircle.clear();
  manager.newMarker.clear();
  if (!getDrawingManager()) manager.customRadiusMarker.clear();
};

export const moveModalBackdrop = ($dealBlock: JQuery<HTMLElement>) => {
  $dealBlock.append($('.modal-backdrop'));
};

export const renderNewDealPin = (position: google.maps.LatLng) => {
  const manager = MarkerManager.getInstance();
  manager.radiusMarker.clear();
  manager.customRadiusMarker.clear();
  clearSearchMarkers();

  manager.newMarker.draw(position);
};

let images: { id: number; overlay: any }[] = [];
const renderMapImageOverlay = async (bounds: google.maps.LatLngBounds, show: boolean) => {
  if (show) {
    const res = await MapImageService.index(bounds.toUrlValue());
    const inNewIdxs: Set<number> = new Set();
    const newImages = res.data.map_images.map((mapImage: MapImage) => {
      const imgIdx = images.findIndex((img) => img.id === mapImage.id);
      let imgOverlay;
      if (imgIdx !== -1) {
        inNewIdxs.add(imgIdx);
        imgOverlay = images[imgIdx].overlay;
      } else {
        const ImageOverlay = initImageOverlayClass();
        imgOverlay = new ImageOverlay(
          mapImage.id,
          new google.maps.LatLngBounds(JSON.parse(mapImage.bounds)),
          mapImage.file.url,
          map,
          parseInt(mapImage.rotation) || 0,
          parseInt(mapImage.opacity) || 100
        );
        imgOverlay.setMap(map);
      }
      return { id: mapImage.id, overlay: imgOverlay };
    });
    images.map((img, i) => !inNewIdxs.has(i) && img.overlay.setMap(null));
    images = newImages;
  } else {
    clearMapImages();
  }
};

export const clearMapImages = () => {
  images.map((image) => image.overlay.setMap(null));
  images = [];
};
