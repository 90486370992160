import * as React from 'react';

import { SetState } from '@/typings';

const CellBtn = (props: {
  show: boolean;
  children: React.ReactNode;
  setShow?: SetState<boolean>;
  onClick?: React.MouseEventHandler;
  className?: string;
}) => {
  const { setShow, show, children, onClick, className } = props;
  return (
    <td
      onMouseEnter={() => setShow && setShow(true)}
      onMouseLeave={() => setShow && setShow(false)}
      className={className}
    >
      <a className='link' onClick={onClick}>
        {show ? children : <div style={{ width: '17.5px' }} />}
      </a>
    </td>
  );
};

export default CellBtn;
