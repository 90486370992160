import React, { useRef, useState } from 'react';

import { Form } from 'react-bootstrap';
import Select, { OptionProps, SingleValue, SingleValueProps, components } from 'react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ColorPicker from '@/components/ColorPicker';
import Modal from '@/components/Modal';
import { useEffectNotFirstRender } from '@/hooks';
import { Pin, PinType } from '@/typings';
import { getPinIcon } from '@/utils/pin_icon';

const PinModal = (props: {
  show: boolean;
  setShow: (bool: boolean) => void;
  title: string;
  initPin?: Pin & { reminderDuration: number | null };
  onSave: (pin: {
    name: string;
    color: string;
    icon: PinType;
    reminderDuration: number | null;
  }) => void;
}) => {
  const { show, setShow, title, initPin, onSave } = props;
  const icons = [PinType.Marker, PinType.Star, PinType.Pin];
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState(initPin?.name);
  const [color, setColor] = useState(initPin?.color);
  const [reminderDuration, setReminderDuration] = useState(initPin?.reminderDuration);
  const [icon, setIcon] = useState(initPin?.icon ?? icons[0]);

  const form = useRef<HTMLFormElement>(null);

  useEffectNotFirstRender(() => {
    setName(initPin?.name);
    setColor(initPin?.color);
    setReminderDuration(initPin?.reminderDuration);
    setIcon(initPin?.icon ?? icons[0]);
  }, [initPin]);

  const handleClose = () => {
    setShow(false);
    setName(initPin?.name);
    setColor(initPin?.color);
    setReminderDuration(initPin?.reminderDuration);
    setIcon(initPin?.icon ?? icons[0]);
    setValidated(false);
  };

  const handleSave = () => {
    if (!form.current?.checkValidity() || !name || !color) {
      form.current?.reportValidity();
      setValidated(true);
      return;
    }
    onSave({ name, color, icon, reminderDuration: reminderDuration ?? null });
    handleClose();
  };

  const Option = (props: OptionProps<{ label: PinType; value: PinType }>) => (
    <components.Option {...props}>
      <div className='d-flex align-items-center justify-content-start'>
        <div className='me-1'>
          <FontAwesomeIcon icon={getPinIcon(props.data.label)} fixedWidth />
        </div>
        <div>{props.data.label.toUpperCase()}</div>
      </div>
    </components.Option>
  );

  const SingleValue = ({ ...props }: SingleValueProps<{ label: PinType; value: PinType }>) => (
    <components.SingleValue {...props}>
      <div className='d-flex align-items-center justify-content-start'>
        <div className='me-1'>
          <FontAwesomeIcon icon={getPinIcon(props.data.label)} fixedWidth />
        </div>
        <div>{props.data.label.toUpperCase()}</div>
      </div>
    </components.SingleValue>
  );

  return (
    <Modal
      onSave={handleSave}
      onClose={handleClose}
      title={title}
      modalProps={{ backdrop: 'static', show }}
    >
      <Form validated={validated} ref={form}>
        <Form.Group className='mb-3'>
          <Form.Label>Name *</Form.Label>
          <Form.Control
            type='text'
            placeholder='Name'
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group className='mb-3 row'>
          <div className='col'>
            <Form.Label>Color *</Form.Label>
            <ColorPicker color={color} setColor={setColor} />
          </div>
          <div className='col'>
            <Form.Label>Icon *</Form.Label>
            <Select
              defaultValue={stringToOption(icon)}
              onChange={(value) => setIcon((value as SingleValue<{ label: PinType }>)!.label)}
              options={icons.map(stringToOption)}
              components={{ Option, SingleValue }}
            />
          </div>
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Update Pin Reminder Duration (Days)</Form.Label>
          <Form.Control
            type='number'
            placeholder='Days'
            min='1'
            max='360'
            value={reminderDuration ?? undefined}
            onChange={(e) => {
              const num = parseInt(e.target.value);
              setReminderDuration(isNaN(num) ? undefined : Math.min(Math.max(num, 1), 360));
            }}
          />
        </Form.Group>
      </Form>
    </Modal>
  );
};

const stringToOption = <T extends string>(str: T) => ({ label: str, value: str });

export default PinModal;
