import { Coordinates } from '@/typings';

class Radius {
  private _marker: google.maps.Circle;

  constructor(map: google.maps.Map) {
    this._marker = new google.maps.Circle({
      map,
      visible: false,
      clickable: false,
      strokeColor: '#000000',
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: '#000000',
      fillOpacity: 0.2,
    });
  }

  get marker() {
    return this._marker;
  }

  draw(coords: google.maps.LatLng | Coordinates, radius: number): void {
    if (!radius) return;
    this._marker.setCenter(coords);
    this._marker.setRadius(radius / 0.00062137); // miles to meters
    this._marker.setVisible(true);
  }

  clear(): void {
    this._marker.setCenter(null);
    this._marker.setRadius(0);
    this._marker.setVisible(false);
  }
}

export default Radius;
