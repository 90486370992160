import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';

import style from '@/components/Modal/style.module.scss';

const ConfirmationModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('Confirmation');
  const [resolveFn, setResolveFn] = useState<(value: boolean) => void>(() => {});

  const handleConfirm = () => {
    setShowModal(false);
    resolveFn(true);
  };

  const handleCancel = () => {
    setShowModal(false);
    resolveFn(false);
  };

  window.customConfirm = (title: string, message: string) => {
    setMessage(message);
    setTitle(title);
    setShowModal(true);
    return new Promise((resolve) => {
      setResolveFn(() => resolve);
    });
  };

  return (
    <>
      <Modal show={showModal} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='d-flex align-items-center justify-content-center'>
          <span className='fst-italic'>{message}</span>
        </Modal.Body>
        <Modal.Footer>
          <button className={style.cancelBtn} onClick={handleCancel}>
            Cancel
          </button>
          <button className={style.saveBtn} onClick={handleConfirm}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
