import * as React from 'react';
import { useState } from 'react';

import Confidential from '@/containers/DealWindow/PinDetails/Confidential';
import { ParamsNames } from '@/services/deal_service';
import { Option } from '@/typings';

import DealsList from './DealsList';
import DealsSelect from './DealsSelect';
import InputForm from './InputForm';
import Row from './Row';
import SectionHeader from './SectionHeader';
import SelectForm from './SelectForm';
import Value from './Value';

const PinDetailsComponent = <T extends Option>(props: {
  deal: {
    id: number;
    confidential: boolean;
    tags: Option[];
    opportunities: Option[];
    workingUsers: Option[];
    followers: Option[];
    relatedDeals: T[];
    nickname: string;
    transactionType: Option;
    latitude: string;
    longitude: string;
  };
  options: {
    opportunities: Option[];
    tags: Option[];
    resourceTags: Option[];
    transactionTypes: Option[];
    users: Option[];
  };
  showEdit: {
    opportunities: boolean;
    tags: boolean;
    transaction_type: boolean;
    nickname: boolean;
    radius: boolean;
    custom_radius: boolean;
    related_deals: boolean;
    working_users: boolean;
    followers: boolean;
  };
  setShowEdit: (paramName: ParamsNames, bool: boolean) => void;
  onSave: (
    option: Option | Option[] | T[] | string,
    paramName: Exclude<ParamsNames, 'broker_id' | 'radius' | 'custom_radius'>
  ) => void;
  onDelete: (paramName: Exclude<ParamsNames, 'broker_id' | 'radius' | 'custom_radius'>) => void;
  openNewDeal: (deal: T) => void;
  canViewConfidential: boolean;
  setConfidential?: (confidential: boolean) => void;
  Radius: React.ReactChild;
  Parcels: React.ReactChild;
  relatedDealsBtn?: React.ReactChild;
}) => {
  const {
    deal,
    options,
    onSave,
    onDelete,
    openNewDeal,
    setConfidential,
    canViewConfidential,
    Radius,
    Parcels,
    relatedDealsBtn,
    showEdit,
    setShowEdit,
  } = props;
  const [isExpanded, setExpanded] = useState(true);

  const DetailsSelectForm = (props: {
    options: Option[] | Array<{ label: string; options: Option[] }>;
    defaultOptions: Option | Option[];
    paramName: Exclude<ParamsNames, 'broker_id' | 'nickname' | 'radius' | 'custom_radius'>;
    isMulti?: boolean;
  }) => {
    const { options, defaultOptions, paramName, isMulti } = props;
    return (
      <SelectForm
        options={options}
        defaultOptions={defaultOptions}
        onSaveClick={(options) => onSave(options, paramName)}
        onCloseClick={() => setShowEdit(paramName, false)}
        onDeleteClick={() => onDelete(paramName)}
        isMulti={isMulti ?? false}
      />
    );
  };

  return (
    <div className='mt-20 ml-20 mr-20'>
      <SectionHeader
        title='Pin Details'
        isBodyExpanded={isExpanded}
        onClick={() => setExpanded(!isExpanded)}
      />

      {isExpanded && (
        <>
          {canViewConfidential && (
            <Row name='Confidential'>
              <Confidential
                setConfidential={setConfidential}
                confidential={deal.confidential}
                dealId={deal.id}
              />
            </Row>
          )}

          <Row name='Latitude, Longitude'>
            <p>
              {deal.latitude}, {deal.longitude}
            </p>
          </Row>

          <Row name='Tenant'>
            <Value
              value={deal.opportunities}
              onClick={() => setShowEdit('opportunities', true)}
              showEdit={showEdit.opportunities}
            >
              <DetailsSelectForm
                options={options.opportunities}
                defaultOptions={deal.opportunities}
                paramName='opportunities'
                isMulti
              />
            </Value>
          </Row>

          <Row name='Tags'>
            <Value
              value={deal.tags}
              onClick={() => setShowEdit('tags', true)}
              showEdit={showEdit.tags}
            >
              <DetailsSelectForm
                options={[
                  { label: 'Pins', options: options.tags },
                  { label: 'Resources', options: options.resourceTags },
                ]}
                defaultOptions={deal.tags}
                paramName='tags'
                isMulti
              />
            </Value>
          </Row>

          <Row name='Transaction Type'>
            <Value
              value={deal.transactionType?.name}
              onClick={() => setShowEdit('transaction_type', true)}
              showEdit={showEdit.transaction_type}
            >
              <DetailsSelectForm
                options={options.transactionTypes}
                defaultOptions={deal.transactionType}
                paramName='transaction_type'
              />
            </Value>
          </Row>

          <Row name='Nickname'>
            <Value
              value={deal.nickname}
              onClick={() => setShowEdit('nickname', true)}
              showEdit={showEdit.nickname}
            >
              <InputForm
                defaultValue={deal.nickname}
                onSave={(option) => onSave(option, 'nickname')}
                onClose={() => setShowEdit('nickname', false)}
                onDelete={() => onDelete('nickname')}
              />
            </Value>
          </Row>

          <Row name='Radius'>{Radius}</Row>

          <Row name='Related to'>
            <div>
              <Value
                value={null}
                showEdit={showEdit.related_deals}
                onClick={() => setShowEdit('related_deals', true)}
              >
                <DealsSelect
                  defaultValues={deal.relatedDeals}
                  onSaveClick={(options) => onSave(options, 'related_deals')}
                  onCloseClick={() => setShowEdit('related_deals', false)}
                  onDeleteClick={() => onDelete('related_deals')}
                />
              </Value>
              <DealsList deals={deal.relatedDeals} onClick={openNewDeal}>
                {relatedDealsBtn}
              </DealsList>
            </div>
          </Row>

          <Row name='Working Deal'>
            <Value
              value={deal.workingUsers}
              onClick={() => setShowEdit('working_users', true)}
              showEdit={showEdit.working_users}
            >
              <DetailsSelectForm
                options={options.users}
                defaultOptions={deal.workingUsers}
                paramName='working_users'
                isMulti
              />
            </Value>
          </Row>

          <Row name='Following Deal'>
            <Value
              value={deal.followers}
              onClick={() => setShowEdit('followers', true)}
              showEdit={showEdit.followers}
            >
              <DetailsSelectForm
                options={options.users}
                defaultOptions={deal.followers}
                paramName='followers'
                isMulti
              />
            </Value>
          </Row>

          <Row name='Assemblage'>{Parcels}</Row>
        </>
      )}
    </div>
  );
};

export default PinDetailsComponent;
