import * as React from 'react';
import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CustomField, CustomFieldData } from '@/containers/DealWindow/CustomFields/typings';

import CustomFieldModal from './CustomFieldModal';

const CustomFieldRow = ({
  children,
  customField,
  onMouseDown,
  deleteCustomField,
  updateCustomField,
  sharedMapWindow,
}: {
  children: React.ReactNode;
  customField: CustomField;
  onMouseDown: React.MouseEventHandler;
  deleteCustomField: (id: number) => Promise<void>;
  updateCustomField: (data: CustomFieldData, id: number) => Promise<void>;
  sharedMapWindow?: boolean;
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [isEditBtnsVisible, setEditBtnsVisibility] = useState(false);

  const url = () => {
    if (
      (customField.fieldType === 'hyperlink' || customField.fieldType === 'file') &&
      customField.value?.text
    ) {
      let url = customField.value.text;
      url = /\:\/\//.test(url) ? url : 'http://' + url;
      return new URL(url);
    } else return null;
  };

  const EditBtns = () => (
    <div className='w-20p me-4'>
      {!customField.global && !sharedMapWindow && isEditBtnsVisible && (
        <div className='d-flex'>
          <a className='btn p-0 m-top-3' onClick={() => setShowEdit(true)}>
            <FontAwesomeIcon icon='pencil-alt' className='light-blue-color mr-15 pointer' />
          </a>
          <a className='btn p-0 m-top-3' onClick={() => deleteCustomField(customField.id)}>
            <FontAwesomeIcon icon='trash-alt' className='light-blue-color mr-15 pointer' />
          </a>
        </div>
      )}
    </div>
  );

  return (
    <div
      className='d-flex'
      onMouseEnter={() => setEditBtnsVisibility(true)}
      onMouseLeave={() => setEditBtnsVisibility(false)}
    >
      <FontAwesomeIcon
        icon='align-justify'
        className='fa-w-14 mt-1 me-2'
        onMouseDown={sharedMapWindow ? undefined : onMouseDown}
      />
      <p className='source-sans-semi-bold-font w-198p fuscous-gray-color'>
        {customField.name}
        {customField.privacy === 'private' && (
          <FontAwesomeIcon icon='user-secret' className='ms-2' />
        )}
        {customField.fieldType === 'hyperlink' && (
          <a href={url()?.href} target='_blank' rel='noopener noreferrer'>
            <FontAwesomeIcon icon='external-link-alt' className='ms-2' />
          </a>
        )}
        {customField.fieldType === 'file' && (
          <a href={url()?.href} target='_blank' rel='noopener noreferer'>
            <FontAwesomeIcon icon={['far', 'file']} className='ms-2' />
          </a>
        )}
      </p>
      <EditBtns />
      <div className='pointer ml-20 m-top-3'>{children}</div>
      <CustomFieldModal
        show={showEdit}
        setShow={setShowEdit}
        customField={customField}
        title='Edit'
        handleSubmit={updateCustomField}
      />
    </div>
  );
};

export default CustomFieldRow;
