import * as React from 'react';
import { useState } from 'react';

import Form from 'react-bootstrap/Form';

import Modal from '@/components/Modal';
import { useEffectNotFirstRender } from '@/hooks';
import { SetState } from '@/typings';

import { SelectionType, Tag } from './typings';

const TagModal = (props: {
  show: boolean;
  setShow: SetState<boolean>;
  title: string;
  saveTag: (tag: { name: string; selectionType: SelectionType }) => Promise<void>;
  activeNames: string[];
  initTag?: Tag;
}) => {
  const { show, setShow, title, saveTag, initTag, activeNames } = props;
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState(initTag?.name);
  const [selectionType, setSelectionType] = useState(initTag?.selectionType ?? SelectionType.Pin);

  useEffectNotFirstRender(() => {
    setName(initTag?.name);
    setSelectionType(initTag?.selectionType ?? SelectionType.Pin);
  }, [initTag]);

  const handleTypeOnChange = () => {
    setSelectionType(
      selectionType === SelectionType.Pin ? SelectionType.Resource : SelectionType.Pin
    );
  };

  const handleClose = () => {
    setName(initTag?.name);
    setSelectionType(initTag?.selectionType ?? SelectionType.Pin);
    setValidated(false);
    setShow(false);
  };

  const handleSave = () => {
    if (!isValidName(name, activeNames, name)) {
      setValidated(true);
      return;
    }
    saveTag({
      name,
      selectionType,
    });
    handleClose();
  };

  return (
    <Modal
      onSave={handleSave}
      onClose={handleClose}
      title={title}
      modalProps={{ backdrop: 'static', show }}
    >
      <Form.Group className='mb-3'>
        <Form.Label>Name *</Form.Label>
        <Form.Control
          type='text'
          placeholder='Name'
          defaultValue={name}
          onChange={(e) => setName(e.target.value)}
          isInvalid={validated && !isValidName(name, activeNames, initTag?.name)}
        />
        <Form.Control.Feedback type='invalid'>
          Name can not be empty and must be uniq
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Type *</Form.Label>
        <div>
          <Form.Check
            inline
            label='Pin'
            name='group-selection-type'
            type='radio'
            checked={selectionType === SelectionType.Pin}
            onChange={() => handleTypeOnChange()}
          />
          <Form.Check
            inline
            label='Resource'
            name='group-selection-type'
            type='radio'
            checked={selectionType === SelectionType.Resource}
            onChange={() => handleTypeOnChange()}
          />
        </div>
      </Form.Group>
    </Modal>
  );
};

export default TagModal;

const isValidName = (
  name: string | undefined,
  activeNames: string[],
  tagName: string | undefined
): name is string => {
  if (!isString(name)) return false;

  if (tagName) return !activeNames.filter((name) => name !== tagName).includes(name);
  else return !activeNames.includes(name);
};

const isString = (str: string | undefined): str is string =>
  str ? str.trim().length !== 0 : false;
