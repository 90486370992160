import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ReminderButton from '@/components/ReminderButton';
import ShareBtn from '@/components/ShareBtn';
import { InfoDeal } from '@/containers/DealWindow/typings';

const Info = ({ children, deal }: { children?: React.ReactChild; deal: InfoDeal }) => {
  return (
    <div className='deal-window-info'>
      <div className='d-md-flex justify-content-between align-items-start mt-2'>
        <div className='deal-window-info-address fuscous-gray-color'>
          <h2 className='fs-24 fw-600'>{deal.nickname}</h2>
          <h2 className='fs-24'> {deal.address} </h2>
        </div>

        <div className='d-flex align-items-center'>
          {children}
          <ShareBtn
            className='d-flex justify-content-center align-items-center ml-10'
            link={deal.link}
          />
          <ReminderButton
            className='d-flex justify-content-center align-items-center ml-10'
            deal={deal}
          />
        </div>
      </div>

      <div className='d-flex mt-2'>
        {deal.name && (
          <p className='me-3'>
            <FontAwesomeIcon icon='building' /> {deal.name}
          </p>
        )}

        {deal.phone && (
          <p className='me-3'>
            <FontAwesomeIcon icon='phone-volume' /> {deal.phone}
          </p>
        )}
        {deal.acres && (
          <p className='me-3'>
            <FontAwesomeIcon icon='arrows-alt-h' /> {deal.acres} acres
          </p>
        )}
      </div>
      <div>
        {deal.latitude && deal.longitude && (
          <p className='m-0'>
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${deal.latitude}%2C${deal.longitude}`}
              target='_blank'
              className='no-link link-dark'
            >
              <FontAwesomeIcon icon='map-marker-alt' />
              {` ${deal.latitude} ${deal.longitude} `}
              <FontAwesomeIcon
                icon='up-right-from-square'
                className='pointer light-blue-color'
                title='Open on Google Maps'
              />
            </a>
          </p>
        )}
      </div>
    </div>
  );
};

export default Info;
