import Cookies from 'js-cookie';

import { ParcelData, ReportAllData } from '@/typings';
import { parcelDataToReportAllData } from '@/utils';

import { map } from './map';

///////////////////
// Parcel Layout //
///////////////////

const MIN_PARCEL_ZOOM = 17;

export const initParcelLayout = (
  parcelExploreBtn: JQuery<HTMLElement>,
  sharedMapParcelLayer?: boolean
) => {
  parcelExploreBtn.off('click').on('click', () => {
    setParcelLayout(!isParcelLayoutVisible());
    toggleParcelExploreBtn(parcelExploreBtn);
  });
  setParcelLayout(sharedMapParcelLayer || isParcelLayoutVisible());
  toggleParcelExploreBtn(parcelExploreBtn);

  let previousZoom = map.getZoom()!;
  google.maps.event.addListener(map, 'zoom_changed', () => {
    const zoom = map.getZoom()!;
    if (isParcelLayoutVisible()) {
      if (zoom < previousZoom && zoom < MIN_PARCEL_ZOOM) {
        setParcelLayout(false);
        toggleParcelExploreBtn(parcelExploreBtn);
      } else if (zoom === MIN_PARCEL_ZOOM && previousZoom < MIN_PARCEL_ZOOM) showParcelOverlay();
    }
    previousZoom = zoom;
  });
};

const setParcelLayout = (active: boolean) => {
  if (active) {
    Cookies.set('report-all-layout', 'true');
    map.getZoom()! >= MIN_PARCEL_ZOOM && showParcelOverlay();
  } else {
    Cookies.remove('report-all-layout');
    clearParcelBuildings();
    hideParcelOverlay();
  }
};

const toggleParcelExploreBtn = (btn: JQuery<HTMLElement>) => {
  btn.addClass(isParcelLayoutVisible() ? 'js-parcel-on' : 'js-parcel-off');
  btn.removeClass(isParcelLayoutVisible() ? 'js-parcel-off' : 'js-parcel-on');
  btn.find('input:checkbox:first').prop('checked', isParcelLayoutVisible());

  $('.shared-map-content')
    .find('.parcels-checkbox')
    .find('input:checkbox:first')
    .prop('checked', isParcelLayoutVisible());
};

const isParcelLayoutVisible = () => Cookies.get('report-all-layout') === 'true';

const showParcelOverlay = () => {
  // inserts overlayMapType "Parcels" to map.overlayMapTypes
  REP.Layer.Google.Initialize(map, { Return_Buildings: true });
  document.dispatchEvent(new Event('showParcels'));
};

const hideParcelOverlay = () => {
  const overlays = map.overlayMapTypes.getArray();
  overlays.forEach((overlay, idx) => {
    if (overlay?.name === 'Parcels') map.overlayMapTypes.removeAt(idx);
  });
};

//////////////////////
// Parcel Buildings //
//////////////////////

let parcelBuildings: google.maps.Polygon[] = [];

export const clearParcelBuildings = () => {
  parcelBuildings.forEach((building) => {
    building.setMap(null);
  });
  parcelBuildings = [];
};

export const initNewDealReportAllEvents = async (coords: google.maps.LatLng) => {
  const res = await REPIdentifyByPoint(coords);
  if (res && res.length) {
    showReportAllDataForNewDeal(parcelInfoToReportAllData(res[0]));

    if (isParcelLayoutVisible()) {
      clearParcelBuildings();
      drawBuildings(res[0]['buildings_poly']);
      drawBorders(res[0]['geom']);
    }
  } else {
    $('.js-deal-parcel-data-block').html(
      '<div class="d-flex justify-content-center">' +
        '<p class="fs-14 mt-2 silver-color fst-italic">No Parcel Data Retrieved</p>' +
        '</div>'
    );
  }
};

export const drawParcelBordersAndBuildings = async (coords: google.maps.LatLng[]) => {
  if (!isParcelLayoutVisible()) return;
  const results = await Promise.all(coords.map((coord) => REPIdentifyByPoint(coord)));
  clearParcelBuildings();
  results.forEach((res) => {
    if (res && res.length) {
      drawBuildings(res[0]['buildings_poly']);
      drawBorders(res[0]['geom']);
    } else console.error('No Parcel Data Retrieved');
  });
};

const drawBuildings = (buildings: { geom: google.maps.Polygon[] }[] | undefined) => {
  if (!buildings) return;

  buildings.forEach((building) => {
    const buildingGeometry = building.geom;
    if (!buildingGeometry) return;

    buildingGeometry.forEach((geom) => {
      geom.setOptions({
        strokeColor: 'rgb(255,128,255)',
        clickable: false,
      });
      geom.setMap(map);

      parcelBuildings.push(geom);
    });
  });
};

const drawBorders = (borders: google.maps.Polygon[] | undefined) => {
  if (!borders) return;
  borders.forEach((geom) => {
    geom.setOptions({
      fillColor: 'rgb(144,238,144)',
      strokeColor: 'rgb(200,0,0)',
      clickable: false,
    });
    geom.setMap(map);
    parcelBuildings.push(geom);
  });
};

/////////////////
// Parcel Data //
/////////////////

const showReportAllDataForNewDeal = (reportAllData: ReportAllData) => {
  if (!reportAllData) return;

  reportAllData.forEach((value, key) => {
    if (key === 'Acreage Calculated') {
      $('.js-deal-parcel-acres').html(`<i class="fas fa-arrows-alt-h"></i> ${value} acres`);
      return;
    }
    $('.js-deal-parcel-data-block').append(
      '<div class="d-flex">' + `<p class="w-50">${key}</p><p class="w-50">${value}</p>` + '</div>'
    );
  });
};

// utils

export const REPIdentifyByPoint = async (
  coords: google.maps.LatLng
): Promise<ParcelInfo[] | null> => {
  console.log('Report All USA API triggered');
  return await new Promise((resolve) => {
    REP.Layer.Google.IdentifyByPoint(
      map,
      coords,
      (response) => {
        resolve(response.results);
      },
      (err) => {
        resolve(null);
        alert('REP Overlays error: ' + err.message);
      }
    );
  });
};

const parcelInfoToReportAllData = (parcelInfo: ParcelInfo): ReportAllData => {
  const pair = (x: string | undefined, y: string | undefined) => {
    if (x && y) return `${x}, ${y}`;
    else if (x) return x;
    else if (y) return y;
    else return undefined;
  };
  const formatPrice = (str: string | undefined) => {
    if (str && !isNaN(parseInt(str)))
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(parseInt(str));
    else return str;
  };

  const parcelData: ParcelData = {
    owner: parcelInfo['owner'],
    owner_mail_name: parcelInfo['mail_name'],
    owner_mail_address: pair(parcelInfo['mail_address1'], parcelInfo['mail_address2']),
    owner_mail_address_2: parcelInfo['mail_address3'],
    land: pair(parcelInfo['land_use_class'], parcelInfo['land_use_code']),
    acreage_deeded: parcelInfo['acreage_deeded'],
    acreage_calc: parcelInfo['acreage_calc'],
    country: pair(parcelInfo['county_name'], parcelInfo['county_id']),
    muni: pair(parcelInfo['muni_name'], parcelInfo['muni_id']),
    market_value_land: formatPrice(parcelInfo['mkt_val_land']),
    market_value_building: formatPrice(parcelInfo['mkt_val_bldg']),
    market_value_total: formatPrice(parcelInfo['mkt_val_tot']),
    census_zip: parcelInfo['census_zip'],
    transaction_data: parcelInfo['transc_date'],
    parcel_id: parcelInfo['parcel_id'],
    report_all_usa_id: parcelInfo['rausa_id'],
  };

  return parcelDataToReportAllData(parcelData);
};

// types

export type ParcelInfo = Partial<{
  owner: string;
  mail_name: string;
  mail_address1: string;
  mail_address2: string;
  mail_address3: string;
  land_use_class: string;
  land_use_code: string;
  acreage_deeded: string;
  acreage_calc: string;
  county_name: string;
  county_id: string;
  muni_name: string;
  muni_id: string;
  mkt_val_land: string;
  mkt_val_bldg: string;
  mkt_val_tot: string;
  census_zip: string;
  transc_date: string;
  parcel_id: string;
  rausa_id: string;
  buildings_poly: { geom: google.maps.Polygon[] }[];
  geom: google.maps.Polygon[];
}>;
