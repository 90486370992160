import Cookies from 'js-cookie';

import { Error, KeysToCamel, Result } from '@/typings';
import { camelize } from '@/utils';

export const useFilters = <FT extends Record<string, any>, AFT extends Record<string, any>>(
  getAvailiableFilters: () => Promise<Result<AFT>>,
  addError: (s: string, e: Error) => void,
  setSelectedFilters: (f: FT) => void,
  setSavedFilters: (f: FT) => void,
  setAvailableFilters: (f: KeysToCamel<AFT>) => void
) => {
  const getAvailableFilters = async () => {
    const res = await getAvailiableFilters();
    if (res.ok) setAvailableFilters(camelize(res.data));
    else addError('Error in getAvailableFilters', res);
  };

  const clearAllFilters = (objectToFilter: string, defaultFilters: FT) => {
    Cookies.set(`${objectToFilter}-index-saved-filters`, JSON.stringify(defaultFilters));
    setSelectedFilters(defaultFilters);
    setSavedFilters(defaultFilters);
  };

  const setSavedFiltersFromSelected = (objectToFilter: string, selectedFilters: FT) => {
    Cookies.set(`${objectToFilter}-index-saved-filters`, JSON.stringify(selectedFilters));
    setSavedFilters({ ...selectedFilters });
  };

  const restoreSelectedFiltersFromSaved = (savedFilters: FT) => {
    setSelectedFilters({ ...savedFilters });
  };

  const restoreFiltersFromCookies = (objectToFilter: string, savedFilters: FT) => {
    const cookiesFilters = Cookies.get(`${objectToFilter}-index-saved-filters`);
    if (!cookiesFilters) return;

    const cookiesFiltersParsed = JSON.parse(cookiesFilters);
    if (JSON.stringify(cookiesFiltersParsed) == JSON.stringify(savedFilters)) return;

    setSavedFilters({ ...cookiesFiltersParsed });
    setSelectedFilters({ ...cookiesFiltersParsed });
  };

  const restoreFiltersFromString = (filters: string) => {
    const filtersParsed = JSON.parse(filters);
    setSavedFilters({ ...filtersParsed });
    setSelectedFilters({ ...filtersParsed });
  };

  return {
    clearAllFilters,
    setSavedFiltersFromSelected,
    restoreSelectedFiltersFromSaved,
    restoreFiltersFromCookies,
    restoreFiltersFromString,
    getAvailableFilters,
  };
};
