import { DataType, Option, RequestMethod, Result } from '@/typings';
import { fetchResult } from '@/utils';

export const WorkingCompanyService = {
  index: (q: Record<string, string>): Promise<Result<(Option & { active: boolean })[]>> => {
    const body = $.param({ q });
    const url = '/working_companies?' + body;
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  options: (): Promise<Result<(Option & { active: true })[]>> => {
    const url = '/working_companies/options';
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  create: (name: string): Promise<Result<number>> => {
    const body = JSON.stringify({ working_company: { name } });
    const url = '/working_companies';
    const req = { url, method: RequestMethod.Post, dataType: DataType.JSON, body };
    return fetchResult(req);
  },
  update: (working_company: Option): Promise<Result<'ok'>> => {
    const body = JSON.stringify({ working_company });
    const url = `/working_companies/${working_company.id}`;
    const req = { url, method: RequestMethod.Put, dataType: DataType.JSON, body };
    return fetchResult(req);
  },
  destroy: (id: number): Promise<Result<Option & { active: boolean }>> => {
    const url = `/working_companies/${id}`;
    const req = { url, method: RequestMethod.Delete, dataType: DataType.JSON };
    return fetchResult(req);
  },
  restore: (id: number): Promise<Result<Option & { active: boolean }>> => {
    const url = `/working_companies/${id}/restore`;
    const req = { url, method: RequestMethod.Put, dataType: DataType.JSON };
    return fetchResult(req);
  },
};
