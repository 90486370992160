import React, { useRef, useState } from 'react';

import { Form } from 'react-bootstrap';
import Select from 'react-select';

import Modal from '@/components/Modal';
import {
  FieldType,
  FieldTypeTitle,
  FieldTypes,
} from '@/containers/DealWindow/CustomFields/typings';
import { useEffectNotFirstRender } from '@/hooks';
import { Option } from '@/typings';

import { CustomField } from '.';

const CustomFieldModal = (props: {
  title: string;
  show: boolean;
  setShow: (bool: boolean) => void;
  initCustomField?: CustomField;
  onSave: (customField: {
    name: string;
    privacy: 'private' | 'public';
    fieldType: FieldType;
    pins: Option[];
  }) => void;
  pins: Option[] | undefined;
}) => {
  const { title, show, setShow, initCustomField, onSave } = props;
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState(initCustomField?.name ?? '');
  const [fieldType, setFieldType] = useState(initCustomField?.fieldType ?? FieldTypes[0]);
  const [privacy, setPrivacy] = useState<'public' | 'private'>(
    initCustomField?.privacy ?? 'public'
  );
  const [pins, setPins] = useState(initCustomField?.pins ?? []);
  const form = useRef<HTMLFormElement>(null);

  const setDefault = () => {
    setName(initCustomField?.name ?? '');
    setFieldType(initCustomField?.fieldType ?? FieldTypes[0]);
    setPrivacy(initCustomField?.privacy ?? 'public');
    setPins(initCustomField?.pins ?? []);
  };

  useEffectNotFirstRender(() => {
    setDefault();
  }, [initCustomField]);

  const handleClose = () => {
    setDefault();
    setShow(false);
    setValidated(false);
  };

  const handleSave = () => {
    if (!form.current?.checkValidity()) {
      form.current?.reportValidity();
      setValidated(true);
      return;
    }

    onSave({
      name,
      fieldType,
      privacy,
      pins,
    });
    handleClose();
  };

  return (
    <Modal
      onSave={handleSave}
      onClose={handleClose}
      title={title}
      modalProps={{ backdrop: 'static', show }}
    >
      <Form validated={validated} ref={form}>
        <Form.Group className='mb-3'>
          <Form.Label>Name *</Form.Label>
          <Form.Control
            type='text'
            placeholder='Name'
            defaultValue={name}
            onChange={(e) => setName(e.target.value?.trim() ?? '')}
            required
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Type *</Form.Label>
          <Form.Select
            defaultValue={fieldType}
            onChange={(e) => setFieldType(e.target.value as FieldType)}
          >
            {FieldTypes.map((fieldType) => (
              <option key={fieldType} value={fieldType}>
                {FieldTypeTitle[fieldType]}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Location *</Form.Label>
          <Form.Select defaultValue='details'>
            <option value='details'>Details</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>Pin Types</Form.Label>
          <Select
            defaultValue={pins}
            onChange={(value) => setPins([...value])}
            options={props.pins}
            isClearable
            isMulti
            closeMenuOnSelect={false}
            placeholder='All'
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id.toString()}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Privacy</Form.Label>
          <div>
            <Form.Check
              inline
              label='Public'
              name='privacy'
              type='radio'
              checked={privacy === 'public'}
              id='public-custom-field'
              onChange={() => setPrivacy('public')}
            />
            <Form.Check
              inline
              label='Private'
              name='privacy'
              type='radio'
              id='private-custom-field'
              checked={privacy === 'private'}
              onChange={() => setPrivacy('private')}
            />
          </div>
        </Form.Group>
      </Form>
    </Modal>
  );
};

export default CustomFieldModal;
