import * as React from 'react';
import { createContext, useContext } from 'react';
import { useEffect, useState } from 'react';

import { CustomField } from '@/containers/DealWindow/CustomFields/typings';
import { UserOption } from '@/containers/DealWindow/typings';
import { CompanyService } from '@/services/company_service';
import { DealService, ParamsNames, UpdateParamData } from '@/services/deal_service';
import { DealTabService } from '@/services/deal_tab_service';
import { OpportunityService } from '@/services/opportunity_service';
import { TagService } from '@/services/tag_service';
import { AjaxRequest, Avatar, Option, RequestMethod, SetState, TabTitle } from '@/typings';
import { camelize } from '@/utils';

import { ErrorContext } from './ErrorContext';
import { LoadingContext } from './LoadingContext';
import { StasContext } from './StasContext';

type DealsState = {
  isDealWindowVisible: boolean;
  setDealWindowVisibility: SetState<boolean>;
  showDealWindow: (dealId: number, tab?: TabTitle) => Promise<void>;
  currentDeal: Deal;
  setCurrentDeal: SetState<Deal>;
  tabsTitles: Array<TabTitle>;
  options: {
    opportunities: Option[];
    tags: Option[];
    resourceTags: Option[];
    transactionTypes: Option[];
    users: UserOption[];
  };
  getDetails: (dealId: number) => void;
  deleteDeal: (dealId: number) => void;
  changePinType: (dealId: number, pinId: number) => void;
  updateParam: (details: Details, param: ParamsNames, data: UpdateParamData) => Promise<void>;
  deleteParam: (details: Details, param: ParamsNames) => Promise<void>;
  setConfidential: (confidential: boolean) => void;
  setActiveTabTitle: SetState<TabTitle>;
  activeTabTitle: TabTitle;
  setBulkedConfidential: (ids: number[], confidential: boolean) => void;
  bulkedDeals: number[];
  setBulkedDeals: SetState<number[]>;
};

const defaultState = {
  isDealWindowVisible: false,
  currentDeal: {
    id: null,
    pinId: null,
    nickname: null,
    address: null,
    name: null,
    phone: null,
    acres: null,
    coordinates: null,
    link: null,
    details: null,
    confidential: null,
    isForSale: null,
    followers: null,
  },
  tabsTitles: ['Details', 'Parcel Data', 'Tasks', 'Notes', 'History'] as Array<TabTitle>,
  options: {
    opportunities: [],
    tags: [],
    resourceTags: [],
    transactionTypes: [],
    users: [],
  },
  activeTabTitle: 'Details' as TabTitle,
  bulkedDeals: [],
};

export const DealsContext = createContext<DealsState>(null as unknown as DealsState);

export const DealsProvider: React.FC = ({ children }) => {
  const { addError } = useContext(ErrorContext);
  const { loading } = useContext(LoadingContext);
  const { getSta } = useContext(StasContext);
  const [isDealWindowVisible, setDealWindowVisibility] = useState<boolean>(false);
  const [currentDeal, setCurrentDeal] = useState<Deal>(defaultState.currentDeal as unknown as Deal);
  const [deals, setDeals] = useState<Array<Deal>>([]);
  const [tabsTitles, setTabsTitles] = useState<Array<TabTitle>>(defaultState.tabsTitles);
  const [activeTabTitle, setActiveTabTitle] = useState<TabTitle>(tabsTitles[0]);
  const [bulkedDeals, setBulkedDeals] = useState<number[]>([]);

  // options
  const [opportunities, setOpportunities] = useState<Array<Option>>([]);
  const [tags, setTags] = useState<Array<Option>>([]);
  const [resourceTags, setResourceTags] = useState<Array<Option>>([]);
  const [transactionTypes, setTransactionTypes] = useState<Array<Option>>([]);
  const [users, setUsers] = useState<Array<UserOption>>([]);
  const options = { tags, resourceTags, transactionTypes, opportunities, users };

  useEffect(() => {
    (async function () {
      const [tabsTitles, opportunities, tags, transactionTypes, users] = await Promise.all([
        DealTabService.index(),
        OpportunityService.options(),
        TagService.index(),
        DealService.getTransactionTypes(),
        CompanyService.usersList(),
      ]);

      if (tabsTitles.ok) {
        setTabsTitles(tabsTitles.data);
        setActiveTabTitle(tabsTitles.data[0]);
      } else addError('Error cannot get deal tabs:', tabsTitles);

      if (opportunities.ok) setOpportunities(opportunities.data);
      else addError('Error cannot get opportunities:', opportunities);

      if (tags.ok) {
        setTags(tags.data.deals_tags);
        setResourceTags(tags.data.resources_tags);
      } else addError('Error cannot get tags:', tags);

      if (transactionTypes.ok) setTransactionTypes(transactionTypes.data);
      else addError('Error cannot get transaction types:', transactionTypes);

      if (users.ok) setUsers(users.data);
      else addError('Error cannot get users:', users);
    })();
  }, []);

  const getData: GetData = async (req, action) => {
    return loading(async () => {
      try {
        return (await $.ajax(req)) || true;
      } catch (e: any) {
        if (e.status === 200) return true;
        addError('Error in DealsContext: ', {
          status: e.status,
          statusText: `Cannot ${action}: ` + e.statusText,
          error: e,
        });
      }
    });
  };

  const getDeal = async (dealId: number): Promise<Deal | null> => {
    const req = { url: `deals/${dealId}`, method: RequestMethod.Get, dataType: 'json' };
    const res = await getData(req, 'get deal');
    if (res) return formatDeal(res);
    else return null;
  };

  const showDealWindow = async (dealId: number, tab?: TabTitle) => {
    const deal = deals.find((deal) => deal.id === dealId);
    if (!deal) {
      const deal = await getDeal(dealId);
      if (!deal) return;
      setDeal(deal);
    } else if (currentDeal.id !== deal.id) setCurrentDeal(deal);

    if (tab) setActiveTabTitle(tab);
    else setActiveTabTitle(tabsTitles[0]);

    setDealWindowVisibility(true);
  };

  const deleteDeal = async (dealId: number) => {
    const req = { url: 'ajax-deal-unsave', method: RequestMethod.Delete, data: { id: dealId } };
    const res = await getData(req, 'delete deal');
    if (!res) return;
    setDealWindowVisibility(false);
    setDeals(deals.filter((deal) => deal.id !== dealId));
    getSta();
  };

  const setDeal = (newDeal: Deal) => {
    setCurrentDeal(newDeal);
    setDeals([...deals.filter((deal) => deal.id !== newDeal.id), newDeal]);
  };

  const changePinType = async (dealId: number, pinId: number) => {
    const req = { url: `/deals/${dealId}/update_pin/${pinId}`, method: RequestMethod.Put };
    const res = await getData(req, 'change pin type');
    if (!res) return;
    setDeal({ ...currentDeal, pinId });
    getSta();
  };

  const updateParam = async (details: Details, param: ParamsNames, data: UpdateParamData) => {
    const res = await DealService.updateParam(currentDeal.id!, param, data);
    if (res.ok) {
      setDeal({ ...currentDeal, details });
      getSta();
    }
  };

  const deleteParam = async (details: Details, param: string) => {
    const url = `/deals/${currentDeal.id}/delete_param/${param}`;
    const req = { url, method: RequestMethod.Put };
    const res = await getData(req, 'delete param');
    if (!res) return;
    setDeal({ ...currentDeal, details });
    getSta();
  };

  const setConfidential = (confidential: boolean) => {
    setDeal({ ...currentDeal, confidential });
  };

  const setBulkedConfidential = (ids: number[], confidential: boolean) => {
    setDeals(
      deals.map((deal) => {
        if (!ids.includes(deal.id)) return deal;
        return { ...deal, confidential };
      })
    );
    setCurrentDeal({ ...currentDeal, confidential });
  };

  const getDetails = async (dealId: number) => {
    const req = {
      url: '/ajax-deal-details',
      method: RequestMethod.Get,
      data: { id: dealId },
    };
    const res = await getData(req, 'get deal details');
    setDeal({ ...currentDeal, details: res ? (camelize(res) as Details) : null });
  };

  return (
    <DealsContext.Provider
      value={{
        isDealWindowVisible,
        setDealWindowVisibility,
        showDealWindow,
        currentDeal,
        setCurrentDeal,
        tabsTitles,
        options,
        deleteDeal,
        changePinType,
        getDetails,
        updateParam,
        deleteParam,
        setConfidential,
        activeTabTitle,
        setActiveTabTitle,
        setBulkedConfidential,
        bulkedDeals,
        setBulkedDeals,
      }}
    >
      {children}
    </DealsContext.Provider>
  );
};

const formatDeal = (res: any): Deal => {
  res = camelize(res);
  const link = `${window.location.origin}/?deal_id=${res.id}&deal_latitude=${res.latitude}&deal_longitude=${res.longitude}`;
  const coordinates = { lat: parseFloat(res.latitude), lng: parseFloat(res.longitude) };
  return { ...res, address: res.fullAddress, link, coordinates };
};

// types

type GetData = (req: AjaxRequest, action: string) => Promise<any>;

export type Deal = {
  id: number;
  pinId: number;
  nickname: string;
  address: string;
  name: string;
  phone: string;
  acres: number;
  coordinates: { lat: number; lng: number };
  link: string;
  details: Details | null;
  confidential: boolean;
  isForSale: boolean | null;
  followers: Option[];
};

export type Details = {
  opportunities: Array<Option>;
  tags: Array<Option>;
  transactionType: Option;
  nickname: string;
  radius: 'custom' | number;
  relatedDeals: Array<Option>;
  workingUsers: Option[];
  customFields: Array<CustomField>;
  customFieldsPositionChanged?: Boolean;
  followers: Option[];
};

export type History = {
  user: { fullName: string; avatar: Avatar };
  titleText: string;
  bodyText: string;
  tabName: { text: string; tabTitle: TabTitle };
  createdAt: string;
  bodyTextLink: string;
  changedField: string;
};
