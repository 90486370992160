import * as React from 'react';

import PaginationBtns from './PaginationBtns';

const Pagination = (props: {
  currentPage: number;
  totalPages: number;
  clickHandler: (current: number) => void;
  showAllHandler: () => void;
}) => {
  const { currentPage, totalPages, clickHandler, showAllHandler } = props;

  const handleClick = (e: React.MouseEvent<HTMLElement>): void => {
    const current =
      e.currentTarget.innerText === '‹'
        ? currentPage - 1
        : e.currentTarget.innerText === '›'
        ? currentPage + 1
        : parseInt(e.currentTarget.innerText);

    clickHandler(current);
  };

  return (
    <div>
      <div className='pagination-block align-items-center corduroyapprox-color mx-5 mb-2'>
        <div>
          {totalPages === 1 ? (
            `1 page`
          ) : (
            <>
              {currentPage} of {totalPages} pages
              <span className='pointer' onClick={showAllHandler}>
                &nbsp;- Show All
              </span>
            </>
          )}
        </div>
        <nav>
          <ul className='pagination justify-content-end align-items-center my-auto'>
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <a onClick={handleClick} className='page-link pointer'>
                ‹
              </a>
            </li>
            <PaginationBtns
              totalPages={totalPages}
              currentPage={currentPage}
              handleClick={handleClick}
            />
            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
              <a onClick={handleClick} className='page-link pointer'>
                ›
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Pagination;
