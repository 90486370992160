import { MultiValue } from 'react-select';

import { TagifySettings } from '@yaireo/tagify';

import { UserOption } from '@/containers/DealWindow/typings';

import { DateServer, ParcelData, ReportDataKeys } from '../typings';

export { default as camelize } from './camelize';
export { fetchResult, ajaxResult } from './request';

export const isOptionArray = <T>(test: T | MultiValue<T>): test is T[] => {
  return Array.isArray(test);
};

export const isNonEmptyArray = (obj: any): obj is Array<any> =>
  obj && Array.isArray(obj) && obj.length > 0;

export const isString = (str: any): str is string => {
  return typeof str === 'string';
};

export const isEmailValid = (email: string | null | undefined): boolean => {
  if (!email) return false;
  const res =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return res.test(String(email).toLowerCase());
};

export const isNullOrEmptyString = (str: string | null | undefined): boolean =>
  str ? str.trim().length === 0 : true;

export const camelToSnakeCase = (str: string): string =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const snakeObjKeys = (obj: { [key: string]: any } | Array<Object>): any => {
  if (Array.isArray(obj)) return obj.map((o: any) => snakeObjKeys(o));
  return Object.keys(obj).reduce((snakedObj, key) => {
    if (Array.isArray(obj[key]))
      obj[key] = obj[key].map((o: any) => (typeof o === 'number' ? o : snakeObjKeys(o)));
    if (typeof obj[key] === 'object' && obj[key]) obj[key] = snakeObjKeys(obj[key]);
    snakedObj[camelToSnakeCase(key)] = obj[key];
    return snakedObj;
  }, {} as { [key: string]: any });
};

export const parseCoordinates = (coordinates: { latitude: string; longitude: string }) => ({
  lat: parseFloat(coordinates.latitude),
  lng: parseFloat(coordinates.longitude),
});

export const waitFor = (
  conditionFunction: () => boolean,
  maxAttempts: number
): Promise<boolean> => {
  let attempts = 0;
  const poll = (resolve: (result: boolean) => void) => {
    ++attempts;
    if (conditionFunction()) resolve(true);
    else if (attempts >= maxAttempts) resolve(false);
    else setTimeout((_) => poll(resolve), 200);
  };

  return new Promise(poll);
};

export const tagifySettings = (users: UserOption[]): TagifySettings => {
  const whitelist = users.map((user) => ({ value: user.nickname, title: user.id }));
  return {
    pattern: '@',
    mode: 'mix',
    whitelist,
    enforceWhitelist: true,
    dropdown: { enabled: 0, highlightFirst: true, fuzzySearch: true },
    callbacks: {
      // NOTE: This hack is used to show the dropdown after a user has made a typo in a tag.
      // It breaks the internal flow of Tagify and can cause glitches with autocomplete after a typo is made,
      // but it works...
      keydown: (e) => {
        const tagify = e.detail.tagify;
        const currentTag = (tagify as any).state.tag;
        setTimeout(() => {
          // If the tag value is present and doesn't contain any whitespace characters...
          if (currentTag && currentTag.value && !/\s/.test(currentTag.value)) {
            // Use values from the Tagify state as filter values while showing the dropdown.
            // Note that this approach relies on Tagify's internal behavior and may not be reliable.
            tagify.dropdown.show(currentTag.prefix + currentTag.value);
            const matchingItem = whitelist.find(
              (user) => user.value === currentTag.prefix + currentTag.value
            );
            if (matchingItem) {
              tagify.addMixTags([matchingItem]);
            }
          }
        }, 50);
      },
      add: (e) => {
        const tagify = e.detail.tagify;
        setTimeout(() => {
          tagify.dropdown.hide();
        }, 70);
      },
    },
  };
};

export const nextDay = (date?: Date) => {
  date = date ?? new Date();
  date.setDate(new Date().getDate() + 1);
  return date;
};

export const formatDateServer = (date: Date): DateServer => {
  const day = leadingZero(date.getDate());
  const month = leadingZero(date.getMonth() + 1);
  const year = date.getFullYear();
  const hours = leadingZero(date.getHours());
  const minutes = leadingZero(date.getMinutes());
  return `${day}-${month}-${year} ${hours}:${minutes}`;
};

const leadingZero = (num: number) => (num < 10 ? '0' : '') + num;

export const parseIntNoNaN = (cookie: string | undefined | null): number | null => {
  const parsed = parseInt(cookie ?? '');
  if (isNaN(parsed)) return null;
  else return parsed;
};

export const parseFloatNoNaN = (cookie: string | undefined | null): number | null => {
  const parsed = parseFloat(cookie ?? '');
  if (isNaN(parsed)) return null;
  else return parsed;
};

export const parcelDataToReportAllData = (parcelData: ParcelData) => {
  return new Map(
    ReportDataKeys.map(([key, value]) => {
      return [key, parcelData[value] ?? '-'];
    })
  );
};

export const expanderStyle = (expanded: boolean): React.CSSProperties =>
  expanded
    ? {
        transition: 'all 0.6s, max-height 0.4s',
        visibility: 'visible',
        maxHeight: '100vh',
        opacity: 1,
      }
    : {
        transition: 'all 0.3s, max-height 0.5s',
        maxHeight: '0',
        opacity: 0,
        visibility: 'hidden',
      };

export const highlightSearchString = (input: string, searchString: string): string => {
  const substring = input.match(new RegExp(searchString, 'ig'));
  if (searchString.length && substring)
    return input.replace(new RegExp(searchString, 'ig'), `<b>${substring[0]}</b>`);
  else return input;
};

export const arrayMove = <T>(list: readonly T[], startIndex: number, endIndex: number): T[] => {
  const newList = [...list];
  const [item] = newList.splice(startIndex, 1);
  newList.splice(endIndex, 0, item);
  return newList;
};

export const capitalize = (s: string) => s[0].toUpperCase() + s.slice(1);

export const getFormData = <T extends Record<string, Blob | string | null | undefined>>(
  obj: T,
  model?: string
) =>
  Object.keys(obj).reduce((formData, key) => {
    const value = obj[key];
    if (value !== null) formData.append(model ? `${model}[${key}]` : key, value ?? '');
    return formData;
  }, new FormData());

export const onClassMutation = (
  elements: Element[] | Element,
  callback: (target: HTMLElement) => void
) => {
  const observer = new MutationObserver((mutations) => {
    const mutation = mutations.find((mutation) => mutation.attributeName === 'class');
    if (!mutation) return;
    const { target } = mutation;
    callback(target as HTMLElement);
  });

  if (Array.isArray(elements)) {
    elements.forEach((element) => {
      observer.observe(element, {
        attributes: true,
      });
    });
  } else {
    observer.observe(elements, {
      attributes: true,
    });
  }
};

export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  wait: number
): ((...args: Parameters<F>) => ReturnType<F> | void) => {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;
  let firstExecution = true;

  return (...args: Parameters<F>) => {
    if (firstExecution) {
      firstExecution = false;
      return func(...args);
    } else {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        const res = func(...args);
        timeoutId = null;
        return res;
      }, wait);
    }
  };
};
