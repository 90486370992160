import { FilterOption } from 'typings';

import { DateClient, Option, Pin, SetState, SortObject, SortState } from '@/typings';

import { UserOption } from '../DealWindow/typings';

// Sta
export type StaState = {
  sta: StaDeal[] | null;
  pages: {
    current: number;
    total: number | null;
    perPage: number;
  };
  sort: SortObject;
};

export type StaDeal = StaRelatedDeal & {
  relatedDeals: StaRelatedDeal[];
  isStarred: boolean;
};

export type StaRelatedDeal = {
  id: number;
  nickname: string;
  formattedAddress: string;
  city: string;
  state: string;
  postalCode: string;
  opportunitiesNames: string;
  tagsNames: string;
  workingUsers: Option[];
  updatedAtFormatted: DateClient;
  pin: Pin;
  brokerName: string;
  followers: Option[];
  assemblage: boolean;
};

// Tabs
export enum TabName {
  WorkedSta = 'workedSta',
  Starred = 'starred',
  NonSta = 'nonSta',
  AllDeals = 'allDeals',
  ActiveSta = 'activeSta',
}

// Table
export type Column = {
  sortState: SortState;
  cssClass: string;
  identifier: string;
};

export const ColumnName = {
  id: 'Pin type',
  nickname: 'Property Nickname',
  opportunities: 'Tenant',
  tags: 'Tags',
  city: 'City',
  state: 'State',
  formatted_address: 'Address',
  postal_code: 'Zip',
  broker: 'Broker',
  updated_at: 'Last Updated',
  related_updated_at: 'Related Last Updated',
  followers: 'Followers',
  working_users: 'Working Deal',
  assemblage: 'Assemblage',
};

export type PagesState = [PagesCondition, SetState<PagesCondition>];

type PagesCondition = {
  currentStaAllPage: number;
  totalStaAllPages: number;
  currentStaStarredPage: number;
  totalStaStarredPages: number;
  currentNonStaPage: number;
  totalNonStaPages: number;
};

export type TableSettings = [string, boolean][];

//Filters
export type AvailableFilters = {
  tags: Option[];
  opportunities: Option[];
  pins: Option[];
  cities: string[];
  states: string[];
  zips: string[];
  users: UserOption[];
  brokers: UserOption[];
  transactionTypes: FilterOption[];
};

export type FiltersOptions = {
  opportunities: FilterOption[];
  tags: FilterOption[];
  states: FilterOption[];
  cities: FilterOption[];
  users: FilterOption[];
  followers: FilterOption[];
  zips: FilterOption[];
  pins: FilterOption[];
  brokers: FilterOption[];
  transactionTypes: FilterOption[];
};

export const defaultFilters = Object.freeze({
  opportunities: [],
  tags: [],
  states: [],
  cities: [],
  users: [],
  zips: [],
  pins: [],
  brokers: [],
  transactionTypes: [],
  followers: [],
});

export const defaultFiltersState = {
  availableFilters: defaultFilters,
  selectedFilters: { ...defaultFilters, followers: [] },
  savedFilters: { ...defaultFilters, followers: [] },
};

type SetFilterOptions = (arg: FilterOption[]) => void;

export type SetFiltersOptions = {
  opportunities: SetFilterOptions;
  tags: SetFilterOptions;
  states: SetFilterOptions;
  cities: SetFilterOptions;
  users: SetFilterOptions;
  followers: SetFilterOptions;
  zips: SetFilterOptions;
  pins: SetFilterOptions;
  brokers: SetFilterOptions;
  transactionTypes: SetFilterOptions;
};

export type SelectFilters = {
  selectedOption: Array<FilterOption>;
  setSelectedOption: SetFilterOptions;
  options: Array<FilterOption>;
  label: string;
};
