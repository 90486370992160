import * as React from 'react';
import { useRef, useState } from 'react';

import CommunicationComponent from '@/components/DealWindow/CommunicationTab/Communication';
import CommunicationModal from '@/components/DealWindow/CommunicationTab/CommunicationModal';
import ErrorComponent from '@/components/ErrorComponent';
import SearchForm from '@/components/SearchForm';
import TextWithIconBtn from '@/components/TextWithIconBtn';
import { useEffectInfiniteScroll, useEffectNotFirstRender, useErrorState } from '@/hooks';
import { CommunicationService } from '@/services/communication_service';
import { isNonEmptyArray } from '@/utils';

import { BaseCommunication, Communication, UpdateCommunication } from './typings';

const DefaultCommunication = {
  title: '',
  kind: null,
  description: null,
} as const;

const CommunicationTab = (props: { show: boolean; dealId: number }) => {
  const { show, dealId } = props;
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number | null>(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [communications, setCommunications] = useState<Communication[]>([]);
  const [errors, addError, removeError] = useErrorState();

  const initialized = useRef(false);
  useEffectNotFirstRender(() => {
    if (show && !initialized.current) {
      getCommunications(1);
      initialized.current = true;
    }
  }, [show]);

  const stopCondition = currentPage === totalPages || !show;
  useEffectInfiniteScroll(
    '.explore-overflow-deal-block',
    stopCondition,
    () => getCommunications(currentPage + 1),
    [searchQuery, stopCondition]
  );

  useEffectNotFirstRender(() => {
    getCommunications(1);
  }, [searchQuery]);

  const getCommunications = async (currentPage: number) => {
    const res = await CommunicationService.index(dealId, searchQuery, currentPage);
    if (res.ok) {
      setTotalPages(res.data.total_pages);
      setCommunications(
        currentPage === 1
          ? res.data.communications
          : [...communications, ...res.data.communications]
      );
      setCurrentPage(currentPage);
    } else addError('Error in getCommunications:', res);
  };

  const onDelete = async (id: number) => {
    const confirmation = await window.customConfirm(
      'Delete Communication',
      'Are you sure that you want to delete this Communication?'
    );
    if (!confirmation) return;

    const res = await CommunicationService.destroy(id);
    if (res.ok) {
      setCommunications(communications.filter((com) => com.id !== id));
    } else addError('Error in CommunicationTab onDelete:', res);
  };

  const onUpdate = async (communication: UpdateCommunication) => {
    const res = await CommunicationService.update(communication);
    if (res.ok) {
      setCommunications(
        communications.map((com) =>
          com.id === communication.id ? { ...com, ...communication } : com
        )
      );
    } else addError('Error in CommunicationTab onUpdate:', res);
  };

  const onCreate = async (com: BaseCommunication) => {
    const res = await CommunicationService.create(dealId, com);
    if (res.ok) {
      setCommunications([res.data, ...communications]);
    } else addError('Error in CommunicationTab onCreate:', res);
  };

  if (!show) return null;
  return (
    <div>
      <CommunicationModal
        title='Add Communication'
        show={showAddModal}
        communication={DefaultCommunication}
        handleClose={() => setShowAddModal(false)}
        handleSubmit={(com) => {
          setShowAddModal(false);
          onCreate(com);
        }}
      />
      <div className='d-flex align-items-center justify-content-between'>
        <SearchForm
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder='Search communications...'
          className='page-search-input deal-notes-search-input'
          wrapperClassName='ml-20'
        />
        <TextWithIconBtn
          icon='plus-circle'
          text='Add New'
          onClick={() => setShowAddModal(true)}
          className='add-new-btn add-new-modal-btn'
        />
      </div>
      <ErrorComponent errors={errors} removeError={removeError} />
      {isNonEmptyArray(communications) ? (
        <div className='deal-window-notes'>
          {communications.map((com) => (
            <CommunicationComponent
              key={com.id}
              communication={com}
              handleDelete={onDelete}
              handleUpdate={onUpdate}
            />
          ))}
        </div>
      ) : (
        <div className='d-flex justify-content-center'>
          <p className='fs-14 mt-2 silver-color fst-italic'>No Communications Retrieved</p>
        </div>
      )}
    </div>
  );
};

export default CommunicationTab;
