import React, { useState } from 'react';

import CellIcon from '@/components/CellIcon';
import { Opportunity } from '@/services/opportunity_service';
import { Option } from '@/typings';

import OpportunityModal from './OpportunityModal';

const OpportunityRow = (props: {
  opportunity: Opportunity;
  onUpdate: (opportunity: Option, logo?: File) => void;
  onArchive: (id: number) => void;
  onRestore: (id: number) => void;
}) => {
  const { opportunity, onUpdate, onArchive, onRestore } = props;
  const [showBtns, setShowBtns] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  return (
    <>
      <OpportunityModal
        title='Edit Opportunity'
        show={showEditModal}
        setShow={setShowEditModal}
        onSave={(name, logo) => onUpdate({ name, id: opportunity.id }, logo)}
        initOpportunity={opportunity}
      />
      <tr
        className={`fs-14 fuscous-gray-color ${opportunity.active ? '' : 'inactive'}`}
        onMouseEnter={() => setShowBtns(true)}
        onMouseLeave={() => setShowBtns(false)}
      >
        <td>{opportunity.name}</td>
        <td>
          <img src={opportunity.logo} className='max-w-30' />
        </td>

        <td className='w-55p d-flex justify-content-between'>
          <CellIcon
            className='pointer'
            icon='pencil'
            show={showBtns}
            onClick={() => setShowEditModal(true)}
          />
          {opportunity.active ? (
            <CellIcon
              className='pointer'
              onClick={() => onArchive(opportunity.id)}
              icon='archive'
              show={showBtns}
            />
          ) : (
            <CellIcon
              className='pointer'
              onClick={() => onRestore(opportunity.id)}
              icon='undo-alt'
              show={showBtns}
            />
          )}
        </td>
      </tr>
    </>
  );
};

export default OpportunityRow;
