import { RequestMethod, Result } from '@/typings';
import { ajaxResult } from '@/utils';

export const TaskCommentService = {
  create: (text: string, taskId: number): Promise<Result> => {
    const url = '/ajax-create-task-comment';
    const data = { task_comment: { task_id: taskId, text } };
    const req = { url, dataType: 'json', method: RequestMethod.Post, data };
    return ajaxResult(req);
  },

  update: (id: number, text: string): Promise<Result> => {
    const url = '/ajax-update-task-comment';
    const data = { task_comment: { id, text } };
    const req = { url, dataType: 'json', method: RequestMethod.Patch, data };
    return ajaxResult(req);
  },

  destroy: (id: number): Promise<Result> => {
    const url = '/ajax-delete-task-comment';
    const data = { task_comment: { id } };
    const req = { url, dataType: 'json', method: RequestMethod.Delete, data };
    return ajaxResult(req);
  },
};
