import * as React from 'react';

import MultipleSelect from 'components/MultipleSelect';
import { FilterOption } from 'typings';

const Filter = (props: {
  label: string;
  options: FilterOption[];
  selectedOptions: FilterOption[];
  setSelectedOptions: (options: FilterOption[]) => void;
}) => {
  const { label, options, selectedOptions, setSelectedOptions } = props;
  return (
    <li className='position-relative d-flex align-items-center justify-content-between w-350p mt-10'>
      <label className='ml-10'>{label}</label>

      <MultipleSelect
        selectedOptions={selectedOptions}
        options={options}
        setSelectedOptions={setSelectedOptions}
      />
    </li>
  );
};
export default Filter;
