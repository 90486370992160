import { UserOption } from '@/containers/DealWindow/typings';
import { DataType, RequestMethod, Result } from '@/typings';
import { fetchResult } from '@/utils';

export const CompanyService = {
  usersList: (): Promise<Result<UserOption[]>> => {
    const url = '/ajax-company-users-list';
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
};
