import * as React from 'react';

import HtmlParser from 'html-react-parser';

import { highlightSearchString } from '@/utils';

export const Cell = (props: {
  children: React.ReactNode;
  searchString: string;
  className?: string;
  onClick?: () => void;
  link?: string;
  avatar?: string;
  label?: string;
  isMail?: boolean;
  active?: boolean;
}) => {
  const { className, searchString, onClick, link, avatar, isMail, label, active } = props;

  if (active === false) {
    return null;
  }

  const children =
    typeof props.children === 'string'
      ? HtmlParser(highlightSearchString(props.children, searchString))
      : props.children;

  if (avatar) {
    return (
      <td className={className ?? ''}>
        <div className='d-flex align-items-center'>
          {avatar.length > 2 ? (
            <img className='bor-rad-circle me-2' width='30' height='30' src={avatar}></img>
          ) : (
            <div className='init-icon-30 bor-rad-circle d-flex align-items-center text-center me-2'>
              <span className='w-100'>{avatar}</span>
            </div>
          )}
          {children}
        </div>
      </td>
    );
  }

  if (link) {
    return (
      <td className={className ?? ''}>
        <a className='light-blue-color' href={link}>
          {children}
        </a>
      </td>
    );
  }

  if (isMail) {
    return (
      <td className={className ?? ''}>
        <a href={`mailto:${children}`} className='light-blue-color'>
          {children}
        </a>
      </td>
    );
  }

  return (
    <td
      className={className ?? '' + `${onClick ? 'pointer' : ''}`}
      onClick={() => {
        if (onClick) onClick();
      }}
      title={label ?? ''}
      aria-label={label ?? ''}
    >
      {children}
    </td>
  );
};
