import { Avatar } from '@/typings';

export const CommunicationKinds = ['Phone', 'Email', 'Text', 'Mail'] as const;

export type BaseCommunication = {
  title: string;
  kind: 'Phone' | 'Email' | 'Text' | 'Mail' | null;
  description: string | null;
};
export type UpdateCommunication = BaseCommunication & {
  id: number;
};
export type NewCommunication = BaseCommunication & {
  id: null;
};
export type Communication = BaseCommunication & {
  id: number;
  user: { id: number; name: string; avatar: Avatar };
  date: string;
  time: string;
};
