import { DealService } from '@/services/deal_service';

import { getAddressComponent } from '../map';
import { Marker } from '../map/markers/marker_manager';
import { getPlaceId, getPlaceInfo } from './open_new';

export const updateDealPosition = async (marker: Marker) => {
  const dealId = marker.dealId;
  const position = marker.getPosition();
  if (!position) return;

  const placeId = await getPlaceId(position, null);
  if (!placeId) {
    console.error('Failed to fetch place ID for the marker.');
    return;
  }

  const place = await getPlaceInfo(placeId);
  if (!place) {
    console.error('Failed to fetch place information for the marker.');
    return;
  }

  const latitude = position.lat;
  const longitude = position.lng;
  const streetNumber = getAddressComponent(place, 'street_number');
  const city = getAddressComponent(place, 'locality');
  const area = getAddressComponent(place, 'administrative_area_level_1');
  const postalCode = getAddressComponent(place, 'postal_code');
  const route = getAddressComponent(place, 'route');
  const address = streetNumber && route ? `${streetNumber} ${route}` : undefined;

  DealService.updateParam(dealId, 'location', {
    latitude,
    longitude,
    address,
    city,
    state: area,
    saved: true,
    postal_code: postalCode,
    name: place?.name,
    phone: place?.formatted_phone_number,
    place_id: place?.place_id,
    formatted_address: place?.formatted_address,
  })
    .then((res) => {
      if (res.ok) {
        console.log('Deal location updated successfully');
      } else {
        console.error('Error updating deal location:', res.error);
      }
    })
    .catch((error) => {
      console.error('An error occurred while updating deal location:', error);
    });
};
