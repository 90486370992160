import * as React from 'react';
import { useContext, useState } from 'react';

import { Dropdown } from 'react-bootstrap';

import DropdownBtn, { style } from '@/components/DropdownBtn';

import { UsersContext } from '../context/UsersContext';
import ToggleConfidentialModal from './ToggleConfidentialModal';

const BulkActions = () => {
  const { currentUser } = useContext(UsersContext);
  const [showConfidentialModal, setShowConfidentialModal] = useState(false);
  return (
    <>
      {currentUser?.canViewConfidential && (
        <DropdownBtn
          title='Bulk Actions'
          variant='light'
          btnClassName={style.btn}
          dropdownClassName='me-3'
          text='Bulk Actions'
          chevron='chevron-down'
          autoClose='outside'
        >
          {currentUser?.canViewConfidential && (
            <>
              <Dropdown.Item onClick={() => setShowConfidentialModal(!showConfidentialModal)}>
                Set Confidential
              </Dropdown.Item>
              <ToggleConfidentialModal
                show={showConfidentialModal}
                handleClose={() => setShowConfidentialModal(false)}
              />
            </>
          )}
        </DropdownBtn>
      )}
    </>
  );
};

export default BulkActions;
