import * as React from 'react';
import { useState } from 'react';

import { hideContextMenu } from '@/scripts/context_menu';

import AddMapImageModal from '../MapImage/AddMapImageModal';
import EditImageWindow from '../MapImage/EditImageWindow';
import ReplaceMapImageModal from '../MapImage/ReplaceMapImageModal';
import s from './style.module.scss';

const ContextMenu = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  return (
    <>
      <div className={'context-menu ' + s.contextMenu}>
        <ul>
          <li
            onClick={() => {
              setShowAddModal(true);
              hideContextMenu();
            }}
          >
            Add image
          </li>
          <li className='measure-tool'>Measure Distance</li>
        </ul>
      </div>
      <AddMapImageModal show={showAddModal} setShow={setShowAddModal} />
      <EditImageWindow />
      <ReplaceMapImageModal />
    </>
  );
};

export default ContextMenu;
