import flatpickr from 'flatpickr';

window.initFlatpickr = () => {
  flatpickr($('#flatpickr-with-time'), {
    enableTime: true,
    // NOTE: changing d-m to m-d format here breaks due_date param in controller
    dateFormat: 'd-m-Y H:i',
  });
  flatpickr($('#expiration-date'), {
    enableTime: true,
    minDate: 'today',
    dateFormat: 'd-m-Y H:i',
  });
};

$(document).on('click', '#calendar-icon', function () {
  $('#expiration-date').trigger('click');
});
$(document).on('click', '#flatpickr-with-time-icon', function () {
  $('#flatpickr-with-time').trigger('click');
});
$(document).on('click', '#expiration-date-icon', function () {
  $('#expiration-date').trigger('click');
});

declare global {
  interface Window {
    initFlatpickr: () => void;
  }
}
