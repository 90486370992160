import * as React from 'react';

import { Dropdown } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Option } from '@/typings';

const CustomView = (props: {
  profile: Option & { active: boolean };
  handleEdit: (id: number) => void;
  handleSelect: (id: number) => void;
  handleActivate: (id: number) => void;
}) => {
  const { profile, handleEdit, handleSelect, handleActivate } = props;
  return (
    <Dropdown.Item title={profile.name} className='pe-2'>
      <div
        className='d-flex justify-content-between align-items-center'
        onClick={() => handleSelect(profile.id)}
      >
        <div className='fit-content'>{profile.name}</div>
        <div className='ms-2'>
          <FontAwesomeIcon
            icon={[profile.active ? 'fas' : 'far', 'star']}
            className='light-blue-color me-1'
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleActivate(profile.id);
            }}
          />
          <FontAwesomeIcon
            icon='pencil'
            className='light-blue-color'
            title='Edit'
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleEdit(profile.id);
            }}
          />
        </div>
      </div>
    </Dropdown.Item>
  );
};

export default CustomView;
