import * as React from 'react';
import { useContext, useEffect } from 'react';

import { ActiveTabContext } from '@Deals/context/ActiveTabContext';
import { TabName } from '@Deals/typings';

import { StasContext } from '../context/StasContext';
import Pagination from './PaginationDeals';
import TableBody from './TableBody';
import TableHead from './TableHead';

const Table = (props: { brokerId?: number; tabName?: TabName }) => {
  const { setBrokerId } = useContext(StasContext);
  const { activeTab, setActiveTab } = useContext(ActiveTabContext);

  useEffect(() => {
    if (props.brokerId) setBrokerId(props.brokerId);
    if (props.tabName && props.tabName !== activeTab) setActiveTab(props.tabName);
  }, []);

  return (
    <div>
      <table className='table deals-table align-middle box-shadow'>
        <TableHead />
        <TableBody />
      </table>
      <Pagination />
    </div>
  );
};

export default Table;
