import React, { useRef, useState } from 'react';

import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

import CellIcon from '@/components/CellIcon';
import { Cell as CellComponent } from '@/components/Table/Cell';
import { useComponentWithProps } from '@/hooks/use_component_with_props';
import { Nullable, Option, SetState } from '@/typings';

import ModalBroker from './ModalBroker';
import { Broker } from './typings';

const TableRow = (props: {
  broker: Broker;
  searchString: string;
  deleteBroker: (id: number) => void;
  updateBroker: (broker: Nullable<Broker>) => Promise<void>;
  setBroker: SetState<Broker | null>;
  workingCompanies: Option[];
}) => {
  const { broker, searchString, deleteBroker, updateBroker, setBroker, workingCompanies } = props;

  const [showNote, setShowNote] = useState(false);
  const [showBtns, setShowBtns] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const Cell = useComponentWithProps(CellComponent, {
    searchString,
    label: 'Open Broker Card',
    onClick: () => setBroker(broker),
  });

  const note = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <ModalBroker
        saveBroker={updateBroker}
        title='Edit Broker'
        initBroker={broker}
        show={showEditModal}
        isOwner={false}
        setShow={setShowEditModal}
        workingCompanies={workingCompanies}
      />
      <tr
        className='fs-14 fuscous-gray-color'
        onMouseEnter={() => setShowBtns(true)}
        onMouseLeave={() => setShowBtns(false)}
      >
        <Cell>{broker.name}</Cell>
        <Cell>{broker.phone}</Cell>
        <Cell isMail>{broker.email}</Cell>
        <Cell className='max-column'>{broker.address}</Cell>
        <Cell>{broker.market}</Cell>
        <Cell className='max-column'>{broker.workingCompany?.name}</Cell>
        <td>
          <div className='d-flex'>
            <Overlay target={note.current} show={showNote && !!broker.note} placement='left'>
              <Tooltip className='tooltip-white'>{broker.note}</Tooltip>
            </Overlay>
            <CellIcon
              setRef={(div) => (note.current = div)}
              show={!!broker.note}
              onMouseEnter={() => setShowNote(true)}
              onMouseLeave={() => setShowNote(false)}
              icon='note'
              className='pointer ms-2'
            />
            <CellIcon
              show={showBtns}
              icon='pencil-alt'
              className='pointer ms-2'
              onClick={() => setShowEditModal(true)}
            />
            <CellIcon
              show={showBtns}
              icon='trash-alt'
              className='pointer ms-2'
              onClick={() => deleteBroker(broker.id)}
            />
          </div>
        </td>
      </tr>
    </>
  );
};

export default TableRow;
