export type SetState<Type> = React.Dispatch<React.SetStateAction<Type>>;

export type Result<T = any> = Success<T> | Error;

export type Success<T> = { ok: true; data: T };

export type Error = {
  ok?: never;
  status: number | null;
  statusText: string | null;
  error: any;
  data?: any;
};

export type ErrorTuple = [number, Error]; // [errorId, error]

export enum SortState {
  Default,
  Down,
  Up,
}

export enum SortMethod {
  Asc = 'asc',
  Desc = 'desc',
}

export type SortObject = {
  entity: string;
  method: SortMethod;
};

export type Option = { id: number; name: string };

export type Coordinates = {
  lat: number;
  lng: number;
};

export type City = Option & { state: string };

export type TabTitle = 'Details' | 'Parcel Data' | 'Communication' | 'Tasks' | 'Notes' | 'History';

export type BootstrapVariant =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'success'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark'
  | 'link';

export enum PinType {
  Star = 'star',
  Marker = 'marker',
  Pin = 'pin',
}

export type Pin = {
  id: number;
  name: string;
  icon: PinType;
  color: string;
};

export type TabType<T> = {
  id: string;
  name: T;
  text: string;
  className?: string;
};

export type Avatar = { url: string } | { initialLetters: string };

export type KeysToCamel<T> = {
  [K in keyof T as CamelCase<string & K>]: T[K] extends Array<infer U>
    ? U extends {}
      ? Array<KeysToCamel<U>>
      : T[K]
    : T[K] extends {}
    ? KeysToCamel<T[K]>
    : T[K];
};

export type KeysToSnake<T> = {
  [K in keyof T as SnakeCase<string & K>]: T[K] extends Array<infer U>
    ? U extends {}
      ? Array<KeysToSnake<U>>
      : T[K]
    : T[K] extends {}
    ? KeysToSnake<T[K]>
    : T[K];
};

type SnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? '_' : ''}${Lowercase<T>}${SnakeCase<U>}`
  : S;

type CamelCase<S extends string> = S extends `${infer P1}_${infer P2}${infer P3}`
  ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
  : S;

export type DateClient = `${number}/${number}/${number} ${number}:${number} ${'am' | 'pm'}`;
// date format which server expects
export type DateServer = `${string}-${string}-${string} ${string}:${string}`; // d-m-Y H:i

export type FetchRequest = {
  url: string;
  dataType?: DataType;
  method?: RequestMethod;
  body?: BodyInit | null;
  headers?: Headers;
  cache?: RequestCache;
  credentials?: RequestCredentials;
  integrity?: string;
  keepalive?: boolean;
  mode?: RequestMode;
  redirect?: RequestRedirect;
  referrer?: string;
  referrerPolicy?: ReferrerPolicy;
  signal?: AbortSignal | null;
};

export type AjaxRequest = {
  url: string;
  dataType?: string;
  method: RequestMethod;
  data?: object | string;
  timeout?: number;
  error?: any;
  success?: any;
};

export enum RequestMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE',
}
export enum DataType {
  JSON = 'json',
  HTML = 'html',
}

export const ReportDataKeys = [
  ['Owner', 'owner'],
  ['Owner Mail Name', 'owner_mail_name'],
  ['Owner Mailing Address', 'owner_mail_address'],
  ['Owner Mailing Address2', 'owner_mail_address_2'],
  ['Parcel ID', 'parcel_id'],
  ['Land Use Class & Code', 'land'],
  ['Acreage Deeded', 'acreage_deeded'],
  ['Acreage Calculated', 'acreage_calc'],
  ['County Name/ID', 'country'],
  ['Municipality Name/ID', 'muni'],
  ['Market Value Land', 'market_value_land'],
  ['Market Value Building', 'market_value_building'],
  ['Market Value Total', 'market_value_total'],
  ['Census Zip', 'census_zip'],
  ['Transaction Date', 'transaction_data'],
  ['Report All USA ID', 'report_all_usa_id'],
] as const;
type ReportDataKey = (typeof ReportDataKeys)[number][0];
type ParcelDataKey = (typeof ReportDataKeys)[number][1];
export type ReportAllData = Map<ReportDataKey, string>;
export type ParcelData = Record<ParcelDataKey, string | undefined>;

export type Nullable<T> = { [K in keyof T]: T[K] | null };

export type FilterOption = {
  id: string | number;
  name: string;
};

export type Id = { id: number };

export type HTMLDivElementProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;
