import React, { useEffect, useRef, useState } from 'react';

import { Dropdown } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NotificationService } from '@/services/notification_service';

import NotifictionComponent from './NotificationComponent';
import { Notification } from './typings';

const Notifications = (props: { notificationsUnreadCounter: number }) => {
  const [notificationsUnreadCounter, setNotificationsUnreadCounter] = useState(
    props.notificationsUnreadCounter
  );
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);

  const showModalRef = useRef(false);
  const dropdownElement = useRef<HTMLDivElement>(null);
  const bellElement = useRef<HTMLDivElement>(null);

  const getNotifications = async () => {
    const res = await NotificationService.index();
    if (res.ok) {
      setLoading(false);
      setNotifications(res.data ?? []);
    } else console.error('Error in getNotifications:', res);
  };

  const updateNotification = async (id: number, seen: boolean) => {
    const res = await NotificationService.update(id, { seen });
    if (res.ok) {
      setNotificationsUnreadCounter((i) => i - 1);
    } else console.error('Error in updateNotification:', res);
  };

  useEffect(() => {
    document.addEventListener('mousedown', (e) => {
      if (
        e.composedPath().includes(dropdownElement.current!) ||
        e.composedPath().includes(bellElement.current!) ||
        showModalRef.current
      ) {
        return;
      } else setShow(false);
    });
  }, []);

  return (
    <>
      <Dropdown
        title='Notifications'
        aria-label='Notifications'
        className='d-block'
        show={show}
        ref={dropdownElement}
      >
        <div
          onClick={() => {
            if (!show) getNotifications();
            setShow(!show);
          }}
          className='pointer'
          ref={bellElement}
        >
          <span
            className={
              'regular align-items-center d-flex ' + (!!notificationsUnreadCounter ? 'd-none' : '')
            }
          >
            <i className='far fa-bell fuscous-gray-color fs-20 '></i>
          </span>

          <span
            className={
              'solid align-items-center ' + (!!notificationsUnreadCounter ? 'd-flex' : 'd-none')
            }
          >
            <i className='fas fa-bell fuscous-gray-color fs-20'></i>
            <div className='navbar-notifications-unread-counter'>{notificationsUnreadCounter}</div>
          </span>
        </div>
        <Dropdown.Menu className='navbar-dropdown-notifications'>
          {loading ? (
            <div className='d-flex p-20 justify-content-center align-items-center'>
              Loading...
              <FontAwesomeIcon icon='spinner' pulse />
            </div>
          ) : !notifications || !notifications.length ? (
            <p className='p-20 pb-0'>There are no notifications</p>
          ) : (
            notifications.map((notification) => (
              <NotifictionComponent
                key={notification.id}
                notification={notification}
                showModalRef={showModalRef}
                setShowNotifications={setShow}
                setNotificationSeen={updateNotification}
              />
            ))
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default Notifications;
