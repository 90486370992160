import * as React from 'react';
import { useState } from 'react';

import Form from 'react-bootstrap/Form';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CellBtn from '@/components/CellBtn';
import CellIcon from '@/components/CellIcon';
import { Cell as CellComponent } from '@/components/Table/Cell';
import { useComponentWithProps } from '@/hooks/use_component_with_props';
import { SetState } from '@/typings';

import { UserOption } from '../DealWindow/typings';
import ModalTask from './ModalTask';
import { Deal, Task } from './typings';

const TableRow = (props: {
  task: Task;
  currentUserId: number;
  users: UserOption[];
  deals: Deal[] | undefined;
  searchString: string;
  deleteTask: (task: Task) => void;
  updateTask: (task: Task, setTask: SetState<Task>) => Promise<void>;
  updateTaskStatus: (task: Task) => void;
  followers: string | undefined;
  followersLength: number;
}) => {
  const {
    task,
    searchString,
    users,
    currentUserId,
    deals,
    deleteTask,
    updateTask,
    updateTaskStatus,
    followers,
    followersLength,
  } = props;
  const [showBtns, setShowBtns] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const Cell = useComponentWithProps(CellComponent, { searchString });

  return (
    <>
      <ModalTask
        saveTask={updateTask}
        title='Edit Task'
        initTask={task}
        show={showEditModal}
        users={users}
        deals={deals}
        currentUserId={currentUserId}
        setShow={setShowEditModal}
      />
      <tr
        className='task-row fs-14 fuscous-gray-color'
        onMouseEnter={() => setShowBtns(true)}
        onMouseLeave={() => setShowBtns(false)}
      >
        <td>
          <Form.Check
            aria-label='bulk action'
            onChange={() => {
              updateTaskStatus(task);
            }}
            checked={task.isCompleted ?? false}
          />
        </td>
        <Cell className='max-column'>{task.name}</Cell>
        <Cell className='max-column'>{task.description}</Cell>
        <Cell link={task.pinAddressLink}>{task.pinAddress}</Cell>
        <Cell avatar={task.assignedToBody?.avatar}>{task.assignedToBody?.fullName}</Cell>
        <Cell>{task.dueDate}</Cell>
        <FollowersCell followers={followers} amount={followersLength} />
        <td>
          <div className='d-flex'>
            {deals && (
              <CellIcon
                show={showBtns}
                icon='pencil-alt'
                className='pointer ms-2'
                onClick={() => setShowEditModal(true)}
              />
            )}
            <CellIcon
              show={showBtns}
              icon='trash-alt'
              className='pointer ms-2'
              onClick={() => deleteTask(task)}
            />
          </div>
        </td>
      </tr>
    </>
  );
};

export default TableRow;

const FollowersCell = (props: { followers: string | undefined; amount: number }) => {
  const { followers, amount } = props;
  const [showFollowers, setShowFollowers] = useState(false);
  const div = React.useRef(null);
  const tooltipElem = followers
    ? followers.split(',').map((follower) => (
        <React.Fragment key={follower}>
          {follower}
          <br />
        </React.Fragment>
      ))
    : '';
  return (
    <>
      <Overlay target={div.current} show={showFollowers} placement='left'>
        <Tooltip className='tooltip-white'>{tooltipElem}</Tooltip>
      </Overlay>
      <CellBtn setShow={setShowFollowers} show={!!followers}>
        <div ref={div} className='d-flex align-items-center justify-content-center'>
          <div className='me-1 no-link'>{amount}</div>
          <FontAwesomeIcon icon='people-group' className='light-blue-color' fixedWidth />
        </div>
      </CellBtn>
    </>
  );
};
