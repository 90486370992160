import { EffectCallback, useCallback, useEffect, useRef, useState } from 'react';

import { DealService } from '@/services/deal_service';
import { Error, ErrorTuple, KeysToCamel, ParcelData, Result } from '@/typings';
import { camelize } from '@/utils';

export const useEffectNotFirstRender = (fn: EffectCallback, deps: React.DependencyList) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) return fn();
    else didMount.current = true;
  }, deps);
};

export const useEffectInfiniteScroll = (
  selector: string,
  disableCondition: boolean,
  fn: () => void,
  deps: React.DependencyList
) => {
  useEffect(() => {
    if (disableCondition) $(selector).off('scroll');
    else
      $(selector).on('scroll', function () {
        if (this.scrollHeight - this.offsetHeight === this.scrollTop) fn();
      });
    return () => {
      $(selector).off('scroll');
    };
  }, deps);
};

export const useStickyWindowEffect = (block: HTMLDivElement | null) => {
  const stickyWindowPosition = useCallback(() => {
    if (!window.scrollY) return;
    if (!block) return;
    if (window.scrollY >= 70) {
      block.style.top = '10px';
      block.style.height = 'calc(100% - 15px)';
    } else {
      block.style.top = `${80 - window.scrollY}px`;
      block.style.height = `calc(100% - ${85 - window.scrollY}px)`;
    }
  }, [block]);
  useEffect(() => {
    stickyWindowPosition();
    document.addEventListener('scroll', stickyWindowPosition);
    return () => document.removeEventListener('scroll', stickyWindowPosition);
  }, [block]);
};

export const useErrorState = (): [
  ErrorTuple[],
  (s: string, e: Error) => void,
  (id: number) => void
] => {
  const [errors, setErrors] = useState<ErrorTuple[]>([]);

  const addError = (consoleMessage: string, newError: Error) => {
    const newErrors = errors.map(([_, error], i: number): ErrorTuple => [i + 1, error]);
    setErrors([...newErrors, [newErrors.length + 1, newError]]);
    console.error(consoleMessage, newError);
  };

  const removeError = (errorId: number) => {
    const newErrors = errors.filter(([id, _]) => id !== errorId);
    setErrors(newErrors);
  };

  return [errors, addError, removeError];
};

export const useParcelData = (
  dealId: number,
  addError: (message: string, e: Error) => void
): ParcelData | null => {
  const [parcelData, setParcelData] = useState<ParcelData | null>(null);
  useEffect(() => {
    if (!dealId) return;
    DealService.getParcelData(dealId).then((res) => {
      if (res.ok) setParcelData(res.data);
      else
        addError('Error in useParcelData: ', {
          status: res.status,
          statusText: `Cannot getParcelData: ` + res.statusText,
          error: res,
        });
    });
  }, [dealId]);
  return parcelData;
};

export const useFetchInitial = <T, B extends boolean>(
  addError: (message: string, e: Error) => void,
  request: () => Promise<Result<T>>,
  errorMessage: string,
  doCamelize?: B
): (B extends true ? KeysToCamel<T> : T) | null => {
  const [result, setResult] = useState<T | KeysToCamel<T> | null>(null);

  useEffect(() => {
    request().then((res) => {
      if (res.ok) setResult(doCamelize ? (camelize(res.data) as KeysToCamel<T>) : res.data);
      else addError(errorMessage, res);
    });
  }, []);

  return result as any;
};

export const useDelayedEffect = (fn: () => void, time: number, deps: React.DependencyList) => {
  useEffect(() => {
    const t = setTimeout(() => {
      fn();
    }, time);
    return () => clearTimeout(t);
  }, deps);
};
