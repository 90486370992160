import * as React from 'react';
import { useState } from 'react';

import SectionHeader from '@/components/DealWindow/Details/SectionHeader';
import { DealResource } from '@/containers/DealResources';
import { DealResourceService } from '@/services/deal_resource_service';
import { DealService } from '@/services/deal_service';
import { City, Option } from '@/typings';

import AddResourceModal from './AddResourceModal';
import ResourceRow from './ResourceRow';

const Resources = (props: {
  resources: DealResource[];
  setResources: (resources: DealResource[]) => void;
  sharedMapWindow: boolean;
  deal: { id: number; city: string; state: string };
  cities: City[];
  states: string[];
}) => {
  const { sharedMapWindow, resources, setResources, deal, states, cities } = props;
  const [isExpanded, setExpanded] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const removeResource = async (id: number) => {
    const confirmation = await window.customConfirm(
      'Delete Resource',
      'Are you sure that you want to delete this Resource?'
    );
    if (!confirmation) return;
    const res = await DealResourceService.removeDeal(id, deal.id);
    if (res.ok) setResources(resources.filter((resource) => resource.id !== id));
    else console.error('Error in removeResource: ', res.error);
  };

  const addResource = async (city: Option, state: string, addAll: boolean, id: number | null) => {
    const res = await DealService.addResource(deal.id, city, state, addAll, id);
    if (res.ok) {
      setResources(res.data.resources);
      setShowModal(false);
    } else console.error(res);
  };

  return (
    <div className='mt-20 ml-20 mr-20'>
      <AddResourceModal
        show={showModal}
        setShow={setShowModal}
        cities={cities}
        states={states}
        deal={deal}
        resources={resources}
        addResource={addResource}
      />
      <SectionHeader
        title='Resources'
        isBodyExpanded={isExpanded}
        onClick={() => setExpanded(!isExpanded)}
      >
        {!sharedMapWindow && (
          <p className='pointer light-blue-color m-0' onClick={() => setShowModal(true)}>
            + Add Resource
          </p>
        )}
      </SectionHeader>

      {isExpanded &&
        resources.map((resource) => (
          <ResourceRow
            key={resource.id}
            resource={resource}
            sharedMapWindow={sharedMapWindow}
            removeResource={removeResource}
          />
        ))}
    </div>
  );
};

export default Resources;
