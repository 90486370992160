import * as React from 'react';

const HeaderBlock = (props: {
  children?: React.ReactNode;
  h1: string;
  breadcrumb?: { text: string; onH1Click: React.MouseEventHandler };
}) => {
  const { children, h1, breadcrumb } = props;
  return (
    <>
      <div className='mt-3 mb-2 ms-3'>
        <div className='d-flex'>
          <h1
            className={`heading-one fuscous-gray-color montserrat-font my-0 ${
              breadcrumb ? ' pointer fw-bold' : ''
            } `}
            onClick={breadcrumb ? breadcrumb.onH1Click : () => {}}
          >
            {h1}&nbsp;
          </h1>
          {breadcrumb && (
            <h1 className='heading-one fuscous-gray-color montserrat-font'>
              &gt; {breadcrumb.text}
            </h1>
          )}
          {children}
        </div>
      </div>
    </>
  );
};

export const HeaderBlockEnd = ({ children }: { children: React.ReactNode }) => {
  return <div className='d-flex justify-content-end align-items-center w-100 mx-4'>{children}</div>;
};

export default HeaderBlock;
