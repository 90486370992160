import * as React from 'react';
import { useEffect, useState } from 'react';

import ErrorComponent from '@/components/ErrorComponent';
import { HeaderBlockEnd } from '@/components/HeaderBlock';
import SearchForm from '@/components/SearchForm';
import Table from '@/components/Settings/Table';
import TableBody from '@/components/Table/TableBody';
import Tabs from '@/components/Tabs';
import TextWithIconBtn from '@/components/TextWithIconBtn';
import { useErrorState } from '@/hooks';
import { TagService } from '@/services/tag_service';
import { AjaxRequest, RequestMethod } from '@/typings';
import { ajaxResult } from '@/utils';

import TagModal from './TagModal';
import TagRow from './TagRow';
import { SelectionType, TabName, Tag } from './typings';

const Tags = (props: { show: boolean; setHeader: (elem: JSX.Element) => void }): JSX.Element => {
  const { show, setHeader } = props;
  const [dealsTags, setDealsTags] = useState<Tag[]>([]);
  const [resourcesTags, setResourcesTags] = useState<Tag[]>([]);
  const [searchString, setSearchString] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [errors, addError, removeError] = useErrorState();
  const [activeTab, setActiveTab] = useState<TabName>(TabName.Pins);
  const activeNames = [...dealsTags, ...resourcesTags].map((tag) => tag.name);

  useEffect(() => {
    if (show)
      setHeader(
        <>
          <SearchForm
            onChange={(e) => setSearchString($(e.target)!.val()!.toString())}
            placeholder='Search Tags...'
            className='search-input'
            wrapperClassName='mx-4'
          />

          <HeaderBlockEnd>
            <TextWithIconBtn
              icon='plus-circle'
              text='Add Tag'
              onClick={() => setShowAddModal(true)}
              className='box-shadow add-new-btn h-100'
            />
          </HeaderBlockEnd>
        </>
      );
  }, [show]);

  useEffect(() => {
    getTags();
  }, [searchString, activeTab]);

  const getTags = async () => {
    const q = { tags_search_cont: searchString };
    const res = await TagService.index(q);
    if (res.ok) {
      setDealsTags(res.data.deals_tags);
      setResourcesTags(res.data.resources_tags);
    } else addError('Error in getTags:', res);
  };

  const saveTag = async (tag: { name: string; selectionType: SelectionType }) => {
    const res = await TagService.create(tag);
    if (res.ok) {
      const newTag = { ...tag, id: res.data.id, deletedAt: undefined };
      if (tag.selectionType === SelectionType.Pin) setDealsTags([newTag, ...dealsTags]);
      else setResourcesTags([newTag, ...resourcesTags]);
    } else addError(`Error in saveTags:`, res);
  };

  const updateTag = async (tag: { id: number; name: string; selectionType: SelectionType }) => {
    const res = await TagService.update(tag);
    if (res.ok) {
      const newTag = { ...tag, deletedAt: undefined };

      const replace = (tag: Tag) => (tag.id === newTag.id ? newTag : tag);
      if (tag.selectionType === SelectionType.Pin) setDealsTags(dealsTags.map(replace));
      else setResourcesTags(resourcesTags.map(replace));
    } else addError(`Error in updateTag:`, res);
  };

  const deleteTag = async (tag: Tag) => {
    if (
      !(await window.customConfirm(
        'Archive Tag',
        'Are you sure that you want to archive this tag?'
      ))
    )
      return;
    ajaxTags(`/tags/${tag.id}`, RequestMethod.Delete, 'deleteTag');
  };

  const restoreTag = async (tag: Tag) => {
    if (
      !(await window.customConfirm(
        'Restore Tag',
        'Are you sure that you want to restore this tag?'
      ))
    )
      return;
    ajaxTags(`/tags/${tag.id}/restore`, RequestMethod.Put, 'restoreTag');
  };

  // helpers
  const ajaxTags = async (url: string, method: RequestMethod, fnName: string) => {
    const req: AjaxRequest = { url, method, dataType: 'json' };
    const res = await ajaxResult(req);
    if (res.ok) getTags();
    else addError(`Error in ${fnName}:`, res);
  };

  const TagsBody = () => {
    const tags = activeTab === TabName.Pins ? dealsTags : resourcesTags;

    return (
      <TableBody isResultPresent={tags.length > 0} columnsLength={2}>
        {tags.map((tag) => (
          <TagRow
            key={tag.id}
            tag={tag}
            searchString={searchString}
            deleteTag={deleteTag}
            updateTag={updateTag}
            restoreTag={restoreTag}
            activeNames={activeNames}
          />
        ))}
      </TableBody>
    );
  };

  return (
    <React.StrictMode>
      <div>
        <ErrorComponent errors={errors} removeError={removeError} />

        <TagModal
          saveTag={saveTag}
          show={showAddModal}
          title='Add Tag'
          setShow={setShowAddModal}
          activeNames={activeNames}
        />
        <Table title='Tags'>
          <thead>
            <tr>
              <Tabs
                tabs={[
                  {
                    id: 'pins-tab',
                    name: TabName.Pins,
                    text: 'Pins',
                    className: 'ms-0',
                  },
                  {
                    id: 'resources-tab',
                    name: TabName.Resources,
                    text: 'Resources',
                  },
                ]}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </tr>
            <tr className='table-header-font'>
              <th className='ps-4 w-25'>Name</th>
              <th />
            </tr>
          </thead>
          <TagsBody />
        </Table>
      </div>
    </React.StrictMode>
  );
};

export default Tags;
