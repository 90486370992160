export const initCustomSelect = () => {
  const customSelect = document.querySelector('.custom-select-wrapper');

  if (customSelect !== null) {
    customSelect.addEventListener('click', function (this: HTMLElement) {
      this.querySelector('.custom-select')?.classList?.toggle('open');
    });

    document.querySelectorAll('.custom-option').forEach((option) => {
      option.addEventListener('click', function (this: HTMLElement) {
        if (!this.classList.contains('selected')) {
          this.parentNode?.querySelector('.selected')?.classList?.remove('selected');
          this.classList.add('selected');

          let mainInput = this.closest('.custom-select')?.querySelector(
            '.custom-select-trigger span'
          );
          let frag = this.children[0].cloneNode(true);

          mainInput?.replaceChildren(frag);

          if (this.dataset.value) {
            mainInput?.setAttribute('data-value', this.dataset.value);
            (
              this.closest('.custom-select')?.querySelector(
                '.custom-select-trigger .custom-select-input'
              ) as HTMLInputElement
            ).value = this.dataset.value;
          }
        }
      });
    });
  }
};
