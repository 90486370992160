import React, { useEffect, useState } from 'react';

import ErrorComponent from '@/components/ErrorComponent';
import { HeaderBlockEnd } from '@/components/HeaderBlock';
import SearchForm from '@/components/SearchForm';
import Table from '@/components/Settings/Table';
import TableBody from '@/components/Table/TableBody';
import TextWithIconBtn from '@/components/TextWithIconBtn';
import { useErrorState } from '@/hooks';
import { Opportunity, OpportunityService } from '@/services/opportunity_service';
import { Option } from '@/typings';

import OpportunityModal from './OpportunityModal';
import OpportunityRow from './OpportunityRow';

const Opportunities = (props: { show: boolean; setHeader: (elem: JSX.Element) => void }) => {
  const { show, setHeader } = props;
  const [errors, addError, removeError] = useErrorState();
  const [searchString, setSearchString] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [opportunities, setOpportunities] = useState<Opportunity[]>([]);

  useEffect(() => {
    if (show)
      setHeader(
        <>
          <SearchForm
            onChange={(e) => setSearchString($(e.target)!.val()!.toString())}
            placeholder='Search Tenants...'
            className='search-input'
            wrapperClassName='mx-4'
          />

          <HeaderBlockEnd>
            <TextWithIconBtn
              icon='plus-circle'
              text='Add Tenant'
              onClick={() => setShowAddModal(true)}
              className='box-shadow add-new-btn h-100'
            />
          </HeaderBlockEnd>
        </>
      );
  }, [show]);

  useEffect(() => {
    getOpportunities();
  }, [searchString]);

  const getOpportunities = async () => {
    const res = await OpportunityService.index({ name_cont: searchString });
    if (res.ok) setOpportunities(res.data);
    else addError('Error in getOpportunities:', res);
  };

  const createOpportunity = async (name: string, logo?: File) => {
    const res = await OpportunityService.create(name, logo);
    if (res.ok)
      setOpportunities([
        { id: res.data.id, logo: res.data.logo, name, active: true },
        ...opportunities,
      ]);
    else addError('Error in createOpportunities:', res);
  };

  const updateOpportunity = async (opportunity: Option, logo?: File) => {
    const res = await OpportunityService.update(opportunity, logo);
    if (res.ok)
      setOpportunities(opportunities.map((op) => (op.id === opportunity.id ? res.data : op)));
    else addError('Error in updateOpportunity:', res);
  };

  const deleteOpportunity = async (id: number) => {
    if (
      !(await window.customConfirm(
        'Archive Tenant',
        'Are you sure that you want to archive this tenant?'
      ))
    )
      return;

    const res = await OpportunityService.destroy(id);
    if (res.ok) setOpportunities(opportunities.map((op) => (op.id === id ? res.data : op)));
    else addError('Error in deleteOpportunity:', res);
  };

  const restoreOpportunity = async (id: number) => {
    if (
      !(await window.customConfirm(
        'Restore Tenant',
        'Are you sure that you want to restore this tenant?'
      ))
    )
      return;

    const res = await OpportunityService.restore(id);
    if (res.ok) setOpportunities(opportunities.map((op) => (op.id === id ? res.data : op)));
    else addError('Error in restoreOpportunity:', res);
  };

  return (
    <div>
      <OpportunityModal
        show={showAddModal}
        setShow={setShowAddModal}
        title='Add Tenant'
        onSave={createOpportunity}
      />
      <ErrorComponent errors={errors} removeError={removeError} />

      <Table title='Tenants'>
        <thead>
          <tr className='table-header-font'>
            <th className='w-25'>Name</th>
            <th className='w-25'>Logo</th>
            <th />
          </tr>
        </thead>

        <TableBody isResultPresent={opportunities.length > 0} columnsLength={2}>
          {opportunities.map((opportunity) => (
            <OpportunityRow
              key={opportunity.id}
              opportunity={opportunity}
              onUpdate={updateOpportunity}
              onArchive={deleteOpportunity}
              onRestore={restoreOpportunity}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default Opportunities;
