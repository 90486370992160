import React, { useCallback, useContext, useEffect } from 'react';

import DealWindow from '@Deals/DealWindow';
import Table from '@Deals/Table/Table';

import ErrorComponent from '@/components/ErrorComponent';
import HeaderBlock from '@/components/HeaderBlock';
import Loading from '@/components/Loading';
import PaginationSelect from '@/components/PaginationSelect';
import Tabs from '@/components/Tabs';
import { useFetchInitial } from '@/hooks';
import { useFilters } from '@/hooks/use_filters';
import { ApplicationService } from '@/services/application_service';
import { DealService } from '@/services/deal_service';

import BulkActions from './Header/BulkActions';
import CustomViews from './Header/CustomViews';
import Filters from './Header/Filters';
import SearchDealsForm from './Header/SearchDealsForm';
import TableSettings from './Header/TableSettings';
import { ActiveTabContext } from './context/ActiveTabContext';
import { DealsContext } from './context/DealsContext';
import { ErrorContext } from './context/ErrorContext';
import { LoadingContext } from './context/LoadingContext';
import { StasContext } from './context/StasContext';
import { TabName } from './typings';

const Deals = (): JSX.Element => {
  const { isLoading } = useContext(LoadingContext);
  const { showDealWindow, isDealWindowVisible } = useContext(DealsContext);
  const { errors, removeError, addError } = useContext(ErrorContext);
  const {
    setStaState,
    staState,
    setAllSavedFilters,
    setAllSelectedFilters,
    setAvailableFilters,
    restoreExpandedRows,
  } = useContext(StasContext);

  const { activeTab, setActiveTab, restoreActiveTab } = useContext(ActiveTabContext);
  const googleApiKey = useFetchInitial(
    addError,
    ApplicationService.mapsApiKey,
    'Error while fetching googleApiKey:'
  );

  const filtersFuncs = useFilters(
    DealService.getAvailiableFilters,
    addError,
    setAllSelectedFilters,
    setAllSavedFilters,
    setAvailableFilters
  );

  useEffect(() => {
    document.addEventListener('notificationClick', (e) => {
      const { detail } = e as CustomEvent;
      showDealWindow(detail.id, detail.tab);
    });
    restoreActiveTab();
    restoreExpandedRows();
  }, []);

  const selectHandler = useCallback(
    (perPage: number) => setStaState({ ...staState, pages: { ...staState.pages, perPage } }),
    [staState, setStaState]
  );

  return (
    <div>
      {isDealWindowVisible && <DealWindow googleApiKey={googleApiKey} />}
      <Loading isLoading={isLoading} />
      <ErrorComponent errors={errors} removeError={removeError} />
      <HeaderBlock h1='Deals'>
        <SearchDealsForm />
        <Filters {...filtersFuncs} />
        <BulkActions />
        <TableSettings />
        <CustomViews
          restoreFilters={filtersFuncs.restoreFiltersFromString}
          clearAllFilters={filtersFuncs.clearAllFilters}
        />
        <PaginationSelect selectHandler={selectHandler} perPage={staState.pages.perPage} />
      </HeaderBlock>

      <Tabs
        tabs={[
          {
            id: 'starred-deals-tab',
            name: TabName.Starred,
            text: 'Top Deals',
          },
          {
            id: 'all-deals-tab',
            name: TabName.ActiveSta,
            text: 'Active STA Deals',
          },
          {
            id: 'non-sta-deals-tab',
            name: TabName.NonSta,
            text: 'Non-STA Deals',
          },
          {
            id: 'active-sta-deals-tab',
            name: TabName.WorkedSta,
            text: 'Worked STA Deals',
          },
        ]}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <Table />
    </div>
  );
};

export default Deals;
