import { ResourceColumn } from '@/containers/DealResources/ResourcesTableSettings';
import { DataType, RequestMethod, Result } from '@/typings';
import { fetchResult } from '@/utils';

export const ResourcesTableService = {
  index: (): Promise<Result<{ settings: ResourceColumn[] }>> => {
    const url = '/resources_tables';
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  updatePosition: (columns: ResourceColumn[]): Promise<Result<{ settings: ResourceColumn[] }>> => {
    const body = JSON.stringify({ columns });
    const url = '/resources_tables/update_position';
    const req = { url, method: RequestMethod.Put, dataType: DataType.JSON, body };
    return fetchResult(req);
  },
};
