import React, { useCallback, useEffect, useState } from 'react';

import { useDropzone } from 'react-dropzone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DealService } from '@/services/deal_service';

import { FileDeal } from './typings';

const FileDropzone = ({ dealId }: { dealId: number }) => {
  const [dealFiles, setDealFiles] = useState<FileDeal[]>([]);

  useEffect(() => {
    getFiles(dealId);
  }, []);

  const getFiles = async (dealId: number) => {
    const res = await DealService.getFiles(dealId);
    if (res.ok) setDealFiles(res.data);
    else console.error('Error in getFiles:', res.error);
  };

  const deleteFile = async (fileId: number) => {
    const confirmation = await window.customConfirm(
      'Delete File',
      'File will no longer be available in custom fields. Are you sure?'
    );
    if (!confirmation) return;

    const res = await DealService.deleteFile(dealId, fileId);
    if (res.ok) setDealFiles(dealFiles.filter((file) => file.id !== fileId));
    else console.error('Error in deleteFile:', res.error);
  };

  const onDropAccepted = useCallback(
    async (acceptedFiles: File[]) => {
      const formData = new FormData();
      acceptedFiles.forEach((file) => formData.append('files[]', file, file.name));

      const res = await DealService.uploadFiles(dealId, formData);
      if (res.ok) setDealFiles((prev) => [...prev, ...res.data]);
      else console.error('Error onDropAccepted:', res.error);
    },
    [dealId]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDropAccepted });

  const files = dealFiles.map((file) => (
    <li key={file.id} className='deal-file'>
      <a href={file.url} target='_blank'>
        {file.name.substring(0, 50)}
      </a>
      <span>
        {file.created_at}
        <a onClick={() => deleteFile(file.id)} className='ml-15'>
          <FontAwesomeIcon icon='trash-alt' className='fas-icon' />
        </a>
      </span>
    </li>
  ));

  return (
    <section className='container'>
      <div {...getRootProps({ className: 'dropzone mb-4' })}>
        <input {...getInputProps()} />
        <p>Drag and drop files, or upload here</p>
      </div>
      <aside>
        {dealFiles.length !== 0 && (
          <>
            <p className='mb-1'>
              <b>File Name</b>
            </p>
            <ul className='deal-files-list p-0 mb-3'>{files}</ul>
          </>
        )}
      </aside>
    </section>
  );
};

export default FileDropzone;
