import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SectionHeader = (props: {
  children?: any;
  title: string;
  isBodyExpanded: boolean;
  onClick: React.MouseEventHandler;
}) => {
  const { children, title, isBodyExpanded, onClick } = props;
  return (
    <div className='d-flex justify-content-between bb-alto mb-15'>
      <div className='d-flex align-items-center'>
        <FontAwesomeIcon
          icon={isBodyExpanded ? 'chevron-down' : 'chevron-right'}
          className='me-1'
          onClick={onClick}
        />
        <h2 className='source-sans-semi-bold-font fs-24 m-0'>{title}</h2>
      </div>
      {children}
    </div>
  );
};

export default SectionHeader;
