$(document).on('turbolinks:load', () => {
  $(document).on('click', '.js-eye-icon', function (e) {
    if ($(e.target).siblings('.form-control').attr('type') === 'password') {
      $(e.target).siblings('.form-control').attr('type', 'text');
      e.target.classList.remove('fa-eye-slash');
      e.target.classList.add('fa-eye');
    } else {
      $(e.target).siblings('.form-control').attr('type', 'password');
      e.target.classList.remove('fa-eye');
      e.target.classList.add('fa-eye-slash');
    }
  });

  $('#staticBackdropUserHomeEdit').on('hide.bs.modal', () => {
    $('.home-current-password-field, .home-password-field, .home-password-confirm-field').val('');
    $('.user-current-password-msg, .user-home-password-msg').html('');
    $('.js-home-password-inputs-block').addClass('d-none');
  });

  $('.js-show-home-passwords-block')
    .off('click')
    .on('click', () => {
      $('.js-home-password-inputs-block').removeClass('d-none');
    });

  const fileInput: JQuery<HTMLInputElement> = $('#profile-img');
  const preview = $('#profile-img-tag');
  const readURL = (input: HTMLInputElement) => {
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target?.result;
        if (typeof result !== 'string') return;
        preview.attr('src', result);
      };
      reader.readAsDataURL(input.files[0]);
    }
  };
  fileInput.on('change', (event) => readURL(event.target));
});
