class NewMarker {
  private marker: google.maps.Marker;

  constructor(map: google.maps.Map) {
    this.marker = new google.maps.Marker({
      map,
      visible: false,
    });
  }

  draw(position: google.maps.LatLng) {
    this.marker.setPosition(position);
    this.marker.setVisible(true);
    return this;
  }

  clear() {
    this.marker.setPosition(null);
    this.marker.setVisible(false);
  }
}

export default NewMarker;
