import * as React from 'react';
import { useState } from 'react';

import DropdownBtn, { style } from '@/components/DropdownBtn';
import Filter from '@/components/Filter';
import { FilterOption, Option, SetState } from '@/typings';

export type TFilters = {
  cities: number[];
  states: string[];
  tags: number[];
};

export const DefaultFilters = { cities: [], states: [], tags: [] };

const Filters = (props: {
  tags: Option[];
  cities: Option[];
  states: string[];
  setFilters: SetState<TFilters>;
}) => {
  const { setFilters } = props;
  const [show, setShow] = useState(false);
  const [states, setStates] = useState<FilterOption[]>([]);
  const [cities, setCities] = useState<FilterOption[]>([]);
  const [tags, setTags] = useState<FilterOption[]>([]);

  const onToggle = () => {
    setShow(!show);
  };

  const onClear = () => {
    setFilters(DefaultFilters);
    setStates([]);
    setCities([]);
    setTags([]);
    setShow(false);
  };

  const onSave = () => {
    setFilters({
      cities: cities.map((city) => city.id as number),
      states: states.map((state) => state.name),
      tags: tags.map((tag) => tag.id as number),
    });
    setShow(false);
  };

  return (
    <DropdownBtn
      title='Filters'
      variant='light'
      btnClassName={style.btn}
      dropdownClassName='me-3'
      text=' Filters'
      icon='filter'
      onToggle={onToggle}
      show={show}
    >
      <>
        <Filter
          label='Tags'
          options={props.tags}
          selectedOptions={tags}
          setSelectedOptions={setTags}
        />
        <Filter
          label='City'
          options={props.cities}
          selectedOptions={cities}
          setSelectedOptions={setCities}
        />
        <Filter
          label='State'
          options={props.states.map(stringToOption)}
          selectedOptions={states}
          setSelectedOptions={setStates}
        />

        <li className='d-flex align-items-center justify-content-between mt-2'>
          <a type='button' className='fs-14 filters-btn' onClick={onClear}>
            Clear filters
          </a>

          <div className='btn fs-14 add-new-btn mr-15 hover-btn' onClick={onSave}>
            Save
          </div>
        </li>
      </>
    </DropdownBtn>
  );
};

export default Filters;

const stringToOption = (str: string) => ({ id: str, name: str });
