import React, { useEffect, useState } from 'react';

import QRCode from 'qrcode.react';
import { Form } from 'react-bootstrap';

import style from '@/components/Modal/style.module.scss';
import { UserService } from '@/services/user_service';

import { User } from '../Settings/Users';

export const TwoFactorAuthentication = (props: { user: User; getUser: () => void }) => {
  const { user, getUser } = props;
  const [otp, setOtp] = useState<{ uri: string; secret: string } | null>(null);
  const [otpCode, setOtpCode] = useState('');
  const [error, setError] = useState(false);

  const generateOtp = async () => {
    const res = await UserService.generateOtp();
    if (res.ok) {
      setOtp(res.data);
    } else {
      console.error('Error in generateOtp:', res.error);
    }
  };

  const enableOtp = async () => {
    const res = await UserService.enableOtp(otpCode);
    if (res.ok) {
      getUser();
      setError(false);
    } else {
      setError(true);
      console.error('Error in enableOtp:', res.error);
    }
  };

  const disableOtp = async () => {
    const res = await UserService.disableOtp();
    if (res.ok) {
      setOtp(null);
      getUser();
    } else {
      console.error('Error in disableOtp:', res.error);
    }
  };

  const getOtp = async () => {
    if (user.otpEnabled) {
      const res = await UserService.getOtp();

      if (res.ok) {
        setOtp(res.data);
      } else {
        console.error('Error in getOtp:', res.error);
      }
    }
  };

  useEffect(() => {
    getOtp();
  }, []);

  return (
    <Form.Group className='mb-3'>
      <Form.Label>Two-Factor Authentication</Form.Label>
      <div className='d-flex justify-content-between'>
        <div>
          <small className='boulder-color'>Status: </small>
          {user.otpEnabled ? (
            <small className='text-success'>Enabled</small>
          ) : (
            <small className='text-danger'>Disabled</small>
          )}
        </div>

        {user.otpEnabled ? (
          <div className={style.grayBtn} onClick={disableOtp}>
            Disable
          </div>
        ) : (
          <div className={style.grayBtn} onClick={generateOtp}>
            Enable
          </div>
        )}
      </div>

      {otp && (
        <>
          {!user.otpEnabled && (
            <div className='my-1'>
              <small className='boulder-color'>
                Scan the below QR code with an authenticator app of your choice. For example,
                "Authenticator" by Google. Once verified, you will be required to enter a QR code
                when logging into Plots.
              </small>
            </div>
          )}
          <div className='d-flex flex-column align-items-center justify-content-center'>
            <QRCode value={otp.uri}></QRCode>
            <small className='boulder-color mt-1'>{otp.secret}</small>
          </div>

          {!user.otpEnabled && (
            <Form.Group className='mb-3'>
              <Form.Label>Enter Code From Authenticator App</Form.Label>
              <div className='d-flex gap-1'>
                <Form.Control
                  type='text'
                  value={otpCode}
                  onChange={(e) => setOtpCode(e.target.value)}
                />

                <div className={style.saveBtn} onClick={enableOtp}>
                  Verify
                </div>
              </div>
              {error && (
                <small className='error-msg'>Two-Factor Authentication Code is invalid</small>
              )}
            </Form.Group>
          )}
        </>
      )}
    </Form.Group>
  );
};
