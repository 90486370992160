// Other
import * as React from 'react';
import { useState } from 'react';

import Cookies from 'js-cookie';

// Components
import PinDetailsComponent from '@/components/DealWindow/Details/PinDetailsComponent';
import { useEffectNotFirstRender } from '@/hooks';
import { openSavedDealWindow } from '@/scripts/deal';
// Scripts
import {
  createDrawingManager,
  deleteDrawingManager,
  onOverlayComplete,
} from '@/scripts/drawing_manager';
import { ParamsNames } from '@/services/deal_service';
// Types
import { Option } from '@/typings';
import { parseCoordinates } from '@/utils';

import Parcels from '../Parcels';
import ParcelsMapInteractions from '../Parcels/MapInteractions';
import { DealOption } from '../typings';
// Containers
import Radius from './Radius';

const PinDetails = <T extends PinDetailsDeal>(props: {
  canViewConfidential: boolean;
  deal: T;
  sharedMapWindow: boolean;
  opportunities: Option[];
  tags: Option[];
  resourceTags: Option[];
  transactionTypes: Option[];
  users: Option[];
  updateParam: (
    option: Option[] | Option | string,
    paramName: ParamsNames,
    hideEdit: () => void
  ) => void;
  deleteParam: (paramName: ParamsNames, hideEdit: () => void) => void;
}) => {
  const {
    canViewConfidential,
    deal,
    sharedMapWindow,
    opportunities,
    tags,
    resourceTags,
    transactionTypes,
    users,
    updateParam,
    deleteParam,
  } = props;
  const [showRelated, setShowRelated] = useState(Cookies.get('show-related-deals') === 'true');
  const [showEdit, _setShowEdit] = useState({
    opportunities: false,
    tags: false,
    transaction_type: false,
    nickname: false,
    radius: false,
    custom_radius: false,
    related_deals: false,
    working_users: false,
    followers: false,
  });
  const setShowEdit = (param: ParamsNames, bool: boolean) => {
    if (!sharedMapWindow) _setShowEdit((showEdit) => ({ ...showEdit, [param]: bool }));
  };

  const onSave = (option: Option[] | Option | string, paramName: ParamsNames) =>
    updateParam(option, paramName, () => setShowEdit(paramName, false));

  const onDelete = (paramName: ParamsNames) =>
    deleteParam(paramName, () => setShowEdit(paramName, false));

  const toggleShowRelated = () => {
    if (Cookies.get('show-related-deals') === 'true') {
      Cookies.remove('show-related-deals');
      Cookies.remove('deal-id');
      setShowRelated(false);
    } else {
      Cookies.set('show-related-deals', 'true');
      Cookies.set('deal-id', `${deal.id}`);
      setShowRelated(true);
    }
    document.dispatchEvent(new Event('render_markers'));
  };

  useEffectNotFirstRender(() => {
    if (showEdit.custom_radius) {
      const drawingManager = createDrawingManager(deal.id);
      onOverlayComplete(drawingManager, deal.id);
    }
    return deleteDrawingManager;
  }, [showEdit.custom_radius]);

  return (
    <PinDetailsComponent
      deal={deal}
      options={{ opportunities, tags, transactionTypes, users, resourceTags }}
      canViewConfidential={canViewConfidential}
      onSave={onSave}
      onDelete={onDelete}
      openNewDeal={(deal) => openSavedDealWindow(deal)}
      Radius={
        <Radius
          radius={deal.radius}
          customRadius={deal.customRadius}
          showEditRadius={showEdit.radius}
          showEditCustomRadius={showEdit.custom_radius}
          setShowEditRadius={(bool) => setShowEdit('radius', bool)}
          setShowEditCustomRadius={(bool) => setShowEdit('custom_radius', bool)}
          sharedMapWindow={sharedMapWindow}
          onSave={onSave}
          onDelete={onDelete}
        />
      }
      Parcels={
        <Parcels deal={{ id: deal.id, ...parseCoordinates(deal) }}>
          {(parcels, setParcels, deal, deleteParcel) => (
            <ParcelsMapInteractions
              parcels={parcels}
              setParcels={setParcels}
              deal={deal}
              deleteParcel={deleteParcel}
            />
          )}
        </Parcels>
      }
      relatedDealsBtn={
        <p className='pointer light-blue-color' onClick={toggleShowRelated}>
          {showRelated ? 'Show All Pins' : 'Show Related Pins'}
        </p>
      }
      showEdit={showEdit}
      setShowEdit={setShowEdit}
    />
  );
};

export default PinDetails;
type PinDetailsDeal = {
  id: number;
  confidential: boolean;
  tags: Option[];
  opportunities: Option[];
  relatedDeals: DealOption[];
  workingUsers: Option[];
  nickname: string;
  radius: number;
  customRadius: string | null;
  transactionType: Option;
  latitude: string;
  longitude: string;
  followers: Option[];
};
