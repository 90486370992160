import { inside } from '@/utils/inside';

import { map } from './map';
import CustomRadius from './map/markers/custom_radius';
import MarkerManager from './map/markers/marker_manager';

export type DrawingManager = google.maps.drawing.DrawingManager & { dealId: number };
let drawingManager: DrawingManager | null;

export const getDrawingManager = () => drawingManager;

export const createDrawingManager = (dealId: number) => {
  if (drawingManager) drawingManager.setMap(null);
  const polygonOptions = {
    strokeColor: '#000000',
    strokeOpacity: 0.8,
    strokeWeight: 1,
    clickable: false,
    editable: true,
    fillColor: '#000000',
    fillOpacity: 0.2,
  };
  const config = {
    drawingMode: google.maps.drawing.OverlayType.POLYGON,
    drawingControl: false,
    polygonOptions,
    dealId,
  };
  drawingManager = new google.maps.drawing.DrawingManager(config) as DrawingManager;
  drawingManager.setMap(map);
  return drawingManager;
};

export const deleteDrawingManager = () => {
  if (drawingManager) drawingManager.setMap(null);
  drawingManager = null;
};

export const onOverlayComplete = (drawingManager: DrawingManager, dealId: number) =>
  google.maps.event.addListener(
    drawingManager,
    'overlaycomplete',
    (event: google.maps.drawing.OverlayCompleteEvent) => {
      const manager = MarkerManager.getInstance();
      const pinLatLng = manager.pinMarkerManager.getMarker(dealId)?.getPosition();
      if (pinLatLng) {
        const pinCoordinates = { lat: pinLatLng.lat(), lng: pinLatLng.lng() };
        const poly = event.overlay as google.maps.Polygon;
        const polygonCoordinatesArr = CustomRadius.polygonToCoordinates(poly);
        if (!inside(pinCoordinates, polygonCoordinatesArr)) {
          alert('Pin must be inside polygon');
          event.overlay?.setMap(null);
        } else {
          manager.customRadiusMarker.tmpMarker = poly;
        }
      }
    }
  );
