import { SelectionType, Tag } from '@/containers/Settings/Tags/typings';
import { DataType, RequestMethod, Result } from '@/typings';
import { fetchResult } from '@/utils';

export const TagService = {
  index: (
    q?: Record<string, string>
  ): Promise<Result<{ deals_tags: Tag[]; resources_tags: Tag[] }>> => {
    const body = $.param({ q });
    const url = '/tags?' + body;
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  create: (tag: {
    name: string;
    selectionType: SelectionType;
  }): Promise<Result<{ id: number }>> => {
    const body = JSON.stringify({ tag: { text: tag.name, selection_type: tag.selectionType } });
    const url = 'tags';
    const req = { url, method: RequestMethod.Post, dataType: DataType.JSON, body };
    return fetchResult(req);
  },
  update: (tag: {
    id: number;
    name: string;
    selectionType: SelectionType;
  }): Promise<Result<{ id: number }>> => {
    const body = JSON.stringify({ tag: { text: tag.name, selection_type: tag.selectionType } });
    const url = `/tags/${tag.id}`;
    const req = { url, method: RequestMethod.Patch, dataType: DataType.JSON, body };
    return fetchResult(req);
  },
};
