// import { TaskService } from '@/services/task_service';

$(document).on('turbolinks:load', () => {
  window.initFlatpickr();
  window.initSelectize($('.js-select'));
  window.initTagifyNotification('.js-notification-task-name, .js-notification-task-description');

  // if (window.location.pathname !== '/tasks') return;
  // updateTaskStatusMain();
  // tasksSearch();
});

// const updateTaskStatusMain = () => {
//   $('.ajax-update-task-status')
//     .off('click')
//     .on('click', async function () {
//       const taskId = $(this).data('task-id');
//       const page = $('.tasks-table').data('page');

//       const res = await TaskService.updateStatus(taskId);
//       if (res.ok) window.location.search = `?page=${page}`;
//       else console.error('Error in updateTaskStatusMain:', res.error);
//     });
// };

// const tasksSearch = () => {
//   const $form = $('form#task_search');
//   $form.on('submit', (e) => e.preventDefault());

//   $('input#q_name_or_description_or_deal_address_or_user_first_name_or_user_last_name_cont').on(
//     'input',
//     () => {*
//       const searchString = $(
//         'input#q_name_or_description_or_deal_address_or_user_first_name_or_user_last_name_cont'
//       ).val() as string;
//       $.ajax({
//         url: $form.attr('action'),
//         type: $form.attr('method'),
//         dataType: 'script',
//         data: {
//           q: {
//             name_or_description_or_deal_address_or_user_first_name_or_user_last_name_cont:
//               searchString,
//           },
//         },
//         success: () => {
//           const tasks = document.querySelectorAll('tr[data-task-id]');
//           tasks.forEach((task) => {
//             const cells = task.querySelectorAll('td');
//             cells.forEach((cell, index) => {
//               switch (index) {
//                 case 1:
//                 case 2:
//                   cell.innerHTML = strip(cell.innerHTML);
//                   cell.innerHTML = boldString(cell.innerHTML, searchString);
//                   break;
//                 case 3:
//                   if (cell.innerText !== 'N/A') {
//                     const address = cell.firstElementChild;
//                     if (!address) return;

//                     address.innerHTML = strip(address.innerHTML);
//                     address.innerHTML = boldString(address.innerHTML, searchString);
//                   }
//                   break;
//                 case 4:
//                   const user = cell.firstElementChild?.lastElementChild;
//                   if (!user) return;

//                   user.innerHTML = strip(user.innerHTML);
//                   user.innerHTML = boldString(user.innerHTML, searchString);
//                   break;
//               }
//             });
//           });
//         },
//       });
//       function boldString(str: string, substr: string) {
//         if (substr === '') {
//           return str;
//         }
//         const strRegExp = new RegExp(substr, 'gi');
//         const caseSubstr = str.match(strRegExp);
//         return str.replace(strRegExp, '<b>' + caseSubstr ?? substr + '</b>');
//       }
//       function strip(html: string) {
//         html = html.replace(/<b>/g, '');
//         html = html.replace(/<\/b>/g, '');
//         return html;
//       }
//     }
//   );
// };
