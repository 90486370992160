import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MapBtn = (props: { link: string }) => (
  <div className='d-flex justify-content-center align-items-center ml-10'>
    <a
      className='btn init-icon-30 blue bor-rad-circle'
      href={props.link}
      target='_blank'
      rel='noopener noreferrer'
      title='Open deal on map'
    >
      <FontAwesomeIcon icon='map-marked-alt' className='light-blue-color pointer' />
    </a>
  </div>
);

export default MapBtn;
