import { DealService } from '@/services/deal_service';

import { createPinIcon } from '../map/markers/marker_factory';
import MarkerManager from '../map/markers/marker_manager';

export const updatePinType = async (dealId: number, pinId: number) => {
  const res = await DealService.updatePinType(dealId, pinId);
  if (res.ok) {
    const deal = res.data;
    MarkerManager.getInstance().pinMarkerManager.changeMarkerType(
      deal.id,
      createPinIcon(deal),
      deal.pin_icon
    );
  } else console.error('updatePinType: ', res);
};
