import * as React from 'react';
import { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useEffectInfiniteScroll } from '@/hooks';
import { DealService } from '@/services/deal_service';
import { TaskCommentService } from '@/services/task_comment_service';
import { TaskData, TaskService } from '@/services/task_service';
import { arrayMove, camelize } from '@/utils';

import { UserOption } from '../typings';
import CommentRow from './CommentRow';
import TaskModal from './TaskModal';
import TaskRow from './TaskRow';
import { Task, TaskComment } from './typings';

const TasksTab = (props: {
  dealId: number;
  show: boolean;
  users: UserOption[];
  currentUserId: number;
}) => {
  const { dealId, show, users, currentUserId } = props;
  const [showAddModal, setShowAddModal] = useState(false);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState<number | null>(null);

  const getTasks = async () => {
    const res = await DealService.getTasksList(dealId, currentPage + 1);
    if (res.ok) {
      const result = res.data;
      setTasks([...tasks, ...(camelize(result.tasks) as Task[])]);
      setCurrentPage(result.current_tasks_page);
      setTotalPages(result.total_tasks_pages);
    } else console.error('Error in getTasks: ', res.error);
  };

  const createTask = async (data: TaskData) => {
    const res = await TaskService.create(data, dealId);
    if (res.ok) {
      setTasks([camelize(res.data) as Task, ...tasks]);
    } else console.error('Error in createTask: ', res.error);
  };

  const updateTask = async (taskId: number, data: TaskData) => {
    const res = await TaskService.update(taskId, data);
    if (res.ok) {
      setTasks(tasks.map((task) => (task.id === taskId ? (camelize(res.data) as Task) : task)));
    } else console.error('Error in updateTask: ', res.error);
  };

  const deleteTask = async (taskId: number) => {
    const res = await TaskService.destroy(taskId);
    if (res.ok) {
      setTasks(tasks.filter((task) => task.id !== taskId));
    } else console.error('Error in deleteTask: ', res.error);
  };

  const updateTaskStatus = async (taskId: number) => {
    const res = await TaskService.updateStatus(taskId);
    if (res.ok) {
      if (res.data.status === 'assigned') {
        const index = tasks.findIndex((task) => task.id === taskId);
        const newTasks = arrayMove(tasks, index, 0);
        newTasks[0].status = 'assigned';
        setTasks(newTasks);
      } else {
        const index = tasks.findIndex((task) => task.id === taskId);
        const newTasks = arrayMove(tasks, index, tasks.length - 1);
        newTasks[tasks.length - 1].status = 'completed';
        setTasks(newTasks);
      }
    } else console.error('Error in updateTaskStatus: ', res.error);
  };

  const setComments = (taskComments: (comments: TaskComment[]) => TaskComment[], taskId: number) =>
    setTasks((tasks) =>
      tasks.map((task) =>
        task.id === taskId ? { ...task, taskComments: taskComments(task.taskComments) } : task
      )
    );

  const createComment = async (text: string, taskId: number) => {
    const res = await TaskCommentService.create(text, taskId);
    if (res.ok) {
      setComments((comments) => [camelize(res.data) as TaskComment, ...comments], taskId);
    } else console.error('Error in createComment: ', res.error);
  };

  const updateComment = async (id: number, text: string, taskId: number) => {
    const res = await TaskCommentService.update(id, text);
    if (res.ok) {
      setComments(
        (comments) =>
          comments.map((comment) =>
            comment.id === id ? (camelize(res.data) as TaskComment) : comment
          ),
        taskId
      );
    } else console.error('Error in updateComment: ', res.error);
  };

  const deleteComment = async (id: number, taskId: number) => {
    const res = await TaskCommentService.destroy(id);
    if (res.ok) {
      setComments((comments) => comments.filter((comment) => comment.id !== id), taskId);
    } else console.error('Error in deleteComment: ', res.error);
  };

  const stopCondition = currentPage === totalPages || !show;
  useEffectInfiniteScroll('.explore-overflow-deal-block', stopCondition, () => getTasks(), [show]);

  useEffect(() => {
    getTasks();
  }, []);

  if (!show) return null;
  return (
    <div className='tab-pane explore-tasks-container'>
      <div className='d-flex justify-content-end' onClick={() => setShowAddModal(true)}>
        <div className='d-flex align-items-center add-new-btn add-new-modal-btn source-sans-font fs-18 pointer'>
          <FontAwesomeIcon icon='plus-circle' />
          <span className='ml-10'>Add Task</span>
        </div>
      </div>

      {!tasks.length ? (
        <div className='d-flex justify-content-center'>
          <p className='fs-14 mt-2 silver-color fst-italic'>No Tasks Retrieved</p>
        </div>
      ) : (
        <div className='deal-window-tasks mh-100 overflow-visible'>
          {tasks.map((task) => (
            <TaskRow
              key={task.id}
              task={task}
              users={users}
              currentUserId={currentUserId}
              deleteTask={deleteTask}
              updateTask={updateTask}
              updateTaskStatus={updateTaskStatus}
              createComment={createComment}
            >
              {task.taskComments.map((comment) => (
                <CommentRow
                  key={comment.id}
                  taskComment={comment}
                  updateComment={updateComment}
                  deleteComment={deleteComment}
                  users={users}
                />
              ))}
            </TaskRow>
          ))}
        </div>
      )}
      <TaskModal
        show={showAddModal}
        handleClose={() => setShowAddModal(false)}
        handleSubmit={(data) => {
          createTask(data);
          setShowAddModal(false);
        }}
        title='Add Task'
        users={users}
        currentUserId={currentUserId}
      />
    </div>
  );
};

export default TasksTab;
