import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SearchForm = (props: {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  className: string;
  placeholder: string;
  wrapperClassName?: string;
  value?: string;
}) => {
  const { onChange, value, className, placeholder, wrapperClassName } = props;
  return (
    <div className={wrapperClassName}>
      <form className='page-search'>
        <FontAwesomeIcon icon='search' className='page-search-icon' />
        <input
          type='search'
          onChange={onChange}
          className={className}
          value={value}
          placeholder={placeholder}
        />
      </form>
    </div>
  );
};

export default SearchForm;
