import * as React from 'react';
import { useEffect, useRef } from 'react';

import flatpickr from 'flatpickr';
import { Form, InputGroup } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DateInput = (props: {
  show: boolean;
  dueDate: Date | null | undefined;
  onChange: (selectedDates: Date[], selectedDateString?: string, self?: flatpickr.Instance) => void;
  config: flatpickr.Options.Options;
}) => {
  const { show, dueDate } = props;
  const dueDateElem = useRef<HTMLInputElement | null>(null);
  const dueDateFp = useRef<flatpickr.Instance | null>(null);

  useEffect(() => {
    if (show && dueDateElem.current) {
      dueDateFp.current = flatpickr(dueDateElem.current as Node, {
        ...props.config,
        defaultDate: dueDate as Date,
        onChange: props.onChange,
      });
    } else dueDateFp.current?.destroy();
    return () => dueDateFp.current?.destroy();
  }, [show]);

  return (
    <InputGroup className='mb-15'>
      <Form.Control
        type='text'
        ref={dueDateElem}
        onInput={() => {
          dueDateFp.current?.close();
        }}
      />
      <InputGroup.Text
        onMouseDown={() => {
          if (dueDateFp.current && !dueDateFp.current.isOpen)
            setTimeout(() => dueDateFp.current?.open(), 0);
        }}
        className='white-background pointer'
      >
        <FontAwesomeIcon icon='calendar-day' className='light-blue-color' />
      </InputGroup.Text>
    </InputGroup>
  );
};

export default DateInput;
