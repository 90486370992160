import React, { useState } from 'react';

import ProfileModal from './ProfileModal';

const Profile = (props: { dealsTabs: { title: string; id: number }[] }) => {
  const { dealsTabs } = props;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <ProfileModal show={showModal} setShow={setShowModal} dealsTabs={dealsTabs} />
      <li className='dropdown-item pointer' onClick={() => setShowModal(true)}>
        <span className='navbar-link'>Profile</span>
      </li>
    </>
  );
};

export default Profile;
