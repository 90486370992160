import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ExpandCommentsBtn = (props: {
  toggleCommentsVisibility: () => void;
  commentsLength: number;
  isCommentsVisible: boolean;
  isCompleted?: boolean;
}) => {
  const { toggleCommentsVisibility, commentsLength, isCommentsVisible, isCompleted } = props;
  if (!commentsLength) return null;
  return (
    <div onClick={toggleCommentsVisibility} className='fs-14 pointer'>
      <div className={isCompleted ? 'op-50' : 'op-100'}>
        <FontAwesomeIcon icon={['far', 'comments']} />
        <span> {commentsLength} </span>
        <FontAwesomeIcon
          icon={isCommentsVisible ? 'chevron-down' : 'chevron-right'}
          className='replies-arrow-icon'
        />
      </div>
    </div>
  );
};

export default ExpandCommentsBtn;
