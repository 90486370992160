import * as React from 'react';
import { useState } from 'react';

import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Select, { MultiValue } from 'react-select';

import { MixedTags } from '@yaireo/tagify/dist/react.tagify';

import DateInput from '@/components/DateInput';
import { Task } from '@/containers/DealWindow/TasksTab/typings';
import { TaskData } from '@/services/task_service';
import { formatDateServer, tagifySettings } from '@/utils';

import { UserOption } from '../typings';

const TaskModal = (props: {
  show: boolean;
  handleClose: () => void;
  handleSubmit: (data: TaskData) => void;
  title: string;
  users: UserOption[];
  currentUserId: number;
  task?: Task;
}) => {
  const { show, handleClose, handleSubmit, title, users, task, currentUserId } = props;
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState(task?.name.raw);
  const [description, setDescription] = useState(task?.description.raw);
  const [assignedTo, setAssignedTo] = useState(
    users.find((user) => user.id === (task?.assignedTo?.id ?? currentUserId)) ?? null
  );
  const [followers, setFollowers] = useState<MultiValue<UserOption>>(
    task
      ? users.filter((user) => task.followers.map((follower) => follower.id).includes(user.id))
      : []
  );
  const [dueDate, setDueDate] = useState(task ? new Date(task.dueDate) : null);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!name || name === ' ') {
      setValidated(true);
      return;
    }
    const taskData = {
      name,
      description: description ?? '',
      assigned_to_id: assignedTo?.id ?? null,
      followers: followers.map((follower) => ({ id: follower.id })),
      due_date: dueDate ? formatDateServer(dueDate) : null,
    };
    handleSubmit(taskData);
    if (!task) setDueDate(null);
  };

  const onClose = () => {
    handleClose();
    setDueDate(task ? new Date(task.dueDate) : null);
  };

  return (
    <Modal show={show} onHide={onClose} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={onSubmit}>
        <Modal.Body>
          <Form.Group className='mb-3'>
            <Form.Label>Task name *</Form.Label>
            <MixedTags
              autoFocus
              value={task?.name.raw ?? ''}
              settings={tagifySettings(users)}
              onChange={(e) => setName(e.detail.value.trim())}
              className={`form-control${(!name || name === ' ') && validated ? ' is-invalid' : ''}`}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Description</Form.Label>
            <MixedTags
              value={task?.description.raw ?? ''}
              settings={tagifySettings(users)}
              onChange={(e) => setDescription(e.detail.value.trim())}
              className='form-control min-h-10rem'
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Due date</Form.Label>
            <DateInput
              show={show}
              dueDate={dueDate}
              onChange={(selectedDates) => setDueDate(selectedDates[0])}
              config={{
                enableTime: true,
                minDate: 'today',
                dateFormat: 'm/d/y h:i K',
              }}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Assigned to</Form.Label>
            <Select
              components={{ IndicatorSeparator: () => null }}
              onChange={(user) => setAssignedTo(user)}
              defaultValue={assignedTo}
              options={users}
              isClearable={false}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => option.name}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Followers</Form.Label>
            <Select
              components={{ IndicatorSeparator: () => null }}
              onChange={setFollowers}
              isMulti
              defaultValue={followers}
              options={users}
              isClearable={false}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => option.name}
            />
          </Form.Group>
        </Modal.Body>
        <div className='d-flex justify-content-between'>
          {task && (
            <p className='mt-3 ms-3 fst-italic fs-14 silver-color'>
              Created by: {task.user.fullName}
            </p>
          )}
          <Modal.Footer className='task-modal-footer'>
            <div className='modal-cancel-btn pointer' onClick={onClose}>
              Cancel
            </div>
            <button type='submit' className='btn modal-submit-btn fw-600 pointer'>
              {task ? 'Update' : 'Create'}
            </button>
          </Modal.Footer>
        </div>
      </Form>
    </Modal>
  );
};
export default TaskModal;
