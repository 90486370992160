import * as React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TextWithIconBtn = (props: {
  icon: IconProp;
  text: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
}) => {
  const { onClick, className, icon, text } = props;
  return (
    <div onClick={onClick} className={'d-flex align-items-center fs-20 pointer ' + className}>
      <FontAwesomeIcon icon={icon} />
      <span className='ms-2'>{text}</span>
    </div>
  );
};

export default TextWithIconBtn;
