import * as React from 'react';

import { Dropdown } from 'react-bootstrap';

import DropdownBtn, { style } from '@/components/DropdownBtn';
import { openSavedDealWindow } from '@/scripts/deal';
import { isNonEmptyArray } from '@/utils';

import { DealOption } from './typings';

const NearbyDealsBtn = (props: { nearbyDeals: DealOption[] }) => {
  const { nearbyDeals } = props;
  if (!isNonEmptyArray(nearbyDeals)) return null;

  return (
    <DropdownBtn
      icon='magnifying-glass-location'
      text={<span className='text-uppercase pl-5'>{nearbyDeals.length}</span>}
      variant='light'
      dropdownClassName='ml-10'
      btnClassName={style.windowBtn}
      title='Nearby Deals'
    >
      {nearbyDeals.map((deal) => (
        <Dropdown.Item key={deal.id} className='fs-14' onClick={() => openSavedDealWindow(deal)}>
          <span>{deal.name}</span>
        </Dropdown.Item>
      ))}
    </DropdownBtn>
  );
};

export default NearbyDealsBtn;
