import { MapDeal, Marker, PinMarker } from './marker_manager';

export const createLabel = (label: string): google.maps.MarkerLabel => {
  return {
    text: label,
    color: '#345d6f',
    fontWeight: '500',
    fontSize: '13px',
    className: 'marker-label',
  };
};

export const createPinMarker = (deal: MapDeal, visible = true): PinMarker => {
  const marker = new google.maps.Marker({
    position: new google.maps.LatLng(parseFloat(deal.latitude), parseFloat(deal.longitude)),
    optimized: true,
    icon: createPinIcon(deal),
    iconType: deal.pin_icon,
    dealId: deal.id,
    labelText: deal.nickname,
    visible,
    animation: null,
  } as google.maps.MarkerOptions) as PinMarker;

  return marker;
};

export const createTagMarker = (deal: MapDeal & { tags: string }): Marker => {
  return new google.maps.Marker({
    position: new google.maps.LatLng(parseFloat(deal.latitude), parseFloat(deal.longitude)),
    label: {
      color: '#FFFFFF',
      fontWeight: 'bold',
      fontSize: '11px',
      text: deal.tags,
      fontFamily: '"SourceSansPro", monospace',
    },
    icon: createTagIcon(deal.tags.length + 1),
    dealId: deal.id,
  } as google.maps.MarkerOptions) as Marker;
};

export const createPinIcon = (deal: {
  pin_color: string;
  pin_icon: string;
  pin_custom_icon: string;
}): google.maps.Symbol | google.maps.Icon => {
  if (deal.pin_custom_icon) {
    const [width, path] = deal.pin_custom_icon.split(' ');
    const anchorWide = parseInt(width) / 2;
    const anchorHigh = 15; // height is always 30px
    const icon = {
      url: path,
      anchor: new google.maps.Point(anchorWide, anchorHigh),
      labelOrigin: new google.maps.Point(anchorWide, 45), // height 30 + anchorHigh
    };
    return icon;
  } else {
    const pinIcon =
      'M 7.999 -0.001 C 5.245 -0.001 3.0043 2.2396 3.0043 4.9939 C 3.0043 7.5938 5.001 9.735 7.5416 9.9669 V 20.1237 C 7.5416 20.3762 7.7465 20.5811 7.999 20.5811 S 8.4564 20.3762 8.4564 20.1237 V 9.9669 C 10.997 9.735 12.9938 7.5938 12.9938 4.9939 C 12.9938 2.2396 10.753 -0.001 7.999 -0.001 Z M 9.3397 4.6871 C 8.6464 4.6871 8.082 4.123 8.082 3.4294 C 8.082 2.7358 8.6462 2.1716 9.3397 2.1716 C 10.0332 2.1716 10.5975 2.7357 10.5975 3.4294 C 10.5979 4.123 10.0334 4.6871 9.3397 4.6871 Z';
    const starIcon =
      'M 3.612 17.443 C 3.226 17.641 2.788 17.294 2.866 16.851 L 3.696 12.121 L 0.173 8.765 C -0.156 8.451 0.015 7.877 0.456 7.815 L 5.354 7.119 L 7.538 2.792 C 7.735 2.402 8.268 2.402 8.465 2.792 L 10.649 7.119 L 15.547 7.815 C 15.988 7.877 16.159 8.451 15.829 8.765 L 12.307 12.121 L 13.137 16.851 C 13.215 17.294 12.777 17.641 12.391 17.443 L 8 15.187 L 3.611 17.443 Z';
    const markerIcon =
      'M 8 9.5 A 2.5 2.5 0 0 1 5.5 7 A 2.5 2.5 0 0 1 8 4.5 A 2.5 2.5 0 0 1 10.5 7 A 2.5 2.5 0 0 1 8 9.5 M 8 0 A 7 7 0 0 0 1 7 C 1 12.25 8 20 8 20 C 8 20 15 12.25 15 7 A 7 7 0 0 0 8 0 Z';

    const pinColor = deal.pin_color?.length ? deal.pin_color : '#000000';

    const anchorWide = 8;
    const anchorHigh = 10;

    let iconPath: string;
    switch (deal.pin_icon) {
      case 'pin':
        iconPath = pinIcon;
        break;
      case 'star':
        iconPath = starIcon;
        break;
      default:
        iconPath = markerIcon;
    }

    const icon = {
      path: iconPath,
      scale: 1.5,
      fillOpacity: 1,
      fillColor: pinColor,
      strokeWeight: 1,
      anchor: new google.maps.Point(anchorWide, anchorHigh),
      labelOrigin: new google.maps.Point(anchorWide, anchorHigh + 20), // 20px aproximate height of icons
    };
    return icon;
  }
};

const createTagIcon = (tagsStrLength: number) => {
  const tagsIconStartPoint = `M${tagsStrLength * -10.25},-40`, // length x-8
    tagsIconHorizontalLine1 = `h${tagsStrLength * 20.5}`, // length x16
    tagsIconHorizontalLine2 = `h${tagsStrLength * -20.5}`, // length x-16
    tagsIconArc1 = 'a20,20 0 0 1 20,20',
    tagsIconVerticalLine1 = 'v34',
    tagsIconArc2 = 'a20,20 0 0 1 -20,20',
    tagsIconArc3 = 'a20,20 0 0 1 -20,-20',
    tagsIconVerticalLine2 = 'v-34',
    tagsIconArc4 = 'a20,20 0 0 1 20,-20';

  const iconPath = `${tagsIconStartPoint} ${tagsIconHorizontalLine1} ${tagsIconArc1}
          ${tagsIconVerticalLine1} ${tagsIconArc2} ${tagsIconHorizontalLine2}
          ${tagsIconArc3} ${tagsIconVerticalLine2} ${tagsIconArc4} z`.replace(/\s\s+/g, ' ');

  const icon = {
    path: iconPath,
    scale: 0.3,
    fillOpacity: 1,
    fillColor: '#696969',
    strokeWeight: 1,
    anchor: new google.maps.Point(8, 100),
  };
  return icon;
};
