import * as React from 'react';
import { useContext, useEffect, useMemo } from 'react';

import { DealsContext } from '@Deals/context/DealsContext';

import BackBtn from '@/components/DealWindow/BackBtn';
import HistoryTab from '@/components/DealWindow/HistoryTab';
import Info from '@/components/DealWindow/Info';
import ParcelDataTab from '@/components/DealWindow/ParcelDataTab';
import PinTypeDropdown from '@/components/DealWindow/PinTypeDropdown';
import Tabs from '@/components/DealWindow/Tabs';
import MapBtn from '@/components/MapBtn';
import StreetView from '@/components/StreetView';
import BrokerOrOwner from '@/containers/DealWindow/BrokerOrOwner';
import CustomFields from '@/containers/DealWindow/CustomFields';
import { CustomField } from '@/containers/DealWindow/CustomFields/typings';
import Files from '@/containers/DealWindow/Files';
import NotesTab from '@/containers/DealWindow/NotesTab';
import TasksTab from '@/containers/DealWindow/TasksTab';
import { NotesProvider } from '@/contexts/NotesContext';
import { UnsavedChangeProvider } from '@/contexts/UnsavedChangeContext';
import { useStickyWindowEffect } from '@/hooks';
import { DealKeys, ParamsNames } from '@/services/deal_service';
import { Option } from '@/typings';
import { isOptionArray, isString } from '@/utils';

import s from '../Deals.module.scss';
import { PinsContext } from '../context/PinsContext';
import { StasContext } from '../context/StasContext';
import { UsersContext } from '../context/UsersContext';
import PinDetails from './PinDetails';

// hide CommunicationTab
// import CommunicationTab from '@/containers/DealWindow/CommunicationTab';

const DealWindow = (props: { googleApiKey: { key: string } | null }) => {
  const { googleApiKey } = props;
  const {
    setDealWindowVisibility,
    deleteDeal,
    changePinType,
    options,
    getDetails,
    currentDeal,
    tabsTitles,
    setActiveTabTitle,
    activeTabTitle,
    setCurrentDeal,
  } = useContext(DealsContext);
  const { pins } = useContext(PinsContext);
  const { getSta } = useContext(StasContext);
  const formattedPins = useMemo(() => pins.map(([id, name, icon]) => ({ id, name, icon })), [pins]);
  const { currentUser } = useContext(UsersContext);
  const exploreDealBlock = React.useRef<HTMLDivElement>(null);
  const { updateParam, deleteParam } = useContext(DealsContext);

  useEffect(() => {
    const callback = (e: KeyboardEvent) => {
      if (
        e.key === 'Escape' &&
        !(e.ctrlKey || e.altKey || e.shiftKey) &&
        !(e.target as HTMLElement).classList.contains('modal')
      )
        setDealWindowVisibility(false);
    };
    document.addEventListener(`keydown`, callback);
    return () => document.removeEventListener(`keydown`, callback);
  }, []);

  const onSave = async (
    option: Option[] | Option | string,
    paramName: ParamsNames,
    callback: () => void
  ) => {
    if (!option) return;
    if (isOptionArray(option) && option.length === 0) return;

    const param = isString(option)
      ? option
      : isOptionArray(option)
      ? option.map((option) => option.id)
      : option.id;

    const details = { ...currentDeal.details!, [DealKeys[paramName]]: option };
    updateParam(details, paramName, { [paramName]: param });
    callback();
  };

  const onDelete = async (paramName: ParamsNames, callback: () => void) => {
    const option: null | [] = ['transaction_type', 'nickname'].includes(paramName) ? null : [];
    deleteParam({ ...currentDeal.details!, [DealKeys[paramName]]: option }, paramName);
    callback();
  };

  const pin = useMemo(
    () => formattedPins.find((pin) => pin.id === currentDeal.pinId) ?? null,
    [pins, currentDeal.pinId]
  );

  const setCustomFields = (customFields: CustomField[]) => {
    const details = { ...currentDeal.details!, customFields };
    setCurrentDeal({ ...currentDeal, details });
  };

  const setIsForSale = (isForSale: boolean | null) => setCurrentDeal({ ...currentDeal, isForSale });

  useStickyWindowEffect(exploreDealBlock.current);

  useEffect(() => {
    getDetails(currentDeal.id);
  }, [currentDeal.id]);

  return (
    <div ref={exploreDealBlock} className={s.exploreDealBlock}>
      <div className='explore-overflow-deal-block'>
        <BackBtn onClick={() => setDealWindowVisibility(false)} />

        <StreetView
          className='image-block'
          apiKey={googleApiKey?.key ?? null}
          coordinates={currentDeal.coordinates}
        />

        <Info
          deal={{
            ...currentDeal,
            latitude: currentDeal.coordinates.lat.toString(),
            longitude: currentDeal.coordinates.lng.toString(),
          }}
        >
          <>
            <PinTypeDropdown
              currentPin={pin}
              dealId={currentDeal.id}
              pins={formattedPins}
              deleteDeal={deleteDeal}
              changePinType={changePinType}
            />
            <MapBtn link={currentDeal.link} />
          </>
        </Info>

        <Tabs
          titles={tabsTitles}
          activeTabTitle={activeTabTitle}
          setActiveTabTitle={setActiveTabTitle}
        />

        <ParcelDataTab dealId={currentDeal.id} show={activeTabTitle === 'Parcel Data'} />
        {activeTabTitle === 'History' && (
          <HistoryTab setActiveTabTitle={setActiveTabTitle} dealId={currentDeal.id} />
        )}
        {activeTabTitle === 'Details' && currentDeal.details && (
          <>
            <PinDetails currentDeal={currentDeal} onSave={onSave} onDelete={onDelete} />
            <BrokerOrOwner
              dealId={currentDeal.id}
              isForSale={currentDeal.isForSale}
              setIsForSale={setIsForSale}
              onChange={getSta}
            />
            <CustomFields
              setCustomFields={setCustomFields}
              dealId={currentDeal.id}
              customFields={currentDeal.details.customFields}
            />
            <Files dealId={currentDeal.id} />
          </>
        )}
        <TasksTab
          show={activeTabTitle === 'Tasks'}
          dealId={currentDeal.id}
          users={options.users}
          currentUserId={currentUser!.id}
        />

        <UnsavedChangeProvider>
          <NotesProvider dealId={currentDeal.id}>
            <NotesTab
              show={activeTabTitle === 'Notes'}
              users={options.users}
              dealId={currentDeal.id}
            />
          </NotesProvider>
        </UnsavedChangeProvider>
        {/* <CommunicationTab show={activeTabTitle === 'Communication'} dealId={currentDeal.id} /> */}
      </div>
    </div>
  );
};

export default DealWindow;
