import * as React from 'react';
import { useEffect, useState } from 'react';

import Avatar from '@/components/Avatar';
import { History } from '@/containers/Deals/context/DealsContext';
import { hideContextMenu } from '@/scripts/context_menu';
import { openNewDealWindow } from '@/scripts/deal/open_new';
import { HistoryService } from '@/services/history_service';
import { SetState, TabTitle } from '@/typings';
import { camelize } from '@/utils';

const HistoryTab = ({
  setActiveTabTitle,
  dealId,
}: {
  setActiveTabTitle: SetState<TabTitle>;
  dealId: number;
}) => {
  const [histories, setHistories] = useState<History[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getHistories();
  }, []);

  const getHistories = async () => {
    const res = await HistoryService.index(dealId);
    if (res.ok) {
      setHistories(camelize(res.data) as History[]);
    } else console.error('Error in getHistories', res.error);
    setLoading(false);
  };

  const link = (tabName: { tabTitle: TabTitle; text: string }) => {
    if (!tabName) return;
    return (
      <span
        onClick={() => setActiveTabTitle(tabName.tabTitle)}
        className='light-blue-color pointer'
      >
        {tabName.text}
      </span>
    );
  };

  const generateBodyText = (changedField: string, bodyTextLink: string, bodyText: string) => {
    const safeStartsWith = (text: string, prefix: string) => {
      return text != null && text.startsWith(prefix);
    };
    const cleanedText = safeStartsWith(bodyText, '""') ? bodyText.slice(1).slice(0, -1) : bodyText;

    if (changedField === 'Location') {
      const params = new URLSearchParams(bodyTextLink);
      const lat = parseFloat(params.get('deal_latitude')!);
      const lng = parseFloat(params.get('deal_longitude')!);
      const latLng = new google.maps.LatLng(lat, lng);
      const [prefix, suffix] = cleanedText.split(' - ');

      return (
        <>
          {prefix} -&nbsp;
          <span
            onClick={() => {
              hideContextMenu();
              openNewDealWindow(latLng);
            }}
            className='light-blue-color pointer'
            dangerouslySetInnerHTML={{ __html: suffix }}
          ></span>
        </>
      );
    }
    return <span dangerouslySetInnerHTML={{ __html: cleanedText }}></span>;
  };

  if (loading) {
    return (
      <div className='d-flex justify-content-center'>
        <div className='mt-2 spinner-border align-self-center light-blue-color' />
      </div>
    );
  }

  if (histories.length === 0) {
    return (
      <div className='d-flex justify-content-center'>
        <p className='fs-14 mt-2 silver-color fst-italic'>No Histories Retrieved</p>
      </div>
    );
  }

  return (
    <div className='ml-20 mt-20'>
      <div className='history-tab-container'>
        {histories.map((history, i) => (
          <div key={i} className='d-flex mt-2'>
            <Avatar avatar={history.user.avatar} />
            <div className='w-100 text-break ml-10 source-sans-font fuscous-gray-color'>
              <p className='mb-0'>
                <span className='source-sans-semi-bold-font'>{history.user.fullName}</span>
                &nbsp;{history.titleText}
                &nbsp;{link(history.tabName)}
              </p>
              <div className='history-tab-deal-field'>
                {generateBodyText(history.changedField, history.bodyTextLink, history.bodyText)}
              </div>
              <p className='mb-0 fs-14 silver-color'>{history.createdAt}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HistoryTab;
