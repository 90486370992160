import { ComponentProps, JSXElementConstructor, createElement } from 'react';

export const useComponentWithProps = <
  C extends JSXElementConstructor<any>,
  P extends Partial<ComponentProps<C>>
>(
  component: C,
  propsInit: P
) => {
  return (props: Omit<ComponentProps<C>, keyof P>) =>
    createElement(component, { ...propsInit, ...props });
};
