import { AvailableFilters } from '@/containers/Tasks/typings';
import { DateServer, KeysToSnake, RequestMethod, Result } from '@/typings';
import { ajaxResult } from '@/utils';

export const TaskService = {
  create: (data: TaskData, dealId: number): Promise<Result> => {
    const req = {
      url: '/tasks/create_from_modal',
      dataType: 'json',
      method: RequestMethod.Post,
      data: { task: { ...data, deal_id: dealId } },
    };
    return ajaxResult(req);
  },

  update: (id: number, data: TaskData): Promise<Result> => {
    const req = {
      url: `/tasks/${id}/update_from_modal`,
      dataType: 'json',
      method: RequestMethod.Put,
      data: { task: data },
    };
    return ajaxResult(req);
  },

  destroy: (id: number): Promise<Result> => {
    const url = `/tasks/${id}`;
    const req = { url, dataType: 'json', method: RequestMethod.Delete };
    return ajaxResult(req);
  },

  updateStatus: (id: number): Promise<Result> => {
    const url = '/ajax-update-task-status';
    const req = { url, dataType: 'json', method: RequestMethod.Put, data: { id } };
    return ajaxResult(req);
  },

  getAvailiableFilters: (): Promise<Result<KeysToSnake<AvailableFilters>>> => {
    const url = '/ajax-task-available-filters';
    const req = { url, method: RequestMethod.Get, dataType: 'json' };
    return ajaxResult(req);
  },
};

export type TaskData = {
  name: string;
  description: string;
  assigned_to_id: number | null;
  followers: { id: number }[];
  due_date: DateServer | null;
};
