import * as React from 'react';
import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Avatar from '@/components/Avatar';
import {
  Communication,
  UpdateCommunication,
} from '@/containers/DealWindow/CommunicationTab/typings';

import CommunicationModal from './CommunicationModal';

const CommunicationComponent = (props: {
  communication: Communication;
  handleDelete: (comId: number) => void;
  handleUpdate: (com: UpdateCommunication) => void;
}) => {
  const { handleDelete, handleUpdate } = props;
  const com = props.communication;
  const [showEditModal, setShowEditModal] = useState(false);

  return (
    <div className='deal-window-note deal-window-note-block mb-15'>
      <CommunicationModal
        title='Edit Communication'
        show={showEditModal}
        communication={com}
        handleClose={() => setShowEditModal(false)}
        handleSubmit={(baseCom) => {
          setShowEditModal(false);
          handleUpdate({ id: com.id, ...baseCom });
        }}
      />
      <div className='d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center'>
          <Avatar avatar={com.user.avatar} />
          <p className='mt-0 mb-0 ml-10 source-sans-semi-bold-font'>{com.user.name}</p>
        </div>

        <div className='d-flex align-items-center fs-14 source-sans-font'>
          <span className='mr-10'>{com.date}</span>
          <span className='corduroyapprox-color mr-10'>{com.time}</span>
        </div>
      </div>

      <div className='d-flex justify-content-between align-items-end source-sans-font mt-2 fs-14 pl-5'>
        <div>
          <div className='d-flex'>
            {com.kind && <p className='m-0 me-1'>Type: {com.kind} |</p>}
            <p className='m-0'>{com.title}</p>
          </div>

          {com.description && (
            <div>
              <p className='m-0 fuscous-gray-color fst-italic'>{com.description}</p>
            </div>
          )}
        </div>
        <div className='d-flex mt-1'>
          <a className='note-link mr-30 pointer' onClick={() => setShowEditModal(true)}>
            <FontAwesomeIcon icon='pencil-alt' className='light-blue-color' />
          </a>
          <a className='note-link mr-15 pointer' onClick={() => handleDelete(com.id)}>
            <FontAwesomeIcon icon='trash-alt' className='light-blue-color' />
          </a>
        </div>
      </div>
    </div>
  );
};

export default CommunicationComponent;
