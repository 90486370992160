import * as React from 'react';
import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Avatar from '@/components/Avatar';
import TextWithTagsModal from '@/components/DealWindow/TextWithTagsModal';

import { UserOption } from '../typings';
import { TaskComment } from './typings';

const CommentRow = (props: {
  taskComment: TaskComment;
  updateComment: (id: number, text: string, taskId: number) => Promise<void>;
  deleteComment: (id: number, taskId: number) => Promise<void>;
  users: UserOption[];
}) => {
  const { taskComment, deleteComment, updateComment, users } = props;
  const [showEdit, setShowEdit] = useState(false);

  const handleDelete = async () => {
    const confirmation = await window.customConfirm(
      'Delete Comment',
      'Are you sure that you want to delete this Comment?'
    );
    if (!confirmation) return;
    deleteComment(taskComment.id, taskComment.taskId);
  };

  const handleUpdate = (text: string) => {
    updateComment(taskComment.id, text, taskComment.taskId);
    setShowEdit(false);
  };
  return (
    <>
      <div className='deal-window-task-comment-block me-2'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <Avatar avatar={taskComment.user.avatar} />
            <p className='mt-0 mb-0 ml-10 source-sans-semi-bold-font'>
              {taskComment.user.fullName}
            </p>
          </div>
          <div className='d-flex align-items-center fs-14 source-sans-font'>
            <span className='mr-10'>{taskComment.date}</span>
            <span className='corduroyapprox-color mr-10'>{taskComment.time}</span>
          </div>
        </div>
        <div className='d-flex justify-content-between align-items-end source-sans-font mt-2 fs-14'>
          <p
            className='pre-line'
            dangerouslySetInnerHTML={{
              __html: taskComment.text.html,
            }}
          />
          <div className='d-flex mt-1'>
            <a className='note-link mr-30 pointer' onClick={() => setShowEdit(true)}>
              <FontAwesomeIcon icon='pencil-alt' className='light-blue-color' />
            </a>
            <a className='note-link mr-15 pointer' onClick={handleDelete}>
              <FontAwesomeIcon icon='trash-alt' className='light-blue-color' />
            </a>
          </div>
        </div>
      </div>
      <TextWithTagsModal
        text={taskComment.text.raw}
        label='Comment'
        title='Edit Comment'
        handleClose={() => setShowEdit(false)}
        handleSubmit={handleUpdate}
        show={showEdit}
        users={users}
      />
    </>
  );
};

export default CommentRow;
