import * as React from 'react';

import style from './style.module.scss';

const Table = (props: { children: React.ReactNode }) => {
  const { children } = props;
  return <table className={`${style.table} table align-middle box-shadow`}>{children}</table>;
};

export default Table;
