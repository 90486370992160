import * as React from 'react';
import { useState } from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { openSavedDealWindow } from '@/scripts/deal';
import { TabTitle } from '@/typings';

import SnoozeModal from './SnoozeModal';
import { Notification, NotificationType } from './typings';

const NotifictionComponent = (props: {
  notification: Notification;
  showModalRef: React.MutableRefObject<boolean>;
  setShowNotifications: (bool: boolean) => void;
  setNotificationSeen: (id: number, seen: boolean) => void;
}) => {
  const { notification, setShowNotifications, setNotificationSeen } = props;
  const [show, _setShow] = useState(false);
  const setShow = (bool: boolean) => {
    _setShow(bool);
    props.showModalRef.current = bool;
  };

  const openNotification = () => {
    setShowNotifications(false);
    if (!notification.seen) setNotificationSeen(notification.id, true);
    if (notification.deal) {
      switch (window.location.pathname) {
        case '/': {
          openSavedDealWindow(notification.deal, getTabTitle(notification.type));
          break;
        }
        case '/deals': {
          document.dispatchEvent(
            new CustomEvent('notificationClick', {
              detail: { id: notification.deal.id, tab: getTabTitle(notification.type) },
            })
          );
          break;
        }
        default: {
          const { id, lat, lng } = notification.deal;
          window.location.href =
            '/' + `?deal_id=${id}` + `&deal_latitude=${lat}` + `&deal_longitude=${lng}`;
        }
      }
    } else {
      if (notification.type === 'Task') {
        if (window.location.pathname === '/tasks') {
          document.dispatchEvent(
            new CustomEvent('notificationClick', {
              detail: { taskName: notification.taskName },
            })
          );
        } else window.location.href = `/tasks?task_name=${notification.taskName}`;
      }
    }
  };

  return (
    <>
      <li className='notification-item' onClick={openNotification}>
        <div className='source-sans-semi-bold-font fuscous-gray-color'>
          <div className='d-flex justify-content-between'>
            <div>
              <FontAwesomeIcon icon={getIcon(notification)} />
              <span className='ms-1'>{title(notification)}</span>
            </div>
            {!notification.seen && (
              <FontAwesomeIcon icon='circle' size='2xs' className='light-blue-color' />
            )}
          </div>
          <div className='d-flex justify-content-between'>
            <p
              className='source-sans-font fuscous-gray-color mb-2'
              dangerouslySetInnerHTML={{ __html: notification.body }}
            />

            {notification.type === 'Deal' && notification.fieldName === 'snooze_notification' && (
              <FontAwesomeIcon
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShow(true);
                }}
                className='light-blue-color pointer'
                icon='calendar-check'
              />
            )}
          </div>
          <div className='d-flex justify-content-between boulder-color fs-14 source-sans-semi-bold-font'>
            <p>{notification.updatedAt}</p>
            {notification.deal && <p>{notification.deal.address}</p>}
          </div>
        </div>
      </li>
      {notification.type === 'Deal' && (
        <SnoozeModal show={show} setShow={setShow} notification={notification} />
      )}
    </>
  );
};

export default NotifictionComponent;

const getIcon = (notification: Notification): IconProp => {
  if (notification.type === 'Deal') return 'calendar-check';
  if (notification.type === 'Note') return 'sticky-note';

  return notification.assigned ||
    notification.completed ||
    notification.fieldName === 'reminder_notification'
    ? 'check-double'
    : 'comment';
};

const getTabTitle = (notificationType: NotificationType): TabTitle => {
  switch (notificationType) {
    case 'Note':
      return 'Notes';
    case 'Task':
      return 'Tasks';
    default:
      return 'Details';
  }
};

const title = (notification: Notification): string => {
  switch (notification.type) {
    case 'Task':
      if (notification.completed) return 'A task you created was completed';
      else if (notification.assigned) return `${notification.author.name} assigned you to a task`;
      else if (notification.fieldName === 'reminder_notification')
        return notification.dueToday ? 'You have a task due today' : 'You have a past due task.';
      else return `${notification.author.name}  mentioned you in a task`;
    case 'Note':
      return `${notification.author.name}  mentioned you in a note`;
    default:
      return `A ${notification.deal?.pin?.name} pin needs to be updated.`;
  }
};
