import * as React from 'react';
import { useState } from 'react';

import Select from 'react-select/async';

import IconBtn from '@/components/IconBtn';
import { DealOption } from '@/containers/DealWindow/typings';
import { DealService } from '@/services/deal_service';
import { Option } from '@/typings';

const DealsSelect = (props: {
  defaultValues: Option[] | DealOption[];
  onSaveClick: (options: Option[] | DealOption[]) => void;
  onCloseClick: () => void;
  onDeleteClick: () => void;
}) => {
  const { onSaveClick, onCloseClick, onDeleteClick } = props;
  const [selectedOptions, setSelectedOptions] = useState(props.defaultValues);
  const loadOptions = (input: string) =>
    new Promise<Option[]>(async (resolve) => {
      const res = await DealService.relatedDealsSelectOptions(input);
      if (res.ok) resolve(res.data);
      else {
        console.error('Error in DealsSelect:', res.error);
        resolve([]);
      }
    });

  return (
    <div className='mb-2'>
      <Select
        className='mb-2 w-370p'
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        defaultValue={props.defaultValues}
        onChange={(options) => setSelectedOptions([...options])}
        getOptionValue={(option) => `${option.id}`}
        getOptionLabel={(option) => option.name}
        loadOptions={loadOptions}
        cacheOptions
        defaultOptions
        isMulti
      />
      <IconBtn
        onClick={() => onSaveClick(selectedOptions)}
        icon='check'
        style='success'
        className='ms-0'
      />
      <IconBtn onClick={onCloseClick} icon='times' style='primary' />
      <IconBtn onClick={onDeleteClick} icon='trash' style='danger' />
    </div>
  );
};

export default DealsSelect;
