import * as React from 'react';

import DealWindow from '@Deals/DealWindow';
import Table from '@Deals/Table/Table';
import ContextProvider from '@Deals/context/Context';
import { TabName } from '@Deals/typings';

import HeaderBlock from '@/components/HeaderBlock';
import { SetState } from '@/typings';

import { DealsContext } from '../Deals/context/DealsContext';
import Profile, { ProfileRow } from './Profile';
import { Broker } from './typings';

const Show = (props: {
  children: React.ReactNode;
  broker: Broker;
  setBroker: SetState<Broker | null>;
  googleApiKey: { key: string } | null;
}): JSX.Element => {
  const { children, broker, setBroker, googleApiKey } = props;
  const breadcrumb = {
    text: broker.name,
    onH1Click: () => {
      setBroker(null);
    },
  };

  return (
    <div>
      {children}
      <HeaderBlock h1='Brokers' breadcrumb={breadcrumb} />
      <Profile>
        <ProfileRow name='Phone' value={broker.phone} />
        <ProfileRow name='Email' value={broker.email} isMail={true} />
        <ProfileRow name='Mailing Address' value={broker.address} />
        <ProfileRow name='Market' value={broker.market} />
        <ProfileRow name='Company' value={broker.workingCompany?.name} />
        <ProfileRow name='Note' value={broker.note} />
      </Profile>
      <h2 className='heading-two m-3'>Related Deals</h2>
      <ContextProvider>
        <div>
          <DealWindowWrapper googleApiKey={googleApiKey} />
          <Table brokerId={broker.id} tabName={TabName.AllDeals} />
        </div>
      </ContextProvider>
    </div>
  );
};

export default Show;

const DealWindowWrapper = (props: { googleApiKey: { key: string } | null }) => {
  const { isDealWindowVisible } = React.useContext(DealsContext);
  if (isDealWindowVisible) return <DealWindow {...props} />;
  else return null;
};
