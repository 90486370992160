import { Broker } from '@/containers/Brokers/typings';
import { Nullable, RequestMethod, Result, SortObject } from '@/typings';
import { ajaxResult } from '@/utils';

export const BrokerService = {
  create: (broker: Nullable<Broker>): Promise<Result> => {
    const { workingCompany, ...newBroker } = broker;
    const working_company = workingCompany;
    const data = { broker: { ...newBroker, working_company } };
    const req = { url: '/brokers', method: RequestMethod.Post, dataType: 'json', data };
    return ajaxResult(req);
  },
  update: (broker: Nullable<Broker>): Promise<Result> => {
    const { workingCompany, ...newBroker } = broker;
    const working_company = workingCompany;
    const data = { broker: { ...newBroker, working_company } };
    const url = `/brokers/${broker.id}`;
    const req = { url, method: RequestMethod.Patch, dataType: 'json', data };
    return ajaxResult(req);
  },
  destroy: (id: number): Promise<Result> => {
    const url = `/brokers/${id}`;
    const req = { url, method: RequestMethod.Delete, dataType: 'json' };
    return ajaxResult(req);
  },
  show: (id: number): Promise<Result> => {
    const url = `brokers/${id}`;
    const req = { url, method: RequestMethod.Get, dataType: 'json' };
    return ajaxResult(req);
  },
  index: (
    sort: SortObject,
    searchString: string,
    currentPage: number,
    perPage: number
  ): Promise<Result> => {
    const q = { s: `${sort.entity} ${sort.method}`, brokers_search_cont: searchString };
    const data = { current_page: currentPage, q, per_page: perPage };
    const req = { url: 'brokers', method: RequestMethod.Get, data, dataType: 'json' };
    return ajaxResult(req);
  },
};
