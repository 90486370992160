import * as React from 'react';
import { useState } from 'react';

import Select from 'react-select';

import FormBtn from '@/components/IconBtn';
import { Option } from '@/typings';

const SelectForm = <T extends Option>(props: {
  options: Array<T> | Array<{ label?: string; options: Array<T> }>;
  defaultOptions: Array<T> | T;
  onCloseClick: React.MouseEventHandler<HTMLButtonElement>;
  onSaveClick: (arg: Array<T> | T) => void;
  onDeleteClick: React.MouseEventHandler<HTMLButtonElement>;
  isMulti: boolean;
}) => {
  const { defaultOptions, options, onCloseClick, onSaveClick, onDeleteClick, isMulti } = props;
  const [selectedOptions, setOptions] = useState(defaultOptions);
  return (
    <div className='d-flex mb-2 align-items-center'>
      <Select
        className='w-198p'
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        defaultValue={defaultOptions}
        options={options}
        closeMenuOnSelect={!isMulti}
        isMulti={isMulti}
        isClearable={false}
        menuShouldScrollIntoView={false}
        onChange={(options) => setOptions(options as T | T[])}
        getOptionValue={(option) => `${option.id}`}
        getOptionLabel={(option) => option.name}
      />
      <FormBtn onClick={() => onSaveClick(selectedOptions)} icon='check' style='success' />
      <FormBtn onClick={onCloseClick} icon='times' style='primary' />
      <FormBtn onClick={onDeleteClick} icon='trash' style='danger' />
    </div>
  );
};

export default SelectForm;
