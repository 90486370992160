import * as React from 'react';
import { useEffect, useState } from 'react';

import { useDropzone } from 'react-dropzone';

import Modal from '@/components/Modal';
import { clearMapImages } from '@/scripts/map';
import { MapImageService } from '@/services/map_image_service';

const ReplaceMapImageModal = () => {
  const [show, setShow] = useState(false);
  const [id, setId] = useState<number | null>(null);
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    document.addEventListener('replace-image-click', (e) => {
      const elem = e.target as HTMLElement;
      setId(parseInt(elem.dataset['id']!));
      setShow(true);
    });
  }, []);

  const handleClose = () => {
    setShow(false);
    setFile(null);
  };

  const handleSave = async () => {
    if (!file) return alert('Please add image');
    if (!id) return alert('This modal is not connected to map image');

    const formData = new FormData();
    formData.append('file', file, file.name);
    const res = await MapImageService.replaceFile(id, formData);
    if (res.ok) {
      setShow(false);
      setFile(null);
      clearMapImages();
      document.dispatchEvent(new Event('render_markers'));
    } else console.error(res.error);
  };

  const onDropAccepted = async (files: File[]) => {
    if (!files[0].type.includes('image')) return alert(`${files[0].name} is not an image`);
    setFile(files[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDropAccepted });

  return (
    <Modal
      modalProps={{ show, backdrop: true }}
      title='Replace Image'
      onSave={handleSave}
      onClose={handleClose}
    >
      <>
        <div {...getRootProps({ className: 'dropzone mb-4' })}>
          <input {...getInputProps()} />
          <p>Drag and drop image or upload here</p>
        </div>
        {file && (
          <ul className='deal-files-list p-0 mb-3'>
            <li className='deal-file'>{file.name.substring(0, 50)}</li>
          </ul>
        )}
      </>
    </Modal>
  );
};

export default ReplaceMapImageModal;
