import * as React from 'react';

const Profile = (props: { children: React.ReactChild[] }) => {
  return (
    <div className='card ms-3 max-w-25rem mt-20 box-shadow'>
      <div className='card-body'>
        <ul className='list-group list-group-flush'>{props.children}</ul>
      </div>
    </div>
  );
};

export const ProfileRow = (props: { name: string; value: string; isMail?: boolean }) => {
  const { name, value, isMail } = props;
  return (
    <li className='list-group-item'>
      <div className='row justify-content-between'>
        <div className='col fw-bold'>{name}</div>
        <div className='col'>{isMail ? <a href={`mailto:${value}`}>{value}</a> : value}</div>
      </div>
    </li>
  );
};

export default Profile;
