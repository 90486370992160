import React, { useMemo, useRef, useState } from 'react';

import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CellIcon from '@/components/CellIcon';
import { Cell as CellComponent } from '@/components/Table/Cell';
import { useComponentWithProps } from '@/hooks/use_component_with_props';
import { Option } from '@/typings';

import { DealResource } from '.';
import ModalDealResource from './ModalDealResource';
import { ResourceColumn } from './ResourcesTableSettings';

const TableRow = (props: {
  columns: ResourceColumn[];
  searchString: string;
  resource: DealResource;
  isAdmin: boolean;
  deleteResource: (id: number) => void;
  updateResource: (deal: Omit<DealResource, 'lastUpdated'>) => void;
  tags: Option[];
}) => {
  const { resource, searchString, isAdmin, deleteResource, updateResource, tags } = props;

  const [showBtns, setShowBtns] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const Cell = useComponentWithProps(CellComponent, { searchString });

  const tooltip = useRef<HTMLDivElement | null>(null);

  const columns = useMemo(
    () =>
      props.columns.reduce((acc, col) => {
        acc[col.name] = col;
        return acc;
      }, {} as Record<string, ResourceColumn>),
    [props.columns]
  );

  const onUpdate = (newResource: Omit<Omit<DealResource, 'id'>, 'lastUpdated'>) => {
    updateResource({ id: resource.id, ...newResource });
  };

  return (
    <>
      <tr
        className='fs-14 fuscous-gray-color'
        onMouseEnter={() => setShowBtns(true)}
        onMouseLeave={() => setShowBtns(false)}
      >
        <Cell active={columns.name?.active} className='max-column'>
          {resource.name}
        </Cell>
        <Cell active={columns.link?.active} link={resource.link}>
          <FontAwesomeIcon icon='arrow-up-right-from-square' />
        </Cell>
        <Cell active={columns.city?.active}>{resource.city?.name}</Cell>
        <Cell active={columns.state?.active}>{resource.state}</Cell>
        <Cell active={columns.tags?.active}>{resource.tags.map((tag) => tag.name).join(', ')}</Cell>
        <td>
          <div className='d-flex'>
            <Overlay
              target={tooltip.current}
              show={showTooltip && !!resource.lastUpdated}
              placement='left'
            >
              <Tooltip className='tooltip-white'>{resource.lastUpdated}</Tooltip>
            </Overlay>
            <CellIcon
              show={!!resource.lastUpdated}
              icon='clock-rotate-left'
              className='pointer'
              setRef={(div) => (tooltip.current = div)}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            />
            <CellIcon
              show={showBtns}
              icon='pencil-alt'
              className='pointer ms-2'
              onClick={() => setShowModal(true)}
            />
            {isAdmin && (
              <CellIcon
                show={showBtns}
                icon='trash-alt'
                className='pointer ms-2'
                onClick={() => deleteResource(resource.id)}
              />
            )}
          </div>
        </td>
      </tr>
      <ModalDealResource
        title='Edit Resource'
        show={showModal}
        setShow={setShowModal}
        resource={resource}
        tags={tags}
        onSave={onUpdate}
      />
    </>
  );
};

export default TableRow;
