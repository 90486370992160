import * as React from 'react';

import s from './style.module.scss';

const Table = (props: { children: React.ReactNode[] | React.ReactNode; title: string }) => (
  <div className={`${s.tableWrapper} box-shadow mt-4`}>
    <h2 className={`${s.tableHeadline} py-4 ps-4 fs-24 fuscous-gray-color fw-900`}>
      {props.title}
    </h2>
    <table className='table table-borderless mb-4'>{props.children}</table>
  </div>
);

export default Table;
