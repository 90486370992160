import * as React from 'react';

import Deals from '@Deals/Deals';
import ContextProvider from '@Deals/context/Context';

const Index = (): JSX.Element => {
  return (
    <React.StrictMode>
      <ContextProvider>
        <Deals />
      </ContextProvider>
    </React.StrictMode>
  );
};

export default Index;
