import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Loading = ({ isLoading }: { isLoading: boolean }) => {
  if (!isLoading) return <></>;
  return (
    <>
      <div className='modal show fade d-block'>
        <div className='modal-dialog' style={{ maxWidth: '170px' }}>
          <div className='modal-content'>
            <div className='modal-body'>
              <p className='fs-24 source-sans-semi-bold-font m-auto'>
                Loading...&nbsp;
                <FontAwesomeIcon icon='spinner' pulse />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  );
};

export default Loading;
