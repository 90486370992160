import React, { useEffect, useMemo, useState } from 'react';

import DraggableList from '@/components/DraggableList';
import ErrorComponent from '@/components/ErrorComponent';
import { HeaderBlockEnd } from '@/components/HeaderBlock';
import SearchForm from '@/components/SearchForm';
import Table from '@/components/Settings/Table';
import TableBody from '@/components/Table/TableBody';
import TextWithIconBtn from '@/components/TextWithIconBtn';
import { FieldType } from '@/containers/DealWindow/CustomFields/typings';
import { useErrorState, useFetchInitial } from '@/hooks';
import { CustomFieldService } from '@/services/custom_field_service';
import { PinService } from '@/services/pin_service';
import { Option } from '@/typings';
import { arrayMove } from '@/utils';

import CustomFieldModal from './CustomFieldModal';
import CustomFieldRow from './CustomFieldRow';

export type CustomField = {
  id: number;
  name: string;
  privacy: 'public' | 'private';
  fieldType: FieldType;
  location: string;
  pins: Option[];
  active: boolean;
};
const CustomFields = (props: { show: boolean; setHeader: (elem: JSX.Element) => void }) => {
  const { show, setHeader } = props;
  const [errors, addError, removeError] = useErrorState();
  const [searchString, setSearchString] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  const pinTuples = useFetchInitial(
    addError,
    PinService.options,
    'Error while fetching pins:',
    false
  );
  const pins = useMemo(() => pinTuples?.map((pin) => ({ id: pin[0], name: pin[1] })), [pinTuples]);

  useEffect(() => {
    if (show)
      setHeader(
        <>
          <SearchForm
            onChange={(e) => setSearchString($(e.target)!.val()!.toString())}
            placeholder='Search Custom Fields...'
            className='search-input'
            wrapperClassName='mx-4'
          />

          <HeaderBlockEnd>
            <TextWithIconBtn
              icon='plus-circle'
              text='Add Custom Field'
              onClick={() => setShowAddModal(true)}
              className='box-shadow add-new-btn h-100'
            />
          </HeaderBlockEnd>
        </>
      );
  }, [show]);

  useEffect(() => {
    setLoading(true);
    getCustomFields();
  }, [searchString]);

  const getCustomFields = async () => {
    const res = await CustomFieldService.index({ name_cont: searchString });
    if (res.ok) {
      setCustomFields(res.data);
      setLoading(false);
    } else addError('Error in getCustomFields:', res);
  };

  const createCustomField = async (customField: {
    name: string;
    privacy: 'private' | 'public';
    fieldType: FieldType;
    pins: Option[];
  }) => {
    const res = await CustomFieldService.create(customField);
    if (res.ok)
      setCustomFields([
        { ...customField, active: true, location: 'details', id: res.data },
        ...customFields,
      ]);
    else addError('Error in createCustomField:', res);
  };

  const updateCustomField = async (customField: {
    id: number;
    name: string;
    privacy: 'private' | 'public';
    fieldType: FieldType;
    pins: Option[];
  }) => {
    const res = await CustomFieldService.update(customField);
    if (res.ok)
      setCustomFields(customFields.map((cf) => (cf.id === customField.id ? res.data : cf)));
    else addError('Error in updateCustomField:', res);
  };

  const deleteCustomField = async (id: number) => {
    if (
      !(await window.customConfirm(
        'Archive Custom Field',
        'Are you sure that you want to archive this custom field?'
      ))
    )
      return;
    const res = await CustomFieldService.destroy(id);
    if (res.ok) setCustomFields(customFields.map((cf) => (cf.id === id ? res.data : cf)));
    else addError('Error in deleteCustomField:', res);
  };

  const restoreCustomField = async (id: number) => {
    if (
      !(await window.customConfirm(
        'Restore Custom Field',
        'Are you sure that you want to restore this custom field?'
      ))
    )
      return;
    const res = await CustomFieldService.restore(id);
    if (res.ok) setCustomFields(customFields.map((cf) => (cf.id === id ? res.data : cf)));
    else addError('Error in restoreCustomField:', res);
  };

  const onSortEnd = async (oldIndex: number, newIndex: number) => {
    if (oldIndex === newIndex) return;
    const newCustomFields = arrayMove(customFields, oldIndex, newIndex);
    setCustomFields(newCustomFields);
    const res = await CustomFieldService.updateOrderNumber(newCustomFields.map((cf) => cf.id));
    if (!res.ok) addError('Error in onSortEnd:', res);
  };

  return (
    <div>
      <CustomFieldModal
        title='Add Custom Field'
        onSave={createCustomField}
        setShow={setShowAddModal}
        show={showAddModal}
        pins={pins}
      />
      <ErrorComponent errors={errors} removeError={removeError} />

      <Table title='Custom Fields'>
        <thead>
          <tr className='table-header-font'>
            <th />
            <th className='w-20'>Name</th>
            <th className='w-20'>Type</th>
            <th className='w-20'>Location</th>
            <th className='w-20'>Privacy</th>
            <th className='w-20'>Pin types</th>
            <th />
          </tr>
        </thead>

        <DraggableList
          items={customFields}
          onReorder={onSortEnd}
          Container={({ children }) => (
            <TableBody
              isResultPresent={customFields.length > 0}
              isLoading={isLoading}
              columnsLength={7}
            >
              {children}
            </TableBody>
          )}
          Item={({ value, onMouseDown, draggableClassName }) => (
            <CustomFieldRow
              customField={value}
              onMouseDown={onMouseDown}
              onArchive={deleteCustomField}
              onRestore={restoreCustomField}
              draggableClassName={draggableClassName}
              onUpdate={updateCustomField}
              pins={pins}
            />
          )}
        />
      </Table>
    </div>
  );
};

export default CustomFields;
