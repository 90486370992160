import React, { useCallback, useContext } from 'react';

import { StasContext } from '@Deals/context/StasContext';

import Pagination from '@/components/Pagination';

const PaginationDeals = () => {
  const { staState, setStaState } = useContext(StasContext);

  const clickHandler = useCallback(
    (newCurrentPage: number) => {
      setStaState({
        ...staState,
        pages: { ...staState.pages, current: newCurrentPage },
      });
    },
    [staState, setStaState]
  );

  const showAllHandler = useCallback(() => {
    setStaState({
      ...staState,
      pages: { ...staState.pages, perPage: -1 },
    });
  }, [staState, setStaState]);

  return (
    <Pagination
      showAllHandler={showAllHandler}
      clickHandler={clickHandler}
      totalPages={staState.pages.total ?? staState.pages.current}
      currentPage={staState.pages.current}
    />
  );
};

export default PaginationDeals;
