import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import flatpickr from 'flatpickr';
import { Instance } from 'flatpickr/dist/types/instance';
import { InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import DropdownBtn, { style } from '@/components/DropdownBtn';
import Filter from '@/components/Filter';
import { useEffectNotFirstRender } from '@/hooks';
import { useFilters } from '@/hooks/use_filters';
import { TaskService } from '@/services/task_service';
import { Error, SetState } from '@/typings';
import { isNonEmptyArray } from '@/utils';

import { AvailableFilters, FiltersOptions, defaultFilters } from '../typings';

const Filters = (props: {
  addError: (s: string, e: Error) => void;
  availableFilters: AvailableFilters;
  selectedFilters: FiltersOptions;
  savedFilters: FiltersOptions;
  setAvailableFilters: SetState<AvailableFilters>;
  setSelectedFilters: SetState<FiltersOptions>;
  setSavedFilters: SetState<FiltersOptions>;
}) => {
  const {
    addError,
    availableFilters,
    selectedFilters,
    setSelectedFilters,
    savedFilters,
    setAvailableFilters,
    setSavedFilters,
  } = props;

  const [isFiltersVisible, setIsFiltersVisible] = useState<boolean>(false);

  const {
    clearAllFilters,
    setSavedFiltersFromSelected,
    restoreSelectedFiltersFromSaved,
    restoreFiltersFromCookies,
    getAvailableFilters,
  } = useFilters(
    TaskService.getAvailiableFilters,
    addError,
    setSelectedFilters,
    setSavedFilters,
    setAvailableFilters
  );

  const dueDateElemFrom = useRef<any>(null);
  const dueDateFpFrom = useRef<flatpickr.Instance | null>(null);
  let dueDateFrom: Date | undefined = selectedFilters.dueDateFrom
    ? flatpickr.parseDate(selectedFilters.dueDateFrom, 'd/m/Y')
    : undefined;

  const dueDateElemTo = useRef<any>(null);
  const dueDateFpTo = useRef<flatpickr.Instance | null>(null);
  let dueDateTo: Date | undefined = selectedFilters.dueDateTo
    ? flatpickr.parseDate(selectedFilters.dueDateTo, 'd/m/Y')
    : undefined;

  useEffect(() => {
    restoreFiltersFromCookies('tasks', savedFilters);
    getAvailableFilters();
  }, []);

  useEffect(() => {
    if (isFiltersVisible) {
      dueDateFpFrom.current = flatpickr(dueDateElemFrom.current, {
        enableTime: true,
        dateFormat: 'm/d/y',
        defaultDate: dueDateFrom,
        static: true,
      });

      dueDateFpTo.current = flatpickr(dueDateElemTo.current, {
        enableTime: true,
        dateFormat: 'm/d/y',
        defaultDate: dueDateTo,
        static: true,
      });
    } else {
      dueDateFpFrom.current?.destroy();
      dueDateFpTo.current?.destroy();
    }
    return () => {
      dueDateFpFrom.current?.destroy();
      dueDateFpTo.current?.destroy();
    };
  }, [isFiltersVisible]);

  useEffectNotFirstRender(() => {
    if (isFiltersVisible) return;

    setSelectedFilters({
      ...selectedFilters,
      dueDateFrom: flatpickrInstanceToDateString(dueDateFpFrom.current),
      dueDateTo: flatpickrInstanceToDateString(dueDateFpTo.current),
    });
  }, [isFiltersVisible]);

  const onSave = () => {
    setIsFiltersVisible(false);
    setSavedFiltersFromSelected('tasks', {
      ...selectedFilters,
      dueDateFrom: flatpickrInstanceToDateString(dueDateFpFrom.current),
      dueDateTo: flatpickrInstanceToDateString(dueDateFpTo.current),
    });
  };

  const onClear = () => {
    dueDateFpFrom.current?.clear();
    dueDateFpTo.current?.clear();
    setIsFiltersVisible(false);
    clearAllFilters('tasks', { ...defaultFilters });
  };

  const onToggle = (show: boolean) => {
    setIsFiltersVisible(show);
    if (!show) restoreSelectedFiltersFromSaved(savedFilters);
  };

  return (
    <DropdownBtn
      title='Filters'
      variant='light'
      btnClassName={style.btn}
      dropdownClassName='me-3'
      text=' Filters'
      icon='filter'
      onToggle={onToggle}
      show={isFiltersVisible}
    >
      {isFiltersVisible && (
        <>
          <Filter
            label='Assigned To'
            selectedOptions={selectedFilters.assignedUsers}
            setSelectedOptions={(assignedUsers) =>
              setSelectedFilters((filters) => ({ ...filters, assignedUsers }))
            }
            options={availableFilters.assignedUsers}
          />
          <Filter
            label='Following'
            selectedOptions={selectedFilters.followers}
            setSelectedOptions={(followers) =>
              setSelectedFilters((filters) => ({ ...filters, followers }))
            }
            options={availableFilters.followers}
          />
          <Filter
            label='Created By'
            selectedOptions={selectedFilters.createdBy}
            setSelectedOptions={(createdBy) =>
              setSelectedFilters((filters) => ({ ...filters, createdBy }))
            }
            options={availableFilters.createdBy}
          />
          <li className='position-relative d-flex align-items-center justify-content-between w-350p mt-10'>
            <Form.Label className='date-range-label'>Date Range</Form.Label>
            <InputGroup className='w-27'>
              <Form.Control type='text' ref={dueDateElemFrom} />
            </InputGroup>

            <Form.Label>to</Form.Label>
            <InputGroup className='w-27 me-2'>
              <Form.Control type='text' ref={dueDateElemTo} />
            </InputGroup>
          </li>
          <li className='d-flex mt-2 align-items-center justify-content-between'>
            <a type='button' className='fs-14 filters-btn' onClick={onClear}>
              Clear filters
            </a>

            <div className='btn fs-14 add-new-btn mr-15 hover-btn' onClick={onSave}>
              Save
            </div>
          </li>
        </>
      )}
    </DropdownBtn>
  );
};

export default Filters;

const flatpickrInstanceToDateString = (instance: Instance | null) => {
  const selectedDates = instance?.selectedDates;
  return isNonEmptyArray(selectedDates)
    ? new Date(selectedDates[0]).toLocaleDateString('en-GB')
    : undefined;
};
