import * as React from 'react';
import { useState } from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SetState, SortMethod, SortObject, SortState } from '@/typings';

import style from './style.module.scss';

const TableHead = (props: {
  setSort: SetState<SortObject>;
  columns: { children: React.ReactChild; entity: string; className?: string }[];
  defaultSort?: SortObject;
}) => {
  const { columns, defaultSort } = props;
  const [sortStates, setSortStates] = useState(
    Array.from({ length: columns.length }, () => SortState.Default)
  );

  const setSort = (sortState: SortState, entity: string, i: number) => {
    const newSortStates = sortStates.map((_, j) => {
      return i === j ? sortState : SortState.Default;
    });
    setSortStates(newSortStates);

    const sortObject =
      sortState === SortState.Default
        ? defaultSort ?? { entity: 'name', method: SortMethod.Asc }
        : { entity, method: getSortMethod(sortState) };
    props.setSort(sortObject);
  };

  return (
    <thead>
      <tr>
        {columns.map((col, i) => {
          if (col.className === 'table-column-checkbox')
            return (
              <th key={i} scope='col' className={`${style.tableColumn} table-column-checkbox`} />
            );
          else
            return (
              <th key={i} scope='col' className={`${style.tableColumn} ${col.className ?? ''}`}>
                <a
                  className='pointer'
                  onClick={() => {
                    setSort(nextSortState(sortStates[i]), col.entity, i);
                  }}
                >
                  {col.children}&nbsp;
                  <FontAwesomeIcon icon={getSortIcon(sortStates[i])} className='fas-icon' />
                </a>
              </th>
            );
        })}
      </tr>
    </thead>
  );
};

export default TableHead;

const getSortMethod = (sortState: SortState) => {
  return sortState === SortState.Down ? SortMethod.Desc : SortMethod.Asc;
};

export const getSortIcon = (sortState: SortState): IconProp =>
  sortState === SortState.Down ? 'sort-down' : sortState === SortState.Up ? 'sort-up' : 'sort';

export const nextSortState = (sortState: SortState) => {
  if (sortState === 2) return 0;
  return sortState + 1;
};
