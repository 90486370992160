import * as React from 'react';

import Form from 'react-bootstrap/Form';

import Modal from '@/components/Modal';
import {
  CustomField,
  CustomFieldData,
  FieldType,
  FieldTypeTitle,
  FieldTypes,
} from '@/containers/DealWindow/CustomFields/typings';
import { SetState } from '@/typings';

type CustomFieldProps = {
  title: string;
  setShow: SetState<boolean>;
  show: boolean;
};
type NewCustomFieldProps = CustomFieldProps & {
  handleSubmit: (data: CustomFieldData) => Promise<void>;
  customField?: never;
};
type EditCustomFieldsProps = CustomFieldProps & {
  handleSubmit: (data: CustomFieldData, id: number) => Promise<void>;
  customField: CustomField;
};

const CustomFieldModal = (props: NewCustomFieldProps | EditCustomFieldsProps) => {
  const { title, handleSubmit, show, customField } = props;
  const formRef = React.useRef(null);

  const handleClose = () => props.setShow(false);

  const onSubmit = () => {
    if (!formRef.current) return;

    const form = formRef.current;
    const input = form[0] as HTMLInputElement;
    const select = form[1] as HTMLSelectElement;
    const check = form[2] as HTMLInputElement;
    const baseData: CustomFieldData = {
      name: input.value,
      field_type: (select[select.options.selectedIndex] as HTMLOptionElement).value as FieldType,
      privacy: (check.checked ? 'public' : 'private') as 'public' | 'private',
    };
    const data = customField ? baseData : { ...baseData, location: 0, deal_id: undefined };
    if (customField) handleSubmit(data, customField.id);
    else handleSubmit(data);

    props.setShow(false);
  };

  return (
    <Modal
      modalProps={{ show, backdrop: 'static' }}
      title={title}
      onSave={onSubmit}
      onClose={handleClose}
    >
      <Form ref={formRef}>
        <Form.Group className='mb-3' controlId='formCustomFieldName'>
          <Form.Label>Name *</Form.Label>
          <Form.Control type='name' required defaultValue={customField?.name} />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formCustomFieldType'>
          <Form.Label>Type *</Form.Label>
          <Form.Select defaultValue={customField ? customField.fieldType : FieldTypes[0]}>
            {FieldTypes.map((fieldType) => (
              <option key={fieldType} value={fieldType}>
                {FieldTypeTitle[fieldType]}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Label>Privacy</Form.Label>
        <div>
          <Form.Check
            inline
            label='Public'
            name='privacy'
            type='radio'
            defaultChecked={customField ? customField.privacy === 'public' : true}
          />
          <Form.Check
            inline
            label='Private'
            name='privacy'
            type='radio'
            defaultChecked={customField?.privacy === 'private'}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default CustomFieldModal;
