import * as React from 'react';

import Select, { MultiValue } from 'react-select';
import { MenuListProps, components } from 'react-select';
import { FilterOption } from 'typings';

const MultipleSelect = (props: {
  selectedOptions: FilterOption[];
  setSelectedOptions: (arg: FilterOption[]) => void;
  options: FilterOption[];
}) => {
  const { selectedOptions, setSelectedOptions, options } = props;
  return (
    <>
      <Select
        defaultValue={selectedOptions}
        onChange={(newValue) => setSelectedOptions(newValue as FilterOption[])}
        options={options}
        closeMenuOnSelect={false}
        components={{ MenuList }}
        className='w-60 select-filters mr-10'
        isMulti
        getOptionValue={(option) => `${option.id}`}
        getOptionLabel={(option) => option.name}
      />
    </>
  );
};

const MenuList: React.ComponentType<MenuListProps<FilterOption>> = (props) => {
  const { setValue, options, children } = props;
  return (
    <components.MenuList {...props}>
      <div>
        <a
          onClick={() => setValue(options as MultiValue<FilterOption>, 'select-option')}
          className='link ms-2 me-2'
        >
          Select all
        </a>
        <a onClick={() => setValue([], 'select-option')} className='link'>
          Clear all
        </a>
      </div>
      {children}
    </components.MenuList>
  );
};

export default MultipleSelect;
