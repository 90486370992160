import { DataType, RequestMethod, Result } from '@/typings';
import { ajaxResult, fetchResult } from '@/utils';

export const NoteService = {
  index: (deal_id: number, notes_page: number): Promise<Result> => {
    const data = { deal_id, notes_page, q: { text_cont: '' } };
    const req = { url: '/notes', dataType: 'json', method: RequestMethod.Get, data };
    return ajaxResult(req);
  },
  create: (text: string, dealId: number, parentNoteId?: number): Promise<Result> => {
    const data = { note: { text, deal_id: dealId, parent_note_id: parentNoteId ?? null } };
    const url = '/notes/create_from_modal';
    const req = { url, method: RequestMethod.Post, data };
    return ajaxResult(req);
  },
  update: (id: number, text: string): Promise<Result> => {
    const url = `/notes/${id}`;
    const data = { note: { text, id } };
    const req = { url, method: RequestMethod.Patch, data };
    return ajaxResult(req);
  },
  destroy: (id: number): Promise<Result> => {
    const url = `/notes/${id}/destroy_from_modal`;
    const req = { url, method: RequestMethod.Delete };
    return fetchResult(req);
  },
  pin: (id: number): Promise<Result> => {
    const url = `/notes/${id}/pin`;
    const req = { url, method: RequestMethod.Post, dataType: DataType.JSON };
    return fetchResult(req);
  },
};
