import * as React from 'react';
import { ChangeEvent, useContext } from 'react';

import { StasContext } from '@Deals/context/StasContext';

import SearchForm from '@/components/SearchForm';

const SearchDealsForm = () => {
  const { setSearchString } = useContext(StasContext);
  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };
  return (
    <SearchForm
      onChange={handleInput}
      placeholder='Search Deals...'
      className='search-input auto'
      wrapperClassName='mx-4'
    />
  );
};

export default SearchDealsForm;
