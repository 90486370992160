import * as React from 'react';
import { useEffect, useState } from 'react';

import { DealService } from '@/services/deal_service';
import { Error, ReportAllData } from '@/typings';
import { parcelDataToReportAllData } from '@/utils';

const ParcelDataTab = (props: { dealId: number; show: boolean }) => {
  const { dealId, show } = props;
  const [reportAllData, setReportAllData] = useState<ReportAllData | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show && !reportAllData) {
      setError(null);
      setLoading(true);
      getReportAllData();
    }
  }, [dealId, show]);

  const getReportAllData = async () => {
    const res = await DealService.getParcelData(dealId);
    if (res.ok) setReportAllData(parcelDataToReportAllData(res.data));
    else setError(res);
    setLoading(false);
  };

  const parcelData = (reportAllData: ReportAllData) =>
    Array.from(reportAllData).map(([key, value]) => (
      <div key={key} className='d-flex'>
        <p className='w-50'>{key}</p>
        <p className='w-50'>{value}</p>
      </div>
    ));

  if (!show) return null;

  if (loading) {
    return (
      <div className='d-flex justify-content-center'>
        <div className='mt-2 spinner-border align-self-center light-blue-color' />
      </div>
    );
  }

  if (error) {
    return (
      <div className='d-flex justify-content-center'>
        <p className='fs-14 mt-2 silver-color fst-italic'>
          Error fetching Parcel Data: Error status: {error.status}; Error status text:{' '}
          {error.statusText};
        </p>
      </div>
    );
  }

  if (!reportAllData) {
    return (
      <div className='d-flex justify-content-center'>
        <p className='fs-14 mt-2 silver-color fst-italic'>No Parcel Data Retrieved</p>
      </div>
    );
  }

  return (
    <div className='mt-20 ml-20 mr-20'>
      <h2 className='source-sans-semi-bold-font bb-alto mb-15 fs-24'>Parcel Data</h2>
      <div>{parcelData(reportAllData)}</div>
    </div>
  );
};

export default ParcelDataTab;
