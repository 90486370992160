import { Notification } from '@/containers/Notifications/typings';
import { DataType, RequestMethod, Result } from '@/typings';
import { fetchResult } from '@/utils';

export const NotificationService = {
  index: (): Promise<Result<Notification[]>> => {
    const req = { url: '/notifications', method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  update: (id: number, notification: { seen: boolean }): Promise<Result> => {
    const body = JSON.stringify(notification);
    const req = {
      url: `/notifications/${id}`,
      method: RequestMethod.Patch,
      dataType: DataType.JSON,
      body,
    };
    return fetchResult(req);
  },
};
