import { deleteDrawingManager } from '@/scripts/drawing_manager';
import CustomRadius from '@/scripts/map/markers/custom_radius';
import MarkerManager from '@/scripts/map/markers/marker_manager';
import { ParamsNames } from '@/services/deal_service';

import { parseCoordinates } from '.';

export const mapInteractions = (
  paramName: ParamsNames,
  deal: { id: number; latitude: string; longitude: string },
  radius?: string | null
) => {
  const manager = MarkerManager.getInstance();
  switch (paramName) {
    case 'tags':
      manager.tagMarkerManager.clearMarker(deal.id);
      document.dispatchEvent(new Event('render_markers'));
      break;
    case 'radius':
      if (radius) manager.radiusMarker.draw(parseCoordinates(deal), parseInt(radius));
      else manager.radiusMarker.clear();
      break;
    case 'custom_radius':
      deleteDrawingManager();
      if (radius) manager.customRadiusMarker.draw(CustomRadius.stringToCoordinates(radius));
      else manager.customRadiusMarker.clear();
      break;
  }
};
