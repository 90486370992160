import * as React from 'react';
import { useState } from 'react';

// Components
import DetailsSectionHeader from '@/components/DealWindow/Details/SectionHeader';
import DraggableList from '@/components/DraggableList';
import { CustomFieldService } from '@/services/custom_field_service';
import { CustomFieldValueService } from '@/services/custom_field_value_service';
import { DealService } from '@/services/deal_service';
// Other
import { arrayMove, camelize } from '@/utils';

import CustomFieldModal from './CustomFieldModal';
// Containers
import CustomFieldRow from './CustomFieldRow';
import CustomFieldValue from './CustomFieldValue';
// Types
import { CustomField, CustomFieldData } from './typings';

const CustomFields = ({
  dealId,
  customFields,
  setCustomFields,
  sharedMapWindow,
}: {
  dealId: number;
  customFields: CustomField[];
  setCustomFields: (customFields: CustomField[]) => void;
  sharedMapWindow?: boolean;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isExpanded, setExpanded] = useState(true);
  const [isOrderChanged, setOrderChanged] = useState(false);

  const saveCustomFieldsPosition = async () => {
    if (isOrderChanged) {
      const customFieldPositions = customFields.reduce((acc, customField, index) => {
        return { ...acc, [customField.id]: customFields.length - index };
      }, {});
      const res = await DealService.updateCustomFieldsOrderNumber(dealId, customFieldPositions);
      if (res.ok) {
        setOrderChanged(false);
      } else console.error('Error in saveCustomFieldsPosition', res.error);
    }
  };

  const saveCustomField = async (customField: CustomFieldData) => {
    customField = { ...customField, deal_id: dealId };
    const res = await CustomFieldService.createFromDeal(customField);
    if (res.ok) {
      setCustomFields([camelize(res.data) as CustomField, ...customFields]);
    } else console.error('Error in saveCustomField', res.error);
  };

  const updateCustomField = async (data: CustomFieldData, customFieldId: number) => {
    const res = await CustomFieldService.updateFromDeal(data, customFieldId);
    if (res.ok) {
      const newCustomFields = customFields.map((customField) =>
        customField.id === customFieldId
          ? { ...customField, name: data.name, fieldType: data.field_type, privacy: data.privacy }
          : customField
      );
      setCustomFields(newCustomFields);
    } else console.error('error in updateCustomField', res.error);
  };

  const deleteCustomField = async (customFieldId: number) => {
    const res = await CustomFieldService.destroyFromDeal(customFieldId);
    if (res.ok) {
      const newCustomFields = customFields.filter(
        (customField) => customField.id !== customFieldId
      );
      setCustomFields(newCustomFields);
    } else console.error('error in deleteCustomField', res.error);
  };

  const updateCustomFieldValue = async (value: string, valueId: number, customFieldId: number) => {
    const res = await CustomFieldValueService.update(value, valueId, customFieldId, dealId);
    if (res.ok) {
      const newCustomFields = customFields.map((customField) =>
        customField.id === customFieldId ? (camelize(res.data) as CustomField) : customField
      );
      setCustomFields(newCustomFields);
    } else console.error('error in updateCustomField', res.error);
  };

  const createCustomFieldValue = async (value: string, customFieldId: number) => {
    const res = await CustomFieldValueService.create(value, customFieldId, dealId);
    if (res.ok) {
      const newCustomFields = customFields.map((customField) =>
        customField.id === customFieldId ? (camelize(res.data) as CustomField) : customField
      );
      setCustomFields(newCustomFields);
    } else console.error('error in updateCustomField', res.error);
  };

  const deleteCustomFieldValue = async (valueId: number, customFieldId: number) => {
    const res = await CustomFieldValueService.destroy(valueId);
    if (res.ok) {
      const newCustomFields = customFields.map((customField) =>
        customField.id === customFieldId ? { ...customField, value: null } : customField
      );
      setCustomFields(newCustomFields);
    } else console.error('error in updateCustomField', res.error);
  };

  const SortableItem = ({
    value,
    onMouseDown,
    draggableClassName,
  }: {
    value: CustomField;
    onMouseDown: React.MouseEventHandler;
    draggableClassName: string;
  }) => {
    return (
      <li className={'component-deal-custom-field-li ' + draggableClassName}>
        <CustomFieldRow
          customField={value}
          onMouseDown={onMouseDown}
          updateCustomField={updateCustomField}
          deleteCustomField={deleteCustomField}
          sharedMapWindow={sharedMapWindow}
        >
          <CustomFieldValue
            customField={value}
            updateCustomFieldValue={updateCustomFieldValue}
            deleteCustomFieldValue={deleteCustomFieldValue}
            sharedMapWindow={sharedMapWindow}
            createCustomFieldValue={createCustomFieldValue}
            dealId={dealId}
          />
        </CustomFieldRow>
      </li>
    );
  };

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    if (oldIndex === newIndex) return;
    const newCustomFields = arrayMove(customFields, oldIndex, newIndex);
    setCustomFields(newCustomFields);
    setOrderChanged(true);
  };

  return (
    <div className='mt-20 ml-20 mr-20' onMouseLeave={saveCustomFieldsPosition}>
      <DetailsSectionHeader
        title='Custom Fields'
        isBodyExpanded={isExpanded}
        onClick={() => setExpanded(!isExpanded)}
      >
        {!sharedMapWindow && (
          <p className='pointer light-blue-color m-0' onClick={() => setShowModal(true)}>
            + Add Custom Field
          </p>
        )}
      </DetailsSectionHeader>
      {isExpanded && (
        <div>
          <DraggableList
            items={customFields}
            onReorder={onSortEnd}
            Container={({ children }) => <ul className='ps-0'>{children}</ul>}
            Item={SortableItem}
          />
        </div>
      )}
      <CustomFieldModal
        title='Add Custom Field'
        handleSubmit={saveCustomField}
        setShow={setShowModal}
        show={showModal}
      />
    </div>
  );
};

export default CustomFields;
