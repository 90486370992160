import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { PinType } from '@/typings';

export const getPinIcon = (pinIcon: PinType | undefined): IconProp => {
  switch (pinIcon) {
    case PinType.Star:
      return 'star';
    case PinType.Pin:
      return 'map-pin';
    case PinType.Marker:
      return 'map-marker-alt';
    default:
      console.error('Sorry, something went wrong with getPinIcon function');
      return 'question-circle';
  }
};
