import React, { useEffect, useState } from 'react';

import ErrorComponent from '@/components/ErrorComponent';
import { HeaderBlockEnd } from '@/components/HeaderBlock';
import SearchForm from '@/components/SearchForm';
import Table from '@/components/Settings/Table';
import TableBody from '@/components/Table/TableBody';
import TextWithIconBtn from '@/components/TextWithIconBtn';
import { useErrorState, useFetchInitial } from '@/hooks';
import { PinService } from '@/services/pin_service';
import { UserService } from '@/services/user_service';
import { Pin, PinType } from '@/typings';

import PinModal from './PinModal';
import PinRow from './PinRow';

const Pins = (props: { show: boolean; setHeader: (elem: JSX.Element) => void }) => {
  const { show, setHeader } = props;
  const [errors, addError, removeError] = useErrorState();
  const [searchString, setSearchString] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [pins, setPins] = useState<(Pin & { active: boolean; reminderDuration: number | null })[]>(
    []
  );

  const currentUser = useFetchInitial(
    addError,
    UserService.current,
    'Error while fetching currentUser:',
    false
  );

  useEffect(() => {
    if (show)
      setHeader(
        <>
          <SearchForm
            onChange={(e) => setSearchString($(e.target)!.val()!.toString())}
            placeholder='Search Pins...'
            className='search-input'
            wrapperClassName='mx-4'
          />

          <HeaderBlockEnd>
            <TextWithIconBtn
              icon='plus-circle'
              text='Add Pin'
              onClick={() => setShowAddModal(true)}
              className='box-shadow add-new-btn'
            />
          </HeaderBlockEnd>
        </>
      );
  }, [show]);

  useEffect(() => {
    getPins();
  }, [searchString]);

  const getPins = async () => {
    const res = await PinService.index({ name_cont: searchString });
    if (res.ok) setPins(res.data);
    else addError('Error in getPins:', res);
  };

  const savePin = async (pin: {
    name: string;
    color: string;
    icon: PinType;
    reminderDuration: number | null;
  }) => {
    const res = await PinService.create(pin);
    if (res.ok) setPins([{ ...pin, id: res.data, active: true }, ...pins]);
    else addError('Error in savePin:', res);
  };

  const updatePin = async (
    pin: Pin & {
      reminderDuration: number | null;
    }
  ) => {
    const res = await PinService.update(pin);
    if (res.ok)
      setPins(
        pins.map((oldPin) => (oldPin.id === pin.id ? { ...pin, active: oldPin.active } : oldPin))
      );
    else addError('Error in updatePin:', res);
  };

  const archivePin = async (id: number) => {
    if (
      !(await window.customConfirm(
        'Archive Pin',
        'Are you sure that you want to archive this pin?'
      ))
    )
      return;
    const res = await PinService.destroy(id);
    if (res.ok)
      setPins(pins.map((oldPin) => (oldPin.id === id ? { ...oldPin, active: false } : oldPin)));
    else addError('Error in archivePin:', res);
  };

  const restorePin = async (id: number) => {
    if (
      !(await window.customConfirm(
        'Restore Pin',
        'Are you sure that you want to restore this pin?'
      ))
    )
      return;
    const res = await PinService.restore(id);
    if (res.ok)
      setPins(pins.map((oldPin) => (oldPin.id === id ? { ...oldPin, active: true } : oldPin)));
    else addError('Error in restorePin:', res);
  };

  return (
    <div>
      <PinModal show={showAddModal} setShow={setShowAddModal} title='Add Pin' onSave={savePin} />
      <ErrorComponent errors={errors} removeError={removeError} />

      <Table title='Pins'>
        <thead>
          <tr className='table-header-font'>
            <th className='w-25'>Name</th>
            <th className='w-25'>Color</th>
            <th className='w-25'>Icon</th>
            <th className='w-25'>Reminder Time Duration</th>
            <th />
          </tr>
        </thead>

        <TableBody isResultPresent={pins.length > 0} columnsLength={5}>
          {pins.map((pin) => (
            <PinRow
              key={pin.id}
              pin={pin}
              isAdmin={currentUser?.role.value === 'admin'}
              onUpdate={updatePin}
              onArchive={archivePin}
              onRestore={restorePin}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default Pins;
