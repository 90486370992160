import * as React from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

import Cookies from 'js-cookie';

import { DealService } from '@/services/deal_service';
import { FilterOption, RequestMethod, SetState, SortMethod } from '@/typings';
import { camelize } from '@/utils';

import { FiltersOptions, StaDeal, StaState, TabName } from '../typings';
import {
  AvailableFilters,
  SetFiltersOptions,
  defaultFilters,
  defaultFiltersState,
} from '../typings';
import { ActiveTabContext } from './ActiveTabContext';
import { ErrorContext } from './ErrorContext';
import { LoadingContext } from './LoadingContext';

type StasState = {
  staState: StaState;
  setStaState: SetState<StaState>;
  expandedRows: Array<number> | null;
  setExpandedRows?: (ids: number[]) => void;
  getSta: () => void;
  starDeal: Function;
  searchString: string;
  setSearchString: (str: string) => void;
  brokerId: number | null;
  setBrokerId: SetState<number | null>;
  availableFilters: AvailableFilters;
  selectedFilters: FiltersOptions;
  setSelectedFilters: SetFiltersOptions;
  savedFilters: FiltersOptions;
  setAllSelectedFilters: SetState<FiltersOptions>;
  setAllSavedFilters: SetState<FiltersOptions>;
  setAvailableFilters: SetState<AvailableFilters>;
  restoreExpandedRows: () => void;
};

const defaultState = Object.freeze({
  sta: null,
  pages: {
    current: 1,
    total: null,
    perPage: 10,
  },
  sort: {
    entity: 'id',
    method: SortMethod.Desc,
  },
});

export const StasContext = createContext<StasState>(null as unknown as StasState);

let controller: AbortController | undefined;
export const StasProvider: React.FC = ({ children }) => {
  const { activeTab } = useContext(ActiveTabContext);
  const { addError } = useContext(ErrorContext);
  const { loading } = useContext(LoadingContext);
  const [staAllState, setStaAllState] = useState<StaState>(defaultState);
  const [staStarredState, setStaStarredState] = useState<StaState>(defaultState);
  const [nonStaState, setNonStaState] = useState<StaState>(defaultState);
  const [activeStaState, setActiveStaState] = useState<StaState>(defaultState);
  const [allDealsState, setAllDealsState] = useState<StaState>(defaultState);

  const [expandedRowsAll, _setExpandedRowsAll] = useState<number[]>([]);
  const setExpandedRowsAll = (ids: number[]) => {
    _setExpandedRowsAll(ids);
    Cookies.set('expandedRowsAll', JSON.stringify(ids));
  };
  const [expandedRowsActive, _setExpandedRowsActive] = useState<number[]>([]);
  const setExpandedRowsActive = (ids: number[]) => {
    _setExpandedRowsActive(ids);
    Cookies.set('expandedRowsActive', JSON.stringify(ids));
  };
  const [expandedRowsStarred, _setExpandedRowsStarred] = useState<number[]>([]);
  const setExpandedRowsStarred = (ids: number[]) => {
    _setExpandedRowsStarred(ids);
    Cookies.set('expandedRowsStarred', JSON.stringify(ids));
  };
  const [expandedRowsAllDeals, _setExpandedRowsAllDeals] = useState<number[]>([]);
  const setExpandedRowsAllDeals = (ids: number[]) => {
    _setExpandedRowsAllDeals(ids);
    Cookies.set('expandedRowsAllDeals', JSON.stringify(ids));
  };

  const restoreExpandedRows = () => {
    setExpandedRowsAll(JSON.parse(Cookies.get('expandedRowsAll') ?? '[]'));
    setExpandedRowsActive(JSON.parse(Cookies.get('expandedRowsActive') ?? '[]'));
    setExpandedRowsAllDeals(JSON.parse(Cookies.get('expandedRowsAllDeals') ?? '[]'));
    setExpandedRowsStarred(JSON.parse(Cookies.get('expandedRowsStarred') ?? '[]'));
  };

  const [searchString, _setSearchString] = useState<string>('');
  const setSearchString = (str: string) => {
    setStaAllState({
      ...staAllState,
      pages: { ...staAllState.pages, current: 1 },
    });
    setStaStarredState({
      ...staStarredState,
      pages: { ...staStarredState.pages, current: 1 },
    });
    setNonStaState({
      ...nonStaState,
      pages: { ...nonStaState.pages, current: 1 },
    });
    setActiveStaState({
      ...activeStaState,
      pages: { ...activeStaState.pages, current: 1 },
    });
    _setSearchString(str);
  };
  const [brokerId, setBrokerId] = useState<number | null>(null);

  const [setStaState, staState, expandedRows, setExpandedRows] = React.useMemo(() => {
    switch (activeTab) {
      case TabName.WorkedSta:
        return [setStaAllState, staAllState, expandedRowsAll, setExpandedRowsAll];
      case TabName.NonSta:
        return [setNonStaState, nonStaState, null, undefined];
      case TabName.ActiveSta:
        return [setActiveStaState, activeStaState, expandedRowsActive, setExpandedRowsActive];
      case TabName.AllDeals:
        return [setAllDealsState, allDealsState, expandedRowsAllDeals, setExpandedRowsAllDeals];
      default:
        return [setStaStarredState, staStarredState, expandedRowsStarred, setExpandedRowsStarred];
    }
  }, [
    activeTab,
    activeStaState,
    staAllState,
    staStarredState,
    allDealsState,
    nonStaState,
    expandedRowsActive,
    expandedRowsAll,
    expandedRowsStarred,
    expandedRowsAllDeals,
  ]);

  const [availableFilters, setAvailableFilters] = useState<AvailableFilters>(defaultFilters);

  const [selectedFilters, setAllSelectedFilters] = useState<FiltersOptions>(
    defaultFiltersState.selectedFilters
  );

  const setFilters = (setFilters: SetState<FiltersOptions>, filters: FiltersOptions) => ({
    cities: (cities: FilterOption[]) => setFilters({ ...filters, cities }),
    tags: (tags: FilterOption[]) => setFilters({ ...filters, tags }),
    opportunities: (opportunities: FilterOption[]) => setFilters({ ...filters, opportunities }),
    states: (states: FilterOption[]) => setFilters({ ...filters, states }),
    users: (users: FilterOption[]) => setFilters({ ...filters, users }),
    followers: (followers: FilterOption[]) => setFilters({ ...filters, followers }),
    zips: (zips: FilterOption[]) => setFilters({ ...filters, zips }),
    pins: (pins: FilterOption[]) => setFilters({ ...filters, pins }),
    brokers: (brokers: FilterOption[]) => setFilters({ ...filters, brokers }),
    transactionTypes: (transactionTypes: FilterOption[]) =>
      setFilters({ ...filters, transactionTypes }),
  });

  const setSelectedFilters = setFilters(setAllSelectedFilters, selectedFilters);
  const [savedFilters, setAllSavedFilters] = useState<FiltersOptions>(
    defaultFiltersState.savedFilters
  );

  useEffect(() => {
    controller?.abort();
    controller = new AbortController();
    getSta(controller);
  }, [
    activeTab,
    staState.pages.current,
    staState.pages.perPage,
    savedFilters,
    staState.sort,
    searchString,
    brokerId,
  ]);

  const getSta = (controller?: AbortController) => {
    loading(async () => {
      try {
        const res = await DealService.tableDeals(
          activeTab,
          staState.pages.current,
          staState.pages.perPage,
          savedFilters,
          staState.sort,
          searchString,
          brokerId,
          controller
        );
        if (res.ok) {
          const sta = camelize(res.data.sta) as StaDeal[];
          setStaState({
            ...staState,
            sta,
            pages: { ...staState.pages, total: res.data.total_pages },
          });
        } else {
          addError('Error in getSta: ', {
            status: res.error.status,
            statusText: res.error.statusText,
            error: res,
          });
        }
      } catch (e) {
        console.error(e);
      }
    });
  };

  const starDeal = (dealId: number, isStarred: boolean) => {
    switch (activeTab) {
      case TabName.WorkedSta:
        setStaStarredState({ ...staStarredState, sta: null });
        setNonStaState({ ...nonStaState, sta: null });
        setActiveStaState({ ...activeStaState, sta: null });
        break;
      case TabName.NonSta:
        setStaStarredState({ ...staStarredState, sta: null });
        setStaAllState({ ...staAllState, sta: null });
        setActiveStaState({ ...activeStaState, sta: null });
        break;
      case TabName.ActiveSta:
        setStaStarredState({ ...staStarredState, sta: null });
        setStaAllState({ ...staAllState, sta: null });
        setNonStaState({ ...nonStaState, sta: null });
        break;
      default:
        setStaAllState({ ...staAllState, sta: null });
        setNonStaState({ ...nonStaState, sta: null });
        setActiveStaState({ ...activeStaState, sta: null });
    }
    const data = {
      deal_id: dealId,
      is_starred: isStarred,
    };
    const req = {
      url: '/ajax-deal-update-starred',
      method: RequestMethod.Patch,
      data,
    };
    loading(async () => {
      try {
        await $.ajax(req);
      } catch (error: any) {
        addError('Error in starDeal: ', {
          status: error.status,
          statusText: error.statusText,
          error,
        });
      }
      getSta();
    });
  };

  return (
    <StasContext.Provider
      value={{
        staState,
        setStaState,
        expandedRows,
        setExpandedRows,
        getSta,
        starDeal,
        searchString,
        setSearchString,
        brokerId,
        setBrokerId,
        availableFilters,
        selectedFilters,
        setSelectedFilters,
        savedFilters,
        setAllSelectedFilters,
        setAllSavedFilters,
        setAvailableFilters,
        restoreExpandedRows,
      }}
    >
      {children}
    </StasContext.Provider>
  );
};
