import { CookiesService } from '@/services/cookies_service';

export const initTagsExploreBtn = () => {
  $('.js-tags-explore-btn')
    .off('click')
    .on('click', () => {
      setTagsLayout(!CookiesService.showTags.get());
    });
  syncTagsCheckbox();
};

export const setTagsLayoutBtnVisibility = (showTags: boolean) => {
  if (showTags) $('.js-tags-explore-btn').removeClass('d-none');
  else $('.js-tags-explore-btn').addClass('d-none');
  if (!showTags) setTagsLayout(showTags);
};

const setTagsLayout = (showTags: boolean) => {
  CookiesService.showTags.set(showTags);
  document.dispatchEvent(new Event('render_markers'));
  syncTagsCheckbox();
};

const syncTagsCheckbox = () => {
  const $tagsLayoutBtn = $('.js-tags-explore-btn');
  const showTags = CookiesService.showTags.get();
  if (showTags) {
    $tagsLayoutBtn.addClass('js-tags-on');
    $tagsLayoutBtn.removeClass('js-tags-off');
  } else {
    $tagsLayoutBtn.addClass('js-tags-off');
    $tagsLayoutBtn.removeClass('js-tags-on');
  }
  $tagsLayoutBtn.find('input:checkbox:first').prop('checked', showTags);
  $('.shared-map-content')
    .find('.tags-checkbox')
    .find('input:checkbox:first')
    .prop('checked', showTags);
};

export const initPinsExploreBtn = () => {
  $('.js-pins-explore-btn')
    .off('click')
    .on('click', () => {
      const showPins = CookiesService.showPins.get();
      CookiesService.showPins.set(!showPins);
      syncPinsCheckbox();
      document.dispatchEvent(new Event('render_markers'));
      setTagsLayoutBtnVisibility(!showPins);
    });
  syncPinsCheckbox();
};

const syncPinsCheckbox = () => {
  const $pinsLayoutBtn = $('.js-pins-explore-btn');
  const showPins = CookiesService.showPins.get();
  if (showPins) {
    $pinsLayoutBtn.addClass('js-pins-on');
    $pinsLayoutBtn.removeClass('js-pins-off');
  } else {
    $pinsLayoutBtn.addClass('js-pins-off');
    $pinsLayoutBtn.removeClass('js-pins-on');
  }
  $pinsLayoutBtn.find('input:checkbox:first').prop('checked', showPins);
  $('.shared-map-content')
    .find('.pins-checkbox')
    .find('input:checkbox:first')
    .prop('checked', showPins);
};
