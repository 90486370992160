import * as React from 'react';
import { useEffect, useState } from 'react';

import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';

import Modal from '@/components/Modal';
import { DealResource } from '@/containers/DealResources';
import { DealResourceService } from '@/services/deal_resource_service';
import { City, Option } from '@/typings';

const AddResourceModal = (props: {
  show: boolean;
  setShow: (bool: boolean) => void;
  deal: { id: number; city: string; state: string };
  cities: City[];
  states: string[];
  resources: DealResource[];
  addResource: (city: Option, state: string, addAll: boolean, id: number | null) => void;
}) => {
  const { show, deal, cities, states, setShow, addResource } = props;
  const dealCity = cities.find((city) => city.name == deal.city) ?? null;
  const [filters, setFilters] = useState({ city: dealCity, state: deal.state });
  const [resources, setResources] = useState<Option[]>([]);
  const [resource, setResource] = useState<Option | null>(null);
  const [loading, setLoading] = useState(true);
  const [addAll, setAddAll] = useState(false);

  const handleSave = async () => {
    if (!filters.state || !filters.city) return alert('Please choose city and state');

    if (resource || addAll) {
      addResource(filters.city, filters.state, addAll, resource?.id ?? null);
      setFilters({ city: dealCity, state: deal.state });
      setResource(null);
      setAddAll(false);
    } else alert('Please choose Resource');
  };

  const handleClose = () => {
    setFilters({ city: dealCity, state: deal.state });
    setResource(null);
    setAddAll(false);
    setShow(false);
  };

  useEffect(() => {
    if (show) getResources(filters.city, filters.state);
  }, [filters.city, filters.state, show]);

  const getResources = async (city: Option | null, state: string) => {
    setLoading(true);
    const res = await DealResourceService.findByCityAndState(city, state);
    if (res.ok) {
      setResources(
        res.data.resources.filter(
          (resource) => !props.resources.map((r) => r.id).includes(resource.id)
        )
      );
    } else console.error(res);
    setLoading(false);
  };

  return (
    <Modal
      modalProps={{ show, backdrop: true }}
      title='Add Resource'
      onSave={handleSave}
      onClose={handleClose}
    >
      <>
        <Form.Group className='mb-3'>
          <Row>
            <Col>
              <Form.Label>City</Form.Label>
              <Select
                value={filters.city}
                onChange={(city) => setFilters({ ...filters, city, state: city?.state ?? '' })}
                options={cities.filter((city) =>
                  filters.state ? city.state === filters.state : true
                )}
                closeMenuOnSelect
                getOptionValue={(option) => option.id.toString()}
                getOptionLabel={(option) => option.name}
              />
            </Col>
            <Col>
              <Form.Label>State</Form.Label>
              <Select
                value={filters.state ? toSelectOption(filters.state) : null}
                onChange={(newValue) =>
                  setFilters({
                    ...filters,
                    state: newValue?.value ?? '',
                    city: newValue?.value === filters.city?.state ? filters.city : null,
                  })
                }
                isClearable
                options={states.map(toSelectOption)}
                closeMenuOnSelect
              />
            </Col>
          </Row>
        </Form.Group>

        {!!filters.city && !!filters.state && (
          <Form.Group className='mb-3'>
            <Form.Label>Select Resource</Form.Label>
            <Select
              isDisabled={addAll}
              isClearable
              isLoading={loading}
              value={resource}
              onChange={(newValue) => setResource(newValue)}
              options={resources}
              closeMenuOnSelect
              getOptionValue={(option) => option.id.toString()}
              getOptionLabel={(option) => option.name}
            />
          </Form.Group>
        )}

        <Form.Group className='mb-3'>
          <Form.Check
            checked={addAll}
            label='Add all resources for selected city and state'
            id='add-all'
            onChange={() => {
              if (!addAll) setResource(null);
              setAddAll(!addAll);
            }}
          />
        </Form.Group>
      </>
    </Modal>
  );
};

export default AddResourceModal;

const toSelectOption = (str: string) => ({ label: str, value: str });
