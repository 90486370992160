import React, { useState } from 'react';

import { Form } from 'react-bootstrap';

import Modal from '@/components/Modal';
import { useEffectNotFirstRender } from '@/hooks';
import { Option } from '@/typings';

const CompanyModal = (props: {
  title: string;
  show: boolean;
  setShow: (bool: boolean) => void;
  initCompany?: Option;
  onSave: (name: string) => void;
}) => {
  const { title, show, setShow, initCompany, onSave } = props;
  const [name, setName] = useState(initCompany?.name);
  const [validated, setValidated] = useState(false);

  useEffectNotFirstRender(() => setName(initCompany?.name), [initCompany]);

  const handleClose = () => {
    setValidated(false);
    setName(initCompany?.name);
    setShow(false);
  };

  const handleSave = () => {
    if (!name) {
      setValidated(true);
      return;
    }
    onSave(name);
    handleClose();
  };

  return (
    <Modal
      onSave={handleSave}
      onClose={handleClose}
      title={title}
      modalProps={{ backdrop: 'static', show }}
    >
      <Form.Group className='mb-3'>
        <Form.Label>Name *</Form.Label>
        <Form.Control
          type='text'
          placeholder='Name'
          defaultValue={name}
          onChange={(e) => setName(e.target.value?.trim())}
          isInvalid={validated && !name}
        />
        <Form.Control.Feedback type='invalid'>Name is required</Form.Control.Feedback>
      </Form.Group>
    </Modal>
  );
};

export default CompanyModal;
