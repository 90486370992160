import { DataType, Option, RequestMethod, Result } from '@/typings';
import { fetchResult } from '@/utils';

export type Opportunity = Option & { active: boolean; logo: string };
export const OpportunityService = {
  index: (q: Record<string, string>): Promise<Result<Opportunity[]>> => {
    const body = $.param({ q });
    const url = '/opportunities?' + body;
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  options: (): Promise<Result<Option[]>> => {
    const url = '/opportunities/options';
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  create: (name: string, logo?: File): Promise<Result<Opportunity>> => {
    const body = new FormData();
    body.append('opportunity[name]', name);
    if (logo) body.append('opportunity[logo]', logo);
    const url = '/opportunities';
    const req = { url, method: RequestMethod.Post, body };
    return fetchResult(req);
  },
  update: (opportunity: Option, logo?: File): Promise<Result<Opportunity>> => {
    const body = new FormData();
    body.append('opportunity[name]', opportunity.name);
    if (logo) body.append('opportunity[logo]', logo);
    const url = `/opportunities/${opportunity.id}`;
    const req = { url, method: RequestMethod.Put, body };
    return fetchResult(req);
  },
  destroy: (id: number): Promise<Result<Opportunity>> => {
    const url = `/opportunities/${id}`;
    const req = { url, method: RequestMethod.Delete, dataType: DataType.JSON };
    return fetchResult(req);
  },
  restore: (id: number): Promise<Result<Opportunity>> => {
    const url = `/opportunities/${id}/restore`;
    const req = { url, method: RequestMethod.Put, dataType: DataType.JSON };
    return fetchResult(req);
  },
};
