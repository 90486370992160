import * as React from 'react';

import { Form, FormProps } from 'react-bootstrap';
import M, { ModalProps } from 'react-bootstrap/Modal';

import style from './style.module.scss';

const Modal = (props: {
  modalProps: ModalProps;
  onSave: (e: React.FormEvent<HTMLFormElement>) => void;
  onClose: () => void;
  title: string;
  children: React.ReactNode | React.ReactNode[];
  formProps?: FormProps & {
    ref?: React.RefObject<HTMLFormElement>;
  };
}) => {
  const { modalProps, formProps, title, children, onSave, onClose } = props;
  return (
    <M {...modalProps} onHide={onClose}>
      <M.Header closeButton>
        <M.Title>{title}</M.Title>
      </M.Header>
      <Form
        {...formProps}
        onSubmit={(e) => {
          e.preventDefault();
          onSave(e);
        }}
      >
        <M.Body>{children}</M.Body>
        <M.Footer>
          <button type='button' className={style.cancelBtn} onClick={onClose}>
            Cancel
          </button>
          <button type='submit' className={style.saveBtn}>
            Save
          </button>
        </M.Footer>
      </Form>
    </M>
  );
};

export default Modal;
