export const initContextMenu = (map: google.maps.Map) => {
  const element = document.querySelector('.context-menu') as HTMLElement;
  google.maps.event.addListener(map, 'rightclick', (e: MapMouseEvent) => {
    $(element).css({
      display: 'block',
      left: e.pixel.x,
      top: e.pixel.y,
    });
  });
  return element;
};

export const hideContextMenu = () => {
  $('.context-menu').css({ display: 'none' });
};
