import { SharedMap } from '@/containers/SharedMaps/SharedMaps';
import { DataType, RequestMethod, Result, SortObject } from '@/typings';
import { fetchResult } from '@/utils';

export const SharedMapService = {
  index: (
    current_page: number,
    per_page: number,
    searchString: string,
    sort: SortObject
  ): Promise<Result<{ admin: boolean; shared_maps: SharedMap[]; total_pages: number }>> => {
    const q = { name_cont: searchString, s: `${sort.entity} ${sort.method}` };

    const body = $.param({ q, current_page, per_page });
    const url = '/shared_maps?' + body;
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  info: (remote_link: string): Promise<Result> => {
    const body = $.param({ remote_link });
    const url = '/ajax-shared-map-info?' + body;
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
};
