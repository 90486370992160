// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/webpack and only use these pack files to reference
// that code so it'll be compiled.
import 'channels';
import 'flatpickr/dist/flatpickr.js';
import 'jquery-ui/ui/widgets/sortable';
// vendor
import 'scripts/bootstrap-5/bootstrap.js';
import 'selectize/dist/js/selectize.min.js';
import Turbolinks from 'turbolinks';

import '@claviska/jquery-minicolors';
import '@claviska/jquery-minicolors/jquery.minicolors.css';
import '@fortawesome/fontawesome-free/css/all';
// node modules
import '@fortawesome/fontawesome-free/js/all';
import '@fortawesome/fontawesome-pro/css/all';
import '@fortawesome/fontawesome-pro/js/all';
import * as ActiveStorage from '@rails/activestorage';
import Rails from '@rails/ujs';

import '../scripts/custom_select';
import '../scripts/deal';
import '../scripts/flash_messages';
import '../scripts/flatpickr';
import '../scripts/map';
import '../scripts/selectize';
import '../scripts/shared_map';
import '../scripts/tagify';
import '../scripts/task';
import '../scripts/user';
import '../stylesheets/application.scss';
// scripts
import '../utils/clipboard';

global.$ = jQuery;

Rails.start();
Turbolinks.start();
ActiveStorage.start();

const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

// Support component names relative to this directory:
var componentRequireContext = require.context('containers', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);
ReactRailsUJS.detectEvents();
