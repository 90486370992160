import { DealOption } from '@/containers/DealWindow/typings';
import { DealService } from '@/services/deal_service';

import { openSavedDealWindow } from './deal';
import { openNewDealWindow } from './deal/open_new';

let searchMarkers: google.maps.Marker[] = [];
export const searchDeal = (map: google.maps.Map) => {
  const input = document.querySelector('input#js-navbar-search') as HTMLInputElement;
  const searchBox = new google.maps.places.SearchBox(input);

  const main = document.querySelector('div.main');
  const wrapper = document.createElement('div');
  wrapper.classList.add('pac-wrapper');
  if (main) main.appendChild(wrapper);

  let customPacItems: HTMLElement[] = [];

  input.addEventListener('click', () => {
    let recentSearches: string[] = JSON.parse(localStorage.getItem('recentSearches') || '[]');

    if (recentSearches.length > 0) {
      recentSearches.reverse();

      // Remove previous custom pac-items
      customPacItems.forEach((customPacItem) => {
        wrapper.removeChild(customPacItem);
      });

      customPacItems = [];

      // Add new custom pac-items
      recentSearches.slice(-5).forEach((searchValue) => {
        const customPacItem = document.createElement('div');
        customPacItem.classList.add('pac-item', 'recent-search', 'custom-pac-item');

        const iconSpan = document.createElement('span');
        iconSpan.classList.add('pac-icon', 'fas', 'fa-clock', 'recent-searches-icon');
        customPacItem.appendChild(iconSpan);

        const querySpan = document.createElement('span');

        const commaIndex = searchValue.indexOf(',');
        const firstPart = searchValue.slice(0, commaIndex + 1);
        const secondPart = searchValue.slice(commaIndex + 1);

        const firstPartSpan = document.createElement('span');
        firstPartSpan.textContent = firstPart;

        querySpan.appendChild(firstPartSpan);

        customPacItem.appendChild(querySpan);

        const addressSpan = document.createElement('span');
        addressSpan.innerHTML = secondPart;
        customPacItem.appendChild(addressSpan);

        customPacItem.addEventListener('click', () => {
          input.value = searchValue;
          searchBoxPlaceChangedCallback();
        });

        wrapper.appendChild(customPacItem);

        customPacItems.push(customPacItem);
      });

      wrapper.style.display = 'block';
    }
  });

  input.addEventListener('input', () => {
    const inputText = input.value.trim();
    if (inputText !== '') {
      wrapper.style.display = 'none';
    } else {
      wrapper.style.display = 'block';
    }
  });

  document.addEventListener('click', (event) => {
    const wrapper = document.querySelector('.pac-wrapper') as HTMLElement;
    let input = document.querySelector('input#js-navbar-search') as HTMLInputElement;
    const target = event.target as HTMLElement;

    if (
      !target.classList.contains('pac-item') &&
      !input.contains(target) &&
      !target.classList.contains('navbar-search-input')
    ) {
      wrapper.style.display = 'none';
    }
  });

  map.addListener('bounds_changed', () => searchBox.setBounds(map.getBounds() ?? null));

  $('.js-navbar-form')
    .off('submit')
    .on('submit', (e) => {
      e.preventDefault();
      e.stopPropagation();
      clearSearchMarkers();

      const searchQuery = $(input).val() as string;
      if (searchQuery === '') return;

      if (isCoordinates(searchQuery)) {
        disableSearchBox(() => {
          const coordinates = parseCoordinates(searchQuery).map((coord) => parseFloat(coord));
          const latLng = new google.maps.LatLng(coordinates[0], coordinates[1]);
          openDealWindow(latLng, map);
        });
      } else if (isParcelId(searchQuery)) {
        disableSearchBox(() => {
          const res = $.getJSON(
            `https://reportallusa.com/api/parcels?client=${
              REP.ClientKey
            }&v=4&region=USA&parcel_id=${strip(searchQuery)}`
          );
          res.then((data) => {
            const results = data.results;
            if (results.length === 1) {
              const place = results[0];
              const latLng = new google.maps.LatLng(place.latitude, place.longitude);
              openDealWindow(latLng, map, null, place.mail_address1);
            } else if (results.length > 1) {
              openParcelIdModal(results, map);
            }
          });
        });
      } else {
        disableSearchBox(async () => {
          const res = await DealService.search({ nickname_start_all: searchQuery.trim() });
          if (res.ok) {
            if (res.data.deals.length > 0) return openNicknameModal(res.data.deals);
          } else console.error('Error in global search:', res);

          const request = {
            query: searchQuery,
            fields: ['formatted_address', 'geometry', 'place_id', 'name', 'icon'],
            location: map.getCenter(),
            radius: 5000,
            locationBias: map.getBounds(),
          };
          const service = new google.maps.places.PlacesService(map);
          service.textSearch(request, function (places, status) {
            if (status === google.maps.places.PlacesServiceStatus.OK && places) {
              renderPlaces(places, map);
            }
          });
        });
      }
    });

  searchBox.addListener('places_changed', searchBoxPlaceChangedCallback);

  function searchBoxPlaceChangedCallback() {
    const places = searchBox.getPlaces();

    if (places) {
      renderPlaces(places, map);

      const searchValue = input.value.trim();
      let recentSearches: string[] = JSON.parse(localStorage.getItem('recentSearches') || '[]');
      if (searchValue && !recentSearches.includes(searchValue)) {
        recentSearches.push(searchValue);

        if (recentSearches.length > 5) {
          recentSearches.splice(0, recentSearches.length - 5);
        }
        localStorage.setItem('recentSearches', JSON.stringify(recentSearches));
      }
    }
  }

  // do not trigger searchbox place_changed event during function execution
  async function disableSearchBox(fn: Function) {
    google.maps.event.clearInstanceListeners(searchBox);
    await fn();
    setTimeout(() => {
      searchBox.addListener('places_changed', searchBoxPlaceChangedCallback);
    }, 1000);
  }
};

export const clearSearchMarkers = () => {
  searchMarkers.forEach((marker) => marker.setMap(null));
  searchMarkers = [];
};

const openParcelIdModal = (results: any, map: google.maps.Map) => {
  const [addresses, latLngs] = parseREPPlaces(results);
  const addressesHTML = addresses
    .map(
      (address, i) =>
        `<div class="form-check">` +
        `<input class="form-check-input" type="radio" name="addresses" id="${i}">` +
        `<label class="form-check-label" for="${i}">${address}</label>` +
        `</div>`
    )
    .join('');
  const $modal = $('#staticBackdropParcelIdSearch');
  const $body = $modal.find('.modal-body');
  $body.find('div').html(addressesHTML);
  $modal.modal('show');
  const $submitBtn = $modal.find('.modal-submit-btn');
  $submitBtn.on('click', () => {
    const selectedCheckbox = $body
      .find('.form-check-input')
      .toArray()
      .find((el) => $(el).prop('checked'));
    if (selectedCheckbox) {
      openDealWindow(
        latLngs[parseInt(selectedCheckbox.id)],
        map,
        null,
        results[parseInt(selectedCheckbox.id)]?.mail_address1
      );
      $modal.modal('hide');
    }
  });
};

const openNicknameModal = (deals: DealOption[]) => {
  if (deals.length === 1) return openSavedDealWindow(deals[0]);

  const addressesHTML = deals
    .map(
      (deal) =>
        `<div class="form-check">` +
        `<input class="form-check-input" type="radio" name="addresses" id="${deal.id}">` +
        `<label class="form-check-label" for="${deal.id}">${deal.name}</label>` +
        `</div>`
    )
    .join('');
  const $modal = $('#staticBackdropNicknameSearch');
  const $body = $modal.find('.modal-body');
  $body.find('div').html(addressesHTML);
  $modal.modal('show');
  const $submitBtn = $modal.find('.modal-submit-btn');
  $submitBtn.on('click', () => {
    const selectedCheckbox = $body
      .find('.form-check-input')
      .toArray()
      .find((el) => $(el).prop('checked'));

    if (selectedCheckbox) {
      const deal = deals.find((deal) => deal.id === parseInt(selectedCheckbox.id))!;
      openSavedDealWindow(deal);
      $modal.modal('hide');
    }
  });
};

const setSearchOnSharedMap = (lat: number, lng: number, formattedAddress: string | null) => {
  const sharedMap = $('.shared-map-content');
  const search = $('#js-navbar-search').val();
  if (search) sharedMap.find('.js-search').val(search);
  if (formattedAddress) sharedMap.find('.js-search-formatted').val(formattedAddress);
  sharedMap.find('.js-search-latitude').val(lat);
  sharedMap.find('.js-search-longitude').val(lng);
};

const openDealWindow = (
  latLng: google.maps.LatLng,
  map: google.maps.Map,
  place?: google.maps.places.PlaceResult | null,
  name?: string
) => {
  const latitude = latLng.lat();
  const longitude = latLng.lng();
  const formatted_address = place?.formatted_address ?? null;
  $.get({
    url: '/ajax-deal-exist',
    dataType: 'json',
    data: {
      latitude,
      longitude,
      formatted_address,
      name,
    },
    success: (data) => {
      const deal = { id: data.deal_id, lat: latitude, lng: longitude };
      map.setZoom(17);
      if (data.saved) openSavedDealWindow(deal);
      else openNewDealWindow(latLng, place);
    },
  });
  setSearchOnSharedMap(latitude, longitude, formatted_address);
};

const renderPlaces = (places: google.maps.places.PlaceResult[], map: google.maps.Map) => {
  clearSearchMarkers();
  if (places.length === 1) {
    const place = places[0];

    if (place.geometry?.location) openDealWindow(place.geometry.location, map, place);
  } else if (places.length > 1) {
    const bounds = new google.maps.LatLngBounds();

    places.forEach((place) => {
      if (!place.geometry || !place.geometry.location) {
        console.error('Returned place contains no geometry');
        return;
      }

      const icon = {
        url: place.icon as string,
        size: new google.maps.Size(71, 71),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(17, 34),
        scaledSize: new google.maps.Size(25, 25),
      };

      // Create a marker for each place.
      const searchMarker = new google.maps.Marker({
        map,
        icon,
        title: place.name,
        position: place.geometry.location,
      });
      searchMarkers.push(searchMarker);

      google.maps.event.addListener(searchMarker, 'click', () => {
        if (place.geometry?.location) openDealWindow(place.geometry.location, map, place);
      });

      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
      setSearchOnSharedMap(
        place.geometry.location.lat(),
        place.geometry.location.lng(),
        place.formatted_address ?? null
      );
    });
    map.fitBounds(bounds);
  }
};

const parseREPPlaces = (places: any): [string[], google.maps.LatLng[]] => {
  return places.reduce(
    (acc: [string[], google.maps.LatLng[]], place: any) => {
      const latLng = new google.maps.LatLng(place.latitude, place.longitude);
      const address =
        `${place.addr_number} ${place.addr_street_prefix} ${place.addr_street_name} ${place.addr_street_type}`
          .trim()
          .replace(/\s+/g, ' ');
      const city = place.physcity;
      const stateAndZip = `${place.state_abbr} ${place.physzip}`.trim();
      let formattedAddress = city ? `${address}, ${city}` : address;
      formattedAddress = stateAndZip.length
        ? `${formattedAddress}, ${stateAndZip}`
        : formattedAddress;
      formattedAddress = formattedAddress.replace(/undefined/g, '');
      const strs = [...acc[0], formattedAddress];
      const latLngs = [...acc[1], latLng];
      return [strs, latLngs];
    },
    [[], []]
  );
};

const isCoordinates = (searchQuery: string) => {
  const coordinates = parseCoordinates(searchQuery);
  if (coordinates.length !== 2) return false;
  const lat = coordinates[0];
  const lng = coordinates[1];
  const latReX = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
  const lngReX = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;

  return latReX.test(lat.trim()) && lngReX.test(lng.trim());
};

const parseCoordinates = (searchQuery: string) => searchQuery.split(/\s*,\s*/);

const isParcelId = (searchQuery: string) => {
  const regEx = /\b\d{8,12}(\S{1,2})?\b/g;
  searchQuery = strip(searchQuery);
  return regEx.test(searchQuery);
};

const strip = (str: string) => str.replace(/\s/g, '');
