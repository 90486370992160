import { DealResource } from '@/containers/DealResources';
import { DealOption } from '@/containers/DealWindow/typings';
import { AvailableFilters, FiltersOptions, StaDeal, TabName } from '@/containers/Deals/typings';
import { ListDeal } from '@/scripts/deal';

import {
  DataType,
  KeysToSnake,
  Option,
  ParcelData,
  RequestMethod,
  Result,
  SortObject,
} from '../typings';
import { ajaxResult, fetchResult } from '../utils';

export type UpdateParamData = {
  [key in ParamsNames | 'is_for_sale' | keyof typeof LocationDealParams]?:
    | number[]
    | number
    | string
    | boolean
    | null
    | ((prevValue: number) => number);
};

export const DealService = {
  tableDeals: (
    activeTab: TabName,
    page: number,
    perPage: number,
    filters: FiltersOptions,
    sort: SortObject,
    searchString: string,
    brokerId: number | null,
    controller?: AbortController
  ): Promise<Result<{ sta: KeysToSnake<StaDeal[]>; total_pages: number }>> => {
    const data = {
      tab: activeTab,
      page,
      per_page: perPage,
      filters,
      sort,
      q: { sta_search_cont: searchString },
      broker_id: brokerId,
    };
    const body = $.param(data);
    const url = '/ajax-deals?' + body;
    const req = {
      url,
      method: RequestMethod.Get,
      dataType: DataType.JSON,
      signal: controller?.signal,
    };
    return fetchResult(req);
  },
  updateParam: (dealId: number, param: ParamsNames, data: UpdateParamData): Promise<Result> => {
    const url = `/deals/${dealId}/update_param`;
    const req = { url, method: RequestMethod.Put, data: { param, ...data }, dataType: 'json' };
    return ajaxResult(req);
  },
  deleteParam: (dealId: number, param: ParamsNames): Promise<Result> => {
    const url = `/deals/${dealId}/delete_param/${param}`;
    const req = { url, method: RequestMethod.Put, dataType: 'json' };
    return ajaxResult(req);
  },
  search: (q: { [searchQuery: string]: string }): Promise<Result<{ deals: DealOption[] }>> => {
    const body = $.param({ q });
    const url = `/deals/search?` + body;
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  getBroker: (dealId: number): Promise<Result> => {
    const req = { url: `/deals/broker/${dealId}`, method: RequestMethod.Get, dataType: 'json' };
    return ajaxResult(req);
  },
  updateCustomFieldsOrderNumber: (
    dealId: number,
    customFieldPositions: { [id: number]: number }
  ): Promise<Result> => {
    const data = {
      custom_fields: customFieldPositions,
      deal_id: dealId,
    };
    const url = '/ajax-deal-custom-fields-update-order-number';
    const req = { url, method: RequestMethod.Patch, dataType: 'json', data };
    return ajaxResult(req);
  },
  updatePinType: (dealId: number, pinId: number): Promise<Result> => {
    const req = { method: RequestMethod.Put, url: `/deals/${dealId}/update_pin/${pinId}` };
    return ajaxResult(req);
  },
  getTasksList: (dealId: number, tasksPage: number): Promise<Result> => {
    const data = { deal_id: dealId, tasks_page: tasksPage };
    const req = { url: '/ajax-tasks-list', dataType: 'json', method: RequestMethod.Get, data };
    return ajaxResult(req);
  },
  getAvailiableFilters: (): Promise<Result<KeysToSnake<AvailableFilters>>> => {
    const url = 'ajax-deal-available-filters';
    const req = { url, method: RequestMethod.Get, dataType: 'json' };
    return ajaxResult(req);
  },
  getParcelData: (dealId: number): Promise<Result<ParcelData>> => {
    const data = { id: dealId };
    const body = $.param(data);
    const url = '/ajax-deal-report-all-data?' + body;
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  updateConfidentialStatus: (
    dealIds: number | number[],
    isConfidential: boolean
  ): Promise<Result> => {
    const data = Array.isArray(dealIds)
      ? { deal_ids: dealIds, is_confidential: isConfidential }
      : { deal_id: dealIds, is_confidential: isConfidential };
    const url = 'ajax-deal-update-confidential';
    const req = { url, dataType: 'json', method: RequestMethod.Patch, data };
    return ajaxResult(req);
  },
  getFiles: async (dealId: number): Promise<Result> => {
    const url = `/deals/${dealId}/retrieve_files`;
    const req = { url, method: RequestMethod.Get };
    return fetchResult(req);
  },
  uploadFiles: async (dealId: number, formData: FormData): Promise<Result> => {
    const url = `/deals/${dealId}/upload_files`;
    const req = { url, body: formData, method: RequestMethod.Post };
    return fetchResult(req);
  },
  deleteFile: async (dealId: number, fileId: number): Promise<Result> => {
    const url = `/deals/${dealId}/delete_file/${fileId}`;
    const req = { url, method: RequestMethod.Delete };
    return fetchResult(req);
  },
  updateReminderDate: async (dealId: number, date: Date): Promise<Result> => {
    const url = `/deals/${dealId}/update_reminder_date`;
    const req = { url, method: RequestMethod.Put, data: { date: date }, dataType: 'json' };
    return ajaxResult(req);
  },
  getExploreList: async (ids: number[]): Promise<Result<ListDeal[]>> => {
    const body = $.param({ ids });
    const url = `/deals/explore_list?` + body;
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  relatedDealsSelectOptions: async (search: string): Promise<Result<Option[]>> => {
    const body = $.param({ search });
    const url = `/ajax-related-deals?` + body;
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  getTransactionTypes: async (): Promise<Result<Option[]>> => {
    const url = '/ajax-transaction-types';
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },

  addResource: (
    id: number,
    city: Option,
    state: string,
    add_all: boolean,
    resource_id: number | null
  ): Promise<Result<{ resources: DealResource[] }>> => {
    const body = JSON.stringify({ city_id: city.id, state, add_all, resource_id });
    const url = `/deals/${id}/add_resource`;
    const req = { url, method: RequestMethod.Post, dataType: DataType.JSON, body };
    return fetchResult(req);
  },
};

export const DealKeys = {
  related_deals: 'relatedDeals',
  working_users: 'workingUsers',
  custom_radius: 'customRadius',
  opportunities: 'opportunities',
  tags: 'tags',
  transaction_type: 'transactionType',
  nickname: 'nickname',
  radius: 'radius',
  followers: 'followers',
  broker_id: 'brokerId',
  location: 'location',
} as const;

export const LocationDealParams = {
  latitude: 'latitude',
  longitude: 'longitude',
  address: 'address',
  city: 'city',
  state: 'state',
  saved: 'saved',
  postal_code: 'postalCode',
  name: 'name',
  phone: 'phone',
  place_id: 'placeId',
  pin_id: 'pinId',
  formatted_address: 'formattedAddress',
} as const;

export type ParamsNames = keyof typeof DealKeys;
