import * as React from 'react';

import Tasks from './Tasks';
import { TasksProps } from './typings';

const Index = (props: TasksProps): JSX.Element => {
  return (
    <React.StrictMode>
      <Tasks {...props} />
    </React.StrictMode>
  );
};

export default Index;
