import React, { useEffect, useMemo, useState } from 'react';

import ErrorComponent from '@/components/ErrorComponent';
import HeaderBlock, { HeaderBlockEnd } from '@/components/HeaderBlock';
import Pagination from '@/components/Pagination';
import PaginationSelect from '@/components/PaginationSelect';
import SearchForm from '@/components/SearchForm';
import Table from '@/components/Table';
import TableBody from '@/components/Table/TableBody';
import TableHead from '@/components/Table/TableHead';
import TextWithIconBtn from '@/components/TextWithIconBtn';
import { useErrorState } from '@/hooks';
import { DealResourceService } from '@/services/deal_resource_service';
import { ResourcesTableService } from '@/services/resources_table_service';
import { City, Option, SortMethod } from '@/typings';

import Filters, { DefaultFilters, TFilters } from './Filters';
import ModalDealResource from './ModalDealResource';
import ResourcesTableSettings, { ResourceColumn } from './ResourcesTableSettings';
import TableRow from './TableRow';

const DealResources = (props: {
  cities: City[];
  states: string[];
  tags: Option[];
  isAdmin: boolean;
}) => {
  const { cities, states, tags, isAdmin } = props;
  const [errors, addError, removeError] = useErrorState();
  const [searchString, setSearchString] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [perPage, setPerPage] = useState(10);

  const [columnsSettings, setColumnsSettings] = useState<ResourceColumn[]>([]);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [sort, setSort] = useState({ entity: 'name', method: SortMethod.Asc });
  const [filters, setFilters] = useState<TFilters>(DefaultFilters);

  const [resources, setResources] = useState<DealResource[]>([]);

  useEffect(() => {
    (async function () {
      const res = await ResourcesTableService.index();
      if (res.ok) setColumnsSettings(res.data.settings);
      else addError('Error while fetching resource table settings:', res);
    })();
  }, []);

  useEffect(() => {
    getResources();
  }, [sort, currentPage, perPage, searchString, filters]);

  const getResources = async () => {
    const res = await DealResourceService.resources(
      currentPage,
      sort,
      searchString,
      perPage,
      filters
    );
    if (res.ok) {
      setResources(res.data.resources);
      setTotalPages(res.data.total_pages);
    } else addError('Error in getResources:', res);
  };

  const onSave = async (resource: Omit<Omit<DealResource, 'id'>, 'lastUpdated'>) => {
    const res = await DealResourceService.create(resource);
    if (res.ok) setResources([res.data, ...resources]);
    else addError('Error in onSave:', res);
  };

  const onUpdate = async (resource: Omit<DealResource, 'lastUpdated'>) => {
    const res = await DealResourceService.update(resource);
    if (res.ok) setResources(resources.map((r) => (resource.id === r.id ? res.data : r)));
    else addError('Error in onUpdate:', res);
  };

  const onDelete = async (id: number) => {
    if (
      await window.customConfirm(
        'Delete Resource',
        'Are you sure that you want to delete this Resource?'
      )
    ) {
      const res = await DealResourceService.destroy(id);
      if (res.ok) setResources(resources.filter((resource) => resource.id !== id));
      else addError('Error in onDelete:', res);
    }
  };

  const updateColumns = async (columns: ResourceColumn[]) => {
    const res = await ResourcesTableService.updatePosition(columns);
    if (res.ok) setColumnsSettings(res.data.settings);
    else addError('Error in updateColumns:', res);
  };

  const headerColumns = useMemo(
    () => [
      ...columnsSettings
        .filter((col) => col.active)
        .map((col) => {
          if ('name' === col.name || 'tags' === col.name)
            return {
              children: ResourceColumnNames[col.name],
              entity: col.name,
              className: 'table-column-middle',
            };
          else if ('link' === col.name || 'city' === col.name || 'state' === col.name)
            return {
              children: ResourceColumnNames[col.name],
              entity: col.name,
              className: 'table-column-short',
            };
          else
            return {
              children: col.name,
              entity: col.name,
              className: 'table-column-short',
            };
        }),
      { children: '', entity: 'buttons', className: 'table-column-checkbox' },
    ],
    [columnsSettings]
  );

  return (
    <React.StrictMode>
      <ErrorComponent errors={errors} removeError={removeError} />
      <ModalDealResource
        title='Add Resource'
        show={showAddModal}
        setShow={setShowAddModal}
        tags={tags}
        onSave={onSave}
      />
      <div>
        <HeaderBlock h1='Resources'>
          <SearchForm
            onChange={(e) => setSearchString($(e.target).val()?.toString() ?? '')}
            placeholder='Search Resources'
            className='search-input auto'
            wrapperClassName='mx-4'
          />
          <ResourcesTableSettings
            columnsSettings={columnsSettings}
            updateColumnsSettings={updateColumns}
          />
          <PaginationSelect selectHandler={setPerPage} perPage={perPage} />
          <Filters tags={tags} cities={cities} states={states} setFilters={setFilters} />

          <HeaderBlockEnd>
            <TextWithIconBtn
              icon='plus-circle'
              text='Add Resource'
              onClick={() => setShowAddModal(true)}
              className='box-shadow add-new-btn h-100'
            />
          </HeaderBlockEnd>
        </HeaderBlock>
        <div>
          <Table>
            <>
              <TableHead setSort={setSort} columns={headerColumns} />
              <TableBody
                isResultPresent={resources.length > 0}
                columnsLength={headerColumns.length}
              >
                {resources.map((resource) => (
                  <TableRow
                    columns={columnsSettings}
                    key={resource.id}
                    resource={resource}
                    searchString={searchString}
                    isAdmin={isAdmin}
                    tags={tags}
                    deleteResource={onDelete}
                    updateResource={onUpdate}
                  />
                ))}
              </TableBody>
            </>
          </Table>
          <Pagination
            showAllHandler={() => setPerPage(-1)}
            clickHandler={setCurrentPage}
            totalPages={totalPages}
            currentPage={currentPage}
          />
        </div>
      </div>
    </React.StrictMode>
  );
};

export default DealResources;

export const ResourceColumnNames = {
  name: 'Name',
  link: 'Link',
  city: 'City',
  state: 'State',
  tags: 'Tags',
} as const;

export type DealResource = {
  id: number;
  name: string;
  link: string;
  state: string;
  city: City | null;
  tags: Option[];
  lastUpdated: string;
};
