import Cookies from 'js-cookie';

import { moveToLocation, showStreetView } from '../deal';
import { map, onBackBtnClick, renderNewDealPin } from '../map';
import { initNewDealReportAllEvents } from '../report_all';
import { onSavePinBtnClick } from './save';

type GeocoderOrPlaceResult = google.maps.GeocoderResult | google.maps.places.PlaceResult;
export const openNewDealWindow = async (
  position: google.maps.LatLng,
  result: GeocoderOrPlaceResult | null = null
) => {
  renderNewDealPin(position);
  const placeId = await getPlaceId(position, result);
  const place = placeId ? await getPlaceInfo(placeId) : null;

  document.dispatchEvent(new Event('openNewDealWindow'));

  $.get({
    url: '/ajax-deal-window',
    dataType: 'script',
    data: {
      saved: false,
      address: place?.formatted_address,
      name: place?.name,
      phone: place?.formatted_phone_number,
      latitude: position.lat(),
      longitude: position.lng(),
      shared_map_window: $('.shared-map').length === 0 ? '' : true,
    },
    success: () => {
      const [latitude, longitude] = [position.lat(), position.lng()];

      onBackBtnClick();
      showStreetView({ lat: latitude, lng: longitude });
      moveToLocation(latitude, longitude);

      window.initFlatpickr();
      window.initSelectize($('.js-select'));
      window.initTagifyNotification(
        '.js-notification-task-name, .js-notification-task-description, .js-notification-note-text'
      );

      $('.js-deal-tab').on('click', function () {
        Cookies.set('current-deal-tab', $(this).data('bsTarget').substring(1));
      });

      onSavePinBtnClick(place, position);
      onBackBtnClick();

      initNewDealReportAllEvents(position);
    },
  });
};

export const getPlaceId = async (
  position: google.maps.LatLng,
  result: GeocoderOrPlaceResult | null
): Promise<string | null> => {
  const geocoder = new google.maps.Geocoder();
  if (result) {
    if (isDistanceValid(result, position) && result.place_id) return result.place_id;
    return getPlaceIdWithAddress(geocoder, result);
  } else {
    const placeId: string | null = await new Promise((resolve) => {
      geocoder.geocode({ location: position }, (results, status) => {
        if (status !== google.maps.GeocoderStatus.OK)
          return console.log('Geocoder failed due to:', status);
        if (!results || !results[0]) return console.log('No results found');
        if (isDistanceValid(results[0], position)) return resolve(results[0].place_id);
        return resolve(getPlaceIdWithAddress(geocoder, results[0]));
      });
    });
    return placeId;
  }
};

const getPlaceIdWithAddress = async (
  geocoder: google.maps.Geocoder,
  result: GeocoderOrPlaceResult
) => {
  const placeId: string | null = await new Promise((resolve) => {
    geocoder.geocode({ address: result.formatted_address }, (results, status) => {
      if (status !== google.maps.GeocoderStatus.OK) {
        console.log('Geocoder failed due to:', status);
        return resolve(null);
      } else if (!results || !results[0]) {
        console.log('No results found');
        return resolve(null);
      } else return resolve(results[0].place_id);
    });
  });
  return placeId;
};

const isDistanceValid = (result: GeocoderOrPlaceResult, position: google.maps.LatLng) => {
  if (!result?.geometry?.location) return false;
  const latDifference = Math.abs(result.geometry.location.lat() - position.lat());
  const lngDifference = Math.abs(result.geometry.location.lng() - position.lng());
  const distance = latDifference + lngDifference;
  if (distance > 0.0001) return false;
  return true;
};

// open new deal
export const getPlaceInfo = async (placeId: string) => {
  const request = {
    placeId: placeId,
    fields: [
      'name',
      'formatted_address',
      'place_id',
      'geometry',
      'formatted_phone_number',
      'address_components',
    ],
  };
  const service = new google.maps.places.PlacesService(map);
  const place: google.maps.places.PlaceResult | null = await new Promise((resolve) => {
    service.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && place?.geometry?.location) {
        return resolve(place);
      } else return resolve(null);
    });
  });
  return place;
};
