import React, { useRef, useState } from 'react';

import { Form } from 'react-bootstrap';

import Modal from '@/components/Modal';
import { useEffectNotFirstRender } from '@/hooks';
import { SetState } from '@/typings';

const UserModal = (props: {
  show: boolean;
  setShow: SetState<boolean>;
  title: string;
  isAdmin: boolean;
  onSave: (user: {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    canViewConfidential: boolean;
  }) => void;
  user?: {
    firstName: string;
    lastName: string;
    email: string;
    role: { name: string; value: string };
    canViewConfidential: boolean;
  };
}) => {
  const { show, setShow, title, user, isAdmin, onSave } = props;
  const [firstName, setFirstName] = useState<string>(user?.firstName ?? '');
  const [lastName, setLastName] = useState<string>(user?.lastName ?? '');
  const [email, setEmail] = useState<string>(user?.email ?? '');
  const [role, setRole] = useState<string>(user?.role.value ?? 'user');
  const [canViewConfidential, setCanViewConfidential] = useState(
    user?.canViewConfidential ?? false
  );
  const [validated, setValidated] = useState(false);
  const form = useRef<HTMLFormElement>(null);

  const setDefault = () => {
    setEmail(user?.email ?? '');
    setFirstName(user?.firstName ?? '');
    setLastName(user?.lastName ?? '');
    setRole(user?.role.value ?? 'user');
    setCanViewConfidential(user?.canViewConfidential ?? false);
  };

  useEffectNotFirstRender(() => {
    setDefault();
  }, [user]);

  const handleClose = () => {
    setDefault();
    setShow(false);
    setValidated(false);
  };

  const handleSave = () => {
    if (!form.current?.checkValidity()) {
      form.current?.reportValidity();
      setValidated(true);
      return;
    }

    onSave({
      firstName,
      lastName,
      email,
      role,
      canViewConfidential,
    });
    handleClose();
  };

  return (
    <Modal
      modalProps={{ show, backdrop: 'static' }}
      title={title}
      onClose={handleClose}
      onSave={handleSave}
      formProps={{
        validated,
        ref: form,
      }}
    >
      <div className='mb-3 row'>
        <div className='col'>
          <label className='form-label'>First Name*</label>
          <Form.Control
            type='text'
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
            required
          />
        </div>
        <div className='col'>
          <label className='form-label'>Last Name*</label>
          <Form.Control
            type='text'
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
            required
          />
        </div>
      </div>
      <div className='mb-3'>
        <label className='form-label'>Email*</label>
        <Form.Control
          type='email'
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          required
        />
      </div>
      {isAdmin && (
        <>
          <div className='mb-3'>
            <label className='form-label'>Role</label>
            <Form.Select
              value={role}
              onChange={(e) => setRole(e.target.value === 'admin' ? 'admin' : 'user')}
            >
              <option value='admin'>Admin</option>
              <option value='user'>User</option>
            </Form.Select>
          </div>
          <div>
            <Form.Check
              label='Can view confidential'
              name='privacy'
              defaultChecked={canViewConfidential}
              onChange={() => setCanViewConfidential(!canViewConfidential)}
            />
          </div>
        </>
      )}
    </Modal>
  );
};

export default UserModal;
