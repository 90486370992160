import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import BackBtn from '@/components/DealWindow/BackBtn';
import HistoryTab from '@/components/DealWindow/HistoryTab';
import Info from '@/components/DealWindow/Info';
import ParcelDataTab from '@/components/DealWindow/ParcelDataTab';
import PinTypeDropdown from '@/components/DealWindow/PinTypeDropdown';
import Tabs from '@/components/DealWindow/Tabs';
import StreetView from '@/components/StreetView';
import CustomFields from '@/containers/DealWindow/CustomFields';
import { CustomField } from '@/containers/DealWindow/CustomFields/typings';
import TasksTab from '@/containers/DealWindow/TasksTab';
import { NotesProvider } from '@/contexts/NotesContext';
import { useEffectNotFirstRender } from '@/hooks';
import { unsaveDeal } from '@/scripts/deal/unsave';
import { updatePinType } from '@/scripts/deal/update_pin_type';
import { hideExploreDeal } from '@/scripts/map';
import CustomRadius from '@/scripts/map/markers/custom_radius';
import MarkerManager from '@/scripts/map/markers/marker_manager';
import { DealKeys, DealService, ParamsNames } from '@/services/deal_service';
import { Option } from '@/typings';
import { camelize, isOptionArray, isString, parseCoordinates } from '@/utils';
import { mapInteractions } from '@/utils/map_interactions';

import BrokerOrOwner from './BrokerOrOwner';
import Files from './Files';
import NearbyDealsBtn from './NearbyDealsBtn';
import NotesTab from './NotesTab';
import PinDetails from './PinDetails';
import Resources from './Resources';
import { DealOption, DealWindowProps } from './typings';

// hide CommunicationTab
// import CommunicationTab from './CommunicationTab';

const DealWindow = (props: DealWindowProps) => {
  const { googleApiKey, dealsTabs, pins, sharedMapWindow, currentUser, nearbyDeals } = props;
  const { users, tags, transactionTypes, opportunities, resourceTags } = props; // Option[]
  const { resourcesCities, resourcesStates } = props;

  const tabsTitles = useMemo(() => dealsTabs.map((dealsTab) => dealsTab.title), [dealsTabs]);
  const [activeTabTitle, setActiveTabTitle] = useState(props.tab ?? tabsTitles[0]);

  const [deal, setDeal] = useState(camelize(props.deal));
  const coordinates = useMemo(() => parseCoordinates(deal), [deal.longitude, deal.latitude]);

  const setCustomFields = (customFields: CustomField[]) => setDeal({ ...deal, customFields });
  const setIsForSale = (isForSale: boolean | null) => setDeal({ ...deal, isForSale });
  const pin = useMemo(() => pins.find((pin) => pin.id === deal.pinId) ?? null, [pins, deal.pinId]);

  useEffect(() => {
    const manager = MarkerManager.getInstance();
    manager.radiusMarker.draw(parseCoordinates(deal), deal.radius);
    if (deal.customRadius)
      manager.customRadiusMarker.draw(CustomRadius.stringToCoordinates(deal.customRadius));
  }, []);

  useEffectNotFirstRender(() => {
    const manager = MarkerManager.getInstance();
    manager.pinMarkerManager.changeMarkerLabel(deal.id, deal.nickname);
  }, [deal.nickname]);

  const changePinType = (dealId: number, pinId: number) => {
    updatePinType(dealId, pinId);
    setDeal((deal) => ({ ...deal, pinId }));
  };

  const updateParam = async (
    option: Option[] | DealOption[] | Option | string,
    paramName: ParamsNames,
    callback: () => void
  ) => {
    if (!option) return;
    if (isOptionArray(option) && option.length === 0) return;

    const param = isString(option)
      ? option
      : isOptionArray(option)
      ? option.map((option) => option.id)
      : option.id;

    const res = await DealService.updateParam(deal.id, paramName, { [paramName]: param });
    if (res.ok) {
      setDeal((deal) => ({ ...deal, [DealKeys[paramName]]: option }));
      mapInteractions(paramName, deal, isString(param) ? param : null);
      callback();
    } else console.error(res);
  };

  const deleteParam = async (paramName: ParamsNames, callback: () => void) => {
    const res = await DealService.deleteParam(deal.id, paramName);
    if (res.ok) {
      setDeal((deal) => ({ ...deal, [DealKeys[paramName]]: null }));
      mapInteractions(paramName, deal);
      callback();
    } else console.error(res);
  };

  return (
    <React.StrictMode>
      <div className='explore-deal-block'>
        <div className='explore-overflow-deal-block'>
          <BackBtn onClick={hideExploreDeal} />
          <StreetView className='image-block' apiKey={googleApiKey} coordinates={coordinates} />
          <Info deal={deal}>
            <>
              <PinTypeDropdown
                pins={pins}
                dealId={deal.id}
                currentPin={pin}
                deleteDeal={unsaveDeal}
                changePinType={changePinType}
              />
              <NearbyDealsBtn nearbyDeals={nearbyDeals} />
            </>
          </Info>
          <Tabs
            titles={tabsTitles}
            activeTabTitle={activeTabTitle}
            setActiveTabTitle={setActiveTabTitle}
          />

          {activeTabTitle === 'Details' && (
            <>
              <PinDetails
                canViewConfidential={currentUser.canViewConfidential}
                deal={deal}
                sharedMapWindow={sharedMapWindow}
                opportunities={opportunities}
                tags={tags}
                resourceTags={resourceTags}
                transactionTypes={transactionTypes}
                users={users}
                updateParam={updateParam}
                deleteParam={deleteParam}
              />
              <BrokerOrOwner
                dealId={deal.id}
                isForSale={deal.isForSale}
                setIsForSale={setIsForSale}
                sharedMapWindow={sharedMapWindow}
              />
              <CustomFields
                dealId={deal.id}
                customFields={deal.customFields}
                setCustomFields={setCustomFields}
                sharedMapWindow={sharedMapWindow}
              />
              <Resources
                sharedMapWindow={sharedMapWindow}
                deal={deal}
                resources={deal.resources}
                setResources={(resources) => setDeal({ ...deal, resources })}
                cities={resourcesCities}
                states={resourcesStates}
              />
              <Files dealId={deal.id} />
            </>
          )}
          <ParcelDataTab show={activeTabTitle === 'Parcel Data'} dealId={deal.id} />
          {activeTabTitle === 'History' && (
            <HistoryTab setActiveTabTitle={setActiveTabTitle} dealId={deal.id} />
          )}

          <TasksTab
            show={activeTabTitle === 'Tasks'}
            dealId={deal.id}
            users={users}
            currentUserId={currentUser.id}
          />
          <NotesProvider dealId={deal.id}>
            <NotesTab show={activeTabTitle === 'Notes'} dealId={deal.id} users={users} />
          </NotesProvider>

          {/* <CommunicationTab show={activeTabTitle === 'Communication'} dealId={deal.id} /> */}
        </div>
      </div>
    </React.StrictMode>
  );
};

export default DealWindow;
