import * as React from 'react';
import { useEffect, useState } from 'react';

import { map, mapClickCallback, mapClickListener, setMapClickListener } from '@/scripts/map';
import { REPIdentifyByPoint, drawParcelBordersAndBuildings } from '@/scripts/report_all';
import { ParcelService } from '@/services/parcel_service';
import { Coordinates } from '@/typings';

import { Parcel } from './typings';

const ParcelsMapInteractions = (props: {
  deal: { id: number } & Coordinates;
  parcels: Parcel[];
  setParcels: (parcels: Parcel[]) => void;
  deleteParcel: (id: number) => Promise<void>;
}) => {
  const { parcels, setParcels, deal, deleteParcel } = props;
  const [isAddingParcels, setIsAddingParcels] = useState(false);
  let parcelListener: google.maps.MapsEventListener;

  useEffect(() => {
    if (isAddingParcels) {
      google.maps.event.removeListener(mapClickListener);
      parcelListener = map.addListener('click', (e: google.maps.MapMouseEvent) => {
        if (e.latLng) {
          const lat = e.latLng.lat();
          const lng = e.latLng.lng();
          (async function () {
            const parcel = await REPIdentifyByPoint(new google.maps.LatLng(lat, lng));
            const parcelId = parcel ? parcel[0]['parcel_id'] ?? '' : '';
            const sameParcel = parcels.find((parcel) => parcel.parcel_id === parcelId);
            if (parcelId !== '' && sameParcel) deleteParcel(sameParcel.id);
            else createParcel(lat, lng, parcels, parcelId);
          })();
        }
      });
    }
    document.addEventListener('showParcels', () => {
      const parcelsLatLngs = parcels.map(
        (parcel) => new google.maps.LatLng(parcel.lat, parcel.lng)
      );
      drawParcelBordersAndBuildings([
        new google.maps.LatLng(deal.lat, deal.lng),
        ...parcelsLatLngs,
      ]);
    });
    return () => {
      if (isAddingParcels) {
        google.maps.event.removeListener(parcelListener);
        setMapClickListener(map.addListener('click', mapClickCallback));
      }
    };
  }, [isAddingParcels, parcels]);

  const createParcel = async (lat: number, lng: number, parcels: Parcel[], parcelId: string) => {
    const res = await ParcelService.create(deal.id, lat, lng, parcelId);
    if (res.ok) {
      const newParcels = [...parcels, { id: res.data, lat, lng, parcel_id: parcelId }];
      const parcelsLatLngs = [deal, ...newParcels].map(
        (parcel) => new google.maps.LatLng(parcel.lat, parcel.lng)
      );
      drawParcelBordersAndBuildings(parcelsLatLngs);
      setParcels(newParcels);
    } else console.error('Cannot create parcel:', res.error);
  };
  return (
    <div className='pointer light-blue-color' onClick={() => setIsAddingParcels(!isAddingParcels)}>
      {isAddingParcels ? 'Stop adding parcels (click on map to add)' : 'Add parcel'}
    </div>
  );
};

export default ParcelsMapInteractions;
