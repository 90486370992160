import React, { useEffect, useState } from 'react';

import { Tab, Tabs } from 'react-bootstrap';
import M from 'react-bootstrap/Modal';

import style from '@/components/Modal/style.module.scss';
import { DealTabService } from '@/services/deal_tab_service';
import { NotififcationPreferenceService } from '@/services/notification_preference_service';
import { UserService } from '@/services/user_service';

import { User } from '../Settings/Users';
import PreferencesTab from './PreferencesTab';
import ProfileTab from './ProfileTab';

export type Preference = {
  id: number;
  taskMention: PreferenceValue;
  taskAssign: PreferenceValue;
  noteMention: PreferenceValue;
  taskFollow: PreferenceValue;
  taskUncompleted: PreferenceValue;
  taskCompleted: PreferenceValue;
};

export type PreferenceValue = 'in_app' | 'email' | 'both' | 'off';

const ProfileModal = (props: {
  show: boolean;
  setShow: (show: boolean) => void;
  dealsTabs: { title: string; id: number }[];
}) => {
  const { show, setShow } = props;

  const [user, setUser] = useState<User | null>(null);
  const [preference, setPreference] = useState<Preference | null>(null);
  const [tab, setTab] = useState<string | null>(null);
  const [currentDealTabs, setCurrentDealTabs] = useState<{ title: string; id: number }[] | null>(
    null
  );

  const getUser = async () => {
    const res = await UserService.current();
    if (res.ok) setUser(res.data);
    else console.error('Error in getUser:', res);
  };

  const getPreference = async () => {
    const res = await NotififcationPreferenceService.current();
    if (res.ok) setPreference(res.data);
    else console.error('Error in getPreference:', res);
  };

  const getDealTabs = async () => {
    if (!!currentDealTabs) return;

    const res = await DealTabService.current_user_state();
    if (res.ok) setCurrentDealTabs(res.data);
    else console.error('Error in getDealTabs:', res);
  };

  const onClose = () => {
    setShow(false);
    setTab('profile');
  };

  useEffect(() => {
    getUser();
    getPreference();
    getDealTabs();
  }, [show]);

  return (
    <M
      show={show}
      backdrop='static'
      dialogClassName={tab === 'preferences' ? 'max-w-600' : undefined}
      onHide={onClose}
    >
      <M.Header closeButton>
        <M.Title>Edit Profile</M.Title>
      </M.Header>

      <Tabs
        variant='pills'
        activeKey={tab ?? 'profile'}
        onSelect={(tab) => setTab(tab)}
        className='my-2'
      >
        <Tab eventKey='profile' title='Profile Information' tabClassName={style.tabBtn + ' mx-2'}>
          {!!user && <ProfileTab user={user} getUser={getUser} setShow={setShow} setTab={setTab} />}
        </Tab>
        <Tab eventKey='preferences' title='Preferences' tabClassName={style.tabBtn}>
          {!!preference && !!currentDealTabs && (
            <PreferencesTab
              preference={preference}
              setTab={setTab}
              setShow={setShow}
              dealsTabs={currentDealTabs}
              setDealsTabs={setCurrentDealTabs}
            />
          )}
        </Tab>
      </Tabs>
    </M>
  );
};

export default ProfileModal;
