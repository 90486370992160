import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import s from './BackBtn.module.scss';

const BackBtn = ({ onClick }: { onClick: React.MouseEventHandler<HTMLDivElement> }) => (
  <div className={`${s.backBtn} pointer`} onClick={onClick}>
    <FontAwesomeIcon icon='times' className='fs-26' />
  </div>
);
export default BackBtn;
