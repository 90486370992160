import { FilterOption, KeysToSnake, Option, Pin } from '@/typings';

import { UserOption } from '../DealWindow/typings';

export type Task = {
  id?: number | undefined;
  name: string | undefined;
  nameRaw: string | undefined;
  description: string | undefined;
  descriptionRaw: string | undefined;
  pinAddress: string | undefined;
  pinAddressLink: string | undefined;
  assignedToBody: AssignedTo | undefined;
  assignedToId: number | undefined;
  dueDate: string | undefined;
  isCompleted: boolean | undefined;
  createdBy: string | undefined;
  createdById: number | undefined;
  dealId?: number | undefined;
  tags: Tag[] | undefined;
  followers: { id: number }[] | undefined;
  followerNames: string | undefined;
};

export type Deal = {
  id: number;
  address: string;
  pin: Pin;
};

export type AssignedTo = {
  id: number;
  fullName: string;
  avatar: string;
  initialLetters: string;
};

export type Tag = {
  id: string;
  text: string;
};

export type TaskRequestParams = {
  tags: Tag[] | undefined;
  task: {
    follower_ids: number[] | undefined;
    name: string | undefined;
    description: string | undefined;
    due_date: string | undefined;
    deal_id?: number | undefined;
    assigned_to_id: number | undefined;
  };
};

export type TasksProps = {
  tasks: KeysToSnake<Task[]>;
  current_user: UserOption;
  users: UserOption[];
  total_pages: number;
  total_open: number;
  total_completed: number;
};

export const DefaultTask = {
  id: undefined,
  name: undefined,
  nameRaw: undefined,
  description: undefined,
  descriptionRaw: undefined,
  pinAddress: undefined,
  pinAddressLink: undefined,
  assignedToBody: undefined,
  assignedToId: undefined,
  dueDate: undefined,
  isCompleted: undefined,
  createdBy: undefined,
  followers: undefined,
  dealId: undefined,
  tags: undefined,
  followerNames: undefined,
  createdById: undefined,
};

export const defaultFilters = Object.freeze({
  assignedUsers: [],
  followers: [],
  createdBy: [],
  dueDateFrom: undefined,
  dueDateTo: undefined,
});

export type AvailableFilters = {
  assignedUsers: Option[];
  followers: Option[];
  createdBy: Option[];
  dueDateFrom: string | undefined;
  dueDateTo: string | undefined;
};

export type FiltersOptions = {
  assignedUsers: FilterOption[];
  followers: FilterOption[];
  createdBy: FilterOption[];
  dueDateFrom: string | undefined;
  dueDateTo: string | undefined;
};

export enum TabName {
  Open = 'open',
  Completed = 'completed',
}
