import React, { useCallback, useContext, useEffect, useState } from 'react';

import Cookies from 'js-cookie';
import { createPortal } from 'react-dom';

import { Status, Wrapper } from '@googlemaps/react-wrapper';

import { UnsavedChangeContext, UnsavedChangeProvider } from '@/contexts/UnsavedChangeContext';
import { clearSearchMarkers } from '@/scripts/global_search';
import { initMap } from '@/scripts/map';
import MarkerManager, { MapDeal } from '@/scripts/map/markers/marker_manager';
import { drawParcelBordersAndBuildings } from '@/scripts/report_all';
import { DataType, RequestMethod } from '@/typings';
import { fetchResult, parseCoordinates } from '@/utils';

import ContextMenu from '../ContextMenu';
import DealWindow from '../DealWindow';
import { Parcel } from '../DealWindow/Parcels/typings';
import { DealWindowProps } from '../DealWindow/typings';

const Map = (props: { apiKey: string }) => {
  const { apiKey } = props;
  const render = (status: Status) => {
    if (status === Status.FAILURE) return <>Fail to load google maps</>;
    return <>Loading...</>;
  };

  return (
    <Wrapper apiKey={apiKey} libraries={['places', 'drawing', 'geometry']} render={render}>
      <UnsavedChangeProvider>
        <MapComponent />
        <ContextMenu />
      </UnsavedChangeProvider>
    </Wrapper>
  );
};

export default Map;

const MapComponent = () => {
  const { unsavedChange, setUnsavedChange, noteConfirm } = useContext(UnsavedChangeContext);
  const [dealWindowState, setDealWindowState] = useState<'closed' | 'loading' | 'new' | 'saved'>(
    'closed'
  );
  const [dealWindowProps, setDealWindowProps] = useState<DealWindowProps | null>(null);

  const closeDealWindow = useCallback(async () => {
    if (unsavedChange) {
      const answer =
        unsavedChange === 'note'
          ? await noteConfirm()
          : await window.customConfirm(
              'Unsaved Changes',
              'Are you sure that you want to close this deal? There are unsaved changes'
            );

      if (answer) {
        setUnsavedChange(null);
      } else return false;
    }

    setDealWindowState('closed');
    return true;
  }, [unsavedChange]);

  const openSavedDealWindow = useCallback(
    async (e) => {
      if (!(await closeDealWindow())) return;

      const { detail } = e as CustomEvent;
      const show_related_deals = Cookies.get('show-related-deals');
      const shared_map_window = $('.shared-map').length === 0 ? '' : true;

      const data = { id: detail.id, show_related_deals, shared_map_window, tab: detail.tab };

      const body = $.param(data);
      const url = '/ajax-saved-deal-window?' + body;

      const manager = MarkerManager.getInstance();
      manager.newMarker.clear();
      clearSearchMarkers();

      setDealWindowState('loading');
      const res = await fetchResult({
        url,
        dataType: DataType.JSON,
        method: RequestMethod.Get,
      });

      if (res.ok) {
        setDealWindowProps(JSON.parse(res.data.props));
        setDealWindowState('saved');
        const parcels = res.data.parcels as Parcel[];
        const parcelsLatLngs = parcels.map(
          (parcel) => new google.maps.LatLng(parcel.lat, parcel.lng)
        );
        const mapDeal: MapDeal = res.data.map_deal;
        drawParcelBordersAndBuildings([
          new google.maps.LatLng(parseCoordinates(mapDeal)),
          ...parcelsLatLngs,
        ]);
        manager.selectedMarkerCircle.draw(parseCoordinates(mapDeal), mapDeal.id);

        let recentSearches: string[] = JSON.parse(localStorage.getItem('recentSearches') || '[]');
        if (
          !recentSearches.includes(
            `${res.data.map_deal.nickname}, ${res.data.map_deal.formatted_address}`
          )
        ) {
          recentSearches.push(
            `${res.data.map_deal.nickname}, ${res.data.map_deal.formatted_address}`
          );

          if (recentSearches.length > 5) {
            recentSearches.splice(0, recentSearches.length - 5);
          }

          localStorage.setItem('recentSearches', JSON.stringify(recentSearches));
        }
      } else {
        setDealWindowState('closed');
        alert(
          `Opening saved deal window failed, try later or reload page\n` +
            `Error status: ${res.status}\n` +
            `Error message: ${res.statusText}`
        );
        console.error('Cannot get saved-deal-window', res.error);
      }
    },
    [unsavedChange]
  );

  const openNewDealWindow = useCallback(async () => {
    if (await closeDealWindow()) setDealWindowState('new');
  }, [unsavedChange]);

  useEffect(() => {
    document.addEventListener('openNewDealWindow', openNewDealWindow);
    document.addEventListener('openSavedDealWindow', openSavedDealWindow);
    document.addEventListener('closeDealWindow', closeDealWindow);

    return () => {
      document.removeEventListener('openNewDealWindow', openNewDealWindow);
      document.removeEventListener('openSavedDealWindow', openSavedDealWindow);
      document.removeEventListener('closeDealWindow', closeDealWindow);
    };
  }, [unsavedChange]);

  useEffect(() => {
    (async function () {
      Cookies.remove('show-related-deals');

      // Phoenix, AZ
      const defaultLat = 33.448376;
      const defaultLng = -112.074036;
      const urlParams = new URLSearchParams(window.location.search);

      await initMap(defaultLat, defaultLng, urlParams);
    })();
  }, []);

  return (
    <>
      <div id='map' />
      {$('#map').children('div')[0] &&
        createPortal(
          <div className='explore-deal-component'>
            {dealWindowState === 'loading' && (
              <div className='explore-deal-block d-flex justify-content-center'>
                <div className='spinner-border align-self-center light-blue-color' />
              </div>
            )}
            {dealWindowState === 'saved' && dealWindowProps && <DealWindow {...dealWindowProps} />}
            <div
              className={`explore-deal-block-new ${dealWindowState === 'new' ? '' : 'd-none'}`}
            />
          </div>,
          $('#map').children('div')[0]
        )}
    </>
  );
};
