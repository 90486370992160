import Cookies from 'js-cookie';

type FilterName =
  | 'tags'
  | 'opportunities'
  | 'pins'
  | 'users'
  | 'transaction-types'
  | 'followers'
  | 'cities'
  | 'states'
  | 'zip_codes'
  | 'brokers';

export const CookiesService = {
  showTags: {
    get: (): boolean => Cookies.get('show-tags') === 'true',
    set: (bool: boolean): string | undefined => {
      if (bool) Cookies.set('show-tags', 'true');
      else {
        Cookies.remove('show-tags');
        return undefined;
      }
    },
  },
  showPins: {
    get: (): boolean => Cookies.get('show-pins') !== 'false',
    set: (bool: boolean): string | undefined => {
      if (!bool) Cookies.set('show-pins', 'false');
      else {
        Cookies.remove('show-pins');
        return undefined;
      }
    },
  },
  showImages: {
    get: (): boolean => Cookies.get('show-images') === 'true',
    set: (bool: boolean): string | undefined => {
      if (bool) Cookies.set('show-images', 'true');
      else {
        Cookies.remove('show-images');
        return undefined;
      }
    },
  },
  explorePageFilters: {
    set: (name: FilterName, filter: string[]): string | undefined =>
      Cookies.set(name, JSON.stringify(filter)),
    get: (name: FilterName): string | undefined => Cookies.get(name),
    remove: (name: FilterName) => Cookies.remove(name),
  },
  explorePageFiltersSaved: {
    set: (name: FilterName, filter: [string, string][]): string | undefined =>
      Cookies.set(`saved-${name}`, JSON.stringify(filter)),
    get: (name: FilterName): string | undefined => Cookies.get(`saved-${name}`),
    remove: (name: FilterName) => Cookies.remove(`saved-${name}`),
  },
};
