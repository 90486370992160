import React, { useEffect, useState } from 'react';

import ErrorComponent from '@/components/ErrorComponent';
import { HeaderBlockEnd } from '@/components/HeaderBlock';
import SearchForm from '@/components/SearchForm';
import Table from '@/components/Settings/Table';
import TableBody from '@/components/Table/TableBody';
import TextWithIconBtn from '@/components/TextWithIconBtn';
import { useErrorState } from '@/hooks';
import { CityService } from '@/services/city_service';
import { City } from '@/typings';

import CityModal from './CityModal';
import CityRow from './CityRow';

const Cities = (props: { show: boolean; setHeader: (elem: JSX.Element) => void }) => {
  const { show, setHeader } = props;
  const [errors, addError, removeError] = useErrorState();
  const [isLoading, setLoading] = useState(true);
  const [searchString, setSearchString] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [cities, setCities] = useState<City[]>([]);

  const createCity = async (city: { name: string; state: string }) => {
    const res = await CityService.create(city);
    if (res.ok) setCities([{ id: res.data, ...city }, ...cities]);
    else addError('Error in createCity:', res);
  };

  const getCities = async () => {
    setLoading(true);
    const res = await CityService.search(searchString);
    setLoading(false);
    if (res.ok) {
      setCities(res.data.cities);
    } else addError('Error in getCities:', res);
  };

  useEffect(() => {
    getCities();
  }, [searchString]);

  useEffect(() => {
    if (show)
      setHeader(
        <>
          <SearchForm
            onChange={(e) => setSearchString(e.target.value)}
            placeholder='Search Cities...'
            className='search-input'
            wrapperClassName='mx-4'
          />

          <HeaderBlockEnd>
            <TextWithIconBtn
              icon='plus-circle'
              text='Add City'
              onClick={() => setShowAddModal(true)}
              className='box-shadow add-new-btn h-100'
            />
          </HeaderBlockEnd>
        </>
      );
  }, [show]);

  return (
    <div>
      <CityModal
        show={showAddModal}
        title='Add City'
        setShow={setShowAddModal}
        onSave={createCity}
      />
      <ErrorComponent errors={errors} removeError={removeError} />

      <Table title='Cities'>
        <thead>
          <tr className='table-header-font'>
            <th className='w-25'>Name</th>
            <th>State</th>
          </tr>
        </thead>

        <TableBody isResultPresent={cities.length > 0} isLoading={isLoading} columnsLength={2}>
          {cities.map((city) => (
            <CityRow key={city.id} city={city} />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default Cities;
