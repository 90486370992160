import * as React from 'react';
import { useState } from 'react';

import FormBtn from '@/components/IconBtn';

const CustomRadiusForm = (props: {
  onSave: () => void;
  onClose: () => void;
  onDelete: () => void;
  onDrawModeBtnClick: (arg: google.maps.drawing.OverlayType.POLYGON | null) => void;
}) => {
  const { onSave, onDelete, onClose, onDrawModeBtnClick } = props;
  const [isPoligonMode, setPoligonMode] = useState(true);
  return (
    <>
      <p className='boulder-color'>Custom</p>
      {isPoligonMode ? (
        <FormBtn
          onClick={() => {
            setPoligonMode(false);
            onDrawModeBtnClick(null);
          }}
          icon='draw-polygon'
          style='secondary'
        />
      ) : (
        <FormBtn
          onClick={() => {
            setPoligonMode(true);
            onDrawModeBtnClick(google.maps.drawing.OverlayType.POLYGON);
          }}
          icon='hand-paper'
          style='secondary'
        />
      )}
      <FormBtn onClick={onSave} icon='check' style='success' />
      <FormBtn onClick={onClose} icon='times' style='primary' />
      <FormBtn onClick={onDelete} icon='trash' style='danger' />
    </>
  );
};

export default CustomRadiusForm;
