// import types from 'util/types';
import types from 'util';

import { KeysToCamel } from '@/typings';

const camelCase = (str: string): string => {
  if (!/[_-]/.test(str)) return str;
  return str.toLowerCase().replace(/[-_][a-z0-9]/g, (s: string) => s.slice(-1).toUpperCase());
};

function walk(obj: any): any {
  if (!obj || typeof obj !== 'object') return obj;
  if (types.isDate(obj) || types.isRegExp(obj)) return obj;
  if (Array.isArray(obj)) return obj.map((o) => walk(o));

  return Object.keys(obj).reduce((res, key) => {
    const camel = camelCase(key);
    res[camel] = walk(obj[key]);
    return res;
  }, {} as { [key: string]: any });
}

export default function camelize<T>(obj: T): T extends String ? string : KeysToCamel<T> {
  return typeof obj === 'string' ? camelCase(obj) : walk(obj);
}
