import { DataType, Pin, PinType, RequestMethod, Result } from '@/typings';
import { fetchResult } from '@/utils';

export const PinService = {
  index: (
    q: Record<string, string>
  ): Promise<Result<(Pin & { active: boolean; reminderDuration: number | null })[]>> => {
    const body = $.param({ q });
    const url = '/pins?' + body;
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  options: (): Promise<Result<[number, string, PinType][]>> => {
    const url = '/pins/options';
    const req = { url, method: RequestMethod.Get, dataType: DataType.JSON };
    return fetchResult(req);
  },
  create: (pin: {
    name: string;
    color: string;
    icon: PinType;
    reminderDuration: number | null;
  }): Promise<Result<number>> => {
    const url = '/pins';
    const body = JSON.stringify({
      pin: { ...pin, snooze_alert_time_duration: pin.reminderDuration },
    });
    const req = { url, method: RequestMethod.Post, dataType: DataType.JSON, body };
    return fetchResult(req);
  },

  update: (pin: {
    id: number;
    name: string;
    color: string;
    icon: PinType;
    reminderDuration: number | null;
  }): Promise<Result<'ok'>> => {
    const url = `/pins/${pin.id}`;
    const body = JSON.stringify({
      pin: { ...pin, snooze_alert_time_duration: pin.reminderDuration },
    });
    const req = { url, method: RequestMethod.Put, dataType: DataType.JSON, body };
    return fetchResult(req);
  },

  destroy: (id: number): Promise<Result<'ok'>> => {
    const url = `/pins/${id}`;
    const req = { url, method: RequestMethod.Delete, dataType: DataType.JSON };
    return fetchResult(req);
  },
  restore: (id: number): Promise<Result<'ok'>> => {
    const url = `/pins/${id}/restore`;
    const req = { url, method: RequestMethod.Put, dataType: DataType.JSON };
    return fetchResult(req);
  },
};
