import * as React from 'react';
import { useEffect, useState } from 'react';

import ErrorComponent from '@/components/ErrorComponent';
import { HeaderBlockEnd } from '@/components/HeaderBlock';
import SearchForm from '@/components/SearchForm';
import Table from '@/components/Settings/Table';
import TableBody from '@/components/Table/TableBody';
import TextWithIconBtn from '@/components/TextWithIconBtn';
import { useErrorState, useFetchInitial } from '@/hooks';
import { UserService } from '@/services/user_service';
import { Avatar, Option } from '@/typings';

import UserModal from './UserModal';
import UserRow from './UserRow';
import UsersFilter from './UsersFilter';

export type User = Option & {
  firstName: string;
  lastName: string;
  email: string;
  role: { value: string; name: string };
  avatar: Avatar;
  active: boolean;
  canViewConfidential: boolean;
  otpEnabled: boolean;
  lastLogin?: string;
};

const Users = (props: { show: boolean; setHeader: (elem: JSX.Element) => void }) => {
  const { show, setHeader } = props;

  const [errors, addError, removeError] = useErrorState();
  const [isLoading, setLoading] = useState(true);
  const [searchString, setSearchString] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [filters, setFilters] = useState<{ roles: string[] }>({ roles: [] });

  const currentUser = useFetchInitial(
    addError,
    UserService.current,
    'Error while fetching currentUser:',
    false
  );

  const getUsers = async () => {
    const q = {
      first_name_or_last_name_or_email_cont: searchString,
    };
    setLoading(true);
    const res = await UserService.search(q, filters);
    setLoading(false);
    if (res.ok) setUsers(res.data);
    else addError('Error in getUsers:', res);
  };

  const createUser = async (user: {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    canViewConfidential: boolean;
  }) => {
    const res = await UserService.createFromModal(user);
    if (res.ok) setUsers([res.data, ...users]);
    else addError('Error in createUser:', res);
  };

  const updateUser = async (user: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    canViewConfidential: boolean;
  }) => {
    const res = await UserService.update(user);
    if (res.ok) setUsers(users.map((user) => (user.id === res.data.id ? res.data : user)));
    else addError('Error in updateUser:', res);
  };

  const sendInvite = async (email: string) => {
    const res = await UserService.sendInvitation(email);
    if (res.ok) alert('Email succesfully send');
    else addError('Error in sendIvite:', res);
  };

  const archiveUser = async (id: number, newId: number) => {
    const res = await UserService.archive(id, newId);

    if (res.ok) setUsers(users.map((user) => (user.id === res.data.id ? res.data : user)));
    else addError('Error in archiveUser:', res);
  };

  const restoreUser = async (id: number) => {
    if (
      !(await window.customConfirm(
        'Reactivate User',
        'Are you sure that you want to restore this user?'
      ))
    )
      return;

    const res = await UserService.archive(id);
    if (res.ok) setUsers(users.map((user) => (user.id === res.data.id ? res.data : user)));
    else addError('Error in restoreUser:', res);
  };

  useEffect(() => {
    getUsers();
  }, [searchString, filters]);

  useEffect(() => {
    if (show)
      setHeader(
        <>
          <SearchForm
            onChange={(e) => setSearchString($(e.target)!.val()!.toString())}
            placeholder='Search Users...'
            className='search-input'
            wrapperClassName='mx-4'
          />
          <UsersFilter setFilters={setFilters} />

          {currentUser?.role.value === 'admin' && (
            <HeaderBlockEnd>
              <TextWithIconBtn
                icon='plus-circle'
                text='Add User'
                onClick={() => setShowAddModal(true)}
                className='box-shadow add-new-btn h-100'
              />
            </HeaderBlockEnd>
          )}
        </>
      );
  }, [show, currentUser]);

  return (
    <>
      <UserModal
        title='Add User'
        show={showAddModal}
        setShow={setShowAddModal}
        isAdmin={currentUser?.role.value === 'admin'}
        onSave={createUser}
      />
      <ErrorComponent errors={errors} removeError={removeError} />

      <Table title='Users'>
        <thead>
          <tr className='table-header-font'>
            <th />
            <th className='w-25'>Name</th>
            <th className='w-25'>Email</th>
            <th className='w-25'>Role</th>
            <th className='w-25'>Last Login</th>
            <th />
          </tr>
        </thead>

        <TableBody isResultPresent={users.length > 0} isLoading={isLoading} columnsLength={6}>
          {!!currentUser &&
            users.map((user) => (
              <UserRow
                key={user.id}
                user={user}
                users={users}
                currentUser={currentUser}
                restoreUser={restoreUser}
                archiveUser={archiveUser}
                sendInvite={sendInvite}
                updateUser={updateUser}
                isAdmin={currentUser?.role.value === 'admin'}
              />
            ))}
        </TableBody>
      </Table>
    </>
  );
};

export default Users;
