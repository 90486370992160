import React, { useMemo, useState } from 'react';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';

import Modal from '@/components/Modal';
import { STATES } from '@/containers/Brokers/typings';

const CityModal = (props: {
  title: string;
  show: boolean;
  setShow: (bool: boolean) => void;
  onSave: (city: { name: string; state: string }) => void;
}) => {
  const { title, show, setShow, onSave } = props;

  const statesOptions = useMemo(() => STATES.map((s) => toSelectOption(s[1])), []);
  const defaultCity = { name: '', state: STATES[0][1] as string };

  const [city, setCity] = useState(defaultCity);
  const [validated, setValidated] = useState(false);

  const handleClose = () => {
    setValidated(false);
    setCity(defaultCity);
    setShow(false);
  };

  const handleSave = () => {
    if (!city.name) {
      setValidated(true);
      return;
    }
    onSave(city);
    handleClose();
  };

  return (
    <Modal
      onSave={handleSave}
      onClose={handleClose}
      title={title}
      modalProps={{ backdrop: 'static', show }}
    >
      <Form.Group className='mb-3'>
        <Row>
          <Col>
            <Form.Label>Name *</Form.Label>
            <Form.Control
              type='text'
              placeholder='Name'
              defaultValue={city.name}
              onChange={(e) => setCity({ name: e.target.value?.trim(), state: city.state })}
              isInvalid={validated && !city.name}
            />
            <Form.Control.Feedback type='invalid'>Name is required</Form.Control.Feedback>
          </Col>

          <Col>
            <Form.Label>State</Form.Label>
            <Select
              value={toSelectOption(city.state)}
              onChange={(newValue) =>
                setCity({
                  state: newValue!.value,
                  name: city.name,
                })
              }
              options={statesOptions}
              closeMenuOnSelect
            />
          </Col>
        </Row>
      </Form.Group>
    </Modal>
  );
};

const toSelectOption = (str: string) => ({ label: str, value: str });

export default CityModal;
