import * as React from 'react';
import { useEffect, useState } from 'react';

import ErrorComponent from '@/components/ErrorComponent';
import HeaderBlock, { HeaderBlockEnd } from '@/components/HeaderBlock';
import Pagination from '@/components/Pagination';
import PaginationSelect from '@/components/PaginationSelect';
import SearchForm from '@/components/SearchForm';
import Table from '@/components/Table';
import TableBody from '@/components/Table/TableBody';
import TableHead from '@/components/Table/TableHead';
import TextWithIconBtn from '@/components/TextWithIconBtn';
import { useEffectNotFirstRender, useErrorState, useFetchInitial } from '@/hooks';
import { ApplicationService } from '@/services/application_service';
import { BrokerService } from '@/services/broker_service';
import { WorkingCompanyService } from '@/services/working_company_service';
import { Nullable, Option, SortMethod } from '@/typings';

import ModalBroker from './ModalBroker';
import Show from './Show';
import TableRow from './TableRow';
import { Broker, BrokersProps } from './typings';

const Brokers = (props: BrokersProps) => {
  const [brokers, setBrokers] = useState(props.brokers);
  const [currentBroker, setCurrentBroker] = useState<Broker | null>(null);
  const [totalPages, setTotalPages] = useState(props.total_pages);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [perPage, setPerPage] = useState(10);
  const [showAddModal, setShowAddModal] = useState(false);
  const [sort, setSort] = useState({ entity: 'name', method: SortMethod.Asc });
  const [errors, addError, removeError] = useErrorState();
  const [workingCompanies, setWorkingCompanies] = useState<Option[]>([]);
  const googleApiKey = useFetchInitial(
    addError,
    ApplicationService.mapsApiKey,
    'Error while fetching googleApiKey:'
  );

  useEffectNotFirstRender(() => {
    getBrokers();
  }, [currentPage, sort, searchString, perPage]);

  useEffectNotFirstRender(() => {
    if (currentBroker) location.hash = currentBroker.id.toString();
    else history.back();
  }, [currentBroker]);

  useEffect(() => {
    getWorkingCompanies();
    if (/#\d/.test(location.href)) {
      getBroker(parseInt(location.hash.replace('#', '')));
    }
  }, []);

  const getBrokers = async () => {
    const res = await BrokerService.index(sort, searchString, currentPage, perPage);
    if (res.ok) {
      setBrokers(res.data.brokers);
      setTotalPages(res.data.total_pages);
    } else addError('Error in getBrokers:', res);
  };

  const getBroker = async (id: number) => {
    const res = await BrokerService.show(id);
    if (res.ok) setCurrentBroker(res.data);
    else addError(`Error in getBroker: `, res);
  };

  const deleteBroker = async (id: number) => {
    if (
      await window.customConfirm(
        'Delete Broker',
        'Are you sure that you want to delete this broker?'
      )
    ) {
      const res = await BrokerService.destroy(id);
      if (res.ok) getBrokers();
      else {
        const statusText = res.error.responseJSON.join(', ');
        addError('Error in deleteBroker: ', { ...res, statusText });
      }
    }
  };

  const saveBroker = async (broker: Nullable<Broker>) => {
    const res = await BrokerService.create(broker);
    if (res.ok) getBrokers();
    else addError('Error in saveBroker: ', res);
  };

  const updateBroker = async (broker: Nullable<Broker>) => {
    const res = await BrokerService.update(broker);
    if (res.ok) getBrokers();
    else addError('Error in updateBroker: ', res);
  };

  const getWorkingCompanies = async () => {
    const res = await WorkingCompanyService.options();
    if (res.ok) setWorkingCompanies(res.data);
    else addError('Error in getWorkingCompanies: ', res);
  };

  if (currentBroker)
    return (
      <Show broker={currentBroker} setBroker={setCurrentBroker} googleApiKey={googleApiKey}>
        <ErrorComponent errors={errors} removeError={removeError} />
      </Show>
    );

  return (
    <div>
      <ErrorComponent errors={errors} removeError={removeError} />
      <ModalBroker
        show={showAddModal}
        setShow={setShowAddModal}
        title='Add Broker'
        saveBroker={saveBroker}
        isOwner={false}
        workingCompanies={workingCompanies}
      />
      <HeaderBlock h1='Brokers'>
        <SearchForm
          onChange={(e) => setSearchString($(e.target).val()?.toString() ?? '')}
          placeholder='Search Brokers'
          className='search-input auto'
          wrapperClassName='mx-4'
        />
        <PaginationSelect selectHandler={setPerPage} perPage={perPage} />
        <HeaderBlockEnd>
          <TextWithIconBtn
            icon='plus-circle'
            text='Add Broker'
            onClick={() => setShowAddModal(true)}
            className='box-shadow add-new-btn h-100'
          />
        </HeaderBlockEnd>
      </HeaderBlock>
      <div>
        <Table>
          <>
            <TableHead setSort={setSort} columns={columns} />
            <TableBody isResultPresent={brokers.length > 0} columnsLength={columns.length}>
              {brokers.map((broker) => (
                <TableRow
                  key={broker.id}
                  broker={broker}
                  searchString={searchString}
                  deleteBroker={deleteBroker}
                  updateBroker={updateBroker}
                  setBroker={setCurrentBroker}
                  workingCompanies={workingCompanies}
                />
              ))}
            </TableBody>
          </>
        </Table>
        <Pagination
          showAllHandler={() => setPerPage(-1)}
          clickHandler={setCurrentPage}
          totalPages={totalPages}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default Brokers;

const columns = [
  { children: 'Broker Name', entity: 'name', className: 'table-column-middle' },
  { children: 'Phone', entity: 'phone', className: 'table-column-middle' },
  { children: 'Email', entity: 'email', className: 'table-column-middle' },
  { children: 'Mailing Address', entity: 'address', className: 'table-column-long' },
  { children: 'Market', entity: 'market', className: 'table-column-short' },
  { children: 'Company', entity: 'working_company_name', className: 'table-column-middle' },
  { children: '', entity: 'buttons', className: 'table-column-checkbox' },
];
