import * as React from 'react';
import { useEffect, useState } from 'react';

import ErrorComponent from '@/components/ErrorComponent';
import HeaderBlock, { HeaderBlockEnd } from '@/components/HeaderBlock';
import Pagination from '@/components/Pagination';
import PaginationSelect from '@/components/PaginationSelect';
import SearchForm from '@/components/SearchForm';
import Table from '@/components/Table';
import TableBody from '@/components/Table/TableBody';
import TableHead from '@/components/Table/TableHead';
import Tabs from '@/components/Tabs';
import TextWithIconBtn from '@/components/TextWithIconBtn';
import { useEffectNotFirstRender, useErrorState } from '@/hooks';
import { AjaxRequest, RequestMethod, SetState, SortMethod } from '@/typings';
import { ajaxResult, camelize, snakeObjKeys } from '@/utils';

import Filters from './Filters/Filters';
import ModalTask from './ModalTask';
import TableRow from './TableRow';
import {
  AvailableFilters,
  DefaultTask,
  FiltersOptions,
  TabName,
  Task,
  TaskRequestParams,
  TasksProps,
  defaultFilters,
} from './typings';

const Tasks = (props: TasksProps): JSX.Element => {
  const users = props.users;
  const [tasks, setTasks] = useState<Task[]>(camelize(props.tasks) as Task[]);
  const [totalPages, setTotalPages] = useState(props.total_pages);
  const [deals, setDeals] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchString, setSearchString] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [sort, setSort] = useState({ entity: 'default', method: SortMethod.Asc });
  const [activeTab, setActiveTab] = useState('open');
  const [totalOpenTasks, setTotalOpenTasks] = useState(props.total_open);
  const [totalCompletedTasks, setTotalCompletedTasks] = useState(props.total_completed);
  const currentUser = props.current_user;

  const [errors, addError, removeError] = useErrorState();

  const [availableFilters, setAvailableFilters] = useState<AvailableFilters>(defaultFilters);
  const [selectedFilters, setSelectedFilters] = useState<FiltersOptions>(defaultFilters);
  const [savedFilters, setSavedFilters] = useState<FiltersOptions>({ ...defaultFilters });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const taskName = urlParams.get('task_name');
    if (taskName) {
      window.history.pushState({}, document.title, window.location.href.split('?')[0]);
      setSearchString(taskName);
    }

    document.addEventListener('notificationClick', (e) => {
      const { detail } = e as CustomEvent;
      setSearchString(detail.taskName);
    });
  }, []);

  useEffectNotFirstRender(() => {
    getTasks();
  }, [currentPage, sort, searchString, savedFilters, perPage, activeTab]);

  useEffect(() => {
    if (!deals) {
      getDealsAvailableToAssignToTask();
    }
  }, [tasks]);

  const getTasks = async () => {
    let q: any = { tasks_search_cont: searchString };
    if (sort.entity !== 'default') q['s'] = `${sort.entity} ${sort.method}`;
    const data = {
      current_page: currentPage,
      q,
      filters: snakeObjKeys(savedFilters),
      per_page: perPage,
      tab: activeTab,
    };
    const req: AjaxRequest = { url: 'tasks', method: RequestMethod.Get, data, dataType: 'json' };

    const res = await ajaxResult(req);
    if (res.ok) {
      setTasks(camelize(res.data.tasks) as Task[]);
      setTotalPages(res.data.total_pages);
      setTotalOpenTasks(res.data.total_open);
      setTotalCompletedTasks(res.data.total_completed);
    } else addError('Error in getTasks:', res);
  };

  const deleteTask = async (task: Task) => {
    if (
      !(await window.customConfirm(
        'Delete Task',
        'Are you sure that you want to delete this task?'
      ))
    )
      return;
    ajaxTasks(`/tasks/${task.id}`, RequestMethod.Delete, 'deleteTask');
  };

  const saveTask = async (task: Task, setTask: SetState<Task>) => {
    ajaxTasks(
      '/tasks',
      RequestMethod.Post,
      'saveTask',
      formatTaskParamsBeforeRequest(task),
      () => setTask(DefaultTask),
      true
    );
  };

  const updateTask = async (task: Task, setTask: SetState<Task>) => {
    ajaxTasks(
      `/tasks/${task.id}`,
      RequestMethod.Patch,
      'updateTask',
      formatTaskParamsBeforeRequest(task),
      () => setTask(task),
      true
    );
  };

  const updateTaskStatus = async (task: Task) => {
    ajaxTasks('/ajax-update-task-status', RequestMethod.Put, 'updateTaskStatus', task);
  };

  const formatTaskParamsBeforeRequest = (task: Task) => {
    return {
      tags: task.tags,
      task: {
        follower_ids: task.followers!.map((follower) => follower['id']),
        name: task.nameRaw,
        description: task.descriptionRaw,
        due_date: task.dueDate,
        deal_id: task.dealId,
        assigned_to_id: task.assignedToId,
      },
    };
  };

  const getDealsAvailableToAssignToTask = async () => {
    const req: AjaxRequest = {
      url: '/ajax-deals-available-to-assign-task',
      method: RequestMethod.Get,
      dataType: 'json',
    };

    const res = await ajaxResult(req);
    if (res.ok) {
      setDeals(res.data.deals);
    } else addError('Error in getDealsAvailableToAssignToTask:', res);
  };

  // helpers
  const ajaxTasks = async (
    url: string,
    method: RequestMethod,
    fnName: string,
    task?: Task | TaskRequestParams,
    fn?: Function,
    customBody?: boolean
  ) => {
    const req: AjaxRequest = { url, method, dataType: 'json', data: customBody ? task : { task } };

    const res = await ajaxResult(req);
    if (res.ok) {
      getTasks();
      fn && fn();
    } else addError(`Error in ${fnName}:`, res);
  };

  return (
    <div>
      <ErrorComponent errors={errors} removeError={removeError} />

      <ModalTask
        saveTask={saveTask}
        show={showAddModal}
        title='Add Task'
        setShow={setShowAddModal}
        users={users}
        deals={deals}
        currentUserId={currentUser.id}
      />
      <HeaderBlock h1='Tasks'>
        <SearchForm
          value={searchString}
          onChange={(e) => setSearchString($(e.target)!.val()!.toString())}
          placeholder='Search Tasks'
          className='search-input'
          wrapperClassName='mx-4'
        />
        <Filters
          addError={addError}
          availableFilters={availableFilters}
          selectedFilters={selectedFilters}
          savedFilters={savedFilters}
          setAvailableFilters={setAvailableFilters}
          setSelectedFilters={setSelectedFilters}
          setSavedFilters={setSavedFilters}
        />
        <PaginationSelect selectHandler={setPerPage} perPage={perPage} />
        <HeaderBlockEnd>
          <TextWithIconBtn
            icon='plus-circle'
            onClick={() => setShowAddModal(true)}
            text='Add Task'
            className='h-100 box-shadow add-new-btn'
          />
        </HeaderBlockEnd>
      </HeaderBlock>
      <div>
        <div>
          <Tabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabs={[
              {
                id: 'open-tasks-tab',
                name: TabName.Open,
                text: `Open (${totalOpenTasks})`,
              },
              {
                id: 'completed-tasks-tab',
                name: TabName.Completed,
                text: `Completed (${totalCompletedTasks})`,
              },
            ]}
          />
        </div>
        <Table>
          <TableHead setSort={setSort} columns={columns} />
          <TableBody isResultPresent={tasks.length > 0} columnsLength={columns.length}>
            {tasks.map((task) => (
              <TableRow
                key={task.id}
                task={task}
                deals={deals}
                searchString={searchString}
                deleteTask={deleteTask}
                updateTask={updateTask}
                updateTaskStatus={updateTaskStatus}
                currentUserId={currentUser.id}
                users={users}
                followers={task.followerNames}
                followersLength={task.followers?.length ?? 0}
              />
            ))}
          </TableBody>
        </Table>
        <Pagination
          showAllHandler={() => setPerPage(-1)}
          clickHandler={setCurrentPage}
          totalPages={totalPages}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default Tasks;

const columns = [
  { children: '', entity: 'name', className: 'table-column-checkbox' },
  { children: 'Task Name', entity: 'name', className: 'table-column-long' },
  { children: 'Description', entity: 'description', className: 'table-column-long' },
  {
    children: 'Pin Address',
    entity: 'deal_address',
    className: 'table-column-middle',
  },
  { children: 'Assigned to', entity: 'user_first_name', className: 'table-column-middle' },
  {
    children: 'Due Date',
    entity: 'due_date',
    className: 'table-column-short',
  },
  {
    children: 'Followers',
    entity: 'followers',
    className: 'table-column-short',
  },
  { children: '', entity: 'name', className: 'table-column-checkbox' },
];
