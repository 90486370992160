import * as React from 'react';
import { useState } from 'react';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ShareBtn = ({ link, className }: { link: string; className: string }) => {
  const [show, setShow] = useState(false);
  const onCopy = () => window.copyToClipboard(link).then(() => setShow(true));

  return (
    <>
      <div className={className}>
        <OverlayTrigger show={show} placement='top-start' overlay={<Tooltip>Link copied</Tooltip>}>
          <button
            className='btn init-icon-30 blue bor-rad-circle'
            type='button'
            onClick={onCopy}
            onMouseLeave={() => (show ? setShow(false) : null)}
            title='Copy link'
          >
            <FontAwesomeIcon icon='link' className='light-blue-color pointer' />
          </button>
        </OverlayTrigger>
      </div>
    </>
  );
};

export default ShareBtn;
