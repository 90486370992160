import { DataType, DateServer, RequestMethod, Result } from '@/typings';
import { fetchResult } from '@/utils';

export const SnoozeNotificationService = {
  create: async (
    deal_id: number | null,
    alert_date: DateServer,
    parent_notification_id?: number
  ): Promise<Result> => {
    const url = `/snooze_notifications`;
    const body = JSON.stringify({
      snooze_notification: { deal_id, alert_date, parent_notification_id },
    });
    const req = { url, method: RequestMethod.Post, dataType: DataType.JSON, body };
    return fetchResult(req);
  },
};
