import * as React from 'react';

import F from 'react-bootstrap/Form';
import Select from 'react-select';
import Creatable from 'react-select/creatable';

import { Nullable, Option, SetState } from '@/typings';
import { isEmailValid, isNullOrEmptyString } from '@/utils';

import { stringToStateOption } from './ModalBroker';
import { Broker, STATES } from './typings';

const Form = (props: {
  broker: Nullable<Broker>;
  setBroker: SetState<Nullable<Broker>>;
  isOwner: boolean;
  validated: boolean;
  workingCompanies: Option[];
}) => {
  const { broker, setBroker, isOwner, validated, workingCompanies } = props;

  return (
    <>
      <div className='mb-3'>
        <label className='form-label'>{isOwner ? 'Owner' : 'Broker'} Name *</label>
        <F.Control
          type='name'
          onChange={(e) => {
            setBroker({ ...broker, name: e.target.value });
          }}
          defaultValue={broker.name ?? undefined}
          isInvalid={validated && isNullOrEmptyString(broker.name)}
        />
        <F.Control.Feedback type='invalid'>Name can not be empty</F.Control.Feedback>
      </div>
      <div className='mb-3 row'>
        <div className='col'>
          <label className='form-label'>Phone</label>
          <F.Control
            type='phone'
            onChange={(e) => {
              setBroker({ ...broker, phone: e.target.value });
            }}
            defaultValue={broker.phone ?? undefined}
          />
        </div>
        <div className='col'>
          <label className='form-label'>Email</label>
          <F.Control
            type='email'
            onChange={(e) => {
              setBroker({ ...broker, email: e.target.value });
            }}
            defaultValue={broker.email ?? undefined}
            isInvalid={
              validated && (isNullOrEmptyString(broker.email) ? false : !isEmailValid(broker.email))
            }
          />
          <F.Control.Feedback type='invalid'>Email incorrect</F.Control.Feedback>
        </div>
      </div>
      <div className='mb-3'>
        <label className='form-label'>Mailing Address</label>
        <F.Control
          type='address'
          onChange={(e) => {
            setBroker({ ...broker, address: e.target.value });
          }}
          defaultValue={broker.address ?? undefined}
        />
      </div>
      {!isOwner && (
        <div className='mb-3'>
          <label className='form-label'>Market</label>
          <Select
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            defaultValue={broker.market ? stringToStateOption(broker.market) : null}
            options={STATES.map((state) => ({ name: state.join(' ') }))}
            closeMenuOnSelect={true}
            isMulti={true}
            isClearable={true}
            menuShouldScrollIntoView={false}
            onChange={(options) =>
              setBroker({
                ...broker,
                market: options.map((option) => option.name).join(', '),
              })
            }
            getOptionValue={(option) => option.name}
            getOptionLabel={(option) => option.name}
          />
        </div>
      )}
      <div className='mb-3'>
        <label>Company</label>
        <Creatable
          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
          defaultValue={toSelectOption(broker.workingCompany)}
          options={workingCompanies.map((option) => ({
            label: option.name,
            value: `${option.id}`,
          }))}
          closeMenuOnSelect={true}
          isClearable={true}
          menuShouldScrollIntoView={false}
          onChange={(option, actionMeta) => {
            setBroker({
              ...broker,
              workingCompany: formatOption(option, actionMeta.action === 'create-option'),
            });
          }}
        />
      </div>
      <div className='mb-3'>
        <label className='form-label'>Notes</label>
        <F.Control
          type='notes'
          onChange={(e) => {
            setBroker({ ...broker, note: e.target.value });
          }}
          defaultValue={broker.note ?? undefined}
        />
      </div>
    </>
  );
};

export default Form;

const toSelectOption = (
  option: { id: number | null; name: string } | null
): { label: string; value: string } | null => {
  if (!option) return null;
  else return { label: option.name, value: `${option.id}` };
};

const formatOption = (option: { label: string; value: string } | null, isNew: boolean) => {
  if (!option) return null;
  else if (isNew) return { id: null, name: option.label };
  else return { id: parseInt(option.value), name: option.label };
};
