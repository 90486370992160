import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CompleteBtn = (props: {
  isCompleted: boolean;
  id: number;
  updateStatus: (id: number) => Promise<void>;
}) => {
  const { isCompleted, id, updateStatus } = props;
  return (
    <div
      className={
        'init-icon init-icon-complete fs-22 pointer bor-rad-circle' +
        (isCompleted ? ' init-icon-completed' : ' init-icon-uncompleted')
      }
      onClick={() => updateStatus(id)}
    >
      {isCompleted && <FontAwesomeIcon icon='check' />}
      <FontAwesomeIcon icon='check' style={{ color: isCompleted ? undefined : '#B0DCEB' }} />
    </div>
  );
};

export default CompleteBtn;
